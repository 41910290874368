import Http from '@/plugins/Http'

export default {
    async listInstallers ({}, params) 
    {
        try {
            const { data } = await Http.get('/installer', { params })
            return data
        } catch (e) {
            return false            
        }
    },

    async listInstallersToBudget ({}, params)
    {
        try {
            const { data } = await Http.get('/listInstallersToBudget', { params })
            return data
        } catch (e) {
            return false
        }
    },
    
    
    async insertInstallers ({}, installer) 
    {
        try {
            await Http.post('/installer', installer)
            return true
        } catch (e) {
            return false
        }
    },
    
    async showInstallers ({}, id) {
        try {
            const { data } = await Http.get(`/installer/${id}`)
            return data
        } catch (e) {
            return false
        }
    },

    async updateInstallers ({}, installer) {
        try {
            await Http.put('/installer/' + installer.id, installer)
            return true
        } catch (e) {
            return false
        }
    },

    async updateApproveInstaller ({}, installer) {
        try {
            await Http.put('/installer-approve', installer)
            return true
        } catch (e) {
            return false
        }
    },
    

    async deleteInstallers ({}, id) {
        try {
            await Http.delete('/installer/' + id)
            return true
        } catch (e) {
            return false
        }
    },

    async deleteManyInstallers ({}, ids) {
        try {
            await Http.delete('/installer/delete/many', {
                data: ids
            })
            return true
        } catch (e) {
            return false
        }
    },
}
