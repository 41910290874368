<template>
    <div>
        <v-row no-gutters class="mt-10 mb-8">
            <BreadcrumbsComponent title="Avaliações" :items="breadcrumbs" />
        </v-row>

        <v-row v-if="isLoading">
            <v-col
                md="12"
                class="text-center"
            >
                <v-progress-circular
                    indeterminate
                ></v-progress-circular>
            </v-col>
        </v-row>
        <v-row v-else-if="avaliation">
            <v-col cols="12" md="8">
                <v-row>
                    <v-col cols="12" md="12">
                        <v-text-field disabled label="Pedido" placeholder="Pedido" outlined v-model="avaliation.order.custom_id"></v-text-field>
                    </v-col>

                    <v-col cols="12" md="12">
                        <v-text-field disabled label="Instalador" placeholder="Instalador" outlined persistent-hint v-model="avaliation.installer.company_name"></v-text-field>
                    </v-col>

                    <v-col cols="12" md="12">
                        <v-text-field disabled label="Cliente" placeholder="Cliente" outlined persistent-hint v-model="avaliation.client.user.username"></v-text-field>
                    </v-col>

                    <v-col cols="12">
                        <span class="grey--text">Avaliação</span>
                        <v-rating v-model="avaliation.rate" background-color="grey" color="grey" icon-label="custom icon label text {0} of {1}"></v-rating>
                    </v-col>

                    <v-col cols="12">
                        <v-textarea v-model="avaliation.description" outlined></v-textarea>
                    </v-col>

                    <v-col cols="12" md="12">
                        <v-btn 
                            elevation="2" 
                            large 
                            block 
                            class="white--text grey darken-3 elevation-0" 
                            :loading="isLoadingUpdate" 
                            :disabled="isLoadingUpdate"
                            @click="onUpdate"
                        >Atualizar</v-btn>
                    </v-col>

                    <v-col cols="12" md="12">
                        <v-btn large block text  @click="$router.push('/avaliations')">Cancelar</v-btn>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import BreadcrumbsComponent from "@/components/BreadcrumbsComponent/Index"

    import { mapActions } from 'vuex'
    
    export default {
        components: {
            BreadcrumbsComponent
        },
        data: () => {
            return {
                rating:2,
                breadcrumbs: [
                    {
                        text: 'Dashboard'
                    },
                    {
                        text: 'Avaliações',
                        disabled: true
                    },
                    {
                        text: 'Atualizar',
                        disabled: true
                    }
                ],
                avaliation: null,
                isLoading: true,
                isLoadingUpdate: false
                   
            }
        },
        async mounted()
        {
            const r = await this.showAvaliations(this.$route.params.id)
            if (r) {
                this.avaliation = r
            }
            this.isLoading = false
        },
        
        methods: {
            ...mapActions(['showAvaliations', 'updateAvaliations']),


            async onUpdate () {
                this.isLoadingUpdate = true;

                const r = await this.updateAvaliations({
                    description: this.avaliation.description,
                    rate: this.avaliation.rate,
                    id: this.avaliation.id,
                })

                if (r) {
                    this.$eventHub.$emit("snackBar", {
                        color: "success",
                        message: "Avaliações aprovada com sucesso",
                    });
                    this.$router.push("/avaliations");
                } else {
                    this.$eventHub.$emit("snackBar", {
                        color: "error",
                        message: "Erro ao aprovar avaliação",
                    });
                }

                this.isLoadingUpdate = false;
            },
        }
    }
</script>