<template>
    
    
    <div>
        <v-row v-if="isLoading">
            <v-col md="12" class="text-center">
                <v-progress-circular indeterminate></v-progress-circular>
            </v-col>
        </v-row>

        <v-row v-else>
            <v-dialog v-model="dialog" persistent transition="dialog-top-transition" max-width="600">
                <v-card>
                    <v-card-text class="pt-4" v-if="budgetInserted">
                        <svg class="d-block mx-auto" width="147" height="147" viewBox="0 0 147 147" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M73.5 137.813C86.2198 137.813 98.654 134.041 109.23 126.974C119.806 119.907 128.049 109.863 132.917 98.1113C137.785 86.3598 139.058 73.4287 136.577 60.9533C134.095 48.4779 127.97 37.0185 118.976 28.0242C109.982 19.03 98.5221 12.9048 86.0467 10.4233C73.5713 7.94176 60.6402 9.21537 48.8887 14.083C37.1371 18.9507 27.0929 27.1938 20.0261 37.7699C12.9594 48.3461 9.1875 60.7802 9.1875 73.5C9.20663 90.5509 15.9885 106.898 28.0453 118.955C40.1021 131.011 56.4491 137.793 73.5 137.813ZM73.5 15.3125C85.0084 15.3125 96.2584 18.7252 105.827 25.1189C115.396 31.5126 122.854 40.6003 127.258 51.2326C131.662 61.865 132.815 73.5646 130.569 84.8518C128.324 96.1391 122.782 106.507 114.645 114.645C106.507 122.782 96.1391 128.324 84.8518 130.569C73.5646 132.815 61.865 131.662 51.2326 127.258C40.6002 122.854 31.5126 115.396 25.1189 105.827C18.7251 96.2584 15.3125 85.0084 15.3125 73.5C15.33 58.0731 21.4661 43.2831 32.3746 32.3746C43.2831 21.4661 58.0731 15.3301 73.5 15.3125ZM52.9597 75.6653C52.4018 75.0877 52.0931 74.3141 52.1001 73.5112C52.1071 72.7082 52.4292 71.9401 52.997 71.3723C53.5648 70.8045 54.3329 70.4824 55.1359 70.4754C55.9389 70.4684 56.7125 70.7771 57.2901 71.335L67.375 81.4196L89.7097 59.0849C90.2873 58.527 91.0609 58.2184 91.8639 58.2253C92.6668 58.2323 93.4349 58.5544 94.0028 59.1222C94.5706 59.69 94.8927 60.4581 94.8996 61.2611C94.9066 62.0641 94.5979 62.8377 94.0401 63.4153L69.5401 87.9153C69.2558 88.1997 68.9182 88.4253 68.5467 88.5792C68.1752 88.7331 67.777 88.8123 67.3749 88.8123C66.9727 88.8123 66.5746 88.7331 66.203 88.5792C65.8315 88.4253 65.494 88.1997 65.2097 87.9153L52.9597 75.6653Z" fill="#FFA900" stroke="#FFA900" stroke-width="0.5"/> </svg>
                        <h1 class="text-center mt-5 text-h6" >Maravilha, orçamento enviado!</h1>
                        <span style="width:330px" class="d-block mx-auto text-center mb-5 caption">O cliente recebeu o seu orçamento, você pode consultar o status na aba ”Orçamentos".</span>
                        <v-btn style="width:330px" @click="$router.push('/orders/view/' + $route.params.id)" small class="orange white--text d-block mx-auto mt-10">Ok</v-btn>
                    </v-card-text>
                </v-card>
            </v-dialog>

            <v-col cols="12" md="8" class="mx-auto" style="max-width: 664px;">
                <form> <!-- @keydown.enter="isLoadingInsert == false && onInsert()" -->
                    <h2 class="mb-10 mt-8 text-h4 font-weight-medium">Enviar orçamento   <v-chip color="#BFBEC6" dark>{{ installer.company_name }}</v-chip></h2>

                    <v-card class="pa-5 contentCard">
                        <h1 class="title">Anexar proposta</h1>
                        <span class="titleDescription">Anexe a sua proposta de orçamento completa em PDF.</span>

                        <DropzoneComponent v-if="!file" @onOver="onOver" @onLeave="onLeave" bgColor="transparent" class="mt-5 pa-5">
                            <template v-slot:content>
                                <div class="text-center"><svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M22.9004 22C22.9004 22.0656 22.8875 22.1307 22.8624 22.1914C22.8373 22.252 22.8005 22.3072 22.754 22.3536C22.7076 22.4 22.6525 22.4369 22.5918 22.462C22.5311 22.4871 22.4661 22.5 22.4004 22.5H2.40039C2.26778 22.5 2.14061 22.4473 2.04684 22.3535C1.95307 22.2598 1.90039 22.1326 1.90039 22C1.90039 21.8674 1.95307 21.7402 2.04684 21.6464C2.14061 21.5527 2.26778 21.5 2.40039 21.5H22.4004C22.4661 21.4999 22.5311 21.5128 22.5918 21.538C22.6525 21.5631 22.7076 21.5999 22.754 21.6463C22.8005 21.6928 22.8373 21.7479 22.8624 21.8086C22.8875 21.8693 22.9004 21.9343 22.9004 22ZM12.4004 16.5C12.4661 16.5 12.5311 16.4871 12.5918 16.462C12.6525 16.4369 12.7076 16.4 12.754 16.3536C12.8005 16.3072 12.8373 16.252 12.8624 16.1914C12.8875 16.1307 12.9004 16.0656 12.9004 16V3.20697L17.0469 7.35346C17.1412 7.44454 17.2675 7.49494 17.3986 7.4938C17.5297 7.49266 17.6551 7.44008 17.7478 7.34737C17.8405 7.25467 17.8931 7.12926 17.8942 6.99816C17.8953 6.86707 17.8449 6.74077 17.7539 6.64646L12.7539 1.64646C12.7075 1.60003 12.6523 1.5632 12.5917 1.53807C12.531 1.51293 12.466 1.5 12.4004 1.5C12.3347 1.5 12.2697 1.51293 12.2091 1.53807C12.1484 1.5632 12.0933 1.60003 12.0469 1.64646L7.04687 6.64646C6.95579 6.74077 6.90539 6.86707 6.90653 6.99816C6.90767 7.12926 6.96026 7.25467 7.05296 7.34737C7.14567 7.44008 7.27107 7.49266 7.40217 7.4938C7.53327 7.49494 7.65957 7.44454 7.75387 7.35346L11.9004 3.20697V16C11.9004 16.0656 11.9133 16.1307 11.9384 16.1914C11.9635 16.252 12.0003 16.3072 12.0468 16.3536C12.0932 16.4 12.1483 16.4369 12.209 16.462C12.2697 16.4871 12.3347 16.5 12.4004 16.5Z" fill="#6E6B7B" stroke="#6E6B7B" stroke-width="0.5"/> </svg></div>
                                <h3 class="mt-3 mb-0 dropzone-custom-title font-weight-light text-center caption grey--text text--darken-1">Clique ou arraste para anexar</h3>                                
                            </template>
                        </DropzoneComponent>

                        <span class="d-block mt-8" v-else>
                            {{ file.name }}
                            <v-icon @click="file=null; errorFile=false">close</v-icon>
                        </span>

                        <div class="caption text-center mt-6" v-if="!errorFile"><strong>Atenção! </strong> Somente é permitido arquivos em formato PDF.</div>
                        <div class="caption text-center mt-6" v-else style="color: #F50058;">
                            <p v-if="errorFile=='ext'">Tipo de arquivo não suportado. Anexe apenas <strong>PDF</strong> ou <strong>DOC</strong>.</p>
                            <p v-else-if="errorFile=='size'">Erro no upload. O tamanho máximo aceito por arquivo é de <strong>5 MB</strong>.</p>
                        </div>
                    </v-card>

                    <v-card class="pa-5 mt-5 contentCard">
                        <h1 class="title">Valores da obra</h1>
                        <span class="titleDescription">Insira o valor total da obra e a capacidade de geração de energia.</span>

                        <vuetify-money style="width: 341px;" id="txt_val" v-model="total" v-bind="moneyMask" persistent-hint />


                        <vuetify-money
                            style="width:341px;padding-left: 10px;"
                            class="input_defualt"
                            label="Capacidade de Geração"
                            v-model="kwh"
                            @keypress="isNumber($event)"
                            suffix="kWh"
                            :rules="rules.inputInteger"
                            @blur="validate"
                            @focus="clearRules"
                            v-bind="numberMask"
                            v-bind:options="numberMask.options"
                        />
                        
                        
                        
                    </v-card>

                    <v-card class="pa-5 mt-8 contentCard">
                        <!--<v-btn v-if="productsUsed.length > 1" @click="removeProduct(i)" small fab dark color="indigo" class="d-block mx-auto" style="margin-top:-35px"> <v-icon dark> mdi-trash-can-outline </v-icon> </v-btn>-->

                        <h1 class="title">Módulos</h1>
                        <span class="titleDescription">Selecione os módulos que estão contemplados na proposta de orçamento.</span>

                        <!--<v-select
                            :items="products"
                            item-text="name"
                            item-value="id"
                            label="Selecione o produto"
                            v-model="productsNecessary[0].product"
                            @change="selectedProduct(0, productsNecessary[0])"
                            return-object
                            disabled
                        ></v-select>-->
                        
                        
                        
                        <v-select 
                            :items="productsNecessary[0].typesSelect" 
                            item-text="name"
                            label="Tipo de módulo"
                            v-model="productsNecessary[0].type_product"
                            return-object
                            style="font-size: 18px;"
                            class="input_defualt"
                            :error-messages="errorData && !productsNecessary[0].type_product ? ['O preenchimento deste campo é obrigatório']: []"
                            :rules="rules.select" 
                            @blur="validate" 
                            @focus="clearRules"
                        ></v-select>


                        <v-combobox
                            :items="productsNecessary[0].brandsSelect"
                            item-text="trading_name"
                            label="Marca"
                            v-model="productsNecessary[0].brand"
                            return-object
                            :search-input.sync="productsNecessary[0].searchBrand"
                            hide-selected
                            :hint="productsNecessary[0].brand && typeof productsNecessary[0].brand == 'string' ? 'Esta marca não é homologada pela Market Solar' : ''"
                            persistent-hint
                            style="font-size: 18px;"
                            class="input_defualt"
                            :error-messages="errorData && !productsNecessary[0].brand ? ['O preenchimento deste campo é obrigatório']: []"
                            :rules="rules.select" 
                            @blur="validate" 
                            @focus="clearRules"
                        >
                            <template v-slot:no-data v-if="productsNecessary[0].brandsSelect.length > 0">
                                <v-list-item>
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            Pressione <kbd>enter</kbd> para criar "<strong>{{ productsNecessary[0].searchBrand }}</strong>"
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </template>
                        </v-combobox>
                        



                        <vuetify-money
                            v-if="!(productsNecessary[0].product && productsNecessary[0].product.id == 5)"
                            :suffix="'Wp'"
                            label="Potência"
                            v-model="productsNecessary[0].potency"
                            @keypress="isNumber($event)"
                            type="text"
                            style="font-size: 18px;"
                            class="input_defualt"
                            :error-messages="errorData && !productsNecessary[0].potency ? ['O preenchimento deste campo é obrigatório']: []"
                            :rules="rules.inputInteger"
                            @blur="validate"
                            @focus="clearRules"
                            v-bind="numberMask" 
                            v-bind:options="numberMask.options1"
                            persistent-hint 
                        
                        />

                        <div class="d-flex">
                            <v-text-field 
                                label="Quantidade" 
                                v-model="productsNecessary[0].quantity" 
                                style="font-size: 18px;" 
                                class="input_defualt"
                                :error-messages="errorData && !productsNecessary[0].quantity ? ['O preenchimento deste campo é obrigatório']: []"
                                :rules="rules.inputInteger" 
                                @blur="validate" 
                                @focus="clearRules"
                            ></v-text-field>    
                            <v-text-field 
                                label="Garantia" 
                                class="ml-3 input_defualt" 
                                v-model="productsNecessary[0].assurance" 
                                :suffix="'anos'" 
                                style="font-size: 18px;"
                                :error-messages="errorData && !productsNecessary[0].assurance ? ['O preenchimento deste campo é obrigatório']: []"
                                :rules="rules.inputInteger" 
                                @blur="validate" 
                                @focus="clearRules"
                            ></v-text-field> 
                        </div>
                    </v-card>

                    <v-card class="pa-5 mt-8 contentCard">
                        <h1 class="title">Inversor</h1>
                        <span class="titleDescription">Selecione o inversor que está contemplado na proposta de orçamento.</span>

                        <!--<v-select
                            :items="products"
                            item-text="name"
                            item-value="id"
                            label="Selecione o produto"
                            v-model="productsNecessary[1].product"
                            @change="selectedProduct(0, productsNecessary[1])"
                            return-object
                            disabled
                        ></v-select>-->
                        
                        
                        
                        <v-select 
                            :items="productsNecessary[1].typesSelect" 
                            item-text="name"
                            label="Tipo de inversor"
                            v-model="productsNecessary[1].type_product"
                            return-object
                            style="font-size: 18px;"
                            class="input_defualt"
                            :error-messages="errorData && !productsNecessary[1].type_product ? ['O preenchimento deste campo é obrigatório']: []"
                            :rules="rules.select" 
                            @blur="validate" 
                            @focus="clearRules"
                        ></v-select>


                        <v-combobox
                            :items="productsNecessary[1].brandsSelect"
                            item-text="trading_name"
                            label="Marca"
                            v-model="productsNecessary[1].brand"
                            return-object
                            :search-input.sync="productsNecessary[1].searchBrand"
                            hide-selected
                            :hint="productsNecessary[1].brand && typeof productsNecessary[1].brand == 'string' ? 'Esta marca não é homologada pela Market Solar' : ''"
                            persistent-hint
                            style="font-size: 18px;"
                            class="input_defualt"
                            :error-messages="errorData && !productsNecessary[1].brand ? ['O preenchimento deste campo é obrigatório']: []"
                            :rules="rules.select" 
                            @blur="validate" 
                            @focus="clearRules"
                        >
                            <template v-slot:no-data v-if="productsNecessary[1].brandsSelect.length > 0">
                                <v-list-item>
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            Pressione <kbd>enter</kbd> para criar "<strong>{{ productsNecessary[1].searchBrand }}</strong>"
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </template>
                        </v-combobox>
                        

                        <vuetify-money
                            v-if="!(productsNecessary[1].product && productsNecessary[1].product.id == 5)"
                            :suffix="'kW'"
                            label="Potência"
                            v-model="productsNecessary[1].potency"
                            @keypress="isNumber($event)"
                            type="text"
                            style="font-size: 18px;"
                            class="input_defualt"
                            :error-messages="errorData && !productsNecessary[1].potency ? ['O preenchimento deste campo é obrigatório']: []"
                            :rules="rules.inputInteger"
                            @blur="validate"
                            @focus="clearRules"
                            v-bind="numberMask" 
                            v-bind:options="numberMask.options2"
                            persistent-hint 
                        />
                        
                        
                        

                        <div class="d-flex">
                            <v-text-field 
                                label="Quantidade" 
                                v-model="productsNecessary[1].quantity" 
                                style="font-size: 18px;" 
                                class="input_defualt"
                                :error-messages="errorData && !productsNecessary[1].quantity ? ['O preenchimento deste campo é obrigatório']: []"
                                :rules="rules.inputInteger" 
                                @blur="validate" 
                                @focus="clearRules"
                            ></v-text-field>    
                            <v-text-field 
                                label="Garantia" 
                                class="ml-3 input_defualt" 
                                v-model="productsNecessary[1].assurance" 
                                :suffix="'anos'" 
                                style="font-size: 18px;"
                                :error-messages="errorData && !productsNecessary[1].assurance ? ['O preenchimento deste campo é obrigatório']: []"
                                :rules="rules.inputInteger" 
                                @blur="validate" 
                                @focus="clearRules"
                            ></v-text-field> 
                        </div>
                    </v-card>

                    <v-card class="pa-5 mt-8 contentCard" v-for="(p, i) in productsUsed" :key="i">
                        <v-btn v-if="productsUsed.length > 1" @click="removeProduct(i)" small fab dark color="indigo" class="d-block mx-auto" style="margin-top:-35px"> <v-icon dark> mdi-trash-can-outline </v-icon> </v-btn>

                        <h1 class="title">Estrutura</h1>
                        <span class="titleDescription">Selecione a estrutura que está contemplada na proposta de orçamento.</span>


                        <!--<v-select
                            :items="products"
                            item-text="name"
                            item-value="id"
                            label="Selecione o produto"
                            v-model="p.product"
                            @change="selectedProduct(i, p)"
                            return-object
                        ></v-select>-->



                        <v-select
                            :items="p.typesSelect"
                            item-text="name"
                            label="Tipo de estrutura"
                            v-model="p.type_product"
                            return-object
                            style="font-size: 18px;"
                            class="input_defualt"
                        ></v-select>


                        <v-combobox
                            :items="p.brandsSelect"
                            item-text="trading_name"
                            label="Marca"
                            v-model="p.brand"
                            return-object
                            :search-input.sync="p.searchBrand"
                            hide-selected
                            :hint="p.brand && typeof p.brand == 'string' ? 'Esta marca não é homologada pela Market Solar' : ''"
                            persistent-hint
                            style="font-size: 18px;"
                            class="input_defualt"
                        >
                            <template v-slot:no-data v-if="p.brandsSelect.length > 0">
                                <v-list-item>
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            Pressione <kbd>enter</kbd> para criar "<strong>{{ p.searchBrand }}</strong>"
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </template>
                        </v-combobox>


                        <!--<v-text-field
                            v-if="!(p.product && p.product.id == 5)"
                            :suffix="p.product ? p.product.id == 3 ? 'Wp' : 'W' : ''"
                            label="Qual é a potência"
                            v-model="p.potency"
                            @keypress="isNumber($event)"
                            type="text"
                        ></v-text-field>-->

                        <div class="d-flex">
                            <v-text-field label="Quantidade de painéis" v-model="p.quantity" style="font-size: 18px;" class="input_defualt"></v-text-field>
                            <v-text-field label="Garantia" class="ml-3 input_defualt" v-model="p.assurance" :suffix="'anos'" style="font-size: 18px;"></v-text-field>
                        </div>
                    </v-card>

                    <!--<v-card class="pa-3 mt-5 text-center mb-10 rounded-xl contentCard">
                        <v-btn @click="addProduct" small fab dark color="orange"> <v-icon dark> mdi-plus </v-icon> </v-btn>
                    </v-card>-->

                    <v-btn @click="addProduct" large block rounded color="primary" class="white--text mb-10 no-uppercase textButton" style="margin: 48px 0px;">
                        <v-icon dark style="position: absolute;left: 0px;"> mdi-plus </v-icon>
                        <span>Adicionar outra estrutura</span>
                    </v-btn>

                    <v-btn
                        :loading="isLoadingInsert"
                        :disabled="isLoadingInsert"
                        @click="onInsert"
                        large
                        block
                        class="orange white--text mb-10 no-uppercase textButton"
                        style="height: 96px; margin: 48px 0px;"
                    >Enviar orçamento</v-btn>
                </form>

            </v-col>
        </v-row>
    </div>
    
    
</template>


<script>

    import { mapActions } from 'vuex'

    import DropzoneComponent from "@/components/DropzoneComponent/Index"

    export default {
        components:{
            DropzoneComponent,
        },
        data()
        {
            return {
                numberMask:{
                    value: "0",
                    label: "",
                    placeholder: "",
                    readonly: false,
                    disabled: false,
                    outlined: false,
                    clearable: false,
                    valueWhenIsEmpty: "",
                    options: {
                        locale: "pt-BR",
                        prefix: "",
                        suffix: "kWh",
                        length: 11,
                        precision: 2
                    },
                    options1: {
                        locale: "pt-BR",
                        prefix: "",
                        suffix: "Wp",
                        length: 11,
                        precision: 2
                    },
                    options2: {
                        locale: "pt-BR",
                        prefix: "",
                        suffix: "kW",
                        length: 11,
                        precision: 2
                    },
                    properties: {
                        hint: "Apenas números",
                        outlined: true
                    }
                },
                errorData: false,
                rules: [],
                isLoading: true,
                Editor:null,
                searchBrand: null,
                productsUsed:[
                    {
                        brand: null,
                        product: null,
                        quantity: 1,
                        assurance: 1,
                        type_product_id: null,
                        potency: null,



                        typesSelect: [],
                        brandsSelect: [],
                        searchBrand: null
                    }
                ],
                productsNecessary:[
                    {
                        brand: null,
                        product: null,
                        quantity: 1,
                        assurance: 1,
                        type_product_id: null,
                        potency: null,
                        
                        typesSelect: [],
                        brandsSelect: [],
                        searchBrand: null
                    },
                    {
                        brand: null,
                        product: null,
                        quantity: 1,
                        assurance: 1,
                        type_product_id: null,
                        potency: null,
                        
                        typesSelect: [],
                        brandsSelect: [],
                        searchBrand: null
                    }
                ],
                total: 0,
                kwh: null,
                dialog:false,
                products: [],

                moneyMask:{
                    value: "00.00",
                    label: "Valor da Proposta",
                    placeholder: "",
                    readonly: false,
                    disabled: false,
                    outlined: false,
                    clearable: false,
                    valueWhenIsEmpty: "",
                    options: {
                        locale: "pt-BR",
                        prefix: "R$",
                        suffix: "",
                        length: 11,
                        precision: 2
                    },
                    properties: {
                        hint: "Apenas números",
                        solo:false
                    }
                },

                budgetInserted: null,
                isLoadingInsert: false,
                file: null,
                errorFile: false,
                installer: null,
                count: 0
            }
        },
        methods:{
            ...mapActions(['getAllWithProducts', 'insertBudgetInstaller', 'showOrder']),
            onOver(){},
            async onLeave( file )
            {
                console.log(file)
                this.file = file

                let extPermitidas = ['pdf', 'doc'];
                let extArquivo = file.name.split('.').pop();
                if(typeof extPermitidas.find(function(ext){ return extArquivo == ext; }) == 'undefined') {
                    this.errorFile = 'ext';
                } else if(file.size > 5242880){
                    this.errorFile = 'size';
                } else {
                    this.errorFile = false;
                }
            },


            selectedProduct (i, p) {
                this.productsUsed[i].brand = null
                this.productsUsed[i].type_product_id = null
                this.productsUsed[i].typesSelect = p.product.types
                this.productsUsed[i].brandsSelect = p.product.brands
            },

            selectedProductNecessary (i, p) {
                this.productsNecessary[i].brand = null
                this.productsNecessary[i].type_product_id = null
                this.productsNecessary[i].typesSelect = p.product.types
                this.productsNecessary[i].brandsSelect = p.product.brands
            },


            toBase64(file) {
                return new Promise((resolve, reject) => {
                    const reader = new FileReader()
                    reader.readAsDataURL(file)
                    reader.onload = () => resolve(reader.result)
                    reader.onerror = error => reject(error)
                })
            },

            removeProduct (index) {
                if (this.productsUsed.length == 1) return
                this.count--;
                this.productsUsed = this.productsUsed.filter(function(item, i) {
                    return i !== index
                })
            },

            addProduct () {
                this.productsUsed.push({
                    brand: null,
                    product: null,
                    quantity: 1,
                    assurance: 1,
                    type_product: null,
                    potency: null,


                    searchBrand: null,
                    typesSelect: [],
                    brandsSelect: []
                })

                this.productsUsed[this.count].product = this.products[2]
                this.selectedProduct(this.count, this.productsUsed[this.count])
                this.count++;
            },

            async onInsert () {
                this.isLoadingInsert = true
                this.errorData = false

                if(!this.file || this.errorFile){
                    this.$eventHub.$emit('snackBar', {color: 'error', message: 'Para continuar, você deve anexar uma proposta.'})
                    this.isLoadingInsert = false
                    return
                }

                let productsUsed = []

                for (let p of this.productsNecessary) {
                    let obj = {}
                    
                    if (p.brand) {

                        if (typeof p.brand == 'string') {
                            obj.custom_brand = p.brand    
                        } else {
                            obj.brand_id = p.brand.id
                        }
                     
                        
                    } else {
                        this.errorData = true
                        this.$eventHub.$emit('snackBar', {color: 'error', message: 'Verifique a seleção de todas as marcas'})
                        this.isLoadingInsert = false
                        return
                    }
                    
                    if (p.product) {
                        obj.product_id = p.product.id
                    } else {
                        this.errorData = true
                        this.$eventHub.$emit('snackBar', {color: 'error', message: 'Verifique a seleção de todos os produtos'})
                        this.isLoadingInsert = false
                        return
                    }
                    
                    if (p.type_product) {
                        obj.type_product = p.type_product.id
                    } else {
                        this.errorData = true
                        this.$eventHub.$emit('snackBar', {color: 'error', message: 'Verifique a seleção de todos os tipos'})
                        this.isLoadingInsert = false
                        return
                    }
                    
                    obj.assurance = p.assurance
                    obj.quantity = p.quantity
                    obj.potency = p.potency
                    
                    productsUsed.push(obj)
                }
                
                for (let p of this.productsUsed) {
                    let obj = {}

                    if (p.brand) {
                        if (typeof p.brand == 'string') {
                            obj.custom_brand = p.brand
                        } else {
                            obj.brand_id = p.brand.id
                        }
                    }

                    if (p.brand && p.type_product) {
                        obj.product_id = p.product.id
                    }

                    if (p.type_product) {
                        obj.type_product = p.type_product.id
                    }

                    obj.assurance = p.assurance
                    obj.quantity = p.quantity
                    obj.potency = p.potency

                    if(obj.brand_id && obj.product_id && obj.type_product){
                        productsUsed.push(obj)
                    }
                }

                //this.isLoadingInsert = true

                const budget = {
                    order_id: this.$route.params.id,
                    total: this.total,
                    kwh: this.kwh,
                    installer_id: this.$route.params.installer_id
                    //file: this.file
                }

                const form = new FormData()

                for ( let key in budget ) {
                    form.append(key, budget[key]);
                }

                for (let p of productsUsed) {
                    form.append('products[]', JSON.stringify(p));
                }


                if (this.file) {
                    form.append('file', this.file)
                }

                const r = await this.insertBudgetInstaller(form);

                if (r) {
                    this.$eventHub.$emit('snackBar', {color: 'success', message: 'Orçamento inserido com sucesso'})
                    this.budgetInserted = r
                    this.dialog = true
                } else {
                    this.$eventHub.$emit('snackBar', {color: 'error', message: 'Erro ao inserir orçamento'})
                }

                this.isLoadingInsert = false
            },


            isNumber: function (evt) {
                evt = evt ? evt : window.event;
                var charCode = evt.which ? evt.which : evt.keyCode;
                if (
                    charCode > 31 &&
                    (charCode < 48 || charCode > 57) &&
                    charCode !== 46
                ) {
                    evt.preventDefault();
                } else {
                    return true;
                }
            },

            validate(){
                this.rules = {
                    "inputInteger": [
                        v => !!v || "O preenchimento desse campo é obrigatório",
                        v => /^\,|.[0-9]*$/.test(v) || "Insira um valor decimal"
                    ],
                    "select": [
                        v => !!v || "O preenchimento desse campo é obrigatório"
                    ]}
            },

            clearRules(){
                this.rules = []
                this.errorData = false
            }
        },

        async mounted() {

            this.id = this.$route.params.id;
            this.isLoading = true;
            try {
                this.order = await this.showOrder(this.$route.params.id);
                
                
                if (this.order.budget_installers) {
                    for (let b of this.order.budget_installers) {
                        if (b.installer_id == this.$route.params.installer_id) {
                            this.$eventHub.$emit("snackBar", {
                                color: "error",
                                message: "Esse instalador já inseriu orçamento",
                            });

                            this.$router.push('/orders/view/' + this.$route.params.id)
                            
                            return
                        }
                    }
                }


                if (this.order.installers) {
                    let exists = false
                    for (let b of this.order.installers) {
                        if (b.id == this.$route.params.installer_id) {
                            this.installer = b
                            exists = true
                        }
                    }
                    
                    if (!exists) {
                        this.$eventHub.$emit("snackBar", {
                            color: "error",
                            message: "Esse instalador não faz parte dos orçamentos",
                        });

                        this.$router.push('/orders/view/' + this.$route.params.id)

                        return
                    }
                } else {
                    this.$eventHub.$emit("snackBar", {
                        color: "error",
                        message: "Esse instalador não faz parte dos orçamentos",
                    });

                    this.$router.push('/orders/view/' + this.$route.params.id)
                }

            } catch (error) {
                console.log('Erro', error)
                this.$eventHub.$emit("snackBar", {
                    color: "error",
                    message: "Erro ao recuperar pedido",
                });
            }
            
            this.isLoading = false
            
            const r = await this.getAllWithProducts()
            this.products = r
            console.log(r)

            this.productsNecessary[0].product = this.products[0]
            this.productsNecessary[1].product = this.products[1]
            this.productsUsed[this.count].product = this.products[2]
            
            this.selectedProductNecessary(0, this.productsNecessary[0])
            this.selectedProductNecessary(1, this.productsNecessary[1])
            this.selectedProduct(this.count, this.productsUsed[this.count])

            this.count++;
        }
    }
</script>

<style>
    main, .v-application--wrap main {
        background-color: #fafafa !important;
    }
</style>


<style scoped>


    .title {
        font-family: "cerapro-medium";
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 30px;
        letter-spacing: 0.0025em;
        text-align: left;
        color: #0066CC;
    }

    .titleDescription {
        font-family: "cerapro-medium";
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: left;
        color: #6E6B7B;
    }

    .contentCard {
        border: 1px solid #FAFAFA !important;
        box-sizing: border-box !important;
        box-shadow: 1px 2px 4px rgba(204, 212, 221, 0.4) !important;
    }

    .textButton {
        font-family: "cerapro-medium";
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 26px;
        letter-spacing: 0.03em;
        text-align: center;

    }

</style>

<style scoped>
    input{
        font-size: 18px;
    }

    .input_defualt{
        padding: 20px 0px 20px 0px !important;
        font-size: 18px !important;
    }

    .input_defualt > .v-input__control > .v-input__slot > .v-text-field__slot > input{
        padding: 20px 0px 20px 10px !important;
        font-size: 18px !important;
    }

    .v-select__slot>input{
        padding-left: 8px;
        margin-top: 5px !important;
    }

    .v-label{
        padding-left: 10px;
        font-size: 18px !important;
        margin-top: 0px;
        color: rgba(0,0,0,.6) !important;
    }

    .v-select__selections{
        margin-top: 5px;
    }

    .v-select__selection{
        padding-left: 10px;
    }

    .v-text-field__prefix{
        font-size: 18px;
        padding-left: 10px;
    }

    .v-text-field__suffix{
        font-size: 18px;
    }
</style>

