<template>
    <div>
        <v-row no-gutters class="mt-10 mb-8">
            <BreadcrumbsComponent title="Avaliações" :items="breadcrumbs">
                <template v-slot:actions>
                    <v-btn :disabled="isLoading" elevation="2" large class="white--text grey darken-3 mt-2 ml-2 elevation-0" @click="$router.push('/avaliations/edit/' + $route.params.id)">
                        <v-icon class="mr-2">mdi-square-edit-outline</v-icon>
                        Editar
                    </v-btn>

                    <v-btn
                        :disabled="isLoading"
                        elevation="2"
                        large
                        class="elevation-0 mt-2 ml-2"
                        @click="confirmationDialog = true"
                    >
                        <v-icon class="mr-2">mdi-trash-can-outline</v-icon>
                    </v-btn>
                </template>
            </BreadcrumbsComponent>
        </v-row>

        <v-row v-if="isLoading">
            <v-col
                md="12"
                class="text-center"
            >
                <v-progress-circular
                    indeterminate
                ></v-progress-circular>
            </v-col>
        </v-row>

        <v-row v-else>
            <template v-if="avaliation && Object.keys(avaliation).length > 0">
                <v-col cols="12" v-if="avaliation.approved == null">
                    <ActionBarComponent>
                        <template v-slot:content>
                            <div class="d-flex justify-space-between align-center flex-wrap">
                                <span class="grey--text text--darken-1">Revisar avaliação</span>

                                <div>
                                    <v-btn elevation="2" large class="white--text grey darken-3 elevation-0" @click="onUpdateApprove(true)" :disabled="isLoadingApprove" :loading="isLoadingApprove">
                                        <v-icon class="mr-2">mdi-check</v-icon>
                                        Aprovar avaliação
                                    </v-btn>

                                    <v-btn elevation="2" large class="white--text grey lighten-1 ml-2 elevation-0" @click="onUpdateApprove(false)" :disabled="isLoadingApprove" :loading="isLoadingApprove">
                                        <v-icon class="mr-2">mdi-close</v-icon>
                                        Reprovar
                                    </v-btn>
                                </div>
                            </div>
                        </template>
                    </ActionBarComponent>
                </v-col>

                <v-col cols="12" md="9">
                    <DataListComponent >
                        <template v-slot:item>
                            <template v-for="(item, index) in items">
                                <v-divider v-if="index == 0" :key="index"></v-divider>

                                <v-list-item :key="item.title">
                                    <v-col cols="12">
                                        <v-list-item-content class="d-block">
                                            <v-list-item-title :class="`text-caption text--darken-2 font-weight-medium ${item.class} border-clear`" v-text="item.title"></v-list-item-title>
                                            <v-rating readonly v-if="item.rate" v-model="avaliation.rate" background-color="grey" color="grey" icon-label="custom icon label text {0} of {1}"></v-rating>
                                            <v-list-item-subtitle v-else :class="`text-body-2 text--darken-2 font-weight-regular ${item.class}`" v-html="item.value"></v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-col>
                                </v-list-item>

                                <v-divider v-if="index < items.length - 1" :key="item.value"></v-divider>
                            </template>
                        </template>
                    </DataListComponent>
                </v-col>

                <v-col cols="12" md="3">
                    <DetailsPanelComponent title="VEJA TAMBÉM" :items="details"/>
                </v-col>


                <v-dialog v-model="confirmationDialog" transition="dialog-top-transition" max-width="600" persistent>
                    <v-card class="pa-5" v-if="avaliation">
                        <v-card-text class="pt-4">
                            <h1 class="text-center mb-4">Deseja excluir esta avaliação?</h1>
                            <span class="d-block text-center mt-4 mb-5">Importante: essa ação não poderá ser desfeita.</span>

                            <v-btn
                                :disabled="isLoadingDelete"
                                :loading="isLoadingDelete"
                                elevation="2"
                                large
                                block
                                text
                                class="white--text grey darken-3 mt-5 elevation-0 mx-auto"
                                @click="onDelete"
                            >
                                Sim, confirmo a exclusão
                            </v-btn>
                            <v-btn :disabled="isLoadingDelete" large block text class="mt-3" @click="confirmationDialog=false">Cancelar</v-btn>
                        </v-card-text>
                    </v-card>
                </v-dialog>
            </template>

            <template v-else>
                <p>Erro ao carregar avaliação</p>
            </template>
        </v-row>
    </div>
</template>


<script>
    import { mapActions } from 'vuex'

    import BreadcrumbsComponent from "@/components/BreadcrumbsComponent/Index"
    import DataListComponent from "@/components/DataListComponent/Index"
    import DetailsPanelComponent from "@/components/DetailsPanelComponent/Index"
    import ActionBarComponent from '@/components/ActionBarComponent/Index'
    
    import dayjs from 'dayjs'

    export default {
        components: {
            BreadcrumbsComponent,
            DataListComponent,
            DetailsPanelComponent,
            ActionBarComponent
        },
        data: () => {
            return {
                rating:3,
                confirmationDialog: false,
                title:'MAIS DESSA AVALIAÇÃO',
                color:'#1E88E5',
                borderColor:'#1E88E5',
                details:[
                    {
                        name: 'Perfil do usuário',
                        data: 'profile',
                    },
                    {
                        name: 'Perfil do instalador',
                        data: 'installers',
                    },
                    {
                        name: 'istórico de pedido avaliado',
                        data: 'orders',
                    }
                ],
                value:true,
                breadcrumbs: [
                    {
                        text: 'Dashboard'
                    },
                    {
                        text: 'Avaliações',
                    },
                    {
                        text: 'Visualizar',
                        disabled: true
                    }
                ],
                items: [],
                avaliation: null,
                isLoading: false,
                isLoadingDelete: false,
                isLoadingApprove: false
            }
        },
        methods: {
            ...mapActions(['showAvaliations', 'deleteAvaliations', 'updateAvaliations']),


            async onUpdateApprove(status) {
                this.isLoadingApprove = true;

                const r = await this.updateAvaliations({
                    approved: status,
                    id: this.avaliation.id,
                });

                if (r) {
                    this.$eventHub.$emit("snackBar", {
                        color: "success",
                        message: "Avaliações aprovada com sucesso",
                    });
                    this.$router.push("/avaliations");
                } else {
                    this.$eventHub.$emit("snackBar", {
                        color: "error",
                        message: "Erro ao aprovar avaliação",
                    });
                }

                this.isLoadingApprove = false;
            },

            async onDelete() {
                this.isLoadingDelete = true;

                const resp = await this.deleteAvaliations(this.avaliation.id);

                if (resp) {
                    this.$eventHub.$emit("snackBar", {
                        color: "success",
                        message: "Avaliação apagada com sucesso",
                    });
                    this.$router.push("/avaliations");
                } else {
                    this.$eventHub.$emit("snackBar", {
                        color: "error",
                        message: "Erro ao apagar avaliação",
                    });
                }

                this.isLoadingDelete = false;
            },
        },
        
        async mounted() {
            this.isLoading = true
            const r = await this.showAvaliations(this.$route.params.id)
            
            if (r) {
                this.avaliation = r
                
                this.items = [
                    {
                        title: 'ATUALIZADO EM',
                        class:'grey--text',
                        value: dayjs(r.updated_at).format('DD/MM/YYYY HH:mm:ss')
                    },
                    {
                        title: 'PEDIDO',
                        class:'blue--text',
                        value: r.order.custom_id,
                    },
                    {
                        title: 'INSTALADOR',
                        class:'grey--text',
                        value: r.installer.company_name,
                    },
                    {
                        title: 'CLIENTE',
                        class:'grey--text',
                        value: r.client.user.username,
                    },
                    {
                        title: 'AVALIAÇÃO',
                        class:'grey--text',
                        rate:true,
                        value: r.rate,
                    },
                    {
                        title: 'COMENTÁRIO',
                        class:'grey--text comment-box',
                        value: r.description
                    }
                ]
            }

            this.isLoading = false
        }
    }
</script>


<style scoped>
    .comment-box{
        border-left: 4px solid #E0E0E0;
        padding-left: 20px;
    }
    .border-clear{
        border:none !important;
        padding: 0px;
        margin-bottom:20px;
    }
</style>