import Http from '@/plugins/Http'

export default {
    async listBrands ({}, params = {}) {
        try {
            const { data } = await Http.get('/brand', {params})
            return data
        } catch (e) {
            return false
        }
    },

    async insertBrand ({}, brand) {
        try {
            await Http.post('/brand', brand)
            return true
        } catch (e) {
            return false
        }
    },


    async updatetBrand ({}, brand) {
        try {
            await Http.put('/brand/' + brand.id, brand)
            return true
        } catch (e) {
            return false
        }
    },
    
    
    async deleteBrand ({}, brandId) {
        return await Http.delete('/brand/' + brandId)
    },

    async getAllWithProducts ({}) {
        const { data } = await Http.get('/brand/list/getAllWithProducts')
        return data
    },
}
