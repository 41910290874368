var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"mt-10 mb-8",attrs:{"no-gutters":""}},[_c('BreadcrumbsComponent',{attrs:{"title":"Instalador","items":_vm.breadcrumbs},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-btn',{staticClass:"white--text grey darken-3 mt-2 ml-2 elevation-0",attrs:{"elevation":"2","large":""},on:{"click":function($event){return _vm.$router.push(("/installers/view/" + (_vm.$route.params.id)))}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-arrow-left")]),_vm._v(" Voltar ")],1)]},proxy:true}])})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"hide-default-footer":"","items-per-page":-1},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"blue--text"},[_vm._v(_vm._s(item.id))])]}},{key:"item.updatedat",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.__dayjs(item.updatedat).format('DD/MM/YYYY H:mm:ss'))+" ")]}},{key:"item.city",fn:function(ref){
var item = ref.item;
return [(!item.city)?_c('v-chip',{attrs:{"color":"grey lighten-3 grey--text","dark":""}},[_vm._v("-")]):_c('span',[_vm._v(_vm._s(item.city.name))])]}},{key:"item.client",fn:function(ref){
var item = ref.item;
return [(!item.client)?_c('v-chip',{attrs:{"color":"grey lighten-3 grey--text","dark":""}},[_vm._v("Anônimo")]):_c('span',[_vm._v(_vm._s(item.client.name))])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }