import Http from "@/plugins/Http";

export default {
    async listOrders({}, { status, page, clientId = "", csv = false }) {
        const { data } = await Http.get(
            `/orders${
                clientId
                    ? `${clientId}${status ? `&status=${status}` : ""}`
                    : `${status ? `?status=${status}` : ""}`
            }`
        );
        return data;
    },

    async listOrdersAll({}, params) {
        const { data } = await Http.get(`/orders`, { params })
        return data;
    },
    
    async showOrder({}, id) {
        const { data } = await Http.get(`/orders/${id}`);
        return data;
    },

    async showFileOrderPosts({}, id) {
        try {
            const { data } = await Http.get(`/orders-post/${id}/showFile`);
            return data;
        } catch (e) {
            return false;
        }
    },
    async insertOrder({}, order) {
        try {
            const { data } = await Http.post(`/orders`, order);
            return data;
        } catch (e) {
            return false;
        }
    },

    async insertInstallersFromOrder({}, order) {
        try {
            const { data } = await Http.post(`/insertInstallersFromOrder/${order.id}`, order);
            return data;
        } catch (e) {
            return false;
        }
    },
    
    
    
    
    async updateOrder({}, { order }) {
        const { data } = await Http.put(`/orders`, order);
        return data;
    },
    async deleteOrder({}, { id }) {
        const { data } = await Http.delete(`/orders/${id}`);
        return data;
    },
    async deleteManyOrder({}, { ids }) {
        const { data } = await Http.delete(`/orders/delete/many`, {
            data: {ids},
        });
        return data;
    },

    async insertPostStep({}, post) {
        try {
            const { data } = await Http.post(`/orders-post`, post);
            return data;
        } catch (e) {
            return false;
        }
    },

    async nextStepOrder({}, order_id) {
        try {
            const { data } = await Http.post(`/orders-next-step/${order_id}`);
            return data;
        } catch (e) {
            return false;
        }
    },

    async previousStepOrder({}, order_id) {
        try {
            const { data } = await Http.post(
                `/orders-previous-step/${order_id}`
            );
            return data;
        } catch (e) {
            return false;
        }
    },


    async selectInstallerBudget ({}, id) {
        try {
            const { data } = await Http.post(`/select-budget-installer/${id}`);
            console.log('data', data)
            return { 'status': true, data: data };
        } catch (e) {
            return false
        }

    },

    async sendFinishOrder ({}, order_id) {
        try {
            const { data } = await Http.post(`/orders-finish/${order_id}`);
            return data;
        } catch (e) {
            return false
        }
    },
    
    
    async getDocumentBudgetInstaller ({}, id) {
        try {
            const { data } = await Http.get(`/orders/${id}/budget-installers/showFile`);
            return data;
        } catch (e) {
            return false;
        }
    },


    async sendDeleteMessagePost ({}, id) {
        try {
            const { data } = await Http.delete(`/orders-post/${id}`);
            return data;
        } catch (e) {
            return false;
        }
    },


    async sendEditMessagePost ({}, item) {
        try {
            const { data } = await Http.put(`/orders-post/${item.id}`, item);
            return data;
        } catch (e) {
            return false;
        }
    },


    async insertBudgetInstaller({}, budget) {
        try {
            const { data } = await Http({
                method: "post",
                url: `/budget-installer`,
                headers:{ 'Content-Type': 'multipart/form-data' },
                data: budget
            })

            return data
        } catch (e) {
            console.log('AAA', e)
            return false
        }

    },
};
