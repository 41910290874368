import Http from '@/plugins/Http'

export default {
    async listClients ({}, params = {}) {
        try {
            const { data } = await Http.get('/client', {params})
            return data
        } catch (e) {
            return false
        }
    },

    async insertClient ({}, client) {
        try {
            const { data } = await Http.post('/client', client);
            return { status: true, data: data };
        } catch (e) {
            return { status: false };
        }
    },

    async showClient ({}, id) {
        try {
            const { data } = await Http.get(`/client/${id}`)
            return data
        } catch (e) {
            return false
        }
    },

    async updateClient ({}, client) {
        try {
            await Http.put('/client/' + client.id, client)
            return true
        } catch (e) {
            return false
        }
    },

    async deleteClient ({}, id) {
        try {
            await Http.delete('/client/' + id)
            return true
        } catch (e) {
            return false
        }
    },

    async deleteManyClient ({}, ids) {
        try {
            await Http.delete('/client/delete/many', {
                data: ids
            })
            return true
        } catch (e) {
            return false
        }
    },
}