<template>
    <div>
        <v-row no-gutters class="mt-10 mb-8">
            <BreadcrumbsComponent title="Distribuidores" :items="breadcrumbs" />
        </v-row>

        <form @keydown.enter="!isLoading && isValid && onSendInsert()">
            <v-row>
                <v-col cols="12" md="6">
                    <v-row>
                        <v-col cols="12" md="12">
                            <v-text-field
                                label="Nome"
                                placeholder="Nome"
                                outlined
                                v-model="distributors.name"
                                :rules='rules.nameRules'
                                @blur="validate" 
                                @focus="clearRules"
                            ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="12">
                            <v-text-field
                                label="E-mail"
                                placeholder="E-mail"
                                outlined
                                persistent-hint
                                v-model="distributors.email"
                                :rules='rules.emailRules'
                                @blur="validate" 
                                @focus="clearRules"
                            ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="12">
                            <v-text-field
                                v-mask="['##.###.###/####-##', '###.###.##-##']"
                                label="Cnpj"
                                placeholder="Cnpj"
                                outlined
                                persistent-hint
                                v-model="distributors.cnpj"
                                :rules='rules.cnpjRules'
                                @blur="validate" 
                                @focus="clearRules"
                            ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="12">
                            <v-text-field
                                label="DDD Celular"
                                placeholder="DDD Celular"
                                outlined
                                hint="Apenas números"
                                persistent-hint
                                v-model="distributors.phone"
                                v-mask="['(##) ####-####', '(##) #####-####']"
                                :rules='rules.phoneRules'
                                @blur="validate" 
                                @focus="clearRules"
                            ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="12">
                            <v-select
                                :items="optionsStates"
                                item-value="id"
                                item-text="name"
                                label="Estado"
                                outlined
                                v-model="distributors.state_id"
                                :rules='rules.stateRules'
                                @blur="validate" 
                                @focus="clearRules"
                            ></v-select>
                        </v-col>

                        <v-col cols="12" md="12">
                            <v-btn
                                elevation="2"
                                large
                                block
                                class="white--text grey darken-3 elevation-0"
                                @click="onSendInsert"
                                :disabled="isLoading || !isValid"
                                :loading="isLoading"
                                >Cadastrar</v-btn
                            >
                        </v-col>

                        <v-col cols="12" md="12">
                            <v-btn
                                :disabled="isLoading"
                                large
                                block
                                text
                                @click="$router.push('/distributors')"
                                >Cancelar</v-btn
                            >
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </form>
    </div>
</template>

<script>
import { mapActions } from "vuex";
import BreadcrumbsComponent from "@/components/BreadcrumbsComponent/Index";

export default {
    components: {
        BreadcrumbsComponent,
    },
    data: () => {
        return {
            rules: [],
            breadcrumbs: [
                {
                    text: "Dashboard",
                },
                {
                    text: "Distribuidores",
                    disabled: true,
                },
                {
                    text: "Cadastro",
                    disabled: true,
                },
            ],
            distributors: {
                name: "",
                email: "",
                cnpj: "",
                state_id: "",
                phone: "",
            },
            isLoading: false,
            optionsStates: [],
        };
    },
    methods: {
        ...mapActions(["insertDistributors", "getStates"]),
        async onSendInsert() {
            this.isLoading = true;
            const resp = await this.insertDistributors(this.distributors);

            if (resp) {
                this.$eventHub.$emit("snackBar", {
                    color: "success",
                    message: "Distribuidor cadastrado com sucesso",
                });
                this.distributors = {
                    name: "",
                    email: "",
                    cnpj: "",
                    state_id: "",
                    phone: "",
                };
            } else {
                this.$eventHub.$emit("snackBar", {
                    color: "error",
                    message: "Erro ao cadastrar distribuidor",
                });
            }
            this.isLoading = false;
        },
        
        validate() {
            this.rules = {
                "nameRules": [v => !!v || "O preenchimento deste campo é obrigatório"],
                "emailRules": [
                    v => !!v || "O preenchimento deste campo é obrigatório",
                    v => /.+@.+/.test(v) || "Insira um e-mail válido"
                ],
                "phoneRules": [
                    v => !!v || "O preenchimento deste campo é obrigatório",
                    v => v.length >= 14 || "Insira um telefone válido"
                ],
                "cnpjRules": [
                    v => !!v || "O preenchimento deste campo é obrigatório",
                    v => v.length == 18 || "Insira um CPF/CNPJ válido"
                ],
                "stateRules": [v => !!v || "O preenchimento deste campo é obrigatório"]
            }
        },

        clearRules(){
            this.rules = []
        }
    },
    computed: {
        isValid() {
            return !!(
                ["name", "email", "cnpj", "state_id", "phone"].every(
                    (key) => !!this.distributors[key]
                ) &&
                /^[A-Za-z0-9_\-\.+]+@[A-Za-z0-9_\-\.]{2,}\.[A-Za-z0-9]{2,}(\.[A-Za-z0-9])?/i.test(
                    this.distributors.email
                )
            );
        },
    },
    async mounted() {
        const states = await this.getStates();
        this.optionsStates = states;
    },
};
</script>
