<template>
    <div>
        <v-row no-gutters class="mt-10 mb-8">
            <BreadcrumbsComponent title="Tipos de produtos" :items="breadcrumbs">
                <template v-slot:actions>
                    <v-btn elevation="2" large color="secondary" class="white--text mt-2 ml-2 elevation-0" @click="$router.push('/products-types/add')">
                        <v-icon class="mr-2">mdi-plus</v-icon>
                        Adicionar novo
                    </v-btn>
                </template>
            </BreadcrumbsComponent>
        </v-row>

        <v-row no-gutters>
            <v-col cols="12" md="12">
                <FilterPanelComponent :show-content="false">
                    <template v-slot:result>
                        <v-chip class="ma-2" close color="grey darken-1" outlined> <span>Cliente:</span> <span class="ml-2">Logo Nunes Castanheda</span> </v-chip>
                    </template>
                </FilterPanelComponent>
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <v-data-table
                    :headers="headers"
                    :items="items"
                    hide-default-footer
                    :loading="isLoading"
                    :items-per-page="-1"
                >
                    <template v-slot:item.updatedat="{ item }">
                        <v-checkbox
                            v-model="checkbox"
                            :label="`${__dayjs(item.updatedat).format('DD/MM/YYYY H:mm:ss')}`"
                        ></v-checkbox>
                    </template>

                    <template v-slot:item.action="{ item }">
                        <v-icon >mdi-dots-horizontal</v-icon>
                    </template>
                </v-data-table>
            </v-col>

        </v-row>
    </div>
</template>

<script>
    import { mapActions } from 'vuex'
    import BreadcrumbsComponent from "@/components/BreadcrumbsComponent/Index"
    import FilterPanelComponent from "@/components/FilterPanelComponent/Index"

    import dayjs from 'dayjs'

    export default {
        components: {
            BreadcrumbsComponent, 
            FilterPanelComponent
        },
        data: () => {
            return {
                breadcrumbs: [
                    {
                        text: 'Dashboard'
                    },
                    {
                        text: 'tipos de produtos',
                        disabled: true
                    },
                    {
                        text: 'Lista',
                        disabled: true
                    }
                ],
                headers: [
                    {
                        text: 'ATUALIZADO EM',
                        align: 'start',
                        sortable: false,
                        value: 'updatedat',
                    },
                    {
                        text: 'Tipo',
                        align: 'start',
                        sortable: false,
                        value: 'name',
                    },
                    {
                        text: 'Produto',
                        align: 'start',
                        sortable: false,
                        value: 'product.name',
                    },
                    {
                        text: '',
                        align: 'start',
                        sortable: false,
                        value: 'action'
                    },
                ],
                items: [],
                isLoading: true
            }
        },
        methods: {
            ...mapActions(['listProductsTypes']),
            async load (page = 1) 
            {
                this.isLoading = true
                const productsTypes = await this.listProductsTypes({ page })
                this.items     = productsTypes?.data|| []
                this.isLoading = false
            },
            __dayjs (date) 
            {
                return dayjs(date)
            }
        },
        mounted() 
        {
            this.load()
        }
    }
</script>