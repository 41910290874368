<template>
    <div>
        <v-row no-gutters class="mt-10 mb-8">
            <BreadcrumbsComponent title="Clientes" :items="breadcrumbs">
                <template v-slot:actions>
                    <v-btn
                        :disabled="isLoading"
                        elevation="2"
                        large
                        class="white--text grey darken-3 mt-2 ml-2 elevation-0"
                        @click="$router.push('/clients/edit/' + id)"
                    >
                        <v-icon class="mr-2">mdi-square-edit-outline</v-icon>
                        Editar
                    </v-btn>

                    <v-btn 
                        elevation="2" 
                        large 
                        color="secondary" 
                        class="white--text mt-2 ml-2 elevation-0"
                        @click="$router.push({ path: '/simulations/add', query: { client_id: id }})"
                        v-if="getUser.user_type == 2"
                    >
                        <v-icon class="mr-2">mdi-plus</v-icon>
                        Adicionar simulação
                    </v-btn>

                    <v-btn
                        :disabled="isLoading"
                        elevation="2"
                        large
                        class="elevation-0 mt-2 ml-2"
                        @click="confirmationDialog = true"
                        v-if="false"
                    >
                        <v-icon class="mr-2">mdi-trash-can-outline</v-icon>
                    </v-btn>
                </template>
            </BreadcrumbsComponent>
        </v-row>

        <div>
            <v-row v-if="isLoading">
                <v-col md="12" class="text-center">
                    <v-progress-circular indeterminate></v-progress-circular>
                </v-col>
            </v-row>
            <v-row v-else>
                <template v-if="client && Object.keys(client).length > 0">
                    <v-col cols="12" md="9">
                        <DataListComponent>
                            <template v-slot:item>
                                <template v-for="(item, index) in items">
                                    <v-divider
                                        v-if="index == 0"
                                        :key="index"
                                    ></v-divider>

                                    <v-list-item :key="item.title">
                                        <v-col cols="12">
                                            <v-list-item-content
                                                class="d-block"
                                            >
                                                <v-list-item-title
                                                    :class="`text-caption text--darken-2 font-weight-medium ${item.class}`"
                                                    v-text="item.title"
                                                ></v-list-item-title>
                                                <v-list-item-subtitle
                                                    :class="`text-body-2 text--darken-2 font-weight-regular ${item.class}`"
                                                    v-text="item.value"
                                                ></v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-col>
                                    </v-list-item>

                                    <v-divider
                                        v-if="index < items.length - 1"
                                        :key="item.value"
                                    ></v-divider>
                                </template>
                            </template>
                        </DataListComponent>
                    </v-col>

                    <v-col cols="12" md="3">
                        <DetailsPanelComponent
                            :items="details"
                            @onClick="handleDetailsPanel($event)"
                        />
                    </v-col>

                    <v-dialog
                        v-model="confirmationDialog"
                        transition="dialog-top-transition"
                        max-width="600"
                        persistent
                    >
                        <v-card class="pa-5" v-if="client">
                            <v-card-text class="pt-4">
                                <h1 class="text-center mb-4">
                                    Deseja excluir este cliente?
                                </h1>
                                <span class="d-block text-center mt-4 mb-5"
                                    >Importante: essa ação não poderá ser
                                    desfeita.</span
                                >

                                <div class="mt-5 mb-5">
                                    <span class="d-block font-weight-medium"
                                        >Cliente</span
                                    >
                                    <v-divider></v-divider>
                                    <div class="mb-3">
                                        <span class="d-block">{{
                                            client.name
                                        }}</span>
                                        <span class="d-block">{{
                                            client.user
                                                ? client.user.email
                                                : "-"
                                        }}</span>
                                        <span class="d-block">{{
                                            client.cpf_cnpj
                                        }}</span>
                                    </div>
                                </div>

                                <v-btn
                                    :disabled="isLoadingDelete"
                                    :loading="isLoadingDelete"
                                    elevation="2"
                                    large
                                    block
                                    text
                                    class="white--text grey darken-3 mt-5 elevation-0 mx-auto"
                                    @click="onDelete"
                                >
                                    Sim, confirmo a exclusão
                                </v-btn>
                                <v-btn
                                    :disabled="isLoadingDelete"
                                    :loading="isLoadingDelete"
                                    large
                                    block
                                    text
                                    class="mt-3"
                                    @click="confirmationDialog = false"
                                    >Cancelar</v-btn
                                >
                            </v-card-text>
                        </v-card>
                    </v-dialog>
                </template>

                <template v-else>
                    <v-alert border="left" color="red" type="error"
                        >Erro ao carregar usuário</v-alert
                    >
                </template>
            </v-row>
        </div>
    </div>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";
import dayjs from "dayjs";

import BreadcrumbsComponent from "@/components/BreadcrumbsComponent/Index";
import DataListComponent from "@/components/DataListComponent/Index";
import DetailsPanelComponent from "@/components/DetailsPanelComponent/Index";

export default {
    components: {
        BreadcrumbsComponent,
        DataListComponent,
        DetailsPanelComponent,
    },
    data: () => {
        return {
            confirmationDialog: false,
            title: "MAIS DESSE CLIENTE",
            color: "#1E88E5",
            borderColor: "#1E88E5",
            deposition: null,
            details: [
                {
                    name: "Simulações",
                    data: "simulation",
                },
                {
                    name: "Falar com o consultor",
                    data: "talktoconsultant",
                },
                {
                    name: "Pedidos",
                    data: "order",
                },
            ],
            value: true,
            breadcrumbs: [
                {
                    text: "Dashboard",
                },
                {
                    text: "Clientes",
                },
                {
                    text: "Visualizar",
                    disabled: true,
                },
            ],
            items: [
                {
                    title: "ATUALIZADO EM",
                    class: "grey--text",
                    value: "07/06/2021 15:39",
                },
                {
                    title: "NOME",
                    class: "grey--text",
                    value: "Heloisa G Martins",
                },
                {
                    title: "OPT-IN",
                    class: "green--text",
                    value:
                        "Sim (Autorizo a Market Solar a entrar em contato por e-mail, celular ou whatsapp)",
                },
                {
                    title: "E-MAIL",
                    class: "grey--text",
                    value: "helo77@gmail.com",
                },
                {
                    title: "DDD CELULAR",
                    class: "grey--text",
                    value: "21 90900-2020",
                },
                {
                    title: "CPF/CNPJ",
                    class: "grey--text",
                    value: "64.948.875/0001-00",
                },
                {
                    title: "RAZÃO SOCIAL",
                    class: "grey--text",
                    value: "Lekko Administradora de Imóveis",
                },
                {
                    title: "ESTADO",
                    class: "grey--text",
                    value: "Rio de Janeiro",
                },
            ],

            client: null,
            id: null,
            isLoading: true,
            isLoadingDelete: false,
        };
    },

    methods: {
        ...mapActions([
            "listSimulations",
            "listBudgets",
            "listOrders",
            "showClient",
            "deleteClient",
        ]),

        async onDelete() {
            this.isLoadingDelete = true;

            const resp = await this.deleteClient(this.id);

            if (resp) {
                this.$eventHub.$emit("snackBar", {
                    color: "success",
                    message: "Cliente apagado com sucesso",
                });
                this.$router.push("/clients");
            } else {
                this.$eventHub.$emit("snackBar", {
                    color: "error",
                    message: "Erro ao apagar cliente",
                });
            }

            this.isLoadingDelete = false;
        },
        async handleDetailsPanel(value) {
            switch (value.data) {
                case "simulation":
                    this.$router.push(`/clients/simulations/${this.client.id}`);
                    break;

                case "talktoconsultant":
                    this.$router.push(`/clients/talkto/${this.client.id}`);
                    break;

                case "order":
                    const orderResp = await this.listOrders(
                        `?client_id=${this.client.id}`
                    );
                    this.$router.push(`/clients/orders/${this.client.id}`);
                    break;
            }
        },
    },

    async mounted() {
        const id = this.$route.params.id;
        this.id = id;
        this.isLoading = true;
        const client = await this.showClient(id);
        this.client = client;
        this.items[0].value = dayjs(client.updated_at).format(
            "DD/MM/YYYY H:mm:ss"
        );
        this.items[1].value = client.name;
        if (!client.opt_in) {
            this.items[2].value =
                "Não (Autorizo a Market Solar a entrar em contato por e-mail, celular ou whatsapp)";
            this.items[2].class = "red--text";
        }

        this.items[3].value = client.user ? client.user.email : "-";
        this.items[4].value = client.phone;
        this.items[5].value = client.cpf_cnpj;

        this.items[6].value = client.corporate_name
            ? client.corporate_name
            : "-";

        this.items[7].value = client.state ? client.state.name : "-";

        this.isLoading = false;
    },
    computed: {
        ...mapGetters(["getUser"]),
    }
};
</script>
