<template>
    <v-alert color="grey lighten-4" dark>
        <slot name="content"></slot>
    </v-alert>
</template>


<script>
    export default {
        name:'ActionBarComponent'
    }
</script>
