
import { ValidatorPrivateRoute, ValidatorRouteLogin } from '../utils/Auth'

import HomePage from '@/pages/HomePage.vue'
import ActivitiesPage from '@/pages/activities/Index.vue'

import LoginPage from '@/pages/LoginPage.vue'
import NotFound from '@/pages/NotFound.vue'
import RestorePassword from "@/pages/RestorePassword";
import FormRestorePassword from "@/pages/FormRestorePassword";


import IndexBrands from '@/pages/brands/Index'

//Clientes
import IndexClients from '@/pages/clients/Index'
import AddClients from '@/pages/clients/Add'
import ViewClient from '@/pages/clients/View'
import EditClient from '@/pages/clients/Edit'
import ClientSimulations from '@/pages/clients/Simulations'
import ClientOrders from '@/pages/clients/Orders'
import ClientTalkto from '@/pages/clients/TalkToConsultant'


//Administradores
import IndexAdministrators from '@/pages/administrators/Index'
import AddAdministrators from '@/pages/administrators/Add'
import UpdateAdministrators from '@/pages/administrators/Edit'
import ViewAdministrators from '@/pages/administrators/View'

//Distribuidores
import IndexDistributors from '@/pages/distributors/Index'
import AddDistributors from '@/pages/distributors/Add'
import ViewDistributors from '@/pages/distributors/View'

import UpdateDistributors from '@/pages/distributors/Edit'

//Products
import IndexProducts from '@/pages/products/Index'
import AddProduct from '@/pages/products/Add'
import UpdateProduct from '@/pages/products/Update'

import IndexProductsTypes from '@/pages/productsTypes/Index'
import AddProductTypes from '@/pages/productsTypes/Add'


//simulations
import IndexSimulations from '@/pages/simulations/Index'
import AddSimulations from '@/pages/simulations/Add'
import ViewSimulations from '@/pages/simulations/View'
import ViewSimulationsDetails from '@/pages/simulations/Result/Index'

//orders
import IndexOrders from '@/pages/orders/Index'
import ViewOrders from '@/pages/orders/View'

import InsertBudgetOrders from '@/pages/orders/InsertBudget'

//consultants
import IndexConsultants from '@/pages/consultants/Index'
import AddConsultants from '@/pages/consultants/Add'
import UpdateConsultants from '@/pages/consultants/Update'
import ViewConsultants from '@/pages/consultants/View'

//falar com consultor
import talkToConsultant from '@/pages/talkToConsultant/Index'
import talkToConsultantUpdate from '@/pages/talkToConsultant/Update'
import talkToConsultantOrder from '@/pages/talkToConsultant/Order'

import TrainingsPage from '@/pages/trainings/Index.vue'

import InsertTrainings from '@/pages/trainings/Add'

// Instaladores
import IndexInstallers from '@/pages/installers/Index'
import AddInstallers from '@/pages/installers/Add'
import ViewInstallers from '@/pages/installers/View'
import UpdateInstallers from '@/pages/installers/Edit'
import InstallersSimulations from '@/pages/installers/Simulations'


// Avaliations
import IndexAvaliations from '@/pages/avaliations/Index'
import ViewAvaliations from '@/pages/avaliations/View'
import UpdateAvaliations from '@/pages/avaliations/Edit'


export default {
    //mode: 'history',
    base: __dirname,
    routes: [
        { 
            path: '/',
            name:'home',
            component: HomePage,
            beforeEnter: ValidatorPrivateRoute
        },
        { 
            path: '/activities',
            name:'activities',
            component: ActivitiesPage,
            beforeEnter: ValidatorPrivateRoute
        },
        { 
            path: '/trainings',
            name:'trainings',
            component: TrainingsPage,
            beforeEnter: ValidatorPrivateRoute
        },
        {
            path: '/trainings/add',
            name:'trainings-add',
            component: InsertTrainings,
            beforeEnter: ValidatorPrivateRoute
        },
        { 
            path: '/login',
            name:'login',
            component: LoginPage,
            beforeEnter: ValidatorRouteLogin
        },
        {
            path: '/esqueci-senha',
            name:'esqueci-senha',
            component: RestorePassword,
            beforeEnter: ValidatorRouteLogin
        },
        
        {
            path: '/reset-password/:token',
            name:'reset-password',
            component: FormRestorePassword,
            beforeEnter: ValidatorRouteLogin
        },
        
        
        //Clientes
        {
            path: '/clients',
            name:'cliente-index',
            component: IndexClients,
            beforeEnter: ValidatorPrivateRoute
        },
        {
            path: '/clients/add',
            name:'cliente-add',
            component: AddClients,
            beforeEnter: ValidatorPrivateRoute
        },
        {
            path: '/clients/view/:id',
            name:'cliente-view',
            component: ViewClient,
            beforeEnter: ValidatorPrivateRoute
        },

        {
            path: '/clients/edit/:id',
            name:'cliente-edit',
            component: EditClient,
            beforeEnter: ValidatorPrivateRoute
        },

        {
            path: '/clients/simulations/:id',
            name:'clients-simulations',
            component: ClientSimulations,
            beforeEnter: ValidatorPrivateRoute
        },
        {
            path: '/clients/orders/:id',
            name:'clients-orders',
            component: ClientOrders,
            beforeEnter: ValidatorPrivateRoute
        },
        {
            path: '/clients/talkto/:id',
            name:'clients-talkto',
            component: ClientTalkto,
            beforeEnter: ValidatorPrivateRoute
        },


        //Administradores
        {
            path: '/administrators',
            name:'administrators-add',
            component: IndexAdministrators,
            beforeEnter: ValidatorPrivateRoute
        },
        {
            path: '/administrators/add',
            name:'administrators-index',
            component: AddAdministrators,
            beforeEnter: ValidatorPrivateRoute
        },
        {
            path: '/administrators/edit/:id',
            name:'administrators-update',
            component: UpdateAdministrators,
            beforeEnter: ValidatorPrivateRoute
        },
        {
            path: '/administrators/view/:id',
            name:'administrators-view',
            component: ViewAdministrators,
            beforeEnter: ValidatorPrivateRoute
        },
        

        //Instaladores
        {
            path: '/installers',
            name:'installers-add',
            component: IndexInstallers,
            beforeEnter: ValidatorPrivateRoute
        },
        {
            path: '/installers/add',
            name:'installers-index',
            component: AddInstallers,
            beforeEnter: ValidatorPrivateRoute
        },
        {
            path: '/installers/view/:id',
            name:'installers-view',
            component: ViewInstallers,
            beforeEnter: ValidatorPrivateRoute
        },

        {
            path: '/installers/edit/:id',
            name:'installers-edit',
            component: UpdateInstallers,
            beforeEnter: ValidatorPrivateRoute
        },
        {
            path: '/installers/simulations/:id',
            name:'installers-simulations',
            component: InstallersSimulations,
            beforeEnter: ValidatorPrivateRoute
        },
        
        //Avaliations
        {
            path: '/avaliations',
            name:'avaliations',
            component: IndexAvaliations,
            beforeEnter: ValidatorPrivateRoute
        },
        {
            path: '/avaliations/view/:id',
            name:'avaliations-view',
            component: ViewAvaliations,
            beforeEnter: ValidatorPrivateRoute
        },
        {
            path: '/avaliations/edit/:id',
            name:'avaliations-edit',
            component: UpdateAvaliations,
            beforeEnter: ValidatorPrivateRoute
        },


        //Distribuidores
        {
            path: '/distributors',
            name:'distributors-add',
            component: IndexDistributors,
            beforeEnter: ValidatorPrivateRoute
        },
        {
            path: '/distributors/add',
            name:'distributors-index',
            component: AddDistributors,
            beforeEnter: ValidatorPrivateRoute
        },
        {
            path: '/distributors/view/:id',
            name:'distributors-view',
            component: ViewDistributors,
            beforeEnter: ValidatorPrivateRoute
        },
        {
            path: '/distributors/edit/:id',
            name:'distributors-update',
            component: UpdateDistributors,
            beforeEnter: ValidatorPrivateRoute
        },
        
        
        //Products
        {
            path: '/products',
            name:'products-add',
            component: IndexProducts,
            beforeEnter: ValidatorPrivateRoute
        },
        {
            path: '/products/add',
            name:'products-index',
            component: AddProduct,
            beforeEnter: ValidatorPrivateRoute
        },

        {
            path: '/products/edit/:id',
            name:'products-add',
            component: UpdateProduct,
            beforeEnter: ValidatorPrivateRoute
        },


        {
            path: '/products-types',
            name:'products-add',
            component: IndexProductsTypes,
            beforeEnter: ValidatorPrivateRoute
        },
        {
            path: '/products-types/add',
            name:'products-types-index',
            component: AddProductTypes,
            beforeEnter: ValidatorPrivateRoute
        },


        //Simulations
        {
            path: '/simulations',
            name:'simulations-add',
            component: IndexSimulations,
            beforeEnter: ValidatorPrivateRoute
        },
        {
            path: '/simulations/add',
            name:'simulations-index',
            component: AddSimulations,
            beforeEnter: ValidatorPrivateRoute
        },
        {
            path: '/simulations/:id',
            name:'simulations-view',
            component: ViewSimulations,
            beforeEnter: ValidatorPrivateRoute
        },

        {
            path: '/simulations/:id/Details',
            name:'simulations-view-details',
            component: ViewSimulationsDetails,
            beforeEnter: ValidatorPrivateRoute
        },

        //Orders
        {
            path: '/orders',
            name:'orders-index',
            component: IndexOrders,
            beforeEnter: ValidatorPrivateRoute
        },
        {
            path: '/orders/view/:id',
            name:'orders-view',
            component: ViewOrders,
            beforeEnter: ValidatorPrivateRoute
        },
        {
            path: '/orders/view/:id/create-budget/:installer_id',
            name:'orders-view',
            component: InsertBudgetOrders,
            beforeEnter: ValidatorPrivateRoute
        },
        
        //consultants
        {
            path: '/consultants',
            name:'consultants-add',
            component: IndexConsultants,
            beforeEnter: ValidatorPrivateRoute
        },
        {
            path: '/consultants/add',
            name:'consultants-index',
            component: AddConsultants,
            beforeEnter: ValidatorPrivateRoute
        },
        {
            path: '/consultants/edit/:id',
            name:'consultants-update',
            component: UpdateConsultants,
            beforeEnter: ValidatorPrivateRoute
        },
        {
            path: '/consultants/view/:id',
            name:'consultants-view',
            component: ViewConsultants,
            beforeEnter: ValidatorPrivateRoute
        },
        
        //falar com consultor
        {
            path: '/talk-to-consultant',
            name:'talk-to-consultant',
            component: talkToConsultant,
            beforeEnter: ValidatorPrivateRoute
        },
        {
            path: '/talk-to-consultant/:id',
            name:'talk-to-consultant-update',
            component: talkToConsultantUpdate,
            beforeEnter: ValidatorPrivateRoute
        },
        {
            path: '/talk-to-consultant/:id/order',
            name:'talk-to-consultant-order',
            component: talkToConsultantOrder,
            beforeEnter: ValidatorPrivateRoute
        },

        {
            path: '/brands',
            name:'brands-index',
            component: IndexBrands,
            beforeEnter: ValidatorPrivateRoute
        },
              
        { path: "*", name:'404', component: NotFound }
    ]
}