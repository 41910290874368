export const getRoofType = (type) => {

    const roof_types = [
        { id: 1, label: "Telhado cerâmico ou colonial" },
        { id: 2, label: "Telhado de fibrocimento" },
        { id: 3, label: "Telhado metálico ondulado" },
        { id: 7, label: "Telhado metálico trapezoidal" },
        { id: 4, label: "Na minha laje" },
        { id: 5, label: "No solo (terreno, quintal)" },
        { id: 6, label: "Outro lugar / não tenho certeza" },
    ]

    for (let r of roof_types) {
        if (r.id == type) {
            return r.label
        }
    }
    
    return 'Não informado'
}