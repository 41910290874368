<template>
    <vueper-slides class="no-shadow" v-bind="slidesProps">
        <vueper-slide v-for="(slide, i) in slides" :key="i" :title="slide.title" :video="slide.video" :image="slide.image" :content="slide.content" />
    </vueper-slides>
</template>

<script>
    import { VueperSlides, VueperSlide } from 'vueperslides'
    import 'vueperslides/dist/vueperslides.css'

    export default {
        name: "SlidesComponent",
        components: { VueperSlides, VueperSlide },
        props:{
            slidesProps:{
                type:Object,
                defalt()
                {
                    return {
                        'arrows-outside': true,
                        'bullets-outside': true,
                        'transition-speed': 250
                    }
                }
            },
            slides:{
                type:Array,
                default()
                {
                    return []
                }
            }
        }
    }
</script>

<style scoped>
    .ex--center-mode {
        width: 600px;
        max-width: 100%;
        margin: auto;
    }
</style>