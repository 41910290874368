<template>
    <div>
        <v-dialog
            v-model="confirmationDialog"
            transition="dialog-top-transition"
            max-width="600"
            persistent
        >
            <v-card class="pa-5">
                <v-card-text class="pt-4">
                    <h1 class="text-center mb-4" style="line-height: 1">
                        Você selecionou {{ selecteds.length }} item(s), tem
                        certeza que deseja excluí-lo(s)?
                    </h1>
                    <span class="d-block text-center mt-4 mb-5"
                        >Importante: essa ação não poderá ser desfeita.</span
                    >

                    <v-btn
                        :loading="isLoadingDelete"
                        :disabled="isLoadingDelete"
                        elevation="2"
                        large
                        block
                        text
                        class="white--text grey darken-3 mt-5 elevation-0 mx-auto"
                        @click="onDeleteMany"
                        >Sim, confirmo a exclusão</v-btn
                    >
                    <v-btn
                        :disabled="isLoadingDelete"
                        large
                        block
                        text
                        class="mt-3"
                        @click="confirmationDialog = false"
                        >Cancelar</v-btn
                    >
                </v-card-text>
            </v-card>
        </v-dialog>

        <!-- Janel de filtros: TODO => Adicionar os filtros ao filterpanel usando v-chips -->
        <v-dialog
            v-model="dialog"
            transition="dialog-top-transition"
            max-width="600"
        >
            <template v-slot:default="dialog">
                <v-card>
                    <v-card-text class="pt-4">
                        <div class="pt-4 mb-4">
                            <h1>Filtrar por:</h1>
                        </div>

                        <div>
                            <v-btn
                                elevation="2"
                                large
                                block
                                class="white--text grey darken-3 elevation-0"
                                @click="dialog.value = false"
                                >Confirmar</v-btn
                            >
                            <v-btn large block text>Limpar filtro</v-btn>
                        </div>
                    </v-card-text>
                </v-card>
            </template>
        </v-dialog>

        <v-row no-gutters class="mt-10 mb-8">
            <BreadcrumbsComponent title="Instaladores" :items="breadcrumbs">
                <template v-slot:actions>
                    <v-btn
                        elevation="2"
                        large
                        color="secondary"
                        class="white--text mt-2 ml-2 elevation-0"
                        @click="$router.push('/installers/add')"
                    >
                        <v-icon class="mr-2">mdi-plus</v-icon>
                        Adicionar novo
                    </v-btn>
                </template>
            </BreadcrumbsComponent>
        </v-row>

        <v-row no-gutters>
            <v-col cols="12" md="12">
                <FilterPanelComponent
                    :show-content="showContent"
                    @onSearching="sendFilter"
                    @onDownload="load(1, true)"
                >
                    <template
                        v-slot:status
                        v-if="!selected && getUser.user_type == 1"
                    >
                        <v-tabs v-model="tab" slider-color="light-blue">
                            <v-tab
                                :class="{
                                    'light-blue--text ': true,
                                    'text--darken-3': tab == 0,
                                    'text--darken-0': tab != 0,
                                }"
                                @click="onSearchTotals(null)"
                            >
                                Todos
                                <v-chip
                                    :class="{
                                        'ml-2 light-blue white--text': true,
                                        'darken-3': tab == 0,
                                    }"
                                    small
                                    >{{ totals.all }}</v-chip
                                >
                            </v-tab>
                            <v-tab
                                :class="{
                                    'light-blue--text ': true,
                                    'text--darken-3': tab == 1,
                                    'text--darken-0': tab != 1,
                                }"
                                @click="onSearchTotals('approved')"
                            >
                                Aprovados
                                <v-chip
                                    :class="{
                                        'ml-2 light-blue white--text': true,
                                        'darken-3': tab == 1,
                                    }"
                                    small
                                >
                                    {{ totals.approved }}</v-chip
                                >
                            </v-tab>
                            <v-tab
                                :class="{
                                    'light-blue--text ': true,
                                    'text--darken-3': tab == 2,
                                    'text--darken-0': tab != 2,
                                }"
                                @click="onSearchTotals('pending')"
                            >
                                Pendentes
                                <v-chip
                                    :class="{
                                        'ml-2 light-blue white--text': true,
                                        'darken-3': tab == 2,
                                    }"
                                    small
                                    >{{ totals.pending }}</v-chip
                                >
                            </v-tab>
                        </v-tabs>
                    </template>

                    <template v-slot:result>
                        <v-chip
                            class="ma-2"
                            close
                            color="grey darken-1"
                            outlined
                        >
                            <span>Cliente:</span>
                            <span class="ml-2">Logo Nunes Castanheda</span>
                        </v-chip>
                    </template>
                </FilterPanelComponent>

                <ActionBarComponent v-if="selecteds.length > 0">
                    <template v-slot:content>
                        <div class="d-flex justify-space-between flex-wrap">
                            <span class="grey--text text--darken-4"
                                >{{ selecteds.length }} registro(s)
                                selecionado(s)</span
                            >
                            <v-icon
                                color="grey darken-2"
                                @click="confirmationDialog = true"
                                >mdi-trash-can-outline</v-icon
                            >
                        </div>
                    </template>
                </ActionBarComponent>
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <v-data-table
                    :headers="headers"
                    :items="items"
                    hide-default-footer
                    :loading="isLoading"
                    :items-per-page="-1"
                >
                    <template v-slot:item.updatedat="{ item }">
                        <v-checkbox
                            v-model="selecteds"
                            :value="item.id"
                            :label="`${__dayjs(item.updated_at).format(
                                'DD/MM/YYYY HH:mm:ss'
                            )}`"

                            v-if="getUser.user_type === 1"
                        ></v-checkbox>

                        <span v-else>{{ __dayjs(item.updated_at).format('DD/MM/YYYY HH:mm:ss') }}</span>
                    </template>

                    <template v-slot:item.state="{ item, index }">
                        {{ item.state.name }}
                    </template>

                    <template v-slot:item.visible="{ item, index }">
                        <v-switch
                            :input-value="item.visible"
                            @click="handleVisible(index, item)"
                            inset
                            :label="item.visible ? 'Visível' : 'Não visível'"
                            color="success"
                            v-if="item.approved != null"
                        ></v-switch>
                    </template>

                    <template v-slot:item.arrow="{ item }">
                        <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            @click="$router.push('/installers/view/' + item.id)"
                        >
                            <path
                                d="M5 12H19"
                                stroke="#828282"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                d="M12 5L19 12L12 19"
                                stroke="#828282"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                        </svg>
                    </template>

                    <template v-slot:item.action="{ item }">
                        <template v-if="item.approved == null">
                            <v-btn
                                small
                                text
                                outlined
                                color="#2F80ED"
                                @click="
                                    $router.push('/installers/view/' + item.id)
                                "
                                >Revisar</v-btn
                            >
                        </template>

                        <template v-else>
                            <v-menu offset-y>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        color="primary"
                                        dark
                                        v-bind="attrs"
                                        v-on="on"
                                        text
                                    >
                                        <v-icon>mdi-dots-horizontal</v-icon>
                                    </v-btn>
                                </template>
                                <v-card class="mx-auto" max-width="300" tile>
                                    <v-list>
                                        <v-list-item link>
                                            <v-list-item-title
                                                @click="
                                                    $router.push(
                                                        '/installers/view/' +
                                                            item.id
                                                    )
                                                "
                                                ><v-icon
                                                    >mdi-eye-outline</v-icon
                                                >
                                                Visualizar</v-list-item-title
                                            >
                                        </v-list-item>
                                        <v-list-item link>
                                            <v-list-item-title
                                                @click="
                                                    $router.push(
                                                        '/installers/edit/' +
                                                            item.id
                                                    )
                                                "
                                                ><v-icon
                                                    >mdi-square-edit-outline</v-icon
                                                >
                                                Editar</v-list-item-title
                                            >
                                        </v-list-item>
                                        <v-divider></v-divider>

                                        <v-list-item link>
                                            <v-list-item-title
                                                @click="onDelete(item)"
                                                ><v-icon
                                                    >mdi-trash-can-outline</v-icon
                                                >
                                                Excluir</v-list-item-title
                                            >
                                        </v-list-item>
                                    </v-list>
                                </v-card>
                            </v-menu>
                        </template>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <div class="text-center">
                    <v-pagination
                        v-model="page"
                        :length="totalPage"
                        :total-visible="7"
                        next-aria-label="Próximo"
                        @input="load($event)"
                    ></v-pagination>
                </div>
            </v-col>
        </v-row>

        <v-dialog
            v-model="confirmationDialogDelete"
            transition="dialog-top-transition"
            max-width="600"
            persistent
        >
            <v-card class="pa-5" v-if="installer">
                <v-card-text class="pt-4">
                    <h1 class="text-center mb-4">
                        Deseja excluir este instalador?
                    </h1>
                    <span class="d-block text-center mt-4 mb-5"
                        >Importante: essa ação não poderá ser desfeita.</span
                    >

                    <div class="mt-5 mb-5">
                        <span class="d-block font-weight-medium"
                            >Instalador</span
                        >
                        <v-divider></v-divider>
                        <div class="mb-3">
                            <span class="d-block">{{
                                installer.company_name
                            }}</span>
                            <span class="d-block">{{
                                installer.user ? installer.user.email : "-"
                            }}</span>
                        </div>
                    </div>

                    <v-btn
                        :disabled="isLoadingDelete"
                        :loading="isLoadingDelete"
                        elevation="2"
                        large
                        block
                        text
                        class="white--text grey darken-3 mt-5 elevation-0 mx-auto"
                        @click="onSendDelete"
                    >
                        Sim, confirmo a exclusão
                    </v-btn>
                    <v-btn
                        :disabled="isLoadingDelete"
                        large
                        block
                        text
                        class="mt-3"
                        @click="confirmationDialogDelete = false"
                        >Cancelar</v-btn
                    >
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import BreadcrumbsComponent from "@/components/BreadcrumbsComponent/Index";
import FilterPanelComponent from "@/components/FilterPanelComponent/Index";
import ActionBarComponent from "@/components/ActionBarComponent/Index";

import dayjs from "dayjs";
import Papa from "papaparse";

export default {
    components: {
        BreadcrumbsComponent,
        FilterPanelComponent,
        ActionBarComponent,
    },
    data: () => {
        return {
            selected: false,
            selecteds: [],
            dialog: false,
            confirmationDialog: false,
            searching: "",
            tab: 0,
            breadcrumbs: [
                {
                    text: "Dashboard",
                },
                {
                    text: "Instaladores",
                    disabled: true,
                },
                {
                    text: "Lista",
                    disabled: true,
                },
            ],
            showContent: false,
            headers: [],
            items: [],
            isLoading: true,
            isLoadingDelete: false,
            page: 1,
            totalPage: 1,
            timerSearch: null,
            filter: {
                query: "",
                approved: "",
            },
            isSearched: false,
            installer: null,
            confirmationDialogDelete: false,
            totals: {
                all: 0,
                approved: 0,
                pending: 0,
            },
        };
    },
    methods: {
        ...mapActions([
            "listInstallers",
            "deleteManyInstallers",
            "updateInstallers",
            "deleteInstallers",
        ]),

        onDelete(item) {
            this.installer = item;
            this.confirmationDialogDelete = true;
        },

        async onSendDelete() {
            this.isLoadingDelete = true;

            const resp = await this.deleteInstallers(this.installer.id);

            if (resp) {
                this.$eventHub.$emit("snackBar", {
                    color: "success",
                    message: "Instalador apagado com sucesso",
                });
                this.confirmationDialogDelete = false;
                this.load();
            } else {
                this.$eventHub.$emit("snackBar", {
                    color: "error",
                    message: "Erro ao apagar instalador",
                });
            }

            this.isLoadingDelete = false;
        },

        onSearchTotals(type = null) {
            this.filter.approved = type;
            this.load();
        },

        async load(page = 1, csv = false) {
            this.isLoading = true;
            const filter = { ...this.filter };
            filter.page = page;
            if (csv) filter.csv = csv;
            const resp = await this.listInstallers(filter);

            if (resp) {
                if (filter.csv) {
                    const itensCsv = [];
                    for (let i in resp) {
                        const obj = {
                            "Nome do resposável": resp[i].representative,
                            Email: resp[i].user ? resp[i].user.email : "",
                            CNPJ: resp[i].cnpj,
                            Telefone: resp[i].phone,
                            "Razão social": resp[i].company_name,
                            "Nome fantasia": resp[i].trading_name,
                            "Endereço": resp[i].address,
                            "Complemento": resp[i].complement,
                            "Cidade": resp[i].city ? resp[i].city.name: '',
                            "Estado": resp[i].state ? resp[i].state.uf: '',
                            "Site": resp[i].site,
                            "Visível": resp[i].visible ? 'Sim' : 'Não',
                            "Aprovado": resp[i].approved ? 'Sim' : 'Não',
                            
                        };
                        itensCsv.push(obj);
                    }

                    let csv = Papa.unparse(itensCsv, { delimiter: ";" });
                    let csvData = new Blob(["\ufeff", csv], {
                        type: "text/csv;charset=utf-8;",
                    });
                    let csvURL = null;
                    if (navigator.msSaveBlob) {
                        csvURL = navigator.msSaveBlob(
                            csvData,
                            "instaladores.csv"
                        );
                    } else {
                        csvURL = window.URL.createObjectURL(csvData);
                    }

                    let tempLink = document.createElement("a");
                    tempLink.href = csvURL;
                    tempLink.setAttribute("download", "instaladores.csv");
                    tempLink.click();
                } else {
                    this.items = resp.data;
                    this.totalPage = resp.last_page;
                    this.totals.all = resp.total;
                    this.totals.approved = resp.total_approved;
                    this.totals.pending = resp.total_pending;
                }
            }
            this.isLoading = false;
        },
        __dayjs(date) {
            return dayjs(date);
        },
        async handleVisible(index, item) {
            item.visible = !item.visible;
            const resp = await this.updateInstallers({
                id: item.id,
                visible: item.visible,
            });
            if (resp) {
                this.$eventHub.$emit("snackBar", {
                    color: "success",
                    message: "Instalador editado com sucesso",
                });
            } else {
                this.$eventHub.$emit("snackBar", {
                    color: "error",
                    message: "Erro ao editar instalador",
                });
            }
        },
        setLoading() {
            this.$eventHub.emit("loading", {
                title: "Realizando operação...",
                subtitle: "Aguarde só um instante...",
                status: true,
            });
        },
        async onDeleteMany() {
            this.isLoadingDelete = true;
            const ids = this.selecteds;
            const r = await this.deleteManyInstallers({ ids });
            if (r) {
                this.$eventHub.$emit("snackBar", {
                    color: "success",
                    message: "Instaladores apagados com sucesso",
                });
                this.selecteds = [];
                this.confirmationDialog = false;
                this.load();
            } else {
                this.$eventHub.$emit("snackBar", {
                    color: "error",
                    message: "Erro ao apagar instaladores",
                });
            }

            this.isLoadingDelete = false;
        },
        sendFilter(query) {
            this.filter.query = query;
            this.isLoading = true;
            if ((query != "" && query) || this.isSearched) {
                clearTimeout(this.timerSearch);
                this.timerSearch = setTimeout(async () => {
                    await this.load(1);
                    this.isLoading = false;
                    if (query) this.isSearched = true;
                }, 800);
            } else {
                this.isLoading = false;
                clearTimeout(this.timerSearch);
            }
        },
    },
    computed: {
        ...mapGetters(["getUser"]),
    },
    mounted() {
        this.headers = [
            {
                text: "ATUALIZADO EM",
                align: "start",
                sortable: false,
                value: "updatedat",
            },
            {
                text: "NOME FANTASIA",
                align: "start",
                sortable: false,
                value: "trading_name",
            },
            {
                text: "E-MAIL",
                align: "start",
                sortable: false,
                value: "user.email",
            },
            {
                text: "Telefone",
                align: "start",
                sortable: false,
                value: "phone",
            },
            {
                text: "CNPJ",
                align: "start",
                sortable: false,
                value: "cnpj",
            },
            {
                text: "ESTADO",
                align: "start",
                sortable: false,
                value: "state",
            },
            this.getUser.user_type === 1
                ? {
                      text: "VISÍVEL",
                      align: "start",
                      sortable: false,
                      value: "visible",
                  }
                : null,
            {
                text: "",
                align: "start",
                sortable: false,
                value: this.getUser.user_type === 1 ? "action" : "arrow",
            },
        ].filter(Boolean);
        this.load();
    },
};
</script>
