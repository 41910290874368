<template>
    <div>
        <v-row no-gutters class="mt-10 mb-8">
            <BreadcrumbsComponent
                title="Adminsitradores"
                :items="breadcrumbs"
            />
        </v-row>

        <v-row v-if="isLoading">
            <v-col md="12" class="text-center">
                <v-progress-circular indeterminate></v-progress-circular>
            </v-col>
        </v-row>

        <form
            v-else
            @keydown.enter="!isLoading && isValid && onUpdatetAdministrator()"
        >
            <v-row>
                <v-col cols="12" md="6">
                    <v-row>
                        <v-col cols="12" md="12">
                            <v-text-field
                                label="Nome"
                                placeholder="Nome"
                                outlined
                                v-model="administrator.name"
                            ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="12">
                            <v-text-field
                                readonly
                                label="Email"
                                placeholder="Email"
                                outlined
                                hint="Uma senha automática será gerada e enviada para o e-mail cadastrado."
                                persistent-hint
                                v-model="administrator.email"
                            ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="12">
                            <v-text-field
                                flat
                                solo
                                label="Senha Atual"
                                value="*********"
                                readonly
                            >
                                <template v-slot:append>
                                    <v-btn
                                        depressed
                                        color="grey lighten-2"
                                        class="ma-0"
                                        @click="show = !show"
                                    >
                                        Trocar senha
                                    </v-btn>
                                </template>
                            </v-text-field>
                        </v-col>

                        <template v-if="show">
                            <v-col cols="12">
                                <div class="d-flex align-center">
                                    <v-divider></v-divider>
                                    <v-icon class="ml-3" @click="show = false"
                                        >mdi-close</v-icon
                                    >
                                </div>
                            </v-col>

                            <v-col cols="12">
                                <span class="grey--text"
                                    >Use ao menos 8 caracteres, e sua senha deve
                                    conter números e letras maiúsculas e
                                    minúsculas.</span
                                >
                            </v-col>

                            <v-col cols="12">
                                <v-text-field
                                    v-model="administrator.password"
                                    outlined
                                    :append-icon="
                                        visibility.password
                                            ? 'mdi-eye'
                                            : 'mdi-eye-off'
                                    "
                                    :type="
                                        visibility.password
                                            ? 'text'
                                            : 'password'
                                    "
                                    label="Nova senha"
                                    @click:append="
                                        visibility = {
                                            ...visibility,
                                            password: !visibility.password,
                                        }
                                    "
                                ></v-text-field>
                            </v-col>

                            <v-col cols="12">
                                <v-text-field
                                    v-model="administrator.passwordConfirmation"
                                    outlined
                                    :append-icon="
                                        visibility.passwordConfirmation
                                            ? 'mdi-eye'
                                            : 'mdi-eye-off'
                                    "
                                    :type="
                                        visibility.passwordConfirmation
                                            ? 'text'
                                            : 'password'
                                    "
                                    label="Confirme sua nova senha"
                                    @click:append="
                                        visibility = {
                                            ...visibility,
                                            passwordConfirmation: !visibility.passwordConfirmation,
                                        }
                                    "
                                ></v-text-field>
                            </v-col>
                        </template>

                        <v-col cols="12" md="12">
                            <v-btn
                                elevation="2"
                                large
                                block
                                class="white--text grey darken-3 elevation-0"
                                @click="onUpdatetAdministrator"
                                :disabled="isLoading || !isValid"
                                :loading="isLoading"
                            >
                                Editar
                            </v-btn>
                        </v-col>

                        <v-col cols="12" md="12">
                            <v-btn
                                large
                                block
                                text
                                @click="$router.push('/administrators')"
                            >
                                Cancelar
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </form>
    </div>
</template>

<script>
import { mapActions } from "vuex";
import BreadcrumbsComponent from "@/components/BreadcrumbsComponent/Index";

export default {
    components: {
        BreadcrumbsComponent,
    },
    data: () => {
        return {
            show: false,
            isLoadingLoad: true,
            breadcrumbs: [
                {
                    text: "Dashboard",
                },
                {
                    text: "Administradores",
                },
                {
                    text: "Editar",
                    disabled: true,
                },
            ],
            id: null,
            administrator: {
                id: "",
                name: "",
                email: "",
                password: null,
                passwordConfirmation: null,
            },
            visibility: {
                password: false,
                passwordConfirmation: false,
                isLoadingUpdate: false,
            },
            isLoading: true,
        };
    },
    methods: {
        ...mapActions(["updateAdmin", "showAdmin"]),
        async onUpdatetAdministrator() {
            this.isLoadingUpdate = true;

            const admin = Object.assign(this.administrator);

            if (this.show) {
                if (admin.password != admin.passwordConfirmation) {
                    this.$eventHub.$emit("snackBar", {
                        color: "error",
                        message: "Senhas não conferem",
                    });
                    return;
                }
            } else {
                admin.password = null;
                admin.passwordConfirmation = null;
            }

            const resp = await this.updateAdmin(admin);

            if (resp) {
                this.$eventHub.$emit("snackBar", {
                    color: "success",
                    message: "Administrador editado com sucesso",
                });
                this.$router.push("/administrators");
            } else {
                this.$eventHub.$emit("snackBar", {
                    color: "error",
                    message: "Erro ao editar administrador",
                });
            }

            this.isLoadingUpdate = false;
        },
    },
    computed: {
        isValid() {
            return !!(
                ["name"].every((key) => !!this.administrator[key]) &&
                /^[A-Za-z0-9_\-\.+]+@[A-Za-z0-9_\-\.]{2,}\.[A-Za-z0-9]{2,}(\.[A-Za-z0-9])?/i.test(
                    this.administrator.email
                )
            );
        },
    },
    async mounted() {
        this.isLoading = true;
        const id = this.$route.params.id;
        this.id = id;
        const [administrator] = await Promise.all([this.showAdmin(id)]);
        if (administrator) {
            this.administrator = administrator;
            this.administrator.name = administrator.username;
            this.administrator.email = administrator.email;
        } else {
            this.$eventHub.$emit("snackBar", {
                color: "error",
                message: "Erro ao encontrar administrador",
            });
        }
        this.isLoading = false;
    },
};
</script>
