<template>
    <div style="max-width:448px;" class="mb-8">

        
        
        <v-row>
            <v-col cols="12">
                <img class="ml-8 pl-6" src="/images/image-home-panels.svg">        
            </v-col>

            <!--<v-col cols="12">
                <h1 class="mb-2 titles-simulator mt-8 ml-8 pl-6">Área da obra</h1>
                <p class="orange--text font-weight-bold d-block text-h5 ml-8 pl-6" v-if="simulator && simulator.budget">
                    {{ simulator.budget.area_size }} m²
                </p>
                
                <p style="color: #312F37; font-size: 12px;" class="ml-8 pl-6">*Média de painéis standard</p>
                
                <p class="ml-8 pl-6">Os painéis solares podem ser instalados no telhado ou no chão</p>
            </v-col>-->
        </v-row>
        
        <h1 class="mb-2 titles-simulator mt-8 ml-8 pl-6">Painéis solares</h1>
        <p class="ml-8 pl-6"><span class="orange--text font-weight-bold">{{ parseInt(panels_standard) }} unidades</span> de painéis standard, <span style="text-decoration:underline">ou</span></p>
        <p class="ml-8 pl-6"><span class="orange--text font-weight-bold">{{ parseInt(panels_high_performance) }} unidades</span> de alta performance</p>

        
    </div>
</template>


<script>
    export default {
        name: 'SolarPanelsView',
        
        
        props: {
            panels_high_performance: {
                default: 0
            },

            panels_standard: {
                default: 0
            },
            simulator: {
                default: null
            }
        }
    }
</script>

<style>
    @media screen and (max-width: 960px) {
        .textMobileCenter {
            text-align: center;
        }
    }
</style>