<template>
    <DataListComponent @onClick="$emit('onClick', $event)">
        <template v-slot:item>
            <template v-for="(item, index) in items">
                <v-divider v-if="index == 0" :key="index"></v-divider>

                <v-list-item>
                    <v-list-item-content class="d-block">
                        <v-list-item-title
                            class="text-caption grey--text text--darken-2 font-weight-medium"
                            v-text="item.title"
                        ></v-list-item-title>

                        <v-list-item-subtitle
                            class="text-body-2 grey--text text--darken-2 font-weight-regular"
                            v-html="item.value"
                        ></v-list-item-subtitle>
                        
                        
                        <v-row v-if="item.map">
                            <v-col cols="12" md="6">

                                <Gmap-Map
                                    :style="`width: 100%; height: 370px;${
                                enabled ? '' : 'pointer-events:none;'
                            }`"
                                    :zoom="zoom"
                                    :center="center"
                                    :options="options"
                                >
                                    <Gmap-Marker
                                        v-if="place"
                                        :position="position"
                                        :clickable="true"
                                        :draggable="true"
                                        @dragend="changeDrag"
                                    ></Gmap-Marker>
                                </Gmap-Map>
                                
                            </v-col>

                           
                            
                        </v-row>
                    </v-list-item-content>
                </v-list-item>

                <v-divider v-if="index < items.length - 1"></v-divider>
            </template>
        </template>
    </DataListComponent>
</template>

<script>
import DataListComponent from "@/components/DataListComponent/Index";
import dayjs from "dayjs";
import {formatMoney} from "../../utils/formatMoney";

export default {
    props: {
        simulation: {
            type: Object,
            default() {
                return {};
            },
        },
    },
    data() {
        return {
            items: [],


            center: { lat: -15, lng: -55 },
            place: null,
            zoom: 4,
            position: { lat: 0, lng: 0 },
            enabled: false,
            options: {
                zoomControl: false,
                mapTypeControl: false,
                scaleControl: false,
                streetViewControl: false,
                rotateControl: false,
                fullscreenControl: true,
                disableDefaultUi: false,
                mapTypeId: "satellite",
            },
            
            
            
        };
    },
    components: {
        DataListComponent,
    },
    methods: {
        mountData(key) {
            try {
                return eval(`this.simulation.${key}`);
            } catch (error) {
                return "";
            }
        },
        _format(value)
        {
            return formatMoney(value)
        }
    },
    mounted() {

        this.position = {
            lat: parseFloat(this.simulation.latitude),
            lng: parseFloat(this.simulation.longitude),
        };

        this.center = {
            lat: parseFloat(this.simulation.latitude),
            lng: parseFloat(this.simulation.longitude),
        };
        this.zoom = 20;
        
        if (this.simulation.created_consultant) {
            this.items = [
                {
                    title: "ATUALIZADO EM",
                    value: dayjs(this.mountData("updated_at")).format("DD/MM/YYYY"),
                },
                {
                    title: "ID",
                    value: this.mountData("custom_id"),
                },
                {
                    title: "CLIENTE",
                    value: this.mountData("client.user.username"),
                },
                {
                    title: "E-MAIL DO CLIENTE",
                    value: this.mountData("client.user.email"),
                },
                {
                    title: "TELEFONE DO CLIENTE",
                    value: this.mountData("client.phone"),
                },
                {
                    title: "ENDEREÇO DA SIMULAÇÃO",
                    value: this.mountData("address"),
                    map: true
                },
                {
                    title: "GERAÇÃO DA USINA",
                    value: this.mountData("monthly_generated_energy") + " kWh",
                },
                {
                    title: "TARIFA",
                    value: this.mountData("tribute") + " por kWh",
                },
                {
                    title: "VALOR DO INVESTIMENTO",
                    value: "R$ " + this._format(this.simulation.budget_total),
                },
            ];
        } else {
            this.items = [
                {
                    title: "ATUALIZADO EM",
                    value: dayjs(this.mountData("updated_at")).format("DD/MM/YYYY"),
                },
                {
                    title: "ID",
                    value: this.mountData("custom_id"),
                },
                {
                    title: "CLIENTE",
                    value: this.mountData("client.user.username"),
                },
                {
                    title: "E-MAIL DO CLIENTE",
                    value: this.mountData("client.user.email"),
                },
                {
                    title: "TELEFONE DO CLIENTE",
                    value: this.mountData("client.phone"),
                },
                {
                    title: "ENDEREÇO DA SIMULAÇÃO",
                    value: this.mountData("address"),
                    map: true
                },
                {
                    title: "VALOR DA CONTA DE LUZ",
                    value: "R$ " + this._format(this.simulation.value_month),
                },
                {
                    title: "SEU CONSUMO DE ENERGIA",
                    value: this.mountData("consumption_month") + "kWh",
                },
                {
                    title: "CONSUMO DE ENERGIA",
                    value: this.mountData("more_energy") + "%",
                },
                {
                    title: "VALOR DO INVESTIMENTO",
                    value: "R$ " + this._format(this.simulation.budget_total),
                },
            ];
        }
        
        
    },
};
</script>
