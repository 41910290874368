<template>
    <div>
        <DataListComponent @onClick="$emit('onClick', $event)">
            <template v-slot:item>
                <template v-for="(item, index) in items">
                    <v-divider v-if="index == 0" :key="index"></v-divider>

                    <v-list-item>
                        <v-list-item-content class="d-block">
                            <v-list-item-title
                                class="text-caption grey--text text--darken-2 font-weight-medium"
                                v-text="item.title"
                            ></v-list-item-title>

                            <v-list-item-subtitle
                                class="text-body-2 grey--text text--darken-2 font-weight-regular"
                                v-html="mountData(item.value)"
                            ></v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>

                    <v-divider v-if="index < items.length - 1"></v-divider>
                </template>
            </template>
        </DataListComponent>
    </div>
</template>

<script>
import DataListComponent from "@/components/DataListComponent/Index";
import dayjs from "dayjs";

export default {
    props: {
        items: {
            type: Array,
            default() {
                return [];
            },
        },
        order: {
            type: Object,
            default() {
                return {};
            },
        },
    },
    components: {
        DataListComponent,
    },
    methods: {
        mountData(key) {
            try {
                switch (key) {
                    case "updated_at":
                        return dayjs(eval(`this.order.${key}`)).format(
                            "DD/MM/YYYY"
                        );

                    case "budget.documents":
                        return this.order.budget.documents
                            .map(({ file }) => {
                                return `
                                <a href="${file.split("/").pop()}">Baixar</a>
                            `;
                            })
                            .join("");

                    default:
                        return eval(`this.order.${key}`);
                }
            } catch (error) {
                return "";
            }
        },
    },
};
</script>
