import store from '../store/index'

export const ValidatorPrivateRoute = (to, from, next) => {
    store.modules.system.state.token = sessionStorage.getItem("token")
    store.modules.system.state.user  = JSON.parse( sessionStorage.getItem("user") )
    return !store.modules.system.state.token || !store.modules.system.state.user ? next("/login") : next()
}

export const ValidatorRouteLogin = (to, from, next) => {
    return !store.modules.system.state.token || !store.modules.system.state.user ? next() : next('/')
}


