<template>
    <div>
        <v-row no-gutters class="mt-10 mb-8">
            <BreadcrumbsComponent title="Clientes" :items="breadcrumbs" />
        </v-row>

        <v-row>
            <v-col cols="12" md="6">
                <form @keydown.enter="!isLoading && isValid && onInsertClient()">
                    <v-row>
                        <v-col cols="12" md="12">
                            <v-text-field
                                label="Nome"
                                placeholder="Nome"
                                outlined
                                v-model="client.username"
                                :rules='rules.nameRules'
                                @blur="validate" 
                                @focus="clearRules"
                            ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="12">
                            <v-text-field
                                label="Email"
                                placeholder="Email"
                                outlined
                                hint="Uma senha automática será gerada e enviada para o e-mail cadastrado."
                                persistent-hint
                                v-model="client.email"
                                :rules='rules.emailRules'
                                @blur="validate" 
                                @focus="clearRules"
                            ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="12">
                            <v-text-field
                                label="DDD Celular"
                                placeholder="DDD Celular"
                                outlined
                                hint="Apenas números"
                                persistent-hint
                                v-model="client.phone"
                                v-mask="['(##) ####-####', '(##) #####-####']"
                                :rules='rules.phoneRules'
                                @blur="validate" 
                                @focus="clearRules"
                            ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="12">
                            <v-text-field
                                label="CPF/CNPJ"
                                placeholder="CPF/CNPJ"
                                outlined
                                hint="Apenas números"
                                persistent-hint
                                v-mask="[
                                    '###.###.###-##',
                                    '##.###.###/####-##',
                                ]"
                                v-model="client.cpf_cnpj"
                                :rules='rules.cpfCnpjRules'
                                @blur="validate" 
                                @focus="clearRules"
                            ></v-text-field>
                        </v-col>

                        <v-col
                            cols="12"
                            md="12"
                            v-if="client.cpf_cnpj.length >= 15"
                        >
                            <v-text-field
                                label="Razão Social"
                                placeholder="Razão Social"
                                outlined
                                v-model="client.corporate_name"
                                :rules='rules.corporateRules'
                                @blur="validate" 
                                @focus="clearRules"
                            ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="12">
                            <v-select
                                :items="optionsStates"
                                item-value="id"
                                item-text="name"
                                label="Estado"
                                outlined
                                v-model="client.state_id"
                                :rules='rules.stateRules'
                                @blur="validate" 
                                @focus="clearRules"
                            ></v-select>
                        </v-col>

                        <v-col cols="12" md="12">
                            <v-select
                                :items="priorityItems"
                                :item-disabled="disableItem"
                                item-text="name"
                                label="Prioridade 1"
                                v-model="client.priorities[0]"
                                return-object
                                style="font-size: 18px;"
                                class="input_defualt"
                                :rules="rules.select"
                                @blur="validate" 
                                @focus="clearRules"
                            ></v-select>
                        </v-col>

                        <v-col cols="12" md="12">
                            <v-select
                                :items="priorityItems"
                                :item-disabled="disableItem"
                                item-text="name"
                                label="Prioridade 2"
                                v-model="client.priorities[1]"
                                return-object
                                style="font-size: 18px;"
                                class="input_defualt"
                                :rules="rules.select"
                                @blur="validate" 
                                @focus="clearRules"
                            ></v-select>
                        </v-col>

                        <v-col cols="12" md="12">
                            <v-select
                                :items="priorityItems"
                                :item-disabled="disableItem"
                                item-text="name"
                                label="Prioridade 3"
                                v-model="client.priorities[2]"
                                return-object
                                style="font-size: 18px;"
                                class="input_defualt"
                                :rules="rules.select"
                                @blur="validate" 
                                @focus="clearRules"
                            ></v-select>
                        </v-col>

                        <v-col cols="12" md="12">
                            <span class="mt-5 mb-2">Disponibilidade para receber chamadas</span>

                            <div class="d-flex align-center" style="margin-top: 27px;">
                                <v-checkbox v-model="client.availabilityToBeCalled.morning"></v-checkbox>
                                    <span class="span-check" style="margin-right: 10px;"
                                        >Manhã</span
                                    >
                                <v-checkbox v-model="client.availabilityToBeCalled.afternoon"></v-checkbox>
                                    <span class="span-check" style="margin-right: 10px;"
                                        >Tarde</span
                                    >
                                <v-checkbox v-model="client.availabilityToBeCalled.night"></v-checkbox>
                                    <span class="span-check" style="margin-right: 10px;"
                                        >Noite</span
                                    >
                                <v-checkbox v-model="client.availabilityToBeCalled.weekend"></v-checkbox>
                                    <span class="span-check"
                                        >Finais de semana</span
                                    >
                            </div>
                        </v-col>

                        <v-col cols="12" md="12">
                            <v-select
                                    :items="installationPlaces"
                                    item-text="name"
                                    label="O projeto será realizado no telhado ou solo?"
                                    v-model="client.installationPlace"
                                    return-object
                                    style="font-size: 18px;"
                                    class="input_defualt"
                                    :rules="rules.select"
                                    @blur="validate"
                                    @focus="clearRules"
                            ></v-select>
                        </v-col>

                        <v-col cols="12" md="12" v-if="client.installationPlace == 'Telhado'">
                            <span class="mt-5 mb-2">O telhado tem mais de 20 anos?</span>

                            <div class="d-flex align-center" style="margin-top: 27px;">
                                <v-radio-group row v-model="client.isRoofAgeGreaterThan20">
                                    <v-radio value="true" id="true" label="Sim"></v-radio>
                                    <v-radio value="false" id="false" label="Não"></v-radio>
                                </v-radio-group>
                            </div>
                        </v-col>

                        <v-col cols="12" md="12">
                            <span class="mt-5 mb-2">Tipo de pagamento</span>

                            <div class="d-flex align-center" style="margin-top: 27px;">
                                <v-radio-group row v-model="client.paymentMethod">
                                    <v-radio value="incash" id="incash" label="À vista"></v-radio>
                                    <v-radio value="financing" id="financing" label="Financiado"></v-radio>
                                </v-radio-group>
                            </div>
                        </v-col>

                        <v-col cols="12" md="12">
                            <v-checkbox
                                v-model="client.opt_in"
                                label="Autorizo a Market Solar a entrar em contato por e-mail, celular ou whatsapp"
                            ></v-checkbox>
                        </v-col>

                        <v-col cols="12" md="12">
                            <v-btn
                                elevation="2"
                                large
                                block
                                class="white--text grey darken-3 elevation-0"
                                @click="onInsertClient"
                                :disabled="isLoading || !isValid"
                                :loading="isLoading"
                            >
                                Cadastrar
                            </v-btn>
                        </v-col>

                        <v-col cols="12" md="12">
                            <v-btn
                                large
                                block
                                text
                                @click="$router.push('/clients')"
                            >
                                Cancelar
                            </v-btn>
                        </v-col>
                    </v-row>
                </form>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import BreadcrumbsComponent from "@/components/BreadcrumbsComponent/Index";
import { mapActions } from "vuex";

export default {
    components: {
        BreadcrumbsComponent,
    },
    data: () => {
        return {
            rules: [],
            breadcrumbs: [
                {
                    text: "Dashboard",
                },
                {
                    text: "Clientes",
                },
                {
                    text: "Adicionar novo",
                    disabled: true,
                },
            ],
            client: {
                username: "",
                email: "",
                cpf_cnpj: "",
                phone: "",
                opt_in: false,
                state_id: null,
                corporate_name: null,
                priorities: [{
                        name: "",
                    },
                    {
                        name: "",
                    },
                    {
                        name: ""
                    }],
                    availabilityToBeCalled: {
                        morning: false,
                        afternoon: false,
                        night: false,
                        weekend: false
                    },
                    paymentMethod: "incash",
                    installationPlace: "",
                    isRoofAgeGreaterThan20: "true",
            },
            isLoading: false,
            optionsStates: [],
            priorityItems: [{
                name: "Menor preço (menor R$/Wp)",
            },
            {
                name: "Melhor tecnologia de materiais",
            },
            {
                name: "Placa mais potente do mercado (Wp)",
            },
            {
                name: "Parcela menor possível do banco",
            },
            {
                name: "Marcas de maior garantia",
            },
            {
                name: "Marcas mais sólidas no mercado nacional",
            }],
            installationPlaces: ['Telhado', 'Solo']
        };
    },
    computed: {
        isValid() {
            if (this.client.cpf_cnpj.length >= 15) {
                if (!this.client.corporate_name) {
                    return false;
                }
            }

            return !!(
                ["username", "cpf_cnpj", "phone", "state_id"].every(
                    (key) => !!this.client[key]
                ) &&
                /^[A-Za-z0-9_\-\.+]+@[A-Za-z0-9_\-\.]{2,}\.[A-Za-z0-9]{2,}(\.[A-Za-z0-9])?/i.test(
                    this.client.email
                ) &&
                this.client.priorities[0].name &&
                this.client.priorities[1].name &&
                this.client.priorities[2].name &&
                this.client.installationPlace
            );
        },
    },
    methods: {
        ...mapActions(["getStates", "insertClient"]),

        async sendClientWelcomeEmail(name, email, password) {

            let token = sessionStorage.getItem('token');

            const formClient = new FormData();

            formClient.append('name', name);
            formClient.append('email', email);
            formClient.append('id', password)

            const optionsClient = {
                method: "POST",
                body: formClient,
                headers: {
                    "Authorization": token
                },
            };

            let sendMailClient = await fetch('https://netapi1.marketsolar.com.br/messages/mail/template/welcomeClients', optionsClient);

            const formConsultant = new FormData();
            formConsultant.append('name', name);
            formConsultant.append('email', JSON.parse(sessionStorage.getItem('user')).email);
            formConsultant.append('id', password)

            const optionsConsultant = {
                method: "POST",
                body: formConsultant,
                headers: {
                    "Authorization": token
                },
            };

            let sendMailConsultant = await fetch('https://netapi1.marketsolar.com.br/messages/mail/template/welcomeClients', optionsConsultant);
        },

        async onInsertClient() {
            this.isLoading = true;

            this.client.email = this.client.email.trim();

            this.client.priorities = `${this.client.priorities[0].name},${this.client.priorities[1].name},${this.client.priorities[2].name}`;
            this.client.availabilityToBeCalled = `${this.client.availabilityToBeCalled.morning},${this.client.availabilityToBeCalled.afternoon},${this.client.availabilityToBeCalled.night},${this.client.availabilityToBeCalled.weekend}`;
            // this.client.isRoofAgeGreaterThan20 = parseInt(this.client.isRoofAgeGreaterThan20);

            if (this.client.installationPlace == 'Telhado') {
                this.client.installationPlace = 'roof';

                if (this.client.isRoofAgeGreaterThan20 == "true") {
                    this.client.isRoofAgeGreaterThan20 = true;
                } else {
                    this.client.isRoofAgeGreaterThan20 = false;
                }
            } else {
                this.client.installationPlace = 'ground';
                this.client.isRoofAgeGreaterThan20 = false;
            };

            const resp = await this.insertClient(this.client);

            if (resp.status) {

                let sendMail = await this.sendClientWelcomeEmail(this.client.username, this.client.email, resp.data.id);

                this.$eventHub.$emit("snackBar", {
                    color: "success",
                    message: "Cliente cadastrado com sucesso",
                });

                this.client = {
                    username: "",
                    email: "",
                    cpf_cnpj: "",
                    phone: "",
                    federative_state: "",
                    opt_in: false,
                    state_id: null,
                    corporate_name: null,
                    priorities: [{
                        name: "",
                    },
                    {
                        name: "",
                    },
                    {
                        name: ""
                    }],
                    availabilityToBeCalled: {
                        morning: false,
                        afternoon: false,
                        night: false,
                        weekend: false
                    },
                    paymentMethod: "incash",
                    isRoofAgeGreaterThan20: "true",
                };

            } else {

                this.$eventHub.$emit("snackBar", {
                    color: "error",
                    message: "E-mail já cadastrado",
                });

            }

            this.isLoading = false;
        },

        disableItem(item) {

            if (item.name == this.client.priorities[0].name || item.name == this.client.priorities[1].name || item.name == this.client.priorities[2].name) return true;

            return false;
        },

        validate() {
            this.rules = {
                "nameRules": [v => !!v || "O preenchimento deste campo é obrigatório"],
                "emailRules": [
                    v => !!v || "O preenchimento deste campo é obrigatório",
                    v => /^[\w+.]+@\w+\.\w{2,}(?:\.\w{2})?$/.test(v) || "Insira um e-mail válido. Verifique se não há espaço no final ou no começo do endereço de e-mail."
                ],
                "phoneRules": [
                    v => !!v || "O preenchimento deste campo é obrigatório",
                    v => v.length >= 14 || "Insira um telefone válido"
                ],
                "cpfCnpjRules": [
                    v => !!v || "O preenchimento deste campo é obrigatório",
                    v => v.length >= 14 || "Insira um CPF/CNPJ válido"
                ],
                "corporateRules": [v => !!v || "O preenchimento deste campo é obrigatório"],
                "stateRules": [v => !!v || "O preenchimento deste campo é obrigatório"],
                "select": [
                    v => !!v || "O preenchimento desse campo é obrigatório"
                ]
            }
        },

        clearRules(){
            this.rules = []
        }
    },

    async mounted() {
        const states = await this.getStates();
        this.optionsStates = states;
        console.log('states', states);
    },
};
</script>
