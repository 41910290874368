<template>
    <v-row no-gutters class="d-flex justify-center">
        <div class="text-center" v-if="isLoading">
            <v-progress-circular
                indeterminate
                color="primary"
            ></v-progress-circular>
        </div>
        <v-col md="6" v-else>
            <v-card elevation="0">
                <v-card-title>Redefinir senha</v-card-title>
                <v-card-text>
                    Redefina sua senha preenchendo o seu email e sua nova senha
                </v-card-text>
                <v-card-text>
                    <form @keydown.enter="onSendResetPassword">
                        <v-row>
                            <v-col cols="12" sm="12" md="12">
                                <v-text-field
                                    label="E-mail"
                                    outlined
                                    v-model="user.email"
                                ></v-text-field>
                            </v-col>

                            <v-col cols="12" sm="12" md="12">
                                <v-text-field
                                    label="Senha"
                                    type="password"
                                    outlined
                                    v-model="user.password"
                                ></v-text-field>
                            </v-col>

                            <v-col cols="12" sm="12" md="12">
                                <v-text-field
                                    label="Repita a senha"
                                    type="password"
                                    outlined
                                    v-model="user.password_confirmation"
                                ></v-text-field>
                            </v-col>

                            <v-col cols="12" sm="12" md="12">
                                <v-btn
                                    large
                                    block
                                    color="blue-grey"
                                    class="white--text"
                                    @click="onSendResetPassword"
                                    :loading="isLoadingReset"
                                    :disabled="isLoadingReset"
                                    >Criar nova senha</v-btn
                                >
                            </v-col>

                            <v-col cols="12" sm="12" md="12">
                                <v-btn
                                    large
                                    block
                                    text
                                    @click="$router.push('/login')"
                                    >Voltar</v-btn
                                >
                            </v-col>
                        </v-row>
                    </form>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
import { mapActions } from "vuex";

export default {
    name: "LoginPage",
    data: () => {
        return {
            showPassword: false,
            tabs: null,
            user: {
                email: "",
                password: "",
                password_confirmation: "",
                token: "",
            },
            isLoading: true,
            isLoadingReset: false,
        };
    },
    async mounted() {
        this.isLoading = true;
        const r = await this.checkTokenReset(this.$route.params.token);
        if (!r) {
            this.$router.push("/");
        }
        this.user.token = this.$route.params.token;
        this.isLoading = false;
    },
    methods: {
        ...mapActions(["checkTokenReset", "restorePassword"]),

        async onSendResetPassword() {
            this.isLoadingReset = true;
            const user = Object.assign(this.user);
            const r = await this.restorePassword(user);
            if (r) {
                this.$eventHub.$emit("snackBar", {
                    color: "success",
                    message: "Senha atualizada com sucesso",
                });
                this.$router.push("/");
            } else {
                this.$eventHub.$emit("snackBar", {
                    color: "error",
                    message: "Erro ao resetar senha",
                });
            }
            this.isLoadingReset = false;
        },
    },
};
</script>
