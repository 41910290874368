<template>
    <div>
        <!-- Janel de filtros: TODO => Adicionar os filtros ao filterpanel usando v-chips -->
        <v-dialog
            v-model="dialog"
            transition="dialog-top-transition"
            max-width="600"
        >
            <template v-slot:default="dialog">
                <v-card>
                    <v-card-text class="pt-4">
                        <div class="pt-4 mb-4">
                            <h1>Filtrar clientes por:</h1>
                        </div>

                        <div>
                            <v-select
                                :items="['Foo', 'Bar', 'Fizz', 'Buzz']"
                                label="Estado"
                                placeholder="Estado"
                                outlined
                                v-model="filter.state"
                            ></v-select>
                            <v-select
                                :items="['Foo', 'Bar', 'Fizz', 'Buzz']"
                                label="Tipo"
                                placeholder="Tipo"
                                outlined
                                v-model="filter.category"
                            ></v-select>
                            <v-select
                                :items="['Foo', 'Bar', 'Fizz', 'Buzz']"
                                label="Etapa"
                                placeholder="Etapa"
                                outlined
                                v-model="filter.category"
                            ></v-select>
                            <v-btn
                                elevation="2"
                                large
                                block
                                class="white--text grey darken-3 elevation-0"
                                @click="dialog.value = false"
                                >Confirmar</v-btn
                            >
                            <v-btn large block text>Limpar filtro</v-btn>
                        </div>
                    </v-card-text>
                </v-card>
            </template>
        </v-dialog>

        <!-- Janel de confirmação de exclusão: TODO => Implementar -->
        <v-dialog
            v-model="confirmationDialog"
            transition="dialog-top-transition"
            max-width="600"
            persistent
        >
            <v-card class="pa-5">
                <v-card-text class="pt-4">
                    <h1 class="text-center mb-4" style="line-height: 1">
                        Você selecionou {{ selecteds.length }} item(s), tem
                        certeza que deseja excluí-lo(s)?
                    </h1>
                    <span class="d-block text-center mt-4 mb-5"
                        >Importante: essa ação não poderá ser desfeita.</span
                    >

                    <v-btn
                        :loading="isLoadingDelete"
                        :disabled="isLoadingDelete"
                        elevation="2"
                        large
                        block
                        text
                        class="white--text grey darken-3 mt-5 elevation-0 mx-auto"
                        @click="onDeleteMany"
                        >Sim, confirmo a exclusão</v-btn
                    >
                    <v-btn
                        :disabled="isLoadingDelete"
                        large
                        block
                        text
                        class="mt-3"
                        @click="confirmationDialog = false"
                        >Cancelar</v-btn
                    >
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-row no-gutters class="mt-10 mb-8">
            <BreadcrumbsComponent title="Clientes" :items="breadcrumbs">
                <template v-slot:actions>
                    <v-btn
                        elevation="2"
                        large
                        color="secondary"
                        class="white--text mt-2 ml-2 elevation-0"
                        @click="$router.push('/clients/add')"
                    >
                        <v-icon class="mr-2">mdi-plus</v-icon>
                        Adicionar novo
                    </v-btn>
                </template>
            </BreadcrumbsComponent>
        </v-row>

        <v-row no-gutters>
            <v-col cols="12" md="12">
                <FilterPanelComponent
                    v-if="!selected"
                    :show-content="showContent"
                    @onSearching="sendFilter"
                    @onDownload="load(1, true)"
                >
                    <template v-slot:result>
                        <v-chip
                            class="ma-2"
                            close
                            color="grey darken-1"
                            outlined
                        >
                            <span>Cliente:</span>
                            <span class="ml-2">Logo Nunes Castanheda</span>
                        </v-chip>
                    </template>
                </FilterPanelComponent>

                <ActionBarComponent v-if="selecteds.length > 0">
                    <template v-slot:content>
                        <div class="d-flex justify-space-between flex-wrap">
                            <span class="grey--text text--darken-4"
                                >{{ selecteds.length }} registro(s)
                                selecionado(s)</span
                            >
                            <v-icon
                                color="grey darken-2"
                                @click="confirmationDialog = true"
                                >mdi-trash-can-outline</v-icon
                            >
                        </div>
                    </template>
                </ActionBarComponent>
            </v-col>
        </v-row>

        <v-row no-gutters>
            <v-col cols="12" md="12">
                <v-data-table
                    :headers="headers"
                    :items="items"
                    hide-default-footer
                    :loading="isLoading"
                    :items-per-page="-1"
                >
                    <template v-slot:item.updated_at="{ item }">
                        <v-checkbox
                            v-model="selecteds"
                            :value="item.id"
                            :label="`${__dayjs(item.updated_at).format(
                                'DD/MM/YYYY HH:mm:ss'
                            )}`"
                            
                            v-if="getUser.user_type === 1"
                        ></v-checkbox>
                        
                        <span v-else>{{ __dayjs(item.updated_at).format('DD/MM/YYYY HH:mm:ss') }}</span>
                    </template>

                    <template v-slot:item.user_created_at="{ item }">
                        <template v-if="item.user_created">
                            <p class="text_origin_func">{{item.user_created.type == 1 ? "Administrador" : "Consultor"}}</p>
                            <p class="text_origin_name">{{item.user_created.username}}</p>
                        </template>
                        
                        <span v-else>Site</span>
                    </template>

                    <template v-slot:item.arrow="{ item }">
                        <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            @click="$router.push('/clients/view/' + item.id)"
                        >
                            <path
                                d="M5 12H19"
                                stroke="#828282"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                d="M12 5L19 12L12 19"
                                stroke="#828282"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                        </svg>
                    </template>

                    <template v-slot:item.action="{ item }">
                        <v-menu offset-y>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    color="primary"
                                    dark
                                    v-bind="attrs"
                                    v-on="on"
                                    text
                                >
                                    <v-icon>mdi-dots-horizontal</v-icon>
                                </v-btn>
                            </template>
                            <v-card class="mx-auto" max-width="300" tile>
                                <v-list>
                                    <v-list-item link>
                                        <v-list-item-title
                                            @click="
                                                $router.push(
                                                    '/clients/view/' + item.id
                                                )
                                            "
                                            ><v-icon>mdi-eye-outline</v-icon>
                                            Visualizar</v-list-item-title
                                        >
                                    </v-list-item>
                                    <v-list-item link>
                                        <v-list-item-title
                                            @click="
                                                $router.push(
                                                    '/clients/edit/' + item.id
                                                )
                                            "
                                            ><v-icon
                                                >mdi-square-edit-outline</v-icon
                                            >
                                            Editar</v-list-item-title
                                        >
                                    </v-list-item>
                                    <v-divider></v-divider>

                                    <v-list-item link>
                                        <v-list-item-title
                                            @click="onDelete(item)"
                                            ><v-icon
                                                >mdi-trash-can-outline</v-icon
                                            >
                                            Excluir</v-list-item-title
                                        >
                                    </v-list-item>
                                </v-list>
                            </v-card>
                        </v-menu>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <div class="text-center">
                    <v-pagination
                        v-model="page"
                        :length="totalPage"
                        :total-visible="7"
                        next-aria-label="Próximo"
                        @input="load($event)"
                    ></v-pagination>
                </div>
            </v-col>
        </v-row>

        <v-dialog
            v-model="confirmationDialogDelete"
            transition="dialog-top-transition"
            max-width="600"
            persistent
        >
            <v-card class="pa-5" v-if="client">
                <v-card-text class="pt-4">
                    <h1 class="text-center mb-4">
                        Deseja excluir este cliente?
                    </h1>
                    <span class="d-block text-center mt-4 mb-5"
                        >Importante: essa ação não poderá ser desfeita.</span
                    >

                    <div class="mt-5 mb-5">
                        <span class="d-block font-weight-medium">Cliente</span>
                        <v-divider></v-divider>
                        <div class="mb-3">
                            <span class="d-block">{{ client.name }}</span>
                            <span class="d-block">{{
                                client.user ? client.user.email : "-"
                            }}</span>
                            <span class="d-block">{{ client.cpf_cnpj }}</span>
                        </div>
                    </div>

                    <v-btn
                        :disabled="isLoadingDelete"
                        :loading="isLoadingDelete"
                        elevation="2"
                        large
                        block
                        text
                        class="white--text grey darken-3 mt-5 elevation-0 mx-auto"
                        @click="onSendDelete"
                    >
                        Sim, confirmo a exclusão
                    </v-btn>
                    <v-btn
                        :disabled="isLoadingDelete"
                        :loading="isLoadingDelete"
                        large
                        block
                        text
                        class="mt-3"
                        @click="confirmationDialogDelete = false"
                        >Cancelar</v-btn
                    >
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import BreadcrumbsComponent from "./../../components/BreadcrumbsComponent/Index";
import FilterPanelComponent from "./../../components/FilterPanelComponent/Index";
import ActionBarComponent from "./../../components/ActionBarComponent/Index";
import Papa from "papaparse";

import { mapActions, mapGetters } from "vuex";
import dayjs from "dayjs";

export default {
    components: {
        BreadcrumbsComponent,
        FilterPanelComponent,
        ActionBarComponent,
    },
    data: () => {
        return {
            selected: false,
            selecteds: [],
            breadcrumbs: [
                {
                    text: "Dashboard",
                },
                {
                    text: "Clientes",
                    disabled: true,
                },
            ],
            searching: "",
            dialog: false,
            confirmationDialog: false,
            showContent: false,
            headers: [],
            items: [],
            isLoading: true,
            isLoadingDelete: false,
            page: 1,
            totalPage: 1,
            timerSearch: null,
            filter: {
                query: "",
            },
            isSearched: false,

            confirmationDialogDelete: false,
            client: null,
        };
    },
    methods: {
        ...mapActions(["listClients", "deleteManyClient", "deleteClient"]),

        onDelete(item) {
            this.client = item;
            this.confirmationDialogDelete = true;
        },

        async onSendDelete() {
            this.isLoadingDelete = true;

            const resp = await this.deleteClient(this.client.id);

            if (resp) {
                this.$eventHub.$emit("snackBar", {
                    color: "success",
                    message: "Cliente apagado com sucesso",
                });
                this.confirmationDialogDelete = false;
                this.load();
            } else {
                this.$eventHub.$emit("snackBar", {
                    color: "error",
                    message: "Erro ao apagar cliente",
                });
            }

            this.isLoadingDelete = false;
        },

        async load(page = 1, csv = false) {
            this.isLoading = true;
            const filter = { ...this.filter };
            filter.page = page;
            if (csv) filter.csv = csv;
            const resp = await this.listClients(filter);
            
            if (resp) {
                if (filter.csv) {
                    const itensCsv = [];
                    for (let i in resp) {
                        const obj = {
                            Nome: resp[i].name,
                            Email: resp[i].user ? resp[i].user.email : "",
                            "CPF/CNPJ": resp[i].cpf_cnpj,
                            Telefone: resp[i].phone
                        };
                        itensCsv.push(obj);
                    }

                    let csv = Papa.unparse(itensCsv, { delimiter: ";" });
                    let csvData = new Blob(["\ufeff", csv], {
                        type: "text/csv;charset=utf-8;",
                    });
                    let csvURL = null;
                    if (navigator.msSaveBlob) {
                        csvURL = navigator.msSaveBlob(csvData, "clientes.csv");
                    } else {
                        csvURL = window.URL.createObjectURL(csvData);
                    }

                    let tempLink = document.createElement("a");
                    tempLink.href = csvURL;
                    tempLink.setAttribute("download", "clientes.csv");
                    tempLink.click();
                } else {
                    this.items = resp.data;
                    this.totalPage = resp.last_page;
                }
            }
            this.isLoading = false;
        },

        __dayjs(date) {
            return dayjs(date);
        },

        setLoading() {
            this.$eventHub.emit("loading", {
                title: "Gerando a planilha de dados ...",
                subtitle:
                    "Aguarde só um instante, logo mais o download do <br/> arquivo será iniciado automaticamente.",
                status: true,
            });
        },

        sendFilter(query) {
            this.filter.query = query;
            this.isLoading = true;
            if ((query != "" && query) || this.isSearched) {
                clearTimeout(this.timerSearch);
                this.timerSearch = setTimeout(async () => {
                    await this.load(1);

                    this.isLoading = false;
                    if (query) this.isSearched = true;
                }, 800);
            } else {
                this.isLoading = false;
                clearTimeout(this.timerSearch);
            }
        },

        async onDeleteMany() {
            this.isLoadingDelete = true;

            const ids = this.selecteds;

            const r = await this.deleteManyClient({ ids });

            if (r) {
                this.$eventHub.$emit("snackBar", {
                    color: "success",
                    message: "Clientes apagados com sucesso",
                });
                this.selecteds = [];
                this.confirmationDialog = false;
                this.load();
            } else {
                this.$eventHub.$emit("snackBar", {
                    color: "error",
                    message: "Erro ao apagar clientes",
                });
            }

            this.isLoadingDelete = false;
        },
    },
    computed: {
        ...mapGetters(["getUser"]),
    },
    mounted() {
        this.headers = [
            {
                text: "ATUALIZADO EM",
                align: "start",
                sortable: false,
                value: "updated_at",
            },
            {
                text: "NOME",
                align: "start",
                sortable: false,
                value: "name",
            },
            {
                text: "E-MAIL",
                align: "start",
                sortable: false,
                value: "user.email",
            },
            {
                text: "Telefone",
                align: "start",
                sortable: false,
                value: "phone",
            },
            {
                text: "CPF/CNPJ",
                align: "start",
                sortable: false,
                value: "cpf_cnpj",
            },
            {
                text: "ESTADO",
                align: "start",
                sortable: false,
                value: "state.name",
            },
            {
                text: "ORIGEM",
                align: "start",
                sortable: false,
                value: "user_created_at",
            },
            {
                text: "",
                align: "start",
                sortable: false,
                value: "opcao",
            },
            {
                text: "",
                align: "start",
                sortable: false,
                value: this.getUser.user_type === 1 ? "action" : "arrow",
            },
        ];
        this.load();
    },
};
</script>

<style>
    .text_origin_func{
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        margin: 0 !important;
    }

    .text_origin_name{
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        margin: 0 !important;
        font-size: #5f5873;
    }
</style>