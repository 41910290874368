<template>
    <div class="dropzone" :style="{backgroundColor:bgColor, border: noBorder ? 'none' : '1px dashed #d3d3d3'}" @dragend="dragEnd" @drop="dropHandler" @dragover="dragOverHandler" @click="$refs.dropzoneFile.value='',$refs.dropzoneFile.click()">
        <input :multiple="multiple" type="file" @change="getFile" ref="dropzoneFile" class="d-none">
        <slot name="content"></slot>
    </div>
</template>

<script>
    export default {
        name:'DropzoneComponent',
        props:{
            bgColor:{
                type: String,
                default:'transparent'
            },
            noBorder:{
                type:Boolean,
                default: false
            },
            multiple: {
                type: Boolean,
                default: false
            }
        },
        data () {
            return {
                file: null,
                ondrop:false
            }
        },
        methods:{
            dropHandler(e)
            {
                e.preventDefault()
                if (this.multiple) {
                    if ( e.dataTransfer.items && e.dataTransfer.items.length > 0 )
                    {
                        let array = []
                        for (let i in e.dataTransfer.items) {
                            array.push(e.dataTransfer.items[i].getAsFile())
                        }
                        this.$emit('onLeave', array)
                    }
                } else {
                    if ( e.dataTransfer.items && e.dataTransfer.items.length > 0 )
                    {
                        this.file = e.dataTransfer.items[0].getAsFile()
                        this.$emit('onLeave', e.dataTransfer.items[0].getAsFile())
                    }    
                }
                
                this.ondrop = false
            },
            dragOverHandler(e)
            {
                e.preventDefault()
                this.ondrop = true
                this.$emit('onOver', e)
            },
            dragEnd(e)
            {
                e.preventDefault()
                this.ondrop = false
            },
            getFile(e)
            {
                if( e.target.files.length > 0 )
                {
                    if (this.multiple) {
                        this.$emit('onLeave', e.target.files)
                    } else {
                        this.file = e.target.files[0]
                        this.$emit('onLeave', e.target.files[0])
                    }
                    
                }
            },
            getFileSize()
            {
                const size = ((this.file.size / 1024) / 1024).toString().substr(0, 7)
                return ( size > 1) ? `${size}MB` : `${size}KB`
            },
            processImage(file)
            {
                return new Promise((res, rej) => {
                    const reader = new FileReader()
                    reader.readAsBinaryString( file )
                    reader.onload  = () => res( btoa(reader.content || reader.result) )
                    reader.onerror = e => rej( e )
                })
            },
            async getBase64Image()
            {
                console.log(
                    `data:${this.file.type};base64,${await this.processImage( this.file )}`
                )
            }
        }
    }
</script>

<style scoped>
    .dropzone{
        cursor:pointer;
    }
</style>