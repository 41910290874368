import Http from '@/plugins/Http'

export default {
    async listAdmins ({}, params = {}) {
        try {
            const { data } = await Http.get('/administrators', {params})
            return data
        } catch (e) {
            return false            
        }
    },

    async insertAdmin ({}, admin) {
        try {
            await Http.post('/administrators', admin)
            return true
        } catch (e) {
            console.log(e.response.data);
            return false
        }
    },


    async showAdmin ({}, id) {
        try {
            const { data } = await Http.get(`/administrators/${id}`)
            return data
        } catch (e) {
            return false
        }
    },

    async updateAdmin ({}, admin) {
        try {
            await Http.put('/administrators/' + admin.id, admin)
            return true
        } catch (e) {
            console.log(e)
            return false
        }
    },


    async deleteAdmin ({}, id) {
        try {
            await Http.delete('/administrators/' + id)
            return true
        } catch (e) {
            return false
        }
    },

    async deleteManyAdmins ({}, ids) {
        try {
            await Http.delete('/administrators/delete/many', {
                data: ids
            })
            return true
        } catch (e) {
            return false
        }
    },

    
}
