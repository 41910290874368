<template>
    <v-list two-line>
        <v-list-item-group>
            <template v-if="items && items.length > 0">
                <template v-for="(item, index) in items">
                    <v-divider v-if="index == 0" :key="index"></v-divider>
                    <v-list-item :key="item.title">
                        <template>
                            <v-list-item-content>
                                <v-list-item-title class="text-caption grey--text text--darken-2 font-weight-medium" v-text="item.title"></v-list-item-title>
                                <v-list-item-subtitle class="text-body-2 grey--text text--darken-2 font-weight-regular mb-2" v-text="item.value"></v-list-item-subtitle>
                            </v-list-item-content>
                        </template>
                    </v-list-item>
                    <v-divider v-if="index < items.length - 1" :key="item.value"></v-divider>
                </template>
            </template>
            <slot name="item"></slot>
        </v-list-item-group>
    </v-list>
</template>


<script>
    export default {
        name: 'DataListComponent',
        props: {
            items:{
                type: Array,
                default(){
                    return []
                }
            }
        }
    }
</script>
