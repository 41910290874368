<template>
    <div>
        <v-row no-gutters class="mt-10 mb-8">
            <BreadcrumbsComponent title="Clientes" :items="breadcrumbs">
                <template v-slot:actions>
                    <v-btn elevation="2" large class="white--text grey darken-3 mt-2 ml-2 elevation-0" @click="$router.push(`/clients/view/${$route.params.id}`)">
                        <v-icon class="mr-2">mdi-arrow-left</v-icon>
                        Voltar
                    </v-btn>
                </template>
            </BreadcrumbsComponent>
        </v-row>

        <v-data-table
            :headers="headers"
            :items="items"
            hide-default-footer
            :items-per-page="-1"
        >
            <template v-slot:item.updatedat="{ item }">
                {{__dayjs(item.updated_at).format('DD/MM/YYYY HH:mm:ss')}}
            </template>
            
            <template v-slot:item.installers.name="{ item }">
                <span class="d-block grey--text text--darken-2">{{ item.installers && item.installers.name || '' }}</span>
                <span class="grey--text">{{ item.installers && item.installers.phone || '' }}</span>
            </template>

            <template v-slot:item.budget.client.name="{ item }">
                <span class="d-block grey--text text--darken-2">{{ item.budget.client.name }}</span>
                <span class="grey--text">{{ item.budget.client.phone }}</span>
            </template>

            <template v-slot:item.consultant="{ item }">
                <div class="d-flex align-center">
                    <v-avatar><img src="https://cdn.vuetifyjs.com/images/john.jpg" alt="John"></v-avatar>
                    
                    <div class="ml-2">
                        <span class="d-block grey--text text--darken-2">{{ item.budget.consultant.name }}</span>
                        <span class="grey--text caption">{{ item.budget.consultant.phone }}</span>
                    </div>
                </div>
            </template>

            <template v-slot:item.status="{ item }">
                <span>Pendente</span>
                <v-progress-linear color="pink" class="mt-1" :value="item.status.value"></v-progress-linear>
            </template>
        </v-data-table>
    </div>
</template>

<script>
    import dayjs from 'dayjs'
    import { mapActions } from 'vuex'
    import BreadcrumbsComponent from "@/components/BreadcrumbsComponent/Index"

    export default {
        components: {
            BreadcrumbsComponent,
        },
        data: () => {
            return {
                items:[],
                showContent:false,
                breadcrumbs: [
                    {
                        text: 'Dashboard'
                    },
                    {
                        text: 'Clientes',
                        disabled: true
                    },
                    {
                        text: 'Pedidos',
                        disabled: true
                    }
                ],
                headers: [
                    {
                        text: 'ATUALIZADO EM',
                        align: 'start',
                        sortable: false,
                        value: 'updatedat',
                    },
                    {
                        text: 'ID',
                        align: 'start',
                        sortable: false,
                        value: 'id',
                    },
                    {
                        text: 'INSTALADORES',
                        align: 'start',
                        sortable: false,
                        value: 'installers.name',
                    },
                    {
                        text: 'CLIENTE',
                        align: 'start',
                        sortable: false,
                        value: 'budget.client.name',
                    },
                    {
                        text: 'CONSULTOR',
                        align: 'start',
                        sortable: false,
                        value: 'consultant',
                    },
                    {
                        text: 'STATUS',
                        align: 'start',
                        sortable: false,
                        value: 'status',
                    },
                    {
                        text: '',
                        align: 'start',
                        sortable: false,
                        value: 'action'
                    },
                ],
            }
        },
        async mounted()
        {
            try {
                const {data} = await this.listOrders(`?client_id=${this.$route.params.id}`)
                this.items = data
            } catch (error) {
                this.$eventHub.$emit('snackBar', {color: 'error', message: 'Erro ao listar simulações'})
            }
        },
        methods: {
            ...mapActions(['listOrders']),
            __dayjs (date) 
            {
                return dayjs(date)
            }
        }
    }
</script>