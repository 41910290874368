<template>
    <div>
        <v-row no-gutters class="mt-10 mb-8">
            <BreadcrumbsComponent title="Instalador" :items="breadcrumbs" />
        </v-row>

        <v-row v-if="isLoading">
            <v-col md="12" class="text-center">
                <v-progress-circular indeterminate></v-progress-circular>
            </v-col>
        </v-row>

        <form
            v-else
            @keydown.enter="!isLoadingUpdate && isValid && onUpdatetInstaller()"
        >




            <v-dialog v-model="dialogCrop" max-width="600">
                <v-card v-if="dialogCrop">
                    <v-card-text>

                        <vue-anka-cropper
                            :options="{
                           aspectRatio: 1,
                           closeOnSave: true,
                           cropArea: 'circle',
                           croppedHeight: 400,
                           croppedWidth: 400,
                           cropperHeight: false,
                           dropareaMessage: 'Arraste o logo da sua empresa ou clique aqui para fazer o upload do arquivo',
                           frameLineDash: [5,3],
                           frameStrokeColor: 'rgba(255, 255, 255, 0.8)',
                           handleFillColor: 'rgba(255, 255, 255, 0.2)',
                           handleHoverFillColor: 'rgba(255, 255, 255, 0.4)',
                           handleHoverStrokeColor: 'rgba(255, 255, 255, 1)',
                           handleSize: 10,
                           handleStrokeColor: 'rgba(255, 255, 255, 0.8)',
                           layoutBreakpoint: 850,
                           maxCropperHeight: 768,
                           maxFileSize: 8000000,
                           overlayFill: 'rgba(0, 0, 0, 0.5)',
                           previewOnDrag: true,
                           previewQuality: 0.65,
                           resultQuality: 0.8,
                           resultMimeType: 'image/jpeg',
                           selectButtonLabel: 'Selecionar Arquivo',
                           showPreview: false,
                           skin: 'light',
                           uploadData: {},
                           uploadTo: false
                        }"
                            @cropper-mounted="onCropperMounted"
                            @cropper-saved="onCropped"
                            ref="dropZone"
                        >
                            <template v-slot:areaDrop>
                                <div
                                    @click="$refs.dropZone.triggerInput()"
                                    @dragend="dragEnd"
                                    @drop="dropHandler"
                                    @dragover="dragOverHandler"
                                >
                                    <CardComponent>
                                        <template v-slot:content>
                                            <div class="text-center" v-if="!installer.image">
                                                <svg
                                                    width="132"
                                                    height="132"
                                                    viewBox="0 0 132 132"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <circle
                                                        cx="66"
                                                        cy="66"
                                                        r="66"
                                                        fill="#BDBDBD"
                                                    />
                                                    <path
                                                        d="M80.6654 75.3333C80.6654 76.0406 80.3844 76.7189 79.8843 77.219C79.3842 77.719 78.7059 78 77.9987 78H53.9987C53.2915 78 52.6132 77.719 52.1131 77.219C51.613 76.7189 51.332 76.0406 51.332 75.3333V60.6667C51.332 59.9594 51.613 59.2811 52.1131 58.781C52.6132 58.281 53.2915 58 53.9987 58H59.332L61.9987 54H69.9987L72.6654 58H77.9987C78.7059 58 79.3842 58.281 79.8843 58.781C80.3844 59.2811 80.6654 59.9594 80.6654 60.6667V75.3333Z"
                                                        stroke="#F2F2F2"
                                                        stroke-width="2"
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                    />
                                                    <path
                                                        d="M66.0013 72.6667C68.9468 72.6667 71.3346 70.2789 71.3346 67.3333C71.3346 64.3878 68.9468 62 66.0013 62C63.0558 62 60.668 64.3878 60.668 67.3333C60.668 70.2789 63.0558 72.6667 66.0013 72.6667Z"
                                                        stroke="#F2F2F2"
                                                        stroke-width="2"
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                    />
                                                </svg>
                                            </div>

                                            <div class="text-center" v-else>
                                                <img
                                                    v-if="installer.image"
                                                    :src="installer.image"
                                                    width="132px"
                                                    style="border-radius: 50%"
                                                />
                                            </div>
                                            <h3
                                                class="mt-5 mb-3 dropzone-custom-title font-weight-light text-center grey--text text--darken-1"
                                            >
                                                Arraste a foto aqui ou<br />
                                                clique para fazer o<br />
                                                upload do avatar
                                            </h3>
                                            <div class="caption text-center mt-5">
                                                <i
                                                ><strong>Dica:</strong> use um
                                                    arquivo PNG, <br />
                                                    quadrado, com 256x256 pixels</i
                                                >
                                            </div>
                                        </template>
                                    </CardComponent>
                                </div>
                            </template>

                        </vue-anka-cropper>
                    </v-card-text>
                </v-card>
            </v-dialog>
            
            <v-row>
                <v-col cols="12" md="3">
                    <div

                        @click="onInitDrop"
                        @dragend="dragEnd"
                        @drop="dropHandler"
                        @dragover="dragOverHandler"
                    >

                        <CardComponent>
                            <template v-slot:content>
                                <div class="text-center" v-if="!installer.image">
                                    <svg
                                        width="132"
                                        height="132"
                                        viewBox="0 0 132 132"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <circle
                                            cx="66"
                                            cy="66"
                                            r="66"
                                            fill="#BDBDBD"
                                        />
                                        <path
                                            d="M80.6654 75.3333C80.6654 76.0406 80.3844 76.7189 79.8843 77.219C79.3842 77.719 78.7059 78 77.9987 78H53.9987C53.2915 78 52.6132 77.719 52.1131 77.219C51.613 76.7189 51.332 76.0406 51.332 75.3333V60.6667C51.332 59.9594 51.613 59.2811 52.1131 58.781C52.6132 58.281 53.2915 58 53.9987 58H59.332L61.9987 54H69.9987L72.6654 58H77.9987C78.7059 58 79.3842 58.281 79.8843 58.781C80.3844 59.2811 80.6654 59.9594 80.6654 60.6667V75.3333Z"
                                            stroke="#F2F2F2"
                                            stroke-width="2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        />
                                        <path
                                            d="M66.0013 72.6667C68.9468 72.6667 71.3346 70.2789 71.3346 67.3333C71.3346 64.3878 68.9468 62 66.0013 62C63.0558 62 60.668 64.3878 60.668 67.3333C60.668 70.2789 63.0558 72.6667 66.0013 72.6667Z"
                                            stroke="#F2F2F2"
                                            stroke-width="2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        />
                                    </svg>
                                </div>
                                <div class="text-center" v-else>
                                    <img
                                        v-if="installer.image"
                                        :src="installer.image"
                                        width="132px"
                                        style="border-radius: 50%"
                                    />
                                </div>
                                <h3
                                    class="mt-5 mb-3 dropzone-custom-title font-weight-light text-center grey--text text--darken-1"
                                >
                                    Arraste a foto aqui ou<br />
                                    clique para fazer o<br />
                                    upload do avatar
                                </h3>
                                <div class="caption text-center mt-5">
                                    <i
                                    ><strong>Dica:</strong> use um
                                        arquivo PNG, <br />
                                        quadrado, com 256x256 pixels</i
                                    >
                                </div>
                            </template>
                        </CardComponent>

                    </div>

                    <!--<img
                        v-if="installer.photo"
                        :src="installer.photo"
                        :style="{ maxWidth: '100%' }"
                    />
                    <v-icon v-if="installer.photo" @click="installer.photo = ''"
                        >mdi-close</v-icon
                    >-->
                </v-col>

                <v-col cols="12" md="9">
                    <v-col cols="12" md="9">
                        <v-text-field
                            label="CNPJ"
                            placeholder="CNPJ"
                            outlined
                            hint="Apenas números"
                            persistent-hint
                            v-mask="'##.###.###/####-##'"
                            v-model="installer.cnpj"
                        ></v-text-field>
                    </v-col>

                    <v-col cols="12" md="9">
                        <v-text-field
                            label="Nome fantasia"
                            placeholder="Nome fantasia"
                            outlined
                            v-model="installer.trading_name"
                        ></v-text-field>
                    </v-col>

                    <v-col cols="12" md="9">
                        <v-text-field
                            label="Razão social"
                            placeholder="Razão social"
                            outlined
                            v-model="installer.company_name"
                        ></v-text-field>
                    </v-col>

                    <v-col cols="12" md="9">
                        <v-text-field
                            label="E-mail"
                            persistent-hint
                            hint="Uma senha automática será gerada e enviada para o e-mail cadastrado."
                            placeholder="E-mail"
                            outlined
                            v-model="installer.email"
                        ></v-text-field>
                    </v-col>

                    <v-col cols="12" md="9">
                        <v-text-field
                            label="Nome do responsável"
                            placeholder="Nome do responsável"
                            outlined
                            v-model="installer.representative"
                        ></v-text-field>
                    </v-col>

                    <v-col cols="12" md="9">
                        <vuetify-google-autocomplete
                            ref="address"
                            id="map"
                            classname="form-control"
                            placeholder="Endereço"
                            v-on:placechanged="getAddressData"
                            country="br"
                            outlined
                            append-icon="mdi-magnify"
                            v-model="installer.address"
                        >
                        </vuetify-google-autocomplete>
                    </v-col>

                    <v-col cols="12" md="9">
                        <v-text-field
                            label="DDD Celular"
                            placeholder="DDD Celular"
                            outlined
                            hint="Apenas números"
                            persistent-hint
                            v-model="installer.phone"
                            v-mask="['(##) ####-####', '(##) #####-####']"
                        ></v-text-field>
                    </v-col>

                    <v-col cols="12" md="9">
                        <v-text-field
                            label="Site da empresa"
                            placeholder="Site da empresa"
                            outlined
                            v-model="installer.site"
                            hint="Inserir com http:// ou https:// , exemplo: https://marketsolar.com.br"
                            persistent-hint
                        ></v-text-field>
                    </v-col>

                    <v-col cols="12" v-if="getCompanySize.length > 0">
                        <span class="d-block grey--text text--darken-4"
                            >Selecione todas as opções que forem
                            compatíveis</span
                        >
                        <span class="d-block grey--text text--darken-3"
                            >Quais portes de projeto você atende?</span
                        >
                        <v-checkbox
                            v-for="(item, i) in getCompanySize"
                            v-model="installer.company_size"
                            :value="item.id"
                            :key="i"
                            persistent-hint
                            :hint="item.description"
                            :label="item.size"
                        ></v-checkbox>
                    </v-col>

                    <v-col
                        cols="12"
                        class="mt-5"
                        v-if="getConsumerType.length > 0"
                    >
                        <span class="d-block grey--text text--darken-4"
                            >Selecione todas as opções que forem
                            compatíveis</span
                        >
                        <span class="d-block grey--text text--darken-3"
                            >Quais tipos de cliente você atende?</span
                        >
                        <v-checkbox
                            v-for="(item, i) in getConsumerType"
                            v-model="installer.consumer_type"
                            :value="item.id"
                            :key="i"
                            persistent-hint
                            :hint="item.description"
                            :label="item.type"
                        ></v-checkbox>
                    </v-col>

                    <v-col cols="12" md="8">
                        <TextBlockComponent
                            @onClick="placesDialog = true"
                            class="mb-4"
                            v-bind="regions"
                        />
                    </v-col>

                    <v-col cols="12" md="9">
                        <p>Sobre a empresa</p>
                        <vue-editor v-model="installer.description" />
                    </v-col>

                    <v-col cols="12" md="8">
                        <TextBlockComponent
                            class="mb-4"
                            v-bind="photos"
                            @onClick="dialogImagens = true"
                        />
                    </v-col>

                    <v-col cols="12" md="8">
                        <TextBlockComponent
                            class="mb-4"
                            v-bind="obras"
                            @onClick="dialogObras = true"
                        />
                    </v-col>


                    <v-col cols="12" md="9" v-if="getUser.user_type === 1">
                        <v-select
                            v-model="installer.plan_id"
                            label="Plano"
                            :items="plans"
                            item-text="name"
                            item-value="id"
                            outlined
                        ></v-select>
                    </v-col>

                    <v-col cols="12" md="8">
                        <v-btn
                            :disabled="!isValid || isLoadingUpdate"
                            :loading="isLoadingUpdate"
                            elevation="2"
                            @click="onUpdatetInstaller"
                            large
                            block
                            text
                            class="white--text grey darken-3 mt-5 elevation-0 mx-auto"
                            dark
                            >Atualizar</v-btn
                        >
                        <v-btn
                            large
                            block
                            text
                            class="mt-3"
                            @click="$router.push('/installers')"
                            >Cancelar</v-btn
                        >
                    </v-col>
                </v-col>
            </v-row>
        </form>

        <PlacesComponent
            v-if="placesDialog"
            @onClose="placesDialog = false"
            @onSave="onSelectedRegion"
            :dialog="placesDialog"
            :initSelecteds="installer.stateCity"
        />

        <v-dialog
            v-model="dialogImagens"
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
        >
            <v-app id="inspire" class="grey lighten-2">
                <v-content>
                    <v-row flat class="grey lighten-2">
                        <v-col cols="12">
                            <v-toolbar flat color="grey lighten-2">
                                <v-toolbar-title>
                                    <v-btn icon @click="dialogImagens = false"
                                        ><v-icon>mdi-arrow-left</v-icon></v-btn
                                    >
                                </v-toolbar-title>
                            </v-toolbar>
                        </v-col>
                    </v-row>

                    <v-col cols="12" no-gutters>
                        <v-row>
                            <v-col cols="12" md="12">
                                <h1>Fotos</h1>
                            </v-col>

                            <v-col cols="12" md="12">
                                <DropzoneComponent
                                    @onOver="onOver"
                                    @onLeave="onLeaveImages"
                                    bgColor="transparent"
                                    :noBorder="true"
                                    :multiple="true"
                                >
                                    <template v-slot:content>
                                        <CardComponent>
                                            <template v-slot:content>
                                                <div class="text-center">
                                                    <svg
                                                        width="132"
                                                        height="132"
                                                        viewBox="0 0 132 132"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <circle
                                                            cx="66"
                                                            cy="66"
                                                            r="66"
                                                            fill="#BDBDBD"
                                                        />
                                                        <path
                                                            d="M80.6654 75.3333C80.6654 76.0406 80.3844 76.7189 79.8843 77.219C79.3842 77.719 78.7059 78 77.9987 78H53.9987C53.2915 78 52.6132 77.719 52.1131 77.219C51.613 76.7189 51.332 76.0406 51.332 75.3333V60.6667C51.332 59.9594 51.613 59.2811 52.1131 58.781C52.6132 58.281 53.2915 58 53.9987 58H59.332L61.9987 54H69.9987L72.6654 58H77.9987C78.7059 58 79.3842 58.281 79.8843 58.781C80.3844 59.2811 80.6654 59.9594 80.6654 60.6667V75.3333Z"
                                                            stroke="#F2F2F2"
                                                            stroke-width="2"
                                                            stroke-linecap="round"
                                                            stroke-linejoin="round"
                                                        />
                                                        <path
                                                            d="M66.0013 72.6667C68.9468 72.6667 71.3346 70.2789 71.3346 67.3333C71.3346 64.3878 68.9468 62 66.0013 62C63.0558 62 60.668 64.3878 60.668 67.3333C60.668 70.2789 63.0558 72.6667 66.0013 72.6667Z"
                                                            stroke="#F2F2F2"
                                                            stroke-width="2"
                                                            stroke-linecap="round"
                                                            stroke-linejoin="round"
                                                        />
                                                    </svg>
                                                </div>
                                                <h3
                                                    class="mt-5 mb-3 dropzone-custom-title font-weight-light text-center grey--text text--darken-1"
                                                >
                                                    Arraste as fotos da sua
                                                    empresa ou clique para
                                                    selecionar os arquivos
                                                    que<br />
                                                    Deseja selecionar. Você pode
                                                    selecionar/arrastar vários
                                                    arquivos de uma vez
                                                </h3>
                                                <div
                                                    class="caption text-center mt-5"
                                                >
                                                    <i
                                                        ><strong>Dica:</strong>
                                                        use arquivos JPG,
                                                        quadrado, com 1200x1200
                                                        pixels. Suba fotos da
                                                        sua empresa: da fachada,
                                                        recepção, escritório, do
                                                        seu time no escritório
                                                        ou trabalhando na
                                                        instalação das obras.
                                                    </i>
                                                </div>
                                            </template>
                                        </CardComponent>
                                    </template>
                                </DropzoneComponent>
                            </v-col>

                            <v-col
                                cols="12"
                                md="3"
                                v-for="(img, index) in installer.photos"
                                :key="index"
                                class="contentGrid"
                            >
                                <CardComponent
                                    :image="img.url"
                                    style="height: 100%"
                                >
                                    <template v-slot:actionsfloat>
                                        <v-btn
                                            class="white ml-3"
                                            depressed
                                            elevation="2"
                                            icon
                                            @click="removeImage(index)"
                                        >
                                            <v-icon
                                                >mdi-trash-can-outline</v-icon
                                            >
                                        </v-btn>
                                    </template>
                                </CardComponent>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-content>
            </v-app>
        </v-dialog>

        <v-dialog :value="removeDialog !== null" persistent max-width="290">
            <v-card>
                <v-card-title class="text-h5"> Remover Obra </v-card-title>

                <v-card-text>Tem certeza que deseja remover?</v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="green darken-1"
                        text
                        @click="removeDialog = null"
                    >
                        Cancelar
                    </v-btn>

                    <v-btn
                        color="green darken-1"
                        text
                        @click="
                            removeObra(removeDialog);
                            removeDialog = null;
                        "
                    >
                        Remover
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog
            v-model="dialogObras"
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
        >
            <v-app id="inspire" class="grey lighten-2">
                <v-content>
                    <v-row flat class="grey lighten-2">
                        <v-col cols="12">
                            <v-toolbar flat color="grey lighten-2">
                                <v-toolbar-title>
                                    <v-btn icon @click="dialogObras = false"
                                        ><v-icon>mdi-arrow-left</v-icon></v-btn
                                    >
                                </v-toolbar-title>
                            </v-toolbar>
                        </v-col>
                    </v-row>

                    <v-col cols="12" no-gutters>
                        <v-row>
                            <v-col cols="12" md="3">
                                <CardComponent>
                                    <template v-slot:content>
                                        <div
                                            class="grey--text text--darken-2 subtitle-1 mt-5 mb-5 pt-5 pb-5"
                                            @click="
                                                editing = null;
                                                showAddObra();
                                            "
                                        >
                                            <svg
                                                class="d-block mx-auto"
                                                width="64"
                                                height="64"
                                                viewBox="0 0 64 64"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M32 58.6667C46.7276 58.6667 58.6667 46.7276 58.6667 32C58.6667 17.2724 46.7276 5.33337 32 5.33337C17.2724 5.33337 5.33333 17.2724 5.33333 32C5.33333 46.7276 17.2724 58.6667 32 58.6667Z"
                                                    stroke="#828282"
                                                    stroke-width="2"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                />
                                                <path
                                                    d="M32 21.3334V42.6667"
                                                    stroke="#828282"
                                                    stroke-width="2"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                />
                                                <path
                                                    d="M21.3333 32H42.6667"
                                                    stroke="#828282"
                                                    stroke-width="2"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                />
                                            </svg>
                                            <span
                                                class="d-block text-center text-h5 mt-5 mb-5 font-weight-light"
                                                >Clique aqui para<br />
                                                cadastrar uma nova<br />
                                                obra instalada</span
                                            >
                                        </div>
                                    </template>
                                </CardComponent>
                            </v-col>

                            <v-col
                                cols="12"
                                md="3"
                                v-for="(ob, index) in installer.obras"
                                :key="index"
                            >
                                <CardComponent :image="ob.photo">
                                    <template v-slot:content>
                                        <span
                                            class="grey--text text--darken-2 subtitle-1 d-block"
                                            >{{
                                                ob.city
                                                    ? ob.city.customName
                                                    : "-"
                                            }}</span
                                        >
                                        <span
                                            class="black--text text--darken-2 subtitle-1 d-block font-weight-bold"
                                            >{{ ob.enterprise_name }}</span
                                        >
                                        <span
                                            class="grey--text text--darken-2 subtitle-1 d-block"
                                            ><b>{{ ob.kwh_month }}</b> kWh de
                                            geração mensal</span
                                        >
                                        <span
                                            class="grey--text text--darken-2 subtitle-1 d-block"
                                            ><b>R$ {{ ob.value_month }}</b> de
                                            economia mensal</span
                                        >
                                    </template>

                                    <template v-slot:actionsfloat>
                                        <v-btn
                                            class="white"
                                            depressed
                                            elevation="2"
                                            icon
                                            @click="onEditObra(index)"
                                        >
                                            <v-icon
                                                >mdi-square-edit-outline</v-icon
                                            >
                                        </v-btn>

                                        <v-btn
                                            class="white ml-3"
                                            depressed
                                            elevation="2"
                                            icon
                                            @click="removeDialog = index"
                                        >
                                            <v-icon
                                                >mdi-trash-can-outline</v-icon
                                            >
                                        </v-btn>
                                    </template>
                                </CardComponent>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-content>
            </v-app>
        </v-dialog>

        <v-dialog
            transition="dialog-bottom-transition"
            max-width="450"
            v-model="dialogAddObra"
        >
            <template>
                <v-card>
                    <v-card-title class="text-h5">
                        <span v-if="editing !== null">Editar obra</span>
                        <span v-else>Adicionar obra</span>
                    </v-card-title>

                    <v-card-text>
                        <DropzoneComponent
                            @onLeave="onLeaveObra"
                            bgColor="transparent"
                            :noBorder="true"
                        >
                            <template v-slot:content>
                                <v-card class="mx-auto textCardObra">
                                    <div class="imageBgIcon" v-if="!obra.photo">
                                        <v-icon color="#fff" size="30"
                                            >mdi-camera</v-icon
                                        >
                                    </div>

                                    <v-img
                                        v-else
                                        height="171"
                                        :src="obra.photo"
                                    ></v-img>

                                    <v-card-text>
                                        <h3
                                            class="mt-5 mb-3 dropzone-custom-title font-weight-light grey--text text--darken-1"
                                        >
                                            Arraste a foto aqui ou clique para
                                            fazer o upload da foto da obra
                                        </h3>
                                        <div class="caption text-center mt-5">
                                            <i
                                                ><strong>Dica:</strong> use um
                                                arquivo PNG, <br />
                                                quadrado, com 256x256 pixels</i
                                            >
                                        </div>
                                    </v-card-text>
                                </v-card>
                            </template>
                        </DropzoneComponent>
                    </v-card-text>

                    <v-card-text>
                        <v-col cols="12" sm="12" md="12">
                            <v-autocomplete
                                v-model="obra.city"
                                :items="cities"
                                :loading="isLoadingSearch"
                                :search-input.sync="searchCities"
                                no-filter
                                color="grey"
                                hide-no-data
                                hide-selected
                                item-text="customName"
                                item-value="id"
                                label="Busque por cidade"
                                placeholder="Busque por cidade"
                                append-icon="mdi-magnify"
                                return-object
                                item-disabled="disable"
                                outlined
                                @keydown="onSearchCities"
                            ></v-autocomplete>
                        </v-col>

                        <v-col cols="12" sm="12" md="12">
                            <v-text-field
                                label="Nome do empreendimento"
                                placeholder="Nome do empreendimento"
                                outlined
                                v-model="obra.enterprise_name"
                            ></v-text-field>
                        </v-col>

                        <v-col cols="12" sm="12" md="12">
                            <v-text-field
                                label="Geração mensal (em kWh)"
                                placeholder="Geração mensal (em kWh)"
                                outlined
                                v-model="obra.kwh_month"
                                :hint="'Apenas números'"
                                persistent-hint
                            ></v-text-field>
                        </v-col>

                        <v-col cols="12" sm="12" md="12">
                            <vuetify-money
                                v-model="obra.value_month"
                                v-bind="moneyMask"
                                persistent-hint
                            />
                        </v-col>

                        <v-col cols="12" md="12">
                            <v-btn
                                v-if="editing !== null"
                                elevation="2"
                                large
                                block
                                class="white--text grey darken-3 elevation-0"
                                @click="
                                    installer.obras[editing] = obra;
                                    cleanObra();
                                    dialogAddObra = false;
                                "
                                >Atualizar</v-btn
                            >
                            <v-btn
                                v-else
                                elevation="2"
                                large
                                block
                                class="white--text grey darken-3 elevation-0"
                                @click="onAddObra"
                                >Adicionar</v-btn
                            >
                        </v-col>

                        <v-col cols="12" md="12">
                            <v-btn
                                large
                                block
                                text
                                @click="dialogAddObra = false"
                                >Cancelar</v-btn
                            >
                        </v-col>
                    </v-card-text>
                </v-card>
            </template>
        </v-dialog>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import BreadcrumbsComponent from "@/components/BreadcrumbsComponent/Index";
import TextBlockComponent from "@/components/TextBlockComponent/Index";
import DropzoneComponent from "@/components/DropzoneComponent/Index";
import CardComponent from "@/components/CardComponent/Index";
import PlacesComponent from "@/components/PlacesComponent/Index";
import vueAnkaCropper from "../../components/Cropper";

export default {
    components: {
        BreadcrumbsComponent,
        TextBlockComponent,
        DropzoneComponent,
        CardComponent,
        PlacesComponent,
        vueAnkaCropper
    },
    data: () => {
        return {
            plans: [
                {
                    id: null,
                    name: "Gratuito"
                }
            ],
            dialogCrop: false,
            breadcrumbs: [
                {
                    text: "Dashboard",
                },
                {
                    text: "Instaladores",
                    disabled: true,
                },
                {
                    text: "Cadastro",
                    disabled: true,
                },
            ],
            id: null,
            installer: {
                id: "",
                cnpj: "",
                trading_name: "",
                company_name: "",
                email: "",
                representative: "",
                address: "",
                phone: "",
                site: "",
                company_size: [],
                consumer_type: [],
                photo: "",
                city_name: "",
                state_name: "",
                description: "",
                stateCity: [],
                obras: [],
                photos: [],
                plan_id: null,
            },
            isLoadingUpdate: false,
            isLoading: true,
            optionsStates: [],
            regions: {
                icon: {
                    name: "mdi-map-marker-outline",
                    color: "blue--text text--darken-2",
                    props: {},
                },
                title: "REGIÕES ATENTIDAS",
                subtitle: "Busque e selecione estados ou cidades",
                legend: "Nenhuma seleção",
            },
            photos: {
                icon: {
                    name: "mdi-image-multiple",
                    color: "blue--text text--darken-2",
                    props: {},
                },
                title: "FOTOS",
                subtitle: "Crie uma galeria de fotos da sua empresa",
                legend: "Sem fotos adicionadas",
            },
            obras: {
                icon: {
                    name: "mdi-home-city",
                    color: "blue--text text--darken-2",
                    props: {},
                },
                title: "OBRAS INSTALADAS",
                subtitle: "Crie  destaque das principais obras realizadas",
                legend: "Sem obras cadastradas",
            },

            timerSearch: null,
            isLoadingSearch: false,
            searchCities: null,
            cities: [],
            obra: {
                photo: null,
                city: null,
                kwh_month: 0,
                value_month: 0,
                enterprise_name: null,
                nova_imagem: null,
            },
            dialogImagens: false,
            moneyMask: {
                value: "00.00",
                label: "Economia mensal (em R$)",
                placeholder: "Economia mensal (em R$)",
                readonly: false,
                disabled: false,
                outlined: true,
                clearable: true,
                valueWhenIsEmpty: "",
                options: {
                    locale: "pt-BR",
                    prefix: "R$",
                    suffix: "",
                    length: 11,
                    precision: 2,
                },
                properties: {
                    hint: "Apenas números",
                },
            },
            removeDialog: null,
            dialogObras: false,
            dialogAddObra: false,
            placesDialog: false,
            selectedCity: null,
            editing: null,
        };
    },
    methods: {
        ...mapActions([
            "updateInstallers",
            "showInstallers",
            "getStates",
            "loadCompanySize",
            "loadConsumerType",
            "getCities",
            "getPlans"
        ]),



        onInitDrop () {
            this.dialogCrop = true
        },

        onCropperMounted () {
            if (this.useFile) {
                this.$refs.dropZone.selectFileManual(this.useFile)
            } else {
                this.$refs.dropZone.triggerInput()
            }
            //this.useFile = null
        },

        onCropped (event) {
            this.installer.image = event.croppedImageURI
            this.dialogCrop = false
        },


        dropHandler(e)
        {
            e.preventDefault()

            if ( e.dataTransfer.items && e.dataTransfer.items.length > 0 )
            {
                let file = e.dataTransfer.items[0].getAsFile()

                this.dialogCrop = true
                this.useFile = file
            }


            this.ondrop = false
        },
        dragOverHandler(e)
        {
            e.preventDefault()
            this.ondrop = true
            this.$emit('onOver', e)
        },
        dragEnd(e)
        {
            e.preventDefault()
            this.ondrop = false
        },
        
        
        
        async onUpdatetInstaller() {
            this.isLoadingUpdate = true;

            const installer = { ...this.installer };

            const resp = await this.updateInstallers(installer);

            if (resp) {
                this.$eventHub.$emit("snackBar", {
                    color: "success",
                    message: "Instalador editado com sucesso",
                });
                this.$router.push("/installers");
            } else {
                this.$eventHub.$emit("snackBar", {
                    color: "error",
                    message: "Erro ao editar instalador",
                });
            }

            this.isLoadingUpdate = false;
        },

        onOver() {},

        async onLeave(file) {
            this.installer.image = await this.toBase64(file);
        },

        toBase64(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = (error) => reject(error);
            });
        },

        getAddressData(event) {
            this.installer.address = event.name;
            this.installer.city_name = event.administrative_area_level_2;
            this.installer.state_name = event.administrative_area_level_1;
        },
        onSelectedRegion(event) {
            this.installer.stateCity = event;
            if (event.length > 0) {
                this.regions.legend = `${event.length} selecionado(os)`;
            } else {
                this.regions.legend = "Nenhuma seleção";
            }
        },
        showAddObra() {
            this.cleanObra();
            this.dialogAddObra = true;
        },
        onAddObra() {
            this.installer.obras.push(this.obra);
            this.cleanObra();

            this.dialogAddObra = false;

            if (this.installer.obras.length > 0) {
                this.obras.legend = `${this.installer.obras.length} criado(os)`;
            } else {
                this.obras.legend = "Sem obras cadastradas";
            }
        },

        onEditObra(index) {
            this.editing = index;
            this.obra = this.installer.obras[index];

            if (this.obra.city && this.obra.state) {
                let obj = this.obra.city;

                obj.state = this.obra.state;

                obj.customName = `${obj.name} (${obj.state.uf})`;

                this.cities = [obj];
            } else {
                this.cities = [];
            }

            this.dialogAddObra = true;
        },

        removeObra(index) {
            let obra = this.installer.obras[index];

            this.installer.obras_apagadas.push(obra);

            this.installer.obras.splice(index, 1);

            if (this.installer.obras.length > 0) {
                this.obras.legend = `${this.installer.obras.length} criado(os)`;
            } else {
                this.obras.legend = "Sem obras cadastradas";
            }
        },
        async onSearchCities() {
            const query = this.searchCities;
            this.isLoadingSearch = true;
            if (query != "" && query) {
                clearTimeout(this.timerSearch);
                this.timerSearch = setTimeout(async () => {
                    const resp = await this.getCities(query);

                    this.cities = resp.data.filter((c) => {
                        if (c.state) {
                            c.customName = `${c.name} (${c.state.uf})`;
                        } else {
                            c.customName = c.name;
                        }
                        return c;
                    });

                    this.isLoadingSearch = false;
                }, 800);
            } else {
                this.isLoadingSearch = false;
                clearTimeout(this.timerSearch);
            }
        },
        async onLeaveImages(event) {
            for (let image of event) {
                this.installer.photos.push({ url: await this.toBase64(image) });
            }

            if (this.installer.photos.length > 0) {
                this.photos.legend = `${this.installer.photos.length} adicionadas(as)`;
            } else {
                this.photos.legend = "Sem fotos adicionadas";
            }
        },
        removeImage(index) {
            const imagem = this.installer.photos[index];

            this.installer.imagens_apagadas.push(imagem);

            this.installer.photos.splice(index, 1);

            if (this.installer.photos.length > 0) {
                this.photos.legend = `${this.installer.photos.length} adicionadas(as)`;
            } else {
                this.photos.legend = "Sem fotos adicionadas";
            }
        },
        async onLeaveObra(file) {
            let base = await this.toBase64(file);
            this.obra.photo = base;
            this.obra.nova_imagem = base;
        },
        cleanObra() {
            this.obra = {
                photo: null,
                city_id: null,
                kwh_month: 0,
                value_month: 0,
                enterprise_name: null,
            };
        },
    },
    computed: {
        ...mapGetters(["getCompanySize", "getConsumerType", "getUser"]),

        isValid() {
            return !!(
                this.installer.cnpj &&
                this.installer.state_id &&
                this.installer.phone &&
                /^[A-Za-z0-9_\-\.+]+@[A-Za-z0-9_\-\.]{2,}\.[A-Za-z0-9]{2,}(\.[A-Za-z0-9])?/i.test(
                    this.installer.email
                )
            );
        },
    },
    async mounted() {
        this.isLoading = true;
        const id = this.$route.params.id;
        this.id = id;
        const [states, installer] = await Promise.all([
            this.getStates(),
            this.showInstallers(id),
        ]);
        this.optionsStates = states;

        const plans = await this.getPlans()
        if (plans) {
            for (let plan of plans) {
                this.plans.push(plan)
            }
        }
        
        if (installer) {
            installer.stateCity = [];

            for (let stateCity of installer.regions) {
                let d = {};
                if (stateCity.city_id) {
                    d = stateCity.city;
                    d.customName = `${d.name} (${d.state.uf})`;
                    d.isCity = true;
                    d.selected = true;
                    d.customId = `city-${d.id}`;
                } else {
                    d = stateCity.state;
                    d.customName = `${d.name} (Estado)`;
                    d.isCity = false;
                    d.selected = true;
                    d.customId = `state-${d.id}`;
                }
                installer.stateCity.push(d);
            }

            if (installer.stateCity.length > 0) {
                this.regions.legend = `${installer.stateCity.length} selecionado(os)`;
            }

            for (let i in installer.images) {
                installer.images[i].nova_imagem = null;
            }

            for (let i in installer.constructions) {
                if (
                    installer.constructions[i].city &&
                    installer.constructions[i].state
                ) {
                    installer.constructions[
                        i
                    ].city.customName = `${installer.constructions[i].city.name} - ${installer.constructions[i].state.uf}`;
                }
            }

            installer.photos = installer.images;
            installer.obras = installer.constructions;

            installer.obras_apagadas = [];
            installer.imagens_apagadas = [];

            this.installer = installer;
            this.installer.email = installer.user ? installer.user.email : null;

            if (this.installer.photos.length > 0) {
                this.photos.legend = `${this.installer.photos.length} adicionadas(as)`;
            } else {
                this.photos.legend = "Sem fotos adicionadas";
            }

            if (this.installer.obras.length > 0) {
                this.obras.legend = `${this.installer.obras.length} criado(os)`;
            } else {
                this.obras.legend = "Sem obras cadastradas";
            }
        } else {
            this.$eventHub.$emit("snackBar", {
                color: "error",
                message: "Erro ao encontrar instalador",
            });
        }

        await Promise.all([this.loadCompanySize(), this.loadConsumerType()]);

        this.isLoading = false;
    },
};
</script>

<style scoped>
.contentGrid {
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;
}
</style>
