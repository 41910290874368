import Http from '@/plugins/Http'

export default {
    async listAvaliations ({}, params) 
    {
        try {
            const { data } = await Http.get('/orders-rate', { params })
            return data
        } catch (e) {
            return false            
        }
    },
    async showAvaliations ({}, id) {
        try {
            const { data } = await Http.get(`/orders-rate/${id}`)
            return data
        } catch (e) {
            return false
        }
    },
    async updateAvaliations ({}, avaliation) {
        try {
            await Http.put('/orders-rate/' + avaliation.id, avaliation)
            return true
        } catch (e) {
            return false
        }
    },
    async deleteAvaliations ({}, id) {
        try {
            await Http.delete('/orders-rate/' + id)
            return true
        } catch (e) {
            return false
        }
    },
    async deleteManyAvaliations ({}, ids) {
        try {
            await Http.delete('/orders-rate/delete/many', {
                data: ids
            })
            return true
        } catch (e) {
            return false
        }
    },
}
