var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"mt-10 mb-8",attrs:{"no-gutters":""}},[_c('BreadcrumbsComponent',{attrs:{"title":"Clientes","items":_vm.breadcrumbs},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-btn',{staticClass:"white--text grey darken-3 mt-2 ml-2 elevation-0",attrs:{"elevation":"2","large":""},on:{"click":function($event){return _vm.$router.push(("/clients/view/" + (_vm.$route.params.id)))}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-arrow-left")]),_vm._v(" Voltar ")],1)]},proxy:true}])})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"hide-default-footer":"","items-per-page":-1},scopedSlots:_vm._u([{key:"item.updatedat",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.__dayjs(item.updated_at).format('DD/MM/YYYY HH:mm:ss'))+" ")]}},{key:"item.installers.name",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"d-block grey--text text--darken-2"},[_vm._v(_vm._s(item.installers && item.installers.name || ''))]),_c('span',{staticClass:"grey--text"},[_vm._v(_vm._s(item.installers && item.installers.phone || ''))])]}},{key:"item.budget.client.name",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"d-block grey--text text--darken-2"},[_vm._v(_vm._s(item.budget.client.name))]),_c('span',{staticClass:"grey--text"},[_vm._v(_vm._s(item.budget.client.phone))])]}},{key:"item.consultant",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('v-avatar',[_c('img',{attrs:{"src":"https://cdn.vuetifyjs.com/images/john.jpg","alt":"John"}})]),_c('div',{staticClass:"ml-2"},[_c('span',{staticClass:"d-block grey--text text--darken-2"},[_vm._v(_vm._s(item.budget.consultant.name))]),_c('span',{staticClass:"grey--text caption"},[_vm._v(_vm._s(item.budget.consultant.phone))])])],1)]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v("Pendente")]),_c('v-progress-linear',{staticClass:"mt-1",attrs:{"color":"pink","value":item.status.value}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }