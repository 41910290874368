<template>
    <div>
        <v-row no-gutters class="mt-10 mb-8">
            <BreadcrumbsComponent title="Distribuidores" :items="breadcrumbs" />
        </v-row>

        <v-row v-if="isLoading">
            <v-col md="12" class="text-center">
                <v-progress-circular indeterminate></v-progress-circular>
            </v-col>
        </v-row>

        <form
            v-else
            @keydown.enter="!isLoading && isValid && onUpdatetDistributor()"
        >
            <v-row>
                <v-col cols="12" md="6">
                    <v-row>
                        <v-col cols="12" md="12">
                            <v-text-field
                                label="Nome"
                                placeholder="Nome"
                                outlined
                                v-model="distributors.name"
                            ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="12">
                            <v-text-field
                                label="E-mail"
                                placeholder="E-mail"
                                outlined
                                persistent-hint
                                v-model="distributors.email"
                            ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="12">
                            <v-text-field
                                v-mask="['##.###.###/####-##', '###.###.##-##']"
                                label="Cnpj"
                                placeholder="Cnpj"
                                outlined
                                persistent-hint
                                v-model="distributors.cnpj"
                            ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="12">
                            <v-text-field
                                label="DDD Celular"
                                placeholder="DDD Celular"
                                outlined
                                hint="Apenas números"
                                persistent-hint
                                v-model="distributors.phone"
                                v-mask="['(##) ####-####', '(##) #####-####']"
                            ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="12">
                            <v-select
                                :items="optionsStates"
                                item-value="id"
                                item-text="name"
                                label="Estado"
                                outlined
                                v-model="distributors.state_id"
                            ></v-select>
                        </v-col>

                        <v-col cols="12" md="12">
                            <v-btn
                                elevation="2"
                                large
                                block
                                class="white--text grey darken-3 elevation-0"
                                @click="onUpdatetDistributor"
                                :disabled="isLoading || !isValid"
                                :loading="isLoading"
                                >Editar</v-btn
                            >
                        </v-col>

                        <v-col cols="12" md="12">
                            <v-btn
                                large
                                block
                                text
                                @click="$router.push('/distributors')"
                                >Cancelar</v-btn
                            >
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </form>
    </div>
</template>

<script>
import { mapActions } from "vuex";
import BreadcrumbsComponent from "@/components/BreadcrumbsComponent/Index";

export default {
    components: {
        BreadcrumbsComponent,
    },
    data: () => {
        return {
            breadcrumbs: [
                {
                    text: "Dashboard",
                },
                {
                    text: "Distribuidores",
                    disabled: true,
                },
                {
                    text: "Cadastro",
                    disabled: true,
                },
            ],
            id: null,
            distributors: {
                id: "",
                name: "",
                email: "",
                cnpj: "",
                state_id: "",
                phone: "",
            },
            isLoadingUpdate: false,
            isLoading: true,
            optionsStates: [],
        };
    },
    methods: {
        ...mapActions(["updateDistributors", "showDistributor", "getStates"]),
        async onUpdatetDistributor() {
            this.isLoadingUpdate = true;

            const resp = await this.updateDistributors(this.distributors);

            if (resp) {
                this.$eventHub.$emit("snackBar", {
                    color: "success",
                    message: "Distribuidor editado com sucesso",
                });
                this.$router.push("/distributors");
            } else {
                this.$eventHub.$emit("snackBar", {
                    color: "error",
                    message: "Erro ao editar distribuidor",
                });
            }

            this.isLoadingUpdate = false;
        },
    },
    computed: {
        isValid() {
            return !!(
                ["name", "email", "cnpj", "state_id", "phone"].every(
                    (key) => !!this.distributors[key]
                ) &&
                /^[A-Za-z0-9_\-\.+]+@[A-Za-z0-9_\-\.]{2,}\.[A-Za-z0-9]{2,}(\.[A-Za-z0-9])?/i.test(
                    this.distributors.email
                )
            );
        },
    },
    async mounted() {
        this.isLoading = true;
        const id = this.$route.params.id;
        this.id = id;
        const [states, distributor] = await Promise.all([
            this.getStates(),
            this.showDistributor(id),
        ]);
        this.optionsStates = states;

        if (distributor) {
            this.distributors = distributor;
            this.distributors.name = distributor.name;
            this.distributors.email = distributor.user
                ? distributor.user.email
                : null;
        } else {
            this.$eventHub.$emit("snackBar", {
                color: "error",
                message: "Erro ao encontrar distribuidor",
            });
        }
        this.isLoading = false;
    },
};
</script>
