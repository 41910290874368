var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"mt-10 mb-8",attrs:{"no-gutters":""}},[_c('BreadcrumbsComponent',{attrs:{"title":"Clientes","items":_vm.breadcrumbs},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-btn',{staticClass:"white--text grey darken-3 mt-2 ml-2 elevation-0",attrs:{"elevation":"2","large":""},on:{"click":function($event){return _vm.$router.push(("/clients/view/" + (_vm.$route.params.id)))}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-arrow-left")]),_vm._v(" Voltar ")],1)]},proxy:true}])})],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items,"hide-default-footer":"","items-per-page":-1},scopedSlots:_vm._u([{key:"item.updated_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.__dayjs(item.updatedat).format( 'DD/MM/YYYY H:mm:ss' ))+" ")]}},{key:"item.simulator.budget_total",fn:function(ref){
var item = ref.item;
return [_vm._v(" R$ "+_vm._s(_vm.__format(item.simulator.budget_total))+" ")]}},{key:"item.client",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.client.name)+" ")]}},{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"blue--text",on:{"click":function($event){return _vm.$router.push('/simulations/'+item.id)}}},[_vm._v(_vm._s(item.id))])]}},{key:"item.consultant.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('v-avatar',[_c('img',{attrs:{"src":_vm.getPath(item),"alt":"consultant image"}})]),_c('div',{staticClass:"d-flex flex-column ml-2"},[_vm._v(" "+_vm._s(item.consultant.name)+" "),_c('span',{staticClass:"grey--text"},[_vm._v("11 99000-0009")])])],1)]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":"grey lighten-2"}},[_vm._v(_vm._s(item.status))])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }