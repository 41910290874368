export const getPowerInput = (type) => {


    const power_inputs = [
        {id: null, label: "Não informado"},
        {id: 1, label: "Monofásico"},
        {id: 2, label: "Bifásico"},
        {id: 3, label: "Trifásico"},
    ]


    for (let r of power_inputs) {
        if (r.id == type) {
            return r.label
        }
    }

    return 'Não informado'
    
    
}