<template>
    <div class="pl-lg-3">
        <h1 class="d-flex align-items-center mt-5 ml-lg-5 titles-simulator">
            <svg
                class="mr-3"
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M26.1306 36.09L26.1306 36.0904C26.1005 36.2937 26.1337 36.5063 26.231 36.6921C26.3286 36.8784 26.4915 37.0368 26.7054 37.1157C26.9224 37.1957 27.1587 37.1805 27.363 37.0775L27.1378 36.6311L27.363 37.0775C27.5635 36.9764 27.7077 36.8046 27.7869 36.6123C27.7871 36.6119 27.7872 36.6114 27.7874 36.611L32.0319 26.3985C32.0321 26.3981 32.0323 26.3976 32.0325 26.3972C32.1314 26.1621 32.1759 25.9053 32.1646 25.6498C32.1533 25.3938 32.086 25.1416 31.965 24.9168C31.844 24.6918 31.6709 24.4975 31.4556 24.3591L31.1853 24.7798C31.0467 24.6907 30.8908 24.6441 30.7323 24.6445V24.1445H30.7317C30.7316 24.1445 30.7314 24.1445 30.7313 24.1445L27.157 24.1445L30.3378 17.0142L30.3379 17.0142L30.3391 17.0114C30.4529 16.752 30.5063 16.4669 30.4972 16.1823C30.488 15.8977 30.4164 15.6169 30.2852 15.3662L29.8479 15.5951L30.2852 15.3662C30.154 15.1155 29.965 14.899 29.7298 14.7441C29.4938 14.5888 29.2208 14.5022 28.9375 14.5V14.5H28.9337L27.784 14.5L27.7826 14.5C27.4477 14.5009 27.1291 14.6197 26.8682 14.8278C26.6087 15.0348 26.4191 15.3194 26.3132 15.6367L26.3127 15.638L22.9098 25.9334L22.9096 25.9339C22.8421 26.1387 22.8191 26.3572 22.841 26.5717C22.863 26.7863 22.9298 26.9956 23.0398 27.1814C23.1498 27.3674 23.3018 27.5275 23.4883 27.6416C23.6754 27.756 23.8895 27.8191 24.1113 27.8193H24.1116H27.3589L26.1306 36.09Z"
                    stroke="#0A85FF"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M11.7895 27.6774L11.7895 27.677L13.2766 18.0485H9.26738H9.26699C9.02397 18.0483 8.78763 17.982 8.57893 17.8592C8.37068 17.7367 8.19798 17.563 8.07166 17.3577C7.94544 17.1526 7.86786 16.92 7.84235 16.6802C7.81683 16.4403 7.84369 16.1964 7.92183 15.9684L7.92198 15.968L12.0055 4.08871L12.006 4.08734C12.1295 3.73133 12.3494 3.41639 12.6452 3.18952C12.9418 2.96197 13.3 2.83499 13.6729 2.83399L13.6742 2.83398L15.0538 2.83398H15.0575L15.0574 2.834C15.3731 2.83631 15.6794 2.92896 15.9467 3.09816C16.2135 3.26702 16.4309 3.50522 16.5832 3.78505C16.7354 4.06472 16.8193 4.37959 16.83 4.70001C16.8408 5.02042 16.7781 5.34051 16.6457 5.63061L16.6444 5.6334L16.6444 5.6334L12.7803 13.9622L17.2112 13.9622C17.2113 13.9622 17.2115 13.9622 17.2117 13.9622H17.2122V14.4622C17.4023 14.4618 17.5894 14.5156 17.7557 14.6184L11.7895 27.6774ZM11.7895 27.6774C11.7551 27.9009 11.7934 28.1336 11.903 28.3348L12.3421 28.0956M11.7895 27.6774L12.3421 28.0956M12.3421 28.0956L11.903 28.3348C12.013 28.5367 12.1927 28.7013 12.4203 28.782C12.6499 28.8634 12.8992 28.8484 13.117 28.7428C13.3322 28.6384 13.4929 28.4579 13.5826 28.2481C13.5828 28.2477 13.5829 28.2474 13.5831 28.247L18.6767 16.4628C18.6769 16.4623 18.6772 16.4618 18.6774 16.4613L12.3421 28.0956Z"
                    stroke="#0A85FF"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </svg>
            <span class="ml-5">Energia gerada por mês</span>
        </h1>

        <div class="ml-10 mb-3">
            <span class="orange--text font-weight-bold d-block ml-8 text-h5"
                >{{
                    parseInt(potency)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                }}
                kWh / mês</span
            >
            <p
                class="ml-8 d-block mt-3 grey--text text--darken-3"
                style="max-width: 448px"
                >A produção de energia é calculada pelo potencial solar da sua
                cidade e do seu sistema instalado. </p
            ><br />
        </div>

        <div class="ml-10">
            <h2 class="ml-8 titles-simulator">
                Sua produção anual de energia solar
            </h2>
        </div>
        <div class="ml-10">
            <span class="orange--text font-weight-bold d-block ml-8 text-h5">
                {{
                    parseInt(annual_production)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                }}
                kWh</span
            >
            <p class="ml-8 d-block mt-3 mr-5" style="max-width: 448px"
                >Ao longo prazo o seu sistema te trará uma grande economia.
            </p>
        </div>
    </div>
</template>

<script>
export default {
    name: "EnergyProductionView",

    props: {
        potency: {
            default: 0,
        },
        annual_production: {
            default: null,
        },
    },
};
</script>
