<template>
    <v-row no-gutters class="d-flex justify-center">
        <v-col md="6">
            <v-card elevation="0">
                <v-card-title>Esqueceu sua senha?</v-card-title>
                <v-card-text>
                    Informe o e-mail associado ao seu cadastro, nós vamos enviar
                    um link para você criar a sua nova senha.
                </v-card-text>
                <v-card-text>
                    <form @keydown.enter="!isLoading && onSend()">
                        <v-row>
                            <v-col cols="12" md="12">
                                <v-tabs v-model="tabs" fixed-tabs>
                                    <v-tabs-slider></v-tabs-slider>
                                    <v-tab class="primary--text">
                                        Administrador
                                    </v-tab>

                                    <v-tab class="primary--text">
                                        Consultor
                                    </v-tab>
                                </v-tabs>
                            </v-col>

                            <v-col cols="12" sm="12" md="12">
                                <v-text-field
                                    label="E-mail"
                                    outlined
                                    v-model="user.email"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="12">
                                <v-btn
                                    large
                                    block
                                    color="blue-grey"
                                    class="white--text"
                                    @click="onSend"
                                    :loading="isLoading"
                                    :disabled="isLoading"
                                    >Solicitar nova senha</v-btn
                                >
                            </v-col>

                            <v-col cols="12" sm="12" md="12">
                                <v-btn
                                    large
                                    block
                                    text
                                    @click="$router.push('/login')"
                                    >Voltar</v-btn
                                >
                            </v-col>
                        </v-row>
                    </form>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
import { mapActions } from "vuex";

export default {
    name: "LoginPage",
    data: () => {
        return {
            showPassword: false,
            tabs: null,
            user: {
                email: "",
            },
            isLoading: false,
        };
    },
    async mounted() {
        //await this.login({ email:'test@test.com', password:'123' })
        //this.$router.push('/')
    },
    methods: {
        ...mapActions(["sendRestorePassword"]),

        async onSend() {
            this.isLoading = true;
            const user = Object.assign(this.user);
            user.type = this.tabs + 1;
            const r = await this.sendRestorePassword(user);
            if (r) {
                this.$eventHub.$emit("snackBar", {
                    color: "success",
                    message: "Link enviado com sucesso",
                });
                this.$router.push("/");
            } else {
                this.$eventHub.$emit("snackBar", {
                    color: "error",
                    message: "Erro ao enviar link",
                });
            }
            this.isLoading = false;
        },
    },
};
</script>
