import currency from 'currency.js'


export const formatMoney = (number, precision = 2, decimal = ',', separator = '.') => {
    return currency(number, { decimal: decimal, separator: separator, precision: 2, symbol: '' }).format()
}


export const getResidenceType = (id) => {
    const types = [
        {label:'Residência', id: 0},
        {label:'Comércio', id: 1},
        {label:'Indústria', id: 2},
    ]
    
    for (let type of types) {
        if (type.id == id) {
            return type.label
        }
    }
    
    return "Tipo inválido"
}


export const getPowerInput = (id) => {
    const types = [
        {label:'Null', id: null},
        {label:'Monofásico', id: 1},
        {label:'Bifásico', id: 2},
        {label:'Trifásico', id: 3},
    ]

    for (let type of types) {
        if (type.id == id) {
            return type.label
        }
    }

    return "Tipo inválido"
}