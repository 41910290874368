import Http from '@/plugins/Http'

export default {
    async insertTrainings({}, training) {
        try {
            await Http.post('/trainings', training)
            return true
        } catch (e) {
            return false
        }
    },

    async listTrainings({}) {
        try {
            const { data } = await Http.get('/trainings')
            return data
        } catch (e) {
            return false
        }
    },


    async getTrainingFile({}, id) {
        try {
            const { data } = await Http.get(`/getTrainingFile/${id}`);
            return data;
        } catch (e) {
            return false;
        }
    },
}
