<template>
    <div>


        <v-dialog
            v-model="confirmationDialog"
            transition="dialog-top-transition"
            max-width="600"
            persistent
        >
            <v-card class="pa-5">
                <v-card-text class="pt-4">
                    <h1 class="text-center mb-4" style="line-height: 1">
                        Você selecionou {{ selecteds.length }} item(s), tem
                        certeza que deseja excluí-lo(s)?
                    </h1>
                    <span class="d-block text-center mt-4 mb-5">
                        Importante: essa ação não poderá ser desfeita. O Pré-pedido e/ou Pedido vinculados a esta simulação também serão removidos.
                    </span>

                    <v-btn
                        :loading="isLoadingDelete"
                        :disabled="isLoadingDelete"
                        elevation="2"
                        large
                        block
                        text
                        class="white--text grey darken-3 mt-5 elevation-0 mx-auto"
                        @click="onDeleteMany"
                    >Sim, confirmo a exclusão</v-btn
                    >
                    <v-btn
                        :disabled="isLoadingDelete"
                        large
                        block
                        text
                        class="mt-3"
                        @click="confirmationDialog = false"
                    >Cancelar</v-btn
                    >
                </v-card-text>
            </v-card>
        </v-dialog>
        
        
        <!-- Janel de filtros: TODO => Adicionar os filtros ao filterpanel usando v-chips -->
        <v-dialog
            v-model="dialog"
            transition="dialog-top-transition"
            max-width="600"
        >
            <template v-slot:default="dialog">
                <v-card>
                    <v-card-text class="pt-4">
                        <div class="pt-4 mb-4">
                            <h1>Filtrar por:</h1>
                        </div>

                        <div>
                            <v-btn
                                elevation="2"
                                large
                                block
                                class="white--text grey darken-3 elevation-0"
                                @click="dialog.value = false"
                                >Confirmar</v-btn
                            >
                            <v-btn large block text>Limpar filtro</v-btn>
                        </div>
                    </v-card-text>
                </v-card>
            </template>
        </v-dialog>

       
        <v-row no-gutters class="mt-10 mb-8">
            <BreadcrumbsComponent
                title="Pré-pedido"
                :items="breadcrumbs"
            >
                <template v-if="false" v-slot:actions>
                    <v-btn
                        elevation="2"
                        large
                        class="white--text grey darken-3 mt-2 ml-2 elevation-0"
                    >
                        <v-icon class="mr-2">mdi-plus</v-icon>
                        Adicionar novo
                    </v-btn>
                </template>
            </BreadcrumbsComponent>
        </v-row>

        <v-row no-gutters>
            <v-col cols="12" md="12">
                <FilterPanelComponent
                    v-if="!selected"
                    :show-content="showContent"
                    @onSearching="sendFilter"
                   
                    @onDownload="load(1, true)"
                >
                    <template v-slot:result>
                        <v-chip
                            class="ma-2"
                            close
                            color="grey darken-1"
                            outlined
                        >
                            <span>Cliente:</span>
                            <span class="ml-2">Logo Nunes Castanheda</span>
                        </v-chip>
                    </template>
                </FilterPanelComponent>

                <ActionBarComponent v-if="selecteds.length > 0">
                    <template v-slot:content>
                        <div class="d-flex justify-space-between flex-wrap">
                            <span class="grey--text text--darken-4"
                            >{{ selecteds.length }} registro(s)
                                selecionado(s)</span
                            >
                            <v-icon
                                color="grey darken-2"
                                @click="confirmationDialog = true"
                            >mdi-trash-can-outline</v-icon
                            >
                        </div>
                    </template>
                </ActionBarComponent>
            </v-col>
        </v-row>

        <v-row no-gutters>
            <v-col cols="12" md="12">
                <v-data-table
                    :headers="headers"
                    :items="items"
                    class="elevation-1"
                    hide-default-footer
                    :loading="isLoading"
                    :items-per-page="-1"
                    :item-class="checkUpdated"
                >
                    <template v-slot:item.updated_at="{ item }">
                        <v-checkbox
                            v-model="selecteds"
                            :value="item.id"
                            :label="`${__dayjs(item.updated_at).format(
                                'DD/MM/YYYY HH:mm:ss'
                            )}`"

                            v-if="getUser.user_type === 1"
                        ></v-checkbox>

                        <span v-else>{{ __dayjs(item.updated_at).format('DD/MM/YYYY HH:mm:ss') }}</span>
                    </template>

                    <template v-slot:item.simulator.budget_total="{ item }">
                        <span v-if="item.simulator">R$ {{ __format(item.simulator.budget_total) }}</span>
                        
                        <span v-else>-</span>
                    </template>

                    <template v-slot:item.client="{ item }">
                        <div>{{ item.client ? item.client.name : '' }}</div>

                        <span class="grey--text">{{ item.client ? item.client.phone : '' }}</span>
                    </template>

                    <template v-slot:item.id="{ item }">
                        <span class="blue--text" @click="$router.push('/simulations/'+item.id)">{{ item.id }}</span>
                    </template>

                    <template v-slot:item.consultant.name="{ item }">
                        <div class="d-flex">
                            <v-avatar>
                                <img
                                    v-if="item.consultant && item.consultant.url"
                                    :src="item.consultant.url"
                                    alt="consultant image"
                                />
                                <v-avatar color="#4F4F4F" v-else>
                                    <span class="white--text text-h5">{{ getIniciais(item.consultant ? item.consultant.name : '') }}</span>
                                </v-avatar>
                            </v-avatar>
                            
                            <div class="d-flex flex-column ml-2">
                                {{ item.consultant ? item.consultant.name : '' }}
                                <span class="grey--text">{{ item.consultant ? item.consultant.phone : '' }}</span>
                            </div>
                        </div>
                    </template>

                    <template v-slot:item.status="{ item }">
                        <v-chip color="#2F80ED" v-if="item.order" dark>
                            <span style="margin-right: 2px">Pedido</span><b>{{ item.order.custom_id }}</b>
                        </v-chip>
                        
                        <v-chip color="grey lighten-2" v-else>
                            Em atendimento
                        </v-chip>
                    </template>

                    <template v-slot:item.action="{ item }">

                        <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            @click="$router.push('/talk-to-consultant/' + item.id)"
                            class="cursor-pointer"
                        >
                            <path
                                d="M5 12H19"
                                stroke="#828282"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                d="M12 5L19 12L12 19"
                                stroke="#828282"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                        </svg>
                        
                        
                        
                    </template>
                </v-data-table>
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <div class="text-center">
                    <v-pagination
                        v-model="page"
                        :length="totalPage"
                        :total-visible="7"
                        next-aria-label="Próximo"
                        @input="load($event)"
                    ></v-pagination>
                </div>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";
import BreadcrumbsComponent from "@/components/BreadcrumbsComponent/Index";
import FilterPanelComponent from "@/components/FilterPanelComponent/Index";
import ActionBarComponent from "@/components/ActionBarComponent/Index";
import dayjs from "dayjs";
import {formatMoney} from "../../utils/formatMoney"
    import Papa from "papaparse";

export default {
    components: {
        BreadcrumbsComponent,
        FilterPanelComponent,
        ActionBarComponent,
    },
    data: () => {
        return {
            confirmationDialog: false,
            selecteds: [],
            isLoadingDelete: false,
            selected: false, //TODO => handle selecteds when to implement requests
            breadcrumbs: [
                {
                    text: "Dashboard",
                },
                {
                    text: "Pré-pedido",
                    disabled: true,
                },
                {
                    text: "Lista",
                    disabled: true,
                },
            ],
            searching: "",
            dialog: false,
            confirmationDialog: false,
            showContent: false,
            isLoading: true,
            items: [],
            headers: [
                { text: "ATUALIZADO EM", align: "start", sortable: false, value: "updated_at" },
                { text: "SIMULAÇÃO", value: "simulator.custom_id", sortable: false },
                { text: "ESTIMATIVA", value: "simulator.budget_total", sortable: false },
                { text: "CLIENTE", value: "client", sortable: false },
                { text: "CONSULTOR", value: "consultant.name", sortable: false },
                { text: "STATUS", value: "status", sortable: false },
                { text: "", value: "action", sortable: false },
            ],

            page: 1,
            totalPage: 1,

            timerSearch: null,
            filter: {
                query: "",
            },
            isSearched: false,
        };
    },
    methods: {
        ...mapActions(["listBudgets", "deleteManyBudgets"]),
        filter() {
            this.showContent = !this.showContent;
            this.dialog = !!this.showContent;
        },
        setLoading() {
            this.$eventHub.emit("loading", {
                title: "Por favor, aguarde!",
                subtitle: "Realizando operação...",
                status: true,
            });
        },
        getPath(item)
        {
            return `${process.env.VUE_APP_API_URL}${item.consultant.image}`
        },
        async load(page = 1, csv = false) {
            this.isLoading = true



            const filter = {...this.filter}
            filter.page = page
            if (csv) filter.csv = csv
            const resp = await this.listBudgets(filter)
            if (resp) {
                if (filter.csv) {
                    const itensCsv = []
                    for (let i in resp) {
                        const obj = {
                            'Área': resp[i].area_size,
                            'Cliente': resp[i].client ? resp[i].client.name : '',
                            'Consultor': resp[i].consultant ? resp[i].consultant.name : '',
                            'Criado em': resp[i].created_at,
                            "Comentários": resp[i].notes,
                            'Pedido': resp[i].order ? resp[i].order.custom_id : '',
                            'Simulação': resp[i].simulator ? resp[i].simulator.custom_id : '',
                            "Valor": resp[i].value
                        }
                        itensCsv.push(obj)
                    }

                    let csv = Papa.unparse(itensCsv, {delimiter: ';'})
                    let csvData = new Blob(["\ufeff", csv], {type: 'text/csv;charset=utf-8;'});
                    let csvURL = null;
                    if (navigator.msSaveBlob) {
                        csvURL = navigator.msSaveBlob(csvData, 'pré pedidos.csv');
                    } else {
                        csvURL = window.URL.createObjectURL(csvData);
                    }

                    let tempLink = document.createElement('a');
                    tempLink.href = csvURL;
                    tempLink.setAttribute('download', 'pré pedidos.csv');
                    tempLink.click();
                } else {
                    this.items = resp.data
                    this.totalPage = resp.last_page
                }
                this.isLoading = false
            }
        },


        sendFilter(query) {
            this.filter.query = query;
            this.isLoading = true;
            if ((query != "" && query) || this.isSearched) {
                clearTimeout(this.timerSearch);
                this.timerSearch = setTimeout(async () => {
                    await this.load(1);

                    this.isLoading = false;
                    if (query) this.isSearched = true;
                }, 800);
            } else {
                this.isLoading = false;
                clearTimeout(this.timerSearch);
            }
        },
        
        
        __dayjs(date) {
            return dayjs(date);
        },
        __format(value)
        {
            return formatMoney(value)
        },

        getIniciais (nameString) {
            try {
                const fullName = nameString.split(' ');
                const initials = fullName.shift().charAt(0) + fullName.pop().charAt(0);
                return initials.toUpperCase();
            } catch (e) {
                return nameString.substring(0,2).toUpperCase()
            }
        },

        async onDeleteMany() {
            this.isLoadingDelete = true;

            const ids = this.selecteds;

            const r = await this.deleteManyBudgets({ ids });

            if (r) {
                this.$eventHub.$emit("snackBar", {
                    color: "success",
                    message: "Clientes apagados com sucesso",
                });
                this.selecteds = [];
                this.confirmationDialog = false;
                this.load();
            } else {
                this.$eventHub.$emit("snackBar", {
                    color: "error",
                    message: "Erro ao apagar clientes",
                });
            }

            this.isLoadingDelete = false;
        },




        checkUpdated (item) {
            if (this.$route.query.recentUpdated) {
                if (item.id == this.$route.query.recentUpdated) {
                    return "recent-updated"
                }
            }
            
            
        }
        
    },
    mounted() {
        this.load();
    },

    computed: {
        ...mapGetters(["getUser"]),
    },
};
</script>

<style>
    .recent-updated td {
        border-bottom: 2px solid #219653 !important;   
    }

</style>
