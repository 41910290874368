<template>
    <div>
        <v-row no-gutters class="mt-10 mb-8">
            <BreadcrumbsComponent title="Tipos de produtos" :items="breadcrumbs" />
        </v-row>

        <v-row v-if="isLoadingLoad">
            <v-col md="12" class="text-center" >
                <v-progress-circular indeterminate></v-progress-circular>
            </v-col>
        </v-row>
        
        <v-row v-else>
            <v-col cols="12" md="6">
                <v-row>
                    <!--<v-col cols="12"  md="12">
                        <v-select 
                            :items="brands"
                            item-text="trading_name"
                            item-value="id"
                            label="Marca" 
                            placeholder="Marca" 
                            outlined 
                            v-model="product.brand_id"
                        ></v-select>
                    </v-col>-->
                    
                    <!--<v-col cols="12"  md="12">
                        <v-select :items="['Foo', 'Bar', 'Fizz', 'Buzz']" label="Categoria" placeholder="Categoria" outlined v-model="product.category"></v-select>
                    </v-col>-->

                    <v-col cols="12" md="12">
                        <v-text-field label="Nome" placeholder="Nome" outlined v-model="productType.name"></v-text-field>
                    </v-col>
                    
                    
                    <v-col cols="12" md="12">
                        <v-select 
                            :items="products" 
                            item-value="id" 
                            item-text="name" 
                            label="Produto" 
                            placeholder="Produto" 
                            outlined 
                            v-model="productType.product_id"
                        ></v-select>
                    </v-col>

                    <v-col  cols="12" md="12">
                        <v-btn 
                            elevation="2" 
                            large 
                            block 
                            class="white--text grey darken-3 elevation-0" 
                            @click="onSendInsert" 
                            :disabled="isLoading" 
                            :loading="isLoading"
                        >Cadastrar</v-btn>
                    </v-col>

                    <v-col cols="12" md="12">
                        <v-btn large block text @click="$router.push('/products-types')">Cancelar</v-btn>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </div>
</template>



<script>
    import { mapActions } from 'vuex'
    import BreadcrumbsComponent from "@/components/BreadcrumbsComponent/Index"

    export default {
        components: {
            BreadcrumbsComponent,
        },
        async mounted()
        {
            this.isLoadingLoad = true
            const products = await this.listProducts({ csv: true })
            this.products = products
            this.isLoadingLoad = false
        },
        data: () => {
            return {
                breadcrumbs: [
                    {
                        text: 'Dashboard'
                    },
                    {
                        text: 'Tipos de produtos',
                        disabled: true
                    },
                    {
                        text: 'Cadastro',
                        disabled: true
                    }
                ],
                productType: {
                    name: '',
                    product_id: null
                },
                isLoading: false,
                isLoadingLoad: true,
                products: []
            }
        },
        methods: {
            ...mapActions(['insertProductType', 'listProducts']),
            async onSendInsert () 
            {
                this.isLoading = true
                const productType = {...this.productType}
                const r = await this.insertProductType(productType)

                if (r) {
                    this.$eventHub.$emit('snackBar', {color: 'success', message: 'Produto inserido com sucesso'})
                    this.$router.push('/products-types')
                } else {
                    this.$eventHub.$emit('snackBar', {color: 'error', message: 'Erro ao inserir produto'})
                }
                this.isLoading = false
            },

            toBase64(file)
            {
                return new Promise((resolve, reject) => {
                    const reader = new FileReader()
                    reader.readAsDataURL(file)
                    reader.onload  = () => resolve(reader.result)
                    reader.onerror = error => reject(error)
                })
            },
            onOver(){},
            async onLeave( file )
            {
                this.product.image = await this.toBase64(file)
            },
        },
    }
</script>