<template>
    <div>
        <v-row no-gutters class="mt-10 mb-8">
            <BreadcrumbsComponent
                title="Administradores"
                :items="breadcrumbs"
            >

                <template v-slot:actions>
                    <v-btn elevation="2" large color="secondary" class="white--text mt-2 ml-2 elevation-0" @click="$router.push('/administrators/add')">
                        <v-icon class="mr-2">mdi-plus</v-icon>
                        Adicionar novo
                    </v-btn>
                </template>
            </BreadcrumbsComponent>
        </v-row>

        <v-row no-gutters>
            <v-col cols="12" md="12">
                <FilterPanelComponent v-if="!selected" :show-content="showContent" @onSearching="sendFilter" @onDownload="load(1, true)">
                    <template v-slot:result>
                        <v-chip class="ma-2" close color="grey darken-1" outlined> <span>Cliente:</span> <span class="ml-2">Logo Nunes Castanheda</span> </v-chip>
                    </template>
                </FilterPanelComponent>

                <ActionBarComponent v-if="selecteds.length > 0">
                    <template v-slot:content>
                        <div class="d-flex justify-space-between flex-wrap">
                            <span class="grey--text text--darken-4">{{ selecteds.length }} registro(s) selecionado(s)</span>
                            <v-icon color="grey darken-2" @click="confirmationDialog=true">mdi-trash-can-outline</v-icon>
                        </div>
                    </template>
                </ActionBarComponent>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12" md="12">
                <v-data-table
                    :headers="headers"
                    :items="items"
                    hide-default-footer
                    :loading="isLoading"
                    :items-per-page="-1"
                >
                    <template v-slot:item.updated_at="{ item }">
                        <v-checkbox
                            v-model="selecteds"
                            :value="item.id"
                            :label="`${__dayjs(item.updated_at).format('DD/MM/YYYY HH:mm:ss')}`"
                        ></v-checkbox>
                    </template>
                    
                    <template v-slot:item.acoes="{ item }">
                        <v-btn
                            @click="$router.push('/administrators/view/' + item.id)"
                            text
                        >
                            <v-icon>mdi-dots-horizontal</v-icon>
                        </v-btn>
                    </template>
                    
                </v-data-table>
            </v-col>
        </v-row>
        
        <v-row>
            <v-col>
                <div class="text-center">
                    <v-pagination
                        v-model="page"
                        :length="totalPage"
                        :total-visible="7"
                        next-aria-label="Próximo"
                        @input="load($event)"
                    ></v-pagination>
                </div>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import { mapActions } from 'vuex'
    import BreadcrumbsComponent from "@/components/BreadcrumbsComponent/Index"
    import FilterPanelComponent from "@/components/FilterPanelComponent/Index"
    import ActionBarComponent from "@/components/ActionBarComponent/Index"
    import dayjs from 'dayjs'
    import Papa from "papaparse";

    export default {
        components: {
            BreadcrumbsComponent,
            FilterPanelComponent,
            ActionBarComponent
        },
        data: () => {
            return {
                selecteds: [],
                selected: false,
                showContent:false,
                breadcrumbs: [
                    {
                        text: 'Dashboard'
                    },
                    {
                        text: 'Administradores',
                        disabled: true
                    }
                ],
                headers: [
                    {
                        text: 'ATUALIZADO EM',
                        align: 'start',
                        sortable: false,
                        value: 'updated_at',
                    },
                    {
                        text: 'NOME',
                        align: 'start',
                        sortable: false,
                        value: 'username',
                    },
                    {
                        text: 'E-MAIL',
                        align: 'start',
                        sortable: false,
                        value: 'email',
                    },
                    {
                        text: '',
                        align: 'start',
                        sortable: false,
                        value: 'acoes',
                    },
                ],
                items: [],
                isLoading: true,
                isLoadingDelete: false,
                page: 1,
                totalPage: 1,
                timerSearch: null,
                filter: {
                    query: '',
                },
                isSearched: false,
                confirmationDialogDelete: false,
                admin: null
            }
        },
        methods: {
            ...mapActions(['listAdmins', 'deleteManyAdmins', 'deleteAdmin']),

            onDelete (item) {
                this.admin = item
                this.confirmationDialogDelete = true
            },

            async onSendDelete () {
                this.isLoadingDelete = true

                const resp = await this.deleteAdmin(this.admin.id)

                if (resp) {
                    this.$eventHub.$emit('snackBar', {color: 'success', message: 'Administrador apagado com sucesso'})
                    this.confirmationDialogDelete = false
                    this.load()
                } else {
                    this.$eventHub.$emit('snackBar', {color: 'error', message: 'Erro ao apagar administrador'})
                }

                this.isLoadingDelete = false
            },
            
            
            async load (page = 1, csv = false) {
                this.isLoading = true
                const filter = {...this.filter}
                filter.page = page
                if (csv) filter.csv = csv
                const resp = await this.listAdmins(filter)
                if (resp) {
                    if (filter.csv) {
                        const itensCsv = []
                        for (let i in resp) {
                            const obj = {
                                'Nome': resp[i].name,
                                'Email': resp[i].user ? resp[i].user.email : ''
                            }
                            itensCsv.push(obj)
                        }

                        let csv = Papa.unparse(itensCsv, { delimiter: ';' })
                        let csvData = new Blob(["\ufeff", csv], {type: 'text/csv;charset=utf-8;'});
                        let csvURL =  null;
                        if (navigator.msSaveBlob)
                        {
                            csvURL = navigator.msSaveBlob(csvData, 'administradores.csv');
                        }
                        else
                        {
                            csvURL = window.URL.createObjectURL(csvData);
                        }

                        let tempLink = document.createElement('a');
                        tempLink.href = csvURL;
                        tempLink.setAttribute('download', 'administradores.csv');
                        tempLink.click();
                    } else {
                        this.items = resp.data
                        this.totalPage = resp.last_page
                    }

                }
                this.isLoading = false
            },
            __dayjs (date) 
            {
                return dayjs(date)
            },


            async onDeleteMany () {
                this.isLoadingDelete = true


                const ids = this.selecteds

                const r = await this.deleteManyAdmins({ids})

                if (r) {
                    this.$eventHub.$emit('snackBar', {color: 'success', message: 'Administradores apagados com sucesso'})
                    this.selecteds = []
                    this.confirmationDialog = false
                    this.load()
                } else {
                    this.$eventHub.$emit('snackBar', {color: 'error', message: 'Erro ao apagar administradores'})
                }


                this.isLoadingDelete = false
            },

            sendFilter (query) {
                this.filter.query = query
                this.isLoading = true
                if (query != "" && query || this.isSearched) {
                    clearTimeout(this.timerSearch);
                    this.timerSearch = setTimeout(async () => {
                        await this.load(1)

                        this.isLoading = false
                        if (query) this.isSearched = true
                    }, 800)
                } else {
                    this.isLoading = false
                    clearTimeout(this.timerSearch)
                }
            },
        },
        mounted() 
        {
            this.load()
        }
    }
</script>