<template>
    <div>
        <v-dialog v-model="confirmationDialog" transition="dialog-top-transition" max-width="600" persistent>
            <v-card class="pa-5">
                <v-card-text class="pt-4">
                    <h1 class="text-center mb-4" style="line-height: 1">Você selecionou {{ selecteds.length }} item(s), tem certeza que deseja excluí-lo(s)?</h1>
                    <span class="d-block text-center mt-4 mb-5">Importante: essa ação não poderá ser desfeita.</span>

                    <v-btn :loading="isLoadingDelete" :disabled="isLoadingDelete" elevation="2" large block text class="white--text grey darken-3 mt-5 elevation-0 mx-auto" @click="onDeleteMany">Sim, confirmo a exclusão</v-btn>
                    <v-btn :disabled="isLoadingDelete" large block text class="mt-3" @click="confirmationDialog = false">Cancelar</v-btn>
                </v-card-text>
            </v-card>
        </v-dialog>

        <!-- Janel de filtros: TODO => Adicionar os filtros ao filterpanel usando v-chips -->
        <v-dialog v-model="dialog" transition="dialog-top-transition" max-width="600">
            <template v-slot:default="dialog">
                <v-card>
                    <v-card-text class="pt-4">
                        <div  class="pt-4 mb-4">
                            <h1>Filtrar por:</h1>
                        </div>

                        <div>
                            <v-btn elevation="2" large block class="white--text grey darken-3 elevation-0" @click="dialog.value=false" >Confirmar</v-btn>
                            <v-btn large block text>Limpar filtro</v-btn>
                        </div>
                    </v-card-text>
                </v-card>
            </template>
        </v-dialog>

        <v-row no-gutters class="mt-10 mb-8">
            <BreadcrumbsComponent title="Avaliações" :items="breadcrumbs" />
        </v-row>

        <v-row no-gutters>
            <v-col cols="12" md="12">
                <FilterPanelComponent v-if="!selected" :show-content="showContent" @onSearching="sendFilter" @onDownload="load(1, true)">
                    <template v-slot:status>
                        <v-tabs v-model="tab" slider-color="light-blue">
                            <v-tab :class="{'light-blue--text ':true, 'text--darken-3': tab == 0, 'text--darken-0': tab != 0}"  @click="onSearchTotals(null)">
                                Todos
                                <v-chip :class="{'ml-2 light-blue white--text':true, 'darken-3': tab == 0}" small>{{ totals.all }}</v-chip>
                            </v-tab>
                            <v-tab :class="{'light-blue--text ':true, 'text--darken-3': tab == 1, 'text--darken-0': tab != 1}" @click="onSearchTotals('approved')">
                                Aprovados
                                <v-chip :class="{'ml-2 light-blue white--text':true, 'darken-3': tab == 1}" small>{{ totals.approved }}</v-chip>
                            </v-tab>
                            <v-tab :class="{'light-blue--text ':true, 'text--darken-3': tab == 2, 'text--darken-0': tab != 2}"  @click="onSearchTotals('pending')">
                                Pendentes
                                <v-chip :class="{'ml-2 light-blue white--text':true, 'darken-3': tab == 2}" small>{{ totals.pending }}</v-chip>
                            </v-tab>
                        </v-tabs>
                    </template>

                    <template v-slot:result>
                        <v-chip class="ma-2" close color="grey darken-1" outlined> <span>Cliente:</span> <span class="ml-2">Logo Nunes Castanheda</span> </v-chip>
                    </template>
                </FilterPanelComponent>

                <ActionBarComponent v-if="selecteds.length > 0">
                    <template v-slot:content>
                        <div class="d-flex justify-space-between flex-wrap">
                            <span class="grey--text text--darken-4">{{ selecteds.length }} registro(s) selecionado(s)</span>
                            <v-icon color="grey darken-2" @click="confirmationDialog=true">mdi-trash-can-outline</v-icon>
                        </div>
                    </template>
                </ActionBarComponent>
            </v-col>
        </v-row>

        <v-row>
            
            <v-col>
                <v-data-table
                    :headers="headers"
                    :items="items"
                    hide-default-footer
                    :loading="isLoading"
                    :items-per-page="-1"
                >
                    <template v-slot:item.updatedat="{ item }">
                        <v-checkbox
                            v-model="selecteds"
                            :value="item.id"
                            :label="`${__dayjs(item.updated_at).format(
                                'DD/MM/YYYY HH:mm:ss'
                            )}`"

                            v-if="getUser.user_type === 1"
                        ></v-checkbox>

                        <span v-else>{{ __dayjs(item.updated_at).format('DD/MM/YYYY HH:mm:ss') }}</span>
                    </template>

                    <template v-slot:item.id="{ item }">
                        <span class="subtitle-1 blue--text font-weight-medium">{{ item.id }}</span>
                    </template>

                    <template v-slot:item.installer="{ item }">
                        <span class="subtitle-1 grey--text">{{ item.installer && item.installer.name || '-' }}</span>
                    </template>

                    <template v-slot:item.client_name="{ item }">
                        <template v-if="item.client">
                            <span class="d-block grey--text text--darken-2">{{
                                item.client.name
                            }}</span>
                            <span class="grey--text">{{
                                item.client.phone
                            }}</span>
                        </template>
                    </template>
                    
                    <template v-slot:item.client="{ item }">
                        <span class="subtitle-1 grey--text">{{ item.client && item.client.name || '-' }}</span>
                    </template>

                    <template v-slot:item.description="{ item }">
                        <p class="subtitle-1 grey--text" v-html="item.description"></p>
                    </template>

                    <template v-slot:item.avaliation="{ item }">
                        <v-rating readonly v-model="item.rate" background-color="grey" color="grey" icon-label="custom icon label text {0} of {1}"></v-rating>
                    </template>

                    <template v-slot:item.action="{ item }">
                        <v-btn v-if="item.approved == null" color="primary" outlined @click="$router.push('/avaliations/view/' + item.id)">Revisar</v-btn>
                        <v-menu offset-y v-else>
                            <template v-slot:activator="{ on, attrs }">
                                <div class="d-flex align-center">
                                    <v-switch v-model="item.visible" @change="handleVisible(item.index, item)" inset label="" color="success"></v-switch>

                                    <v-btn color="grey" text @click="$router.push('/avaliations/view/' + item.id)">
                                        <v-icon>mdi-dots-horizontal</v-icon>
                                    </v-btn>
                                </div>
                            </template>
                        </v-menu>
                    </template>
                </v-data-table>
            </v-col>

        </v-row>

        <v-row>
            <v-col>
                <div class="text-center">
                    <v-pagination
                        v-model="page"
                        :length="totalPage"
                        :total-visible="7"
                        next-aria-label="Próximo"
                        @input="load($event)"
                    ></v-pagination>
                </div>
            </v-col>
        </v-row>


        <v-dialog v-model="confirmationDialogDelete" transition="dialog-top-transition" max-width="600" persistent>
            <v-card class="pa-5" v-if="installer">
                <v-card-text class="pt-4">
                    <h1 class="text-center mb-4">Deseja excluir este instalador?</h1>
                    <span class="d-block text-center mt-4 mb-5">Importante: essa ação não poderá ser desfeita.</span>

                    <div class="mt-5 mb-5">
                        <span class="d-block font-weight-medium">Instalador</span>
                        <v-divider></v-divider>
                        <div class="mb-3">
                            <span class="d-block">{{ installer.company_name}}</span>
                            <span class="d-block">{{ installer.user ? installer.user.email : '-' }}</span>
                        </div>
                    </div>

                    <v-btn
                        :disabled="isLoadingDelete"
                        :loading="isLoadingDelete"
                        elevation="2"
                        large
                        block
                        text
                        class="white--text grey darken-3 mt-5 elevation-0 mx-auto"
                        @click="onSendDelete"
                    >
                        Sim, confirmo a exclusão
                    </v-btn>
                    <v-btn :disabled="isLoadingDelete" large block text class="mt-3" @click="confirmationDialogDelete=false">Cancelar</v-btn>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import {mapActions, mapGetters} from 'vuex'
    import BreadcrumbsComponent from "@/components/BreadcrumbsComponent/Index"
    import FilterPanelComponent from "@/components/FilterPanelComponent/Index"
    import ActionBarComponent from "@/components/ActionBarComponent/Index"

    import dayjs from 'dayjs'
    import Papa from "papaparse";

    export default {
        components: {
            BreadcrumbsComponent, 
            FilterPanelComponent,
            ActionBarComponent
        },
        data: () => {
            return {
                selected:false,
                selecteds: [],
                dialog:false,
                confirmationDialog:false,
                searching:'',
                tab:0,
                rating:1,
                breadcrumbs: [
                    {
                        text: 'Dashboard'
                    },
                    {
                        text: 'Avaliações',
                        disabled: true
                    },
                    {
                        text: 'Lista',
                        disabled: true
                    }
                ],
                showContent:false,
                headers: [
                    {
                        text: 'ATUALIZADO EM',
                        align: 'start',
                        sortable: false,
                        value: 'updatedat',
                    },
                    {
                        text: 'ID',
                        align: 'start',
                        sortable: false,
                        value: 'order.custom_id',
                    },
                    {
                        text: 'INSTALADOR',
                        align: 'start',
                        sortable: false,
                        value: 'installer.company_name',
                    },
                    {
                        text: 'CLIENTE',
                        align: 'start',
                        sortable: false,
                        value: 'client_name',
                    },
                    {
                        text: 'AVALIAÇÃO',
                        align: 'start',
                        sortable: false,
                        value: 'avaliation',
                    },
                    {
                        text: 'COMENTÁRIO',
                        align: 'start',
                        sortable: false,
                        value: 'description',
                    },
                    {
                        text: 'VISÍVEL',
                        align: 'start',
                        sortable: false,
                        value: 'action'
                    },
                ],
                items: [
                    {
                        updatedat:new Date(),
                        id:1,
                        installer:{
                            name:'installer name'
                        },
                        avaliation:3,
                        client:{
                            name:'client name'
                        },
                        comment:'Sim',
                        action:1
                    }   
                ],
                isLoading: true,
                isLoadingDelete: false,
                page: 1,
                totalPage: 1,
                timerSearch: null,
                filter: {
                    query: '',
                },
                isSearched: false,
                installer: null,
                confirmationDialogDelete: false,
                totals: {
                    all: 0,
                    approved: 0,
                    pending: 0,
                },
                
            }
        },
        methods: {
            ...mapActions(['listAvaliations', 'deleteManyAvaliations', 'updateAvaliations', 'deleteAvaliations']),

            onSearchTotals(type = null) {
                this.filter.approved = type;
                this.load();
            },


            onDelete (item) 
            {
                this.installer = item
                this.confirmationDialogDelete = true
            },
            async onSendDelete () 
            {
                this.isLoadingDelete = true
                const resp = await this.deleteAvaliations(this.installer.id)
                if (resp) 
                {
                    this.$eventHub.$emit('snackBar', {color: 'success', message: 'Instalador apagado com sucesso'})
                    this.confirmationDialogDelete = false
                    this.load()
                } 
                else 
                {
                    this.$eventHub.$emit('snackBar', {color: 'error', message: 'Erro ao apagar instalador'})
                }
                this.isLoadingDelete = false
            },
            async load (page = 1, csv = false) 
            {
                this.isLoading = true
                const filter = {...this.filter}
                filter.page = page
                if (csv) filter.csv = csv
                const resp = await this.listAvaliations(filter)

                if (resp) 
                {
                    if (filter.csv) 
                    {
                        const itensCsv = []

                        for (let i in resp) 
                        {
                            const obj = {
                                'Instalador': resp[i].installer.company_name,
                                'Cliente': resp[i].client.user ? resp[i].client.user.username : '',
                                'Avaliação': resp[i].rate,
                                'Comentário': resp[i].description
                            }
                            itensCsv.push(obj)
                        }

                        let csv = Papa.unparse(itensCsv, {delimiter: ';'})
                        let csvData = new Blob(["\ufeff", csv], {type: 'text/csv;charset=utf-8;'});
                        let csvURL = null;

                        if (navigator.msSaveBlob) 
                        {
                            csvURL = navigator.msSaveBlob(csvData, 'avaliations.csv');
                        } 
                        else 
                        {
                            csvURL = window.URL.createObjectURL(csvData);
                        }

                        let tempLink = document.createElement('a');
                        tempLink.href = csvURL;
                        tempLink.setAttribute('download', 'avaliations.csv');
                        tempLink.click();
                    } 
                    else 
                    {
                        this.items = resp.data
                        this.totalPage = resp.last_page
                        this.totals.all = resp.total
                        this.totals.approved = resp.total_approved;
                        this.totals.pending = resp.total_pending;
                    }
                }
                this.isLoading = false
            },
            __dayjs (date) 
            {
                return dayjs(date)
            },
            async handleVisible( index, item )
            {
                console.log('AAA', item)
                
                //item.visible = !item.visible
                const resp = await this.updateAvaliations(item)
                if (resp) 
                {
                    this.$eventHub.$emit('snackBar', {color: 'success', message: 'Avaliação editada com sucesso'})
                } 
                else 
                {
                    this.$eventHub.$emit('snackBar', {color: 'error', message: 'Erro ao editar avaliação'})
                }
            },
            setLoading()
            {
                this.$eventHub.emit('loading', {
                    title:'Realizando operação...',
                    subtitle:'Aguarde só um instante...',
                    status:true
                })
            },
            async onDeleteMany () {
                this.isLoadingDelete = true
                const ids = this.selecteds
                const r = await this.deleteManyAvaliations({ids})
                if (r) 
                {
                    this.$eventHub.$emit('snackBar', {color: 'success', message: 'Avaliações apagadas com sucesso'})
                    this.selecteds = []
                    this.confirmationDialog = false
                    this.load()
                } 
                else 
                {
                    this.$eventHub.$emit('snackBar', {color: 'error', message: 'Erro ao apagar avaliações'})
                }

                this.isLoadingDelete = false
            },
            sendFilter (query) 
            {
                this.filter.query = query
                this.isLoading = true

                if (query != "" && query || this.isSearched) 
                {
                    clearTimeout(this.timerSearch);
                    this.timerSearch = setTimeout(async () => {
                        await this.load(1)
                        this.isLoading = false
                        if (query) this.isSearched = true
                    }, 800)
                } 
                else 
                {
                    this.isLoading = false
                    clearTimeout(this.timerSearch)
                }
            }
        },
        mounted() 
        {
            this.load()
        },

        computed: {
            ...mapGetters(["getUser"]),
        },
    }
</script>