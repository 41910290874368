<template>
    <div>
        <div class="text-center" v-if="isLoading">
            <v-progress-circular
                indeterminate
                color="primary"
            ></v-progress-circular>
        </div>

        <div v-else>
            <v-row no-gutters class="mt-10 mb-8">
                <BreadcrumbsComponent
                    title="Pré-pedido"
                    :items="breadcrumbs"
                />
            </v-row>

            <v-row>
                <v-col cols="12" md="7">
                    <v-row>
                        <!--<v-col cols="12">
                            <v-text-field
                                label="Cliente"
                                outlined
                                v-model="form.client_name"
                                disabled
                            ></v-text-field>
                        </v-col>

                        <v-col cols="12">
                            <v-text-field
                                label="Simulação"
                                outlined
                                v-model="form.simulator_id"
                                disabled
                            ></v-text-field>
                        </v-col>-->

                        <v-col cols="12">
                            <span class="title">Abertura de pedido</span>
                            <span class="subtitle d-block mt-4"
                                >Selecione até cinco instaladores para que eles
                                possam iniciar o atendimento desta solicitação,
                                enviando orçamentos para o cliente.</span
                            >
                        </v-col>

                        <v-col cols="12">
                            <p>Filtrar empresas</p>
                        </v-col>

                        <v-col cols="12" md="12">
                            <v-row>
                                
                                <v-col class="pr-md-8">
                                    <v-row>
                                        <v-col md="4" cols="12">
                                            <v-btn
                                                block
                                                :outlined="filterButtomSelected != 0"
                                                @click="setFilter(0)"
                                                dark
                                                color="#0A85FF"
                                                height="40"
                                                class="no-uppercase"
                                                
                                            >{{ budget.simulator && budget.simulator.city ? budget.simulator.city.name: "Cidade inválida" }}</v-btn>
                                            
                                        </v-col>

                                        <v-col md="4" cols="12">
                                            <v-btn
                                                block
                                                :outlined="filterButtomSelected != 1"
                                                @click="setFilter(1)"
                                                dark
                                                color="#0A85FF"
                                                height="40"
                                                class="no-uppercase"
                                            >{{ budget.simulator && budget.simulator.state ? budget.simulator.state.name: "Estado inválida" }}</v-btn>

                                        </v-col>

                                        <v-col md="4" cols="12">
                                            <v-btn
                                                block
                                                :outlined="filterButtomSelected != 2"
                                                @click="setFilter(2)"
                                                dark
                                                color="#0A85FF"
                                                height="40"
                                                class="no-uppercase"
                                            >Qualquer região</v-btn>

                                        </v-col>
                                    </v-row>
                                </v-col>
                                
                                
                                
                                <v-col md="3" cols="12" class="content-text-search">
                                    <v-text-field
                                        placeholder="Nome da empresa"
                                        outlined
                                        v-model="textSearch"
                                        @keyup="sendFilter($event.target.value)"
                                        dense
                                        color="#0A85FF"
                                        :dark="filterButtomSelected == 3"
                                        :background-color="filterButtomSelected == 3 ? '#0A85FF' : undefined"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </v-col>
                        
                        
                        <v-col cols="12">
                            <div class="text-center">
                                <v-progress-circular
                                    v-if="isLoadingSearch"
                                    indeterminate
                                    color="primary"
                                ></v-progress-circular>
                            </div>
                        </v-col>

                        <v-col cols="12" v-if="installers.length > 0">
                            <div
                                class="custom__card pl-3 pr-3 mb-5"
                                v-for="(item, i) in installers"
                                :key="i"
                            >
                                <div class="pb-5" style="position: relative">
                                    <v-checkbox
                                        class="max-width-343"
                                        v-model="item.checked"
                                        :label="item.trading_name"
                                        @change="selectInstaller(item)"
                                        :disabled="item.disabled"
                                    ></v-checkbox>

                                    <div class="ml-8" style="margin-top: -20px">
                                        <span class="text-city d-block">{{ item.city ? item.city.name : '-' }}, {{ item.state ? item.state.uf : '-' }}</span>
                                        <span class="text-plan" v-if="item.is_paid">Plano Premium</span>
                                        <span class="text-plan-standard" v-else>Plano Standard</span>
                                        <span class="text-leads"> • {{ item.last_budget_days }} dia(s) sem receber leads</span>
                                    </div>

                                    <img
                                        class="float-image d-none d-sm-block"
                                        width="80"
                                        :src="
                                            item.url ||
                                            require('../../assets/nf.png')
                                        "
                                    />
                                </div>
                            </div>
                        </v-col>


                        <v-col cols="12" v-else-if="textSearch">
                            <v-card
                                elevation="0"
                            >
                                <v-card-text>
                                    <p class="text-not-found-installers">Ops, não localizamos uma empresa 
                                        <span class="text-decoration-underline">{{ textSearch }}</span> que atende em
                                        <span class="text-decoration-underline">{{ budget.simulator && budget.simulator.city ? budget.simulator.city.name: "Cidade inválida" }}</span>
                                        ou <span class="text-decoration-underline">{{ budget.simulator && budget.simulator.state ? budget.simulator.state.name: "Estado inválida" }}</span>, 
                                        que são as mesmas regiões desta solicitação.</p>
                                </v-card-text>
                            </v-card>
                            
                        </v-col>
                        

                        <v-col cols="12">
                            <v-btn
                                elevation="2"
                                large
                                block
                                class="white--text darken-3 elevation-0"
                                :disabled="isLoadingInsert"
                                :loading="isLoadingInsert"
                                @click="onInsertRequest"
                                color="#0A85FF"
                                >Abrir pedido</v-btn
                            >
                        </v-col>

                        <v-col cols="12">
                            <v-btn
                                large
                                block
                                text
                                @click="$router.push('/talk-to-consultant')"
                                >Cancelar</v-btn
                            >
                        </v-col>
                    </v-row>
                </v-col>

                <v-col cols="12" md="3" offset-md="2">
                    
                        <v-card :class="`cardSelectedInstaller`" :style="`${marginTopSelected ? 'margin-top: ' + (marginTopSelected - 200) + 'px' : ''}`">
                            <v-card-title class="titleSelecteds">Empresas selecionadas</v-card-title>
                            <v-card-text>


                                <v-list
                                    flat
                                    subheader
                                    three-line
                                >
                                    <v-list-item-group
                                        multiple
                                        v-if="!updatingInstallers"
                                    >
                                        <v-list-item v-for="(item, i) in budget.installers_default" :key="i" disabled>
                                            <template>
                                                <v-list-item-action>
                                                    <v-checkbox disabled v-model="checked"></v-checkbox>
                                                </v-list-item-action>

                                                <v-list-item-content disabled>
                                                    <v-list-item-title class="nameInstallerSelected text--disabled">{{ item.trading_name }}</v-list-item-title>
                                                    <v-list-item-subtitle class="cityInstallerSelected text--disabled">{{ item.city ? item.city.name : '-' }}, {{ item.state ? item.state.uf : '-' }}</v-list-item-subtitle>
                                                </v-list-item-content>
                                            </template>
                                        </v-list-item>
                                    </v-list-item-group>
                                </v-list>
                                

                                <v-list
                                    flat
                                    subheader
                                    three-line
                                >
                                    <v-list-item-group
                                        multiple
                                        v-if="!updatingInstallers"
                                    >
                                        <v-list-item v-for="(item, i) in installersSelecteds" :key="i">
                                            <template>
                                                <v-list-item-action>
                                                    <v-checkbox
                                                        v-model="installersSelecteds[i].checked"
                                                        @change="selectInstaller(item)"
                                                    ></v-checkbox>
                                                </v-list-item-action>

                                                <v-list-item-content>
                                                    <v-list-item-title class="nameInstallerSelected">{{ item.trading_name }}</v-list-item-title>
                                                    <v-list-item-subtitle class="cityInstallerSelected">{{ item.city ? item.city.name : '-' }}, {{ item.state ? item.state.uf : '-' }}</v-list-item-subtitle>
                                                </v-list-item-content>
                                            </template>
                                        </v-list-item>
                                    </v-list-item-group>
                                </v-list>
                            </v-card-text>

                            <v-card-text>
                                <v-btn
                                    v-if="!isLoadingInsert"
                                    block
                                    :disabled="isLoadingInsert"
                                    :loading="isLoadingInsert"
                                    @click="onInsertRequest"
                                    color="#0A85FF"
                                    dark
                                    x-large
                                    shaped
                                    elevation="0"
                                    class="no-uppercase"
                                >{{ !budget.order ? 'Salvar' : 'Atualizar pedido' }}</v-btn>


                                <div class="text-center" v-else>
                                    <v-progress-circular
                                        indeterminate
                                        color="primary"
                                    ></v-progress-circular>
                                </div>




                                <v-btn
                                    color="#0A85FF"
                                    class="mt-2 no-uppercase"
                                    large
                                    block
                                    text
                                    @click="$router.push('/talk-to-consultant')"
                                >Cancelar</v-btn
                                >
                            </v-card-text>
                        </v-card>
                </v-col>
                
            </v-row>
        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex";
import BreadcrumbsComponent from "@/components/BreadcrumbsComponent/Index";

export default {
    components: {
        BreadcrumbsComponent,
    },
    data: () => {
        return {
            checked: true,
            textSearch: null,
            isLoading: true,
            breadcrumbs: [
                {
                    text: "Dashboard",
                },
                {
                    text: "Pré-pedido",
                    disabled: true,
                },
                {
                    text: "Abrir Pedido",
                    disabled: true,
                },
            ],
            form: {
                client_name: "",
                simulator_id: "",
            },
            
            filterButtomSelected: 0,
            
            installers: [],
            installersDefault: [],
            budget: null,
            installersSelecteds: [],
            isLoadingInsert: false,
            isLoadingSearch: false,
            isSearched: false,
            timerSearch: null,
            updatingInstallers: false,
            absoluteSelecteds: false,
            marginTopSelected: null
        }
    },

    async mounted() {
        const id = this.$route.params.id;

        this.isLoading = true;

        const budget = await this.showBudget(id);

        if (budget) {
            const installers = await this.listInstallersToBudget({
                city_id: budget.simulator.city_id,
                csv: true,
            });
            this.installers = installers.map((i) => {
                i.checked = false;
                return i;
            });

            this.installersDefault = installers.map((i) => {
                i.checked = false;
                return i;
            });
            
            if (budget.installers_default) {
                this.installers = installers.map((i) => {

                    let exists = false

                    for (let ins of budget.installers_default) {
                        if (ins.id == i.id) {
                            exists = true
                        }
                    }

                    if (!exists) {
                        i.disabled = false
                    } else {
                        i.disabled = true
                    }


                    return i;
                });
            } else {
                this.installers = installers
            }
            
            
            this.budget = budget;
            this.form.client_name = budget.client.name;
        } else {
            this.$eventHub.$emit("snackBar", {
                color: "error",
                message: "Erro ao buscar orçamento",
            });
        }
        this.isLoading = false;




        window.addEventListener('scroll', this.handleScroll);
    },

    methods: {
        ...mapActions(["showBudget", "listInstallersToBudget", "insertOrder", "insertInstallersFromOrder"]),


        handleScroll (event) {
            if (document.body.clientWidth >= 960) {
                if (event.target.documentElement.scrollTop > 180) {
                    this.marginTopSelected = event.target.documentElement.scrollTop
                } else {
                    this.marginTopSelected = false
                }
            } else {
                this.marginTopSelected = false
            }
            
        },

        async selectInstaller (item) {
            this.updatingInstallers = true
            if (item.checked) {
                let exists = false
                for (let installer of this.installersSelecteds) {
                    if (installer.id == item.id) {
                        exists = true
                        break
                    }
                }
                
                if (!exists) this.installersSelecteds.push(item)
            } else {

                this.installersSelecteds = this.installersSelecteds.filter(s => {
                    if (s.id != item.id) return s
                })
            }

            await this.$forceUpdate()
            this.updatingInstallers = false
        },
        
        async onInsertRequest() {
            let installers = this.installersSelecteds

            this.isLoadingInsert = true;

            const data = {
                budget_id: this.$route.params.id,
                installers,
            };
            
            if (!this.budget.order) {
                const r = await this.insertOrder(data);

                if (r) {
                    this.$eventHub.$emit("snackBar", {
                        color: "success",
                        message: "Empresas adicionadas com sucesso ao pedido",
                    });

                    this.$router.push({ path: "/talk-to-consultant", query: { recentUpdated: this.$route.params.id } });

                } else {
                    this.$eventHub.$emit("snackBar", {
                        color: "error",
                        message: "Erro ao inserir pedido",
                    });
                }
            } else {
                data.id = this.budget.order.id
                const r = await this.insertInstallersFromOrder(data);

                if (r) {
                    this.$eventHub.$emit("snackBar", {
                        color: "success",
                        message: "Empresas adicionadas com sucesso ao pedido",
                    });

                    this.$router.push({ path: "/talk-to-consultant", query: { recentUpdated: this.$route.params.id } });

                } else {
                    this.$eventHub.$emit("snackBar", {
                        color: "error",
                        message: "Erro ao inserir pedido",
                    });
                }
            }
           

            this.isLoadingInsert = false;
        },



        sendFilter(query) {
            this.isLoadingSearch = true;
            if ((query != "" && query) || this.isSearched) {
                this.filterButtomSelected = 3
                clearTimeout(this.timerSearch);
                this.timerSearch = setTimeout(async () => {
                    
                    let installers = await this.listInstallersToBudget({
                        city_id: this.budget.simulator.city_id,
                        csv: true,
                        query
                    });

                    
                    if (!query) {
                        // UNE OS ARRAYS
                        for (let selected of this.installersSelecteds) {

                            let exists = false

                            for (let i in installers) {
                                if (installers[i].id == selected.id) {
                                    installers[i].checked = true
                                    exists = true
                                    break
                                }
                            }

                            if (!exists) {
                                installers.unshift(selected)
                            }
                        }
                    } else {
                        for (let selected of this.installersSelecteds) {
                            for (let i in installers) {
                                if (installers[i].id == selected.id) {
                                    installers[i].checked = true
                                    break
                                }
                            }
                            
                            installers = installers.filter(e => {
                                if(!e.checked) return e
                            })
                        }
                    }




                    if (this.budget.installers_default) {
                        this.installers = installers.map((i) => {

                            let exists = false

                            for (let ins of this.budget.installers_default) {
                                if (ins.id == i.id) {
                                    exists = true
                                }
                            }

                            if (!exists) {
                                i.disabled = false
                            } else {
                                i.disabled = true
                            }


                            return i
                        });
                    } else {
                        this.installers = installers
                    }
                    
                    this.ins

                    this.isLoadingSearch = false;
                    if (query) this.isSearched = true;
                }, 800);
            } else {
                this.setFilter(0)
                this.isLoadingSearch = false;
                clearTimeout(this.timerSearch);
            }
        },
        
        
        
        async setFilter (index) {
            this.isLoadingSearch = true;
            this.filterButtomSelected = index
            
            let installers = []
            
            if (index == 0) {
                installers = await this.listInstallersToBudget({
                    city_id: this.budget.simulator.city_id,
                    csv: true
                });
            } else if (index == 1) {
                installers = await this.listInstallersToBudget({
                    state_id: this.budget.simulator.state_id,
                    city_id: this.budget.simulator.city_id,
                    csv: true
                });
            } else if (index == 2) {
                installers = await this.listInstallersToBudget({
                    city_id: this.budget.simulator.city_id,
                    all_regions: true,
                    csv: true
                });
            }


            for (let selected of this.installersSelecteds) {
                for (let i in installers) {
                    if (installers[i].id == selected.id) {
                        installers[i].checked = true
                        break
                    }
                }

                
            }


            if (this.budget.installers_default) {
                this.installers = installers.map((i) => {

                    let exists = false

                    for (let ins of this.budget.installers_default) {
                        if (ins.id == i.id) {
                            exists = true
                        }
                    }

                    if (!exists) {
                        i.disabled = false
                    } else {
                        i.disabled = true
                    }


                    return i;
                });
            } else {
                this.installers = installers
            }
            

            this.isLoadingSearch = false
        }
    },
};
</script>

<style>
.v-input--checkbox label {
    font-style: normal;
    font-weight: normal !important;
    text-transform: capitalize !important;
    font-size: 20px;
    line-height: 140%;
    color: #004d99 !important;
}
</style>

<style scoped>

    @media screen and (min-width: 960px) {
        .absoluteSelected {
            position: fixed;
            width: inherit;
            margin-right: 30px;
        }

    }
    
    .text-not-found-installers {
        font-family: "cerapro-regular";
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px;
        letter-spacing: 0em;
        text-align: left;
        color: #004D99;
    }
    
    .content-text-search .v-text-field--outlined >>> fieldset {
        border-color: #0A85FF;
    }
    
    
    .title {
        font-style: normal;
        font-weight: bold;
        font-size: 32px !important;
        line-height: 40px;
        display: flex;
        align-items: center;
        letter-spacing: 0.0025em;
        color: #6e6b7b;
    }
    .subtitle {
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 140%;
        color: #8a8797;
    }
    .custom__card {
        border: 1px solid #ccc;
        border-radius: 5px;
    }
    .text-city {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 145%;
        color: #9f9daa;
    }
    .text-plan {
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0.02em;
        color: #ffa900;
    }
    .text-plan-standard {
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0.02em;
        color: #9F9DAA;
    }
    
    .text-leads {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0.02em;
        color: #9f9daa;
    }
    
    .float-image {
        position: absolute;
        right: 2px;
        top: 2px;
    }
    .max-width-343 {
        max-width: 343px;
    }
    
    
    
    .titleSelecteds {
        font-family: "cerapro-medium" !important;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 25px;
        letter-spacing: 0em;
        text-align: left;
        color: #6E6B7B;
    }
    
    .nameInstallerSelected {
        font-family: "cerapro-regular" !important;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 23px;
        letter-spacing: 0em;
        text-align: left;
        color: #004D99;
    }
    
    .cityInstallerSelected {
        font-family: "cerapro-medium" !important;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: left;
        color: #9F9DAA;
        
    }
    
    
    .cardSelectedInstaller {
        background: #FFFFFF;
        /* Cinza/C6 */

        border: 1px solid #EAE9EC !important;
        box-sizing: border-box !important;
        /* Botão/Sombra */

        box-shadow: 0px 4px 30px rgba(159, 157, 170, 0.24) !important;
        border-radius: 8px !important;
    }
    
    
    .v-list-item__action {
        margin-bottom: 0px !important;
        margin-right: 15px !important;
    }
    
    .v-list-item__content {
        padding-bottom: 25px !important;
    }
</style>
