import Http from "@/plugins/Http";

export default {
    async listSimulations({}, { page, simulator_id='', csv = false }) {
        try {
            let queryCsv = ''
            if (csv) {
                queryCsv = '&csv=true'
            }
            
            const { data } = await Http.get(`/simulator?page=${page}&type=1${simulator_id}${queryCsv}`);
            return data;
        } catch (e) {
            return false;
        }
    },


    async listSimulationsNew({}, params) {
        try {
            const { data } = await Http.get(`/simulator`, { params });
            return data;
        } catch (e) {
            return false;
        }
    },
    
    async showSimulation({}, id) {
        const { data } = await Http.get(`/simulator/${id}`);
        return data;
    },
    async insertSimulations({}, simulation) {
        try {
            await Http.post("/simulator", simulation);
            return true;
        } catch (e) {
            console.log(e.response.data);
            return false;
        }
    },

    async updateSimulator ({}, simulator) {
        const { data } = await Http.put('/simulator/' + simulator.id, simulator)
        return data
    },


    async insertSimulatorConsultant ({}, simulator) {
        const { data } = await Http.post('/simulator-consultant', simulator)
        return data
    },

    async deleteManySimulations ({}, ids) {
        try {
            await Http.delete('/simulator/delete/many', {
                data: ids
            })
            return true
        } catch (e) {
            return false
        }
    },

    async showSimulatorComplete ({}, id) {
        try {
            const { data } = await Http.get('/simulator/' + id, { params: { complete: true } })
            return data
        } catch (e) {
            return false
        }
    },
};
