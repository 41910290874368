<template>
    <div>
        <v-row no-gutters class="mt-10 mb-8">
            <BreadcrumbsComponent title="Simulações" :items="breadcrumbs">

                <template v-slot:actions>
                    <v-btn
                        :disabled="isLoading"
                        large
                        text
                        class="fontRegular no-uppercase"
                        style="color: #0066CC"
                        @click="$router.push('/simulations/'+$route.params.id+'/details')"
                    >
                        <v-icon class="mr-2">mdi-file-document-outline</v-icon>
                        Abrir resultado
                    </v-btn>

                </template>

            </BreadcrumbsComponent>
        </v-row>

        <List v-if="simulation" :simulation="simulation" />

        <v-alert v-if="error || (!isLoading && !simulation)" type="error"
            >Erro ao carregar simulação</v-alert
        >
        <v-alert v-if="isLoading" type="info">Carregando...</v-alert>
    </div>
</template>

<script>
import { mapActions } from "vuex";
import BreadcrumbsComponent from "@/components/BreadcrumbsComponent/Index";
import List from "./List.vue";

export default {
    components: {
        BreadcrumbsComponent,
        List,
    },
    data: () => {
        return {
            value: true,
            breadcrumbs: [
                {
                    text: "Dashboard",
                },
                {
                    text: "Simulações",
                },
                {
                    text: "Visualizar",
                    disabled: true,
                },
            ],
            simulation: null,
            id: null,
            isLoading: true,
            error: false,
        };
    },
    computed: {
        getData() {
            return Object.keys(this.simulation)
                .filter((key) => ["id", "client"].includes(key))
                .map((key) => {
                    return {
                        title: key == "client" ? "Cliente" : key,
                        value:
                            key == "client"
                                ? this.simulation["client"].name
                                : this.simulation[key],
                        icon: null,
                        createdAt: null,
                    };
                });
        },
    },
    methods: {
        ...mapActions(["showSimulation"]),
    },
    async mounted() {
        this.isLoading = true;
        try {
            this.simulation = await this.showSimulation(this.$route.params.id);
        } catch (error) {
            this.error = true;
        }
        this.isLoading = false;
    },
};
</script>
