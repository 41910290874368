<template>

    <v-dialog
        v-model="teste"
        scrollable
        persistent
        fullscreen
    >
        <v-col md="12" cols="12">
            <v-row class="content-search">
                <v-col md="8" cols="12">
                    <template style="height: 100%">
                        <v-col
                            cols="12"
                            md="12"
                        >
                            <v-text-field
                                placeholder="Busque por estados e cidades"
                                outlined
                                append-icon="mdi-magnify"
                            ></v-text-field>
                        </v-col>

                        <v-col
                            cols="12"
                            md="12"
                        >
                            <vuetify-google-autocomplete
                                ref="address"
                                id="map"
                                classname="form-control"
                                placeholder="Busque por estados e cidades"
                                v-on:placechanged="getAddressData"
                                country="br"
                                outlined
                                append-icon="mdi-magnify"
                            >
                            </vuetify-google-autocomplete>
                            
                        </v-col>

                    </template>
                </v-col>
            </v-row>
        </v-col>
    </v-dialog>


</template>


<script>
    export default {

        data() {
            return {
                teste: false,

                location: '',
                searchResults: [],
                service: null
            }
        },
        
        methods: {
            getAddressData (event) {
                console.log(event)
            }
        }
    }
</script>

<style>
    .content-search {
        height: 100%;
        background-color: #fff;
    }
</style>