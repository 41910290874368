<template>
    <v-row>
        <v-col col="12" md="7">
            <h1 class="mb-3" style="color:#312F37;font-size: 2.5rem;">{{title}}</h1>
            <v-breadcrumbs class="ma-0 pa-0" color="orange" :items="items" :divider="divider"></v-breadcrumbs>
        </v-col>

        <v-col md="5" class="d-flex flex-column flex-md-row justify-end">
            <slot name="actions"></slot>
        </v-col>
    </v-row>
</template>


<script>
    export default {
        name: 'Breadcrumbs',
        props: {
            title:{
                type: String,
                default:'Instaladores'
            },
            divider:{
                type: String,
                default:'•'
            },
            items:{
                type: Array,
                default(){
                    return [
                        {
                            text: 'Dashboard',
                            disabled: false,
                            href: 'breadcrumbs_dashboard',
                        },
                        {
                            text: 'Instaladores',
                            disabled: false,
                            href: 'breadcrumbs_link_1',
                        },
                        {
                            text: 'Visualizar',
                            disabled: true,
                            href: 'breadcrumbs_link_2',
                        }
                    ]
                }
            }
        }
    }
</script>

<style>
    .v-breadcrumbs .v-breadcrumbs__item {
        color: #2D9CDB;
    }
</style>
