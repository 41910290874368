import system from "./modules/system/index.js";
import administrators from "./modules/administrators/index.js";
import clients from "./modules/clients/index.js";
import distributors from "./modules/distributors/index.js";
import products from "./modules/products/index.js";
import simulations from "./modules/simulations/index.js";
import consultants from "./modules/consultants/index.js";
import installers from "./modules/installers/index.js";
import orders from "./modules/orders/index.js";
import budgets from "./modules/budgets/index.js";
import brands from "./modules/brands/index.js";
import avaliations from "./modules/avaliations/index.js";
import productsTypes from "./modules/productsTypes/index.js";
import trainings from "./modules/trainings/index.js";

export default {
    modules: {
        system,
        administrators,
        clients,
        distributors,
        products,
        simulations,
        consultants,
        installers,
        orders,
        budgets,
        brands,
        avaliations,
        productsTypes,
        trainings
        
    },
};
