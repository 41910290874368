<template>
    <div>
        <div class="text-center" v-if="isLoading">
            <v-progress-circular
                indeterminate
                color="primary"
            ></v-progress-circular>
        </div>

        <div v-else>
            <v-row no-gutters class="mt-10 mb-8">
                <BreadcrumbsComponent
                    title="Pré-pedido"
                    :items="breadcrumbs"
                />
            </v-row>

            <!--<v-row no-gutters v-if="!form.order">
                <v-col cols="12">
                    <ActionBarComponent>
                        <template v-slot:content>
                            <div
                                class="d-flex justify-space-between align-center flex-wrap"
                            >
                                <span class="grey--text text--darken-1"
                                    >Completou todas as informações do
                                    pré-projeto?</span
                                >

                                <v-btn
                                    @click="
                                        $router.push(
                                            `/talk-to-consultant/${$route.params.id}/order`
                                        )
                                    "
                                    elevation="2"
                                    large
                                    class="white--text grey darken-3 elevation-0"
                                >
                                    <v-icon class="mr-2">mdi-check</v-icon>
                                    Abrir Pedido
                                </v-btn>
                            </div>
                        </template>
                    </ActionBarComponent>
                </v-col>
            </v-row>-->

            <v-row>
                <v-col cols="12" md="7">
                    <v-row>
                        <v-col cols="12">
                            <v-text-field
                                label="Cliente"
                                outlined
                                v-model="form.client_name"
                                disabled
                            ></v-text-field>
                        </v-col>

                        <v-col cols="12">
                            <v-text-field
                                label="Simulação"
                                outlined
                                v-model="form.simulator_id"
                                disabled
                            ></v-text-field>
                        </v-col>

                        <!--<v-col cols="12">
                            <v-text-field
                                label="Área (em m²)"
                                hint="Apenas números"
                                persistent-hint
                                placeholder="Área disponível para a instalação (em m²)"
                                outlined
                                type="number"
                                v-model="form.area_size"
                            ></v-text-field>
                        </v-col>-->

                        <v-col cols="12">
                            <v-select
                                :items="roof_types"
                                label="Tipo de telhado"
                                outlined
                                v-model="form.roof_type"
                                item-text="label"
                                item-value="id"
                            ></v-select>
                        </v-col>

                        <v-col cols="12">
                            <v-select
                                :items="power_inputs"
                                label="Tipo de conexão"
                                outlined
                                v-model="form.power_input"
                                item-text="label"
                                item-value="id"
                            ></v-select>
                        </v-col>
                        
                        <v-col cols="12">
                            <v-select
                                :items="tecnologias"
                                label="Tecnologia do inversor"
                                outlined
                                v-model="form.tecnology"
                                multiple
                                item-text="name"
                                item-value="id"
                            ></v-select>
                        </v-col>

                        <v-col cols="12">
                            <span class="font-weight-regular">Anexos</span>

                            <div class="text-center" v-if="isLoadingInsertFile">
                                <v-progress-circular
                                    indeterminate
                                    color="primary"
                                ></v-progress-circular>
                            </div>
                            
                            
                            <DropzoneComponent
                                @onOver="onOver"
                                @onLeave="onLeave"
                                bgColor="#FFF"
                                :multiple="true"
                                v-else
                            >
                                <template v-slot:content>
                                    <div class="ma-4">
                                        <div class="text-center">
                                            <svg
                                                width="32"
                                                height="32"
                                                viewBox="0 0 32 32"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M30.6654 25.3333C30.6654 26.0406 30.3844 26.7189 29.8843 27.219C29.3842 27.719 28.7059 28 27.9987 28H3.9987C3.29145 28 2.61318 27.719 2.11308 27.219C1.61298 26.7189 1.33203 26.0406 1.33203 25.3333V10.6667C1.33203 9.95942 1.61298 9.28115 2.11308 8.78105C2.61318 8.28095 3.29145 8 3.9987 8H9.33203L11.9987 4H19.9987L22.6654 8H27.9987C28.7059 8 29.3842 8.28095 29.8843 8.78105C30.3844 9.28115 30.6654 9.95942 30.6654 10.6667V25.3333Z"
                                                    stroke="#828282"
                                                    stroke-width="2"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                />
                                                <path
                                                    d="M16.0013 22.6667C18.9468 22.6667 21.3346 20.2789 21.3346 17.3333C21.3346 14.3878 18.9468 12 16.0013 12C13.0558 12 10.668 14.3878 10.668 17.3333C10.668 20.2789 13.0558 22.6667 16.0013 22.6667Z"
                                                    stroke="#828282"
                                                    stroke-width="2"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                />
                                            </svg>
                                        </div>
                                        <h3
                                            class="mt-3 mb-3 dropzone-custom-title font-weight-regular text-center grey--text text--darken-1"
                                        >
                                            Arraste fotos e documentos, ou
                                            clique<br />
                                            aqui para fazer o upload dos
                                            arquivos necessários.
                                        </h3>
                                        <div
                                            class="subtitle-2 font-weight-regular text-center"
                                        >
                                            <i
                                                ><strong>Dica:</strong> arquivos
                                                aceitos são PDF, DOC, JPG,<br />
                                                PNG, XLS, MP4 e AVI.</i
                                            >
                                        </div>
                                    </div>
                                </template>
                            </DropzoneComponent>
                        </v-col>

                        <div class="text-center" v-if="isLoadingFiles">
                            <v-progress-circular
                                indeterminate
                                color="primary"
                            ></v-progress-circular>
                        </div>

                        <v-col cols="12" v-else>
                            <v-row>
                                <v-col
                                    md="4"
                                    cols="12"
                                    v-for="(n, indexDoc ) in budget.documents"
                                    :key="n.id"
                                >
                                    <CardComponent class="mb-10">
                                        
                                        
                                        <template v-slot:image>
                                            
                                            <div v-if="isLoadingDownloadFileBudget" style="background-color: #bdbdbd; height: 245px; width: 100% !important; display: flex; justify-content: center; align-items: center; cursor: pointer">
                                                <v-progress-circular
                                                    indeterminate
                                                    color="primary"
                                                ></v-progress-circular>
                                            </div>
                                            
                                            <template v-else>
                                                <div style="cursor: pointer; background-color: #bdbdbd; height: 245px; width: 100% !important;" v-if="allowedExtension.includes(n.file.split('.').pop())" @click="onDownloadFileBudget(n)">
                                                    <v-img height="245px" width="100%" :src="n.url"></v-img>
                                                </div>

                                                <div v-else @click="onDownloadFileBudget(n)" style="cursor: pointer; background-color: #bdbdbd; height: 245px; width: 100% !important; display: flex; justify-content: center; align-items: center">
                                                    <v-chip color="#FFF" disabled>{{ n.file.split('.').pop().toUpperCase() }}</v-chip>
                                                </div>
                                            </template>
                                            
                                            
                                        </template>
                                        
                                        
                                        <template v-slot:content>
                                            <span 
                                                class="black--text text--darken-2 subtitle-1 d-block font-weight-bold"
                                            >
                                                <v-combobox
                                                    v-model="n.title"
                                                    :items="itemsTypesDocs"
                                                    label="Nome"
                                                    outlined
                                                    dense
                                                    hide-selected
                                                    @change="onSendUpdateDocument(n, indexDoc)"
                                                    :disabled="isLoadingUpdateFile"
                                                    :loading="isLoadingUpdateFile"
                                                >
                                                     <template v-slot:no-data>
                                                        <v-list-item>
                                                          <v-list-item-content>
                                                            <v-list-item-title>
                                                              Sem resultados. Pressione <kbd>enter</kbd> para criar um novo
                                                            </v-list-item-title>
                                                          </v-list-item-content>
                                                        </v-list-item>
                                                      </template>
                                                </v-combobox>
                                            </span>
                                            <span
                                                class="grey--text text--darken-2 subtitle-1 d-block"
                                            >
                                                {{ n.file.split("/").pop() }}
                                            </span>
                                        </template>

                                        <template v-slot:actionsfloat>
                                            <!--<v-btn
                                                class="white"
                                                depressed
                                                elevation="2"
                                                icon
                                            >
                                                <v-icon>mdi-square-edit-outline</v-icon>
                                            </v-btn>-->
                                            <v-btn
                                                :disabled="isLoadingDeleteFile"
                                                :loading="isLoadingDeleteFile"
                                                class="white ml-3"
                                                depressed
                                                elevation="2"
                                                icon
                                                @click="onDeleteFile(n)"
                                                v-if="!form.order"
                                            >
                                                <v-icon>mdi-trash-can-outline</v-icon>
                                            </v-btn>
                                        </template>
                                    </CardComponent>
                                </v-col>
                            </v-row>
                        </v-col cols="12" class="d-flex flex-wrap">

                        <v-col cols="12" class="mt-3 mb-5">
                            <span class="font-weight-regular">Anotações</span>
                            <vue-editor v-model="form.notes" />
                        </v-col>

                        <v-col cols="12">
                            <v-btn
                                elevation="2"
                                large
                                block
                                class="white--text grey darken-3 elevation-0"
                                @click="onSendUpdate"
                                :disabled="isLoading"
                                :loading="isLoading"
                                >Atualizar</v-btn
                            >
                        </v-col>

                        <v-col cols="12">
                            <v-btn
                                large
                                block
                                text
                                @click="$router.push('/talk-to-consultant')"
                                >Cancelar</v-btn
                            >
                        </v-col>
                    </v-row>
                </v-col>


                <v-col cols="12" md="3" offset-md="2">
                    <v-card class="cardOpenOrder">
                        <v-card-text v-if="!budget.order">
                            Antes de abrir o pedido, primeiro atualize e salve as informações da solicitação.
                        </v-card-text>

                        <v-card-text v-else-if="budget.order.installer_id">
                            Este pedido já foi aprovado e está iniciado. Agora nesta fase não é mais possível adicionar outras empresas.
                        </v-card-text>
                        
                        <v-card-text v-else>
                            Inclua novas empresas a este pedido para que elas possam enviar orçamentos.
                        </v-card-text>
                        
                        <v-card-text>
                            
                            <v-btn
                                v-if="budget.order && budget.order.installer_id"
                                class="elevation-0 no-uppercase"
                                disabled
                                style="background-color: #BFBEC6 !important; color: #FFF !important;"
                            >Adicionar empresas</v-btn>
                            
                            <v-btn
                                v-else
                                @click="
                                        $router.push(
                                            `/talk-to-consultant/${$route.params.id}/order`
                                        )
                                    "
                                elevation="2"
                                large
                                block
                                class="white--text elevation-0 no-uppercase"
                                color="#002A53"
                            >
                                {{ !form.order ? 'Abrir Pedido' : 'Adicionar empresas' }}
                            </v-btn>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex";
import BreadcrumbsComponent from "@/components/BreadcrumbsComponent/Index";
import ActionBarComponent from "@/components/ActionBarComponent/Index";
import DropzoneComponent from "@/components/DropzoneComponent/Index";
import CardComponent from "@/components/CardComponent/Index";

export default {
    components: {
        BreadcrumbsComponent,
        ActionBarComponent,
        DropzoneComponent,
        CardComponent,
    },
    data: () => {
        return {
            isLoading: true,
            searchTypesDocs: "",
            itemsTypesDocs: [],
            roof_types: [
                { id: 1, label: "Telhado cerâmico ou colonial" },
                { id: 2, label: "Telhado de fibrocimento" },
                { id: 3, label: "Telhado metálico ondulado" },
                { id: 7, label: "Telhado metálico trapezoidal" },
                { id: 4, label: "Na minha laje" },
                { id: 5, label: "No solo (terreno, quintal)" },
                { id: 6, label: "Outro lugar / não tenho certeza" },
            ],
            power_inputs: [
                { id: null, label: "Não informado" },
                { id: 1, label: "Monofásico" },
                { id: 2, label: "Bifásico" },
                { id: 3, label: "Trifásico" },
            ],
            breadcrumbs: [
                {
                    text: "Dashboard",
                },
                {
                    text: "Pré-pedido",
                    disabled: true,
                },
                {
                    text: "Atualizar",
                    disabled: true,
                },
            ],
            form: {
                client_name: "",
                simulator_id: "",
                notes: "",
                area_size: "",
                roof_type: null,
                power_input: null,
                tecnology: []
            },
            tecnologias: [],
            budget: null,
            isLoadingDeleteFile: false,
            isLoadingFiles: false,
            isLoadingInsertFile: false,
            isLoadingUpdateFile: false,
            allowedExtension: ['jpeg', 'jpg', 'png','gif','bmp'],
            isLoadingDownloadFileBudget: false
        };
    },
    async mounted() {
        const id = this.$route.params.id;
        
        
        let arr = [
            'Vista superior',
            'Vista 3D',
            '3D da usina',
            'Padrão de entrada',
            'Conta de luz',
            'Estudo de sombreamento',
            'Local do inversor',
            'Quadro de energia',
            'Transformador'
        ]
        
        this.itemsTypesDocs = arr.sort()

        this.isLoading = true;

        const budget = await this.showBudget(id);
        
        if (budget) {
            const tecnologias = await this.listProductsTypes({ product_id: 4, csv: true })

            this.tecnologias = tecnologias.map(t => {
                t.id = parseInt(t.id)
                return t
            })
            
            this.budget = budget;
            this.form.client_name = budget.client.name;
            this.form.simulator_id = budget.simulator_id;
            this.form.area_size = budget.area_size;
            this.form.roof_type = budget.simulator.roof_type;
            this.form.power_input = budget.simulator.power_input;
            this.form.tecnology = budget.tecnology.map(e => parseInt(e))
            this.form.notes = budget.notes
            this.form.order = budget.order
            
            
            
            
            
        } else {
            this.$eventHub.$emit("snackBar", {
                color: "error",
                message: "Erro ao buscar orçamento",
            });
        }

        this.isLoading = false;
    },
    methods: {
        ...mapActions([
            "showBudget",
            "deleteDocumentBudget",
            "insertDocumentBudget",
            "updateBudget",
            "listProductsTypes",
            "updateDocumentBudget",
            "getDocumentBudget"
        ]),
        async onSendUpdate() {
            const form = { ...this.form };
            
            if (parseInt(form.roof_type) == 0) {
                this.$eventHub.$emit("snackBar", {
                    color: "error",
                    message: "Tipo de telhado obrigatório",
                });
                
                return
            }
            
            
            form.id = this.$route.params.id;

            const r = await this.updateBudget(form)
            if (r) {
                this.$eventHub.$emit("snackBar", {
                    color: "success",
                    message: "Orçamento atualizado com sucesso",
                });
            } else {
                this.$eventHub.$emit("snackBar", {
                    color: "error",
                    message: "Erro ao atualizar orçamento",
                });
            }
        },
        async loadFiles() {
            this.isLoadingFiles = true;
            const budget = await this.showBudget(this.$route.params.id);

            if (budget) {
                this.budget.documents = budget.documents;
            }

            this.isLoadingFiles = false;
        },

        downloadBase64File(contentType, base64Data, fileName) {
            const linkSource = `data:${contentType};base64,${base64Data}`;
            const downloadLink = document.createElement("a");
            downloadLink.href = linkSource;
            downloadLink.download = fileName;
            downloadLink.click();
        },


        async onDownloadFileBudget (item) {
            this.isLoadingDownloadFileBudget = true
            const r = await this.getDocumentBudget(item.id);
            if (r) {
                this.downloadBase64File('', r.file, r.name)
            } else {
                this.$eventHub.$emit('snackBar', {color: 'error', message: 'Erro ao baixar arquivo'})
            }
            this.isLoadingDownloadFileBudget = false
        },
        
        async onSendUpdateDocument (file, indexDoc) {
            this.isLoadingUpdateFile = true
            
            const r = await this.updateDocumentBudget(this.budget.documents[indexDoc])
            
            if (r) {
                this.$eventHub.$emit("snackBar", {
                    color: "success",
                    message: "Documento atualizado com sucesso",
                });
            } else {
                this.$eventHub.$emit("snackBar", {
                    color: "error",
                    message: "Erro ao atualizar",
                });
            }

            this.isLoadingUpdateFile = false
        },
        
        async onDeleteFile(file) {
            this.isLoadingDeleteFile = true;
            const r = await this.deleteDocumentBudget(file.id);
            if (r) {
                this.$eventHub.$emit("snackBar", {
                    color: "success",
                    message: "Documento apagado com sucesso",
                });
                this.loadFiles();
            } else {
                this.$eventHub.$emit("snackBar", {
                    color: "error",
                    message: "Erro ao apagar documento",
                });
            }
            this.isLoadingDeleteFile = false;
        },
        toBase64(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = (error) => reject(error);
            });
        },

        async successCallback(result) {
            console.log("It succeeded with " + result.status);
            console.log(result)
            return result.status;
        },

        async failureCallback(error) {
            console.log("It failed with " + error);
            console.log(error)
            return error;
        },

        async onLeave(file) {
            this.isLoadingInsertFile = true;

            const formData = new FormData();

            formData.append('file', file[0]);

            let token = sessionStorage.getItem('token');

            const options = {
                method: 'POST',
                body: formData,
                headers: {
                    'Authorization': token
                }
            };

            let r = await fetch(`https://netapi1.marketsolar.com.br/budgets/${this.$route.params.id}/documents`, options).then(this.successCallback, this.failureCallback);

            if (r == 200) {
                this.$eventHub.$emit("snackBar", {
                    color: "success",
                    message: "Documento inserido com sucesso",
                });
                this.loadFiles();
            } else {
                this.$eventHub.$emit("snackBar", {
                    color: "error",
                    message: "Erro ao inserir documento",
                });
            }
            this.isLoadingInsertFile = false;
        },
        onOver() {},
    },
};
</script>



<style scoped>
    .cardOpenOrder {
        background: #FFFFFF;
        /* Cinza/C6 */

        border: 1px solid #EAE9EC !important;
        box-sizing: border-box !important;
        /* Botão/Sombra */

        box-shadow: 0px 4px 30px rgba(159, 157, 170, 0.24) !important;
        border-radius: 8px !important;
    }
    
</style>