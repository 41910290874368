<template>
    <div>
        <PlacesComponent
            v-if="placesDialog"
            @onClose="placesDialog = false"
            @onSave="onSelectedRegion"
            :dialog="placesDialog"
            :initSelecteds="consultant.stateCity"
        />

        <v-row no-gutters class="mt-10 mb-8">
            <BreadcrumbsComponent title="Consultores" :items="breadcrumbs" />
        </v-row>

        
        <v-dialog v-model="dialogCrop" max-width="600">
            <v-card v-if="dialogCrop">
                <v-card-text>

                    <vue-anka-cropper
                        :options="{
                           aspectRatio: 1,
                           closeOnSave: true,
                           cropArea: 'circle',
                           croppedHeight: 400,
                           croppedWidth: 400,
                           cropperHeight: false,
                           dropareaMessage: 'Arraste o logo da sua empresa ou clique aqui para fazer o upload do arquivo',
                           frameLineDash: [5,3],
                           frameStrokeColor: 'rgba(255, 255, 255, 0.8)',
                           handleFillColor: 'rgba(255, 255, 255, 0.2)',
                           handleHoverFillColor: 'rgba(255, 255, 255, 0.4)',
                           handleHoverStrokeColor: 'rgba(255, 255, 255, 1)',
                           handleSize: 10,
                           handleStrokeColor: 'rgba(255, 255, 255, 0.8)',
                           layoutBreakpoint: 850,
                           maxCropperHeight: 768,
                           maxFileSize: 8000000,
                           overlayFill: 'rgba(0, 0, 0, 0.5)',
                           previewOnDrag: true,
                           previewQuality: 0.65,
                           resultQuality: 0.8,
                           resultMimeType: 'image/jpeg',
                           selectButtonLabel: 'Selecionar Arquivo',
                           showPreview: false,
                           skin: 'light',
                           uploadData: {},
                           uploadTo: false
                        }"
                        @cropper-mounted="onCropperMounted"
                        @cropper-saved="onCropped"
                        ref="dropZone"
                    >
                        <template v-slot:areaDrop>
                            <div
                                @click="$refs.dropZone.triggerInput()"
                                @dragend="dragEnd"
                                @drop="dropHandler"
                                @dragover="dragOverHandler"
                            >
                                <CardComponent>
                                    <template v-slot:content>
                                        <div class="text-center" v-if="!consultant.image">
                                            <svg
                                                width="132"
                                                height="132"
                                                viewBox="0 0 132 132"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <circle
                                                    cx="66"
                                                    cy="66"
                                                    r="66"
                                                    fill="#BDBDBD"
                                                />
                                                <path
                                                    d="M80.6654 75.3333C80.6654 76.0406 80.3844 76.7189 79.8843 77.219C79.3842 77.719 78.7059 78 77.9987 78H53.9987C53.2915 78 52.6132 77.719 52.1131 77.219C51.613 76.7189 51.332 76.0406 51.332 75.3333V60.6667C51.332 59.9594 51.613 59.2811 52.1131 58.781C52.6132 58.281 53.2915 58 53.9987 58H59.332L61.9987 54H69.9987L72.6654 58H77.9987C78.7059 58 79.3842 58.281 79.8843 58.781C80.3844 59.2811 80.6654 59.9594 80.6654 60.6667V75.3333Z"
                                                    stroke="#F2F2F2"
                                                    stroke-width="2"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                />
                                                <path
                                                    d="M66.0013 72.6667C68.9468 72.6667 71.3346 70.2789 71.3346 67.3333C71.3346 64.3878 68.9468 62 66.0013 62C63.0558 62 60.668 64.3878 60.668 67.3333C60.668 70.2789 63.0558 72.6667 66.0013 72.6667Z"
                                                    stroke="#F2F2F2"
                                                    stroke-width="2"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                />
                                            </svg>
                                        </div>

                                        <div class="text-center" v-else>
                                            <img
                                                v-if="consultant.image"
                                                :src="consultant.image"
                                                width="132px"
                                                style="border-radius: 50%"
                                            />
                                        </div>
                                        <h3
                                            class="mt-5 mb-3 dropzone-custom-title font-weight-light text-center grey--text text--darken-1"
                                        >
                                            Arraste a foto aqui ou<br />
                                            clique para fazer o<br />
                                            upload do avatar
                                        </h3>
                                        <div class="caption text-center mt-5">
                                            <i
                                            ><strong>Dica:</strong> use um
                                                arquivo PNG, <br />
                                                quadrado, com 256x256 pixels</i
                                            >
                                        </div>
                                    </template>
                                </CardComponent>
                            </div>
                        </template>

                    </vue-anka-cropper>
                </v-card-text>
            </v-card>
        </v-dialog>
        
        
        
        

        <form @keydown.enter="!isLoadingInsert && isValid && save()">
            <v-row>
                <v-col cols="12" md="3">
                    
                    <div
                        
                        @click="onInitDrop"
                        @dragend="dragEnd" 
                        @drop="dropHandler" 
                        @dragover="dragOverHandler"
                    >
                        
                        <CardComponent>
                            <template v-slot:content>
                                <div class="text-center" v-if="!consultant.image">
                                    <svg
                                        width="132"
                                        height="132"
                                        viewBox="0 0 132 132"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <circle
                                            cx="66"
                                            cy="66"
                                            r="66"
                                            fill="#BDBDBD"
                                        />
                                        <path
                                            d="M80.6654 75.3333C80.6654 76.0406 80.3844 76.7189 79.8843 77.219C79.3842 77.719 78.7059 78 77.9987 78H53.9987C53.2915 78 52.6132 77.719 52.1131 77.219C51.613 76.7189 51.332 76.0406 51.332 75.3333V60.6667C51.332 59.9594 51.613 59.2811 52.1131 58.781C52.6132 58.281 53.2915 58 53.9987 58H59.332L61.9987 54H69.9987L72.6654 58H77.9987C78.7059 58 79.3842 58.281 79.8843 58.781C80.3844 59.2811 80.6654 59.9594 80.6654 60.6667V75.3333Z"
                                            stroke="#F2F2F2"
                                            stroke-width="2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        />
                                        <path
                                            d="M66.0013 72.6667C68.9468 72.6667 71.3346 70.2789 71.3346 67.3333C71.3346 64.3878 68.9468 62 66.0013 62C63.0558 62 60.668 64.3878 60.668 67.3333C60.668 70.2789 63.0558 72.6667 66.0013 72.6667Z"
                                            stroke="#F2F2F2"
                                            stroke-width="2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        />
                                    </svg>
                                </div>
                                <div class="text-center" v-else>
                                    <img
                                        v-if="consultant.image"
                                        :src="consultant.image"
                                        width="132px"
                                        style="border-radius: 50%"
                                    />
                                </div>
                                <h3
                                    class="mt-5 mb-3 dropzone-custom-title font-weight-light text-center grey--text text--darken-1"
                                >
                                    Arraste a foto aqui ou<br />
                                    clique para fazer o<br />
                                    upload do avatar
                                </h3>
                                <div class="caption text-center mt-5">
                                    <i
                                        ><strong>Dica:</strong> use um
                                        arquivo PNG, <br />
                                        quadrado, com 256x256 pixels</i
                                    >
                                </div>
                            </template>
                        </CardComponent>
                        
                    </div>

                    <!--<img
                        v-if="consultant.image"
                        :src="consultant.image"
                        :style="{ maxWidth: '100%' }"
                    />
                    <v-icon
                        v-if="consultant.image"
                        @click="consultant.image = ''"
                        >mdi-close</v-icon
                    >-->
                </v-col>

                <v-col cols="12" md="9">
                    <v-col cols="12" md="9">
                        <v-text-field
                            label="Nome"
                            placeholder="Nome"
                            outlined
                            v-model="consultant.name"
                            :rules='rules.nameRules'
                            @blur="validate" 
                            @focus="clearRules"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="9">
                        <v-text-field
                            label="E-mail"
                            persistent-hint
                            hint="Uma senha automática será gerada e enviada para o e-mail cadastrado."
                            placeholder="E-mail"
                            outlined
                            v-model="consultant.email"
                            :rules='rules.emailRules'
                            @blur="validate" 
                            @focus="clearRules"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="9">
                        <v-text-field
                            label="DDD Celular"
                            v-mask="['(##) ####-####', '(##) #####-####']"
                            persistent-hint
                            hint="Apenas números"
                            placeholder="DDD Celular"
                            outlined
                            v-model="consultant.phone"
                            :rules='rules.phoneRules'
                            @blur="validate" 
                            @focus="clearRules"
                        ></v-text-field>
                    </v-col>

                    <v-col cols="12" v-if="getCompanySize.length > 0">
                        <span class="d-block grey--text text--darken-4"
                            >Selecione todas as opções que forem
                            compatíveis</span
                        >
                        <span class="d-block grey--text text--darken-3"
                            >Quais portes de projeto você atende?</span
                        >
                        <v-checkbox
                            v-for="(item, i) in getCompanySize"
                            @click="setChecked('company_size', item.id)"
                            :value="consultant.company_size.includes(item.id)"
                            :key="i"
                            persistent-hint
                            :hint="item.description"
                            :label="item.size"
                        ></v-checkbox>
                    </v-col>

                    <v-col
                        cols="12"
                        class="mt-5"
                        v-if="getConsumerType.length > 0"
                    >
                        <span class="d-block grey--text text--darken-4"
                            >Selecione todas as opções que forem
                            compatíveis</span
                        >
                        <span class="d-block grey--text text--darken-3"
                            >Quais tipos de cliente você atende?</span
                        >
                        <v-checkbox
                            v-for="(item, i) in getConsumerType"
                            @click="setChecked('consumer_type', item.id)"
                            :value="consultant.consumer_type.includes(item.id)"
                            :key="i"
                            persistent-hint
                            :hint="item.description"
                            :label="item.type"
                        ></v-checkbox>
                    </v-col>

                    <v-col cols="12" md="8">
                        <TextBlockComponent
                            @onClick="placesDialog = true"
                            class="mb-4"
                            v-bind="regions"
                        />
                    </v-col>

                    <v-col cols="12" md="8" v-if="false">
                        <TextBlockComponent class="mb-4" v-bind="installers" />
                    </v-col>

                    <v-col cols="12" md="8">
                        <span class="grey--text text--darken-1"
                            ><strong>Dica:</strong> comece selecionando as
                            regiões atendidas. Os instaladores compatíveis serão
                            selecionados automaticamente a partir das suas
                            regiões atendidas. Depois, você pode desmarcar os
                            instaladores que não são relevantes com o seu perfil
                            de trabalho.</span
                        >
                    </v-col>

                    <v-col>
                        <v-switch
                            v-model="consultant.remote"
                            inset
                            label="Consultor remoto?"
                            color="success"
                        ></v-switch>
                    </v-col>

                    <v-col cols="12" md="8">
                        <v-btn
                            :disabled="isLoadingInsert || !isValid"
                            :loading="isLoadingInsert"
                            elevation="2"
                            @click="save"
                            large
                            block
                            text
                            class="white--text grey darken-3 mt-5 elevation-0 mx-auto"
                            dark
                            >Cadastrar</v-btn
                        >
                        <v-btn
                            large
                            block
                            text
                            class="mt-3"
                            @click="$router.push('/consultants')"
                            >Cancelar</v-btn
                        >
                    </v-col>
                </v-col>
            </v-row>
        </form>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import BreadcrumbsComponent from "@/components/BreadcrumbsComponent/Index";
import TextBlockComponent from "@/components/TextBlockComponent/Index";
import DropzoneComponent from "@/components/DropzoneComponent/Index";
import CardComponent from "@/components/CardComponent/Index";
import PlacesComponent from "@/components/PlacesComponent/Index";
import vueAnkaCropper from '../../components/Cropper'

const INIT_STATE = {
    name: "",
    username: "",
    email: "",
    phone: "",
    image: "",
    cpf_cnpj: "",
    company_size: [],
    consumer_type: [],
    stateCity: [],
    remote: false,
};

export default {
    components: {
        BreadcrumbsComponent,
        TextBlockComponent,
        DropzoneComponent,
        CardComponent,
        PlacesComponent,
        vueAnkaCropper
    },
    computed: {
        ...mapGetters(["getCompanySize", "getConsumerType"]),
        isValid() {
            return !!(
                (
                    this.consultant.name &&
                    /^[A-Za-z0-9_\-\.+]+@[A-Za-z0-9_\-\.]{2,}\.[A-Za-z0-9]{2,}(\.[A-Za-z0-9])?/i.test(
                        this.consultant.email
                    ) &&
                    this.consultant.phone
                ) /*&& this.consultant.image*/
            );
        },
    },
    data: () => {
        return {
            rules: [],
            dialogCrop: false,
            placesDialog: false,
            optionsStates: [],
            regions: {
                icon: {
                    name: "mdi-map-marker-outline",
                    color: "blue--text text--darken-2",
                    props: {},
                },
                title: "REGIÕES ATENTIDAS",
                subtitle: "Busque e selecione estados ou cidades",
                legend: "Nenhuma seleção",
            },
            installers: {
                icon: {
                    name: "mdi-lightning-bolt-outline",
                    color: "blue--text text--darken-2",
                    props: {},
                },
                title: "INSTALADORES ATENDIDOS",
                subtitle: "Busque e selecione estados ou cidades",
                legend: "Nenhuma seleção",
            },
            consultant: { ...INIT_STATE },
            breadcrumbs: [
                {
                    text: "Dashboard",
                },
                {
                    text: "Consultores",
                },
                {
                    text: "Adicionar novo",
                    disabled: true,
                },
            ],
            isLoading: true,
            isLoadingInsert: false,
        };
    },
    async mounted() {
        this.isLoading = true;
        this.optionsStates = await this.getStates();
        await this.loadCompanySize();
        await this.loadConsumerType();
        this.isLoading = false;
        this.useFile = null
    },
    methods: {
        ...mapActions([
            "getStates",
            "insertConsultant",
            "loadCompanySize",
            "loadConsumerType",
        ]),


        onInitDrop () {
            this.dialogCrop = true
        },

        onCropperMounted () {
            if (this.useFile) {
                this.$refs.dropZone.selectFileManual(this.useFile)
            } else {
                this.$refs.dropZone.triggerInput()
            }
            //this.useFile = null
        },

        onCropped (event) {
            this.consultant.image = event.croppedImageURI
            this.dialogCrop = false
        },


        dropHandler(e)
        {
            e.preventDefault()
            
                if ( e.dataTransfer.items && e.dataTransfer.items.length > 0 )
                {
                    let file = e.dataTransfer.items[0].getAsFile()
                    
                    this.dialogCrop = true
                    this.useFile = file
                }
            

            this.ondrop = false
        },
        dragOverHandler(e)
        {
            e.preventDefault()
            this.ondrop = true
            this.$emit('onOver', e)
        },
        dragEnd(e)
        {
            e.preventDefault()
            this.ondrop = false
        },
        
        
        
        
        
        
        
        
        
        
        
        
        toBase64(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = (error) => reject(error);
            });
        },
        onOver() {},
        async onLeave(file) {
            this.consultant.image = await this.toBase64(file);
        },
        async save() {
            this.isLoadingInsert = true;
            try {
                await this.insertConsultant(this.consultant);
                this.$eventHub.$emit("snackBar", {
                    color: "success",
                    message: "Cadastrado com sucesso",
                });
                this.clean();
                
                this.$router.push('/consultants')
            } catch (error) {
                this.$eventHub.$emit("snackBar", {
                    color: "error",
                    message: "Erro ao realizar operação",
                });
            }
            this.isLoadingInsert = false;
        },
        clean() {
            this.consultant = { ...INIT_STATE };
            this.regions.legend = "Nenhuma seleção";
        },
        setChecked(field, id) {
            if (this.consultant[field].includes(id)) {
                this.consultant[field] = this.consultant[field].filter(
                    (_) => _ != id
                );
            } else {
                this.consultant[field].push(id);
            }
        },
        setLoading(title, subtitle = "") {
            this.$eventHub.emit("loading", {
                title,
                subtitle,
                status: title && subtitle,
            });
        },

        onSelectedRegion(event) {
            this.consultant.stateCity = event;
            if (event.length > 0) {
                this.regions.legend = `${event.length} selecionado(os)`;
            } else {
                this.regions.legend = "Nenhuma seleção";
            }
        },

        validate() {
            this.rules = {
                "nameRules": [v => !!v || "O preenchimento deste campo é obrigatório"],
                "emailRules": [
                    v => !!v || "O preenchimento deste campo é obrigatório",
                    v => /.+@.+/.test(v) || "Insira um e-mail válido"
                ],
                "phoneRules": [
                    v => !!v || "O preenchimento deste campo é obrigatório",
                    v => v.length >= 14 || "Insira um telefone válido"
                ]
            }
        },

        clearRules(){
            this.rules = []
        }
    },
};
</script>
