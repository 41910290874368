<template>
    <div>


        <v-dialog
            v-model="confirmationDialog"
            transition="dialog-top-transition"
            max-width="600"
            persistent
        >
            <v-card class="pa-5">
                <v-card-text class="pt-4">
                    <h1 class="text-center mb-4" style="line-height: 1">
                        Você selecionou {{ selecteds.length }} item(s), tem
                        certeza que deseja excluí-lo(s)?
                    </h1>
                    <span class="d-block text-center mt-4 mb-5">
                        Importante: essa ação não poderá ser desfeita. O Pré-pedido e/ou Pedido vinculados a esta simulação também serão removidos.
                    </span>

                    <v-btn
                        :loading="isLoadingDelete"
                        :disabled="isLoadingDelete"
                        elevation="2"
                        large
                        block
                        text
                        class="white--text grey darken-3 mt-5 elevation-0 mx-auto"
                        @click="onDeleteMany"
                    >Sim, confirmo a exclusão</v-btn
                    >
                    <v-btn
                        :disabled="isLoadingDelete"
                        large
                        block
                        text
                        class="mt-3"
                        @click="confirmationDialog = false"
                    >Cancelar</v-btn
                    >
                </v-card-text>
            </v-card>
        </v-dialog>
        
        
        <v-row no-gutters class="mt-10 mb-8">
            <BreadcrumbsComponent title="Simulações" :items="breadcrumbs">
                <template v-slot:actions>
                    <v-btn 
                        elevation="2" 
                        large 
                        color="secondary" 
                        class="white--text mt-2 ml-2 elevation-0" 
                        @click="$router.push('/simulations/add')"
                        v-if="getUser.user_type == 2"
                    >
                        <v-icon class="mr-2">mdi-plus</v-icon>
                        Adicionar novo
                    </v-btn>
                </template>
            </BreadcrumbsComponent>
        </v-row>

        <v-row no-gutters>
            <v-col cols="12" md="12">
                <FilterPanelComponent 
                    :show-content="false"
                    @onDownload="load(1, true)"
                    @onSearching="sendFilter"
                >
                    <template v-slot:result>
                        <v-chip class="ma-2" close color="grey darken-1" outlined> <span>Cliente:</span> <span class="ml-2">Logo Nunes Castanheda</span> </v-chip>
                    </template>
                </FilterPanelComponent>

                <ActionBarComponent v-if="selecteds.length > 0">
                    <template v-slot:content>
                        <div class="d-flex justify-space-between flex-wrap">
                            <span class="grey--text text--darken-4"
                            >{{ selecteds.length }} registro(s)
                                selecionado(s)</span
                            >
                            <v-icon
                                color="grey darken-2"
                                @click="confirmationDialog = true"
                            >mdi-trash-can-outline</v-icon
                            >
                        </div>
                    </template>
                </ActionBarComponent>
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <v-data-table
                    :headers="headers"
                    :items="items"
                    hide-default-footer
                    :loading="isLoading"
                    :items-per-page="-1"
                >
                    <template v-slot:item.id="{ item }">
                        <span class="blue--text">{{item.id}}</span>
                    </template>


                    <template v-slot:item.type="{ item }">
                        {{ __getResidenceType(item.residenceType) }}
                    </template>

                    <template v-slot:item.updatedat="{ item }">
                        <v-checkbox
                            v-model="selecteds"
                            :value="item.id"
                            :label="`${__dayjs(item.updated_at).format(
                                'DD/MM/YYYY HH:mm:ss'
                            )}`"

                            v-if="getUser.user_type === 1"
                        ></v-checkbox>

                        <span v-else>{{ __dayjs(item.updated_at).format('DD/MM/YYYY HH:mm:ss') }}</span>
                    </template>

                    <template v-slot:item.city="{ item }">
                        <v-chip v-if="!item.city" color="grey lighten-3 grey--text" dark>-</v-chip>
                        <span v-else>{{item.city.name}}</span>
                    </template>

                    <template v-slot:item.uf="{ item }">
                        
                        <span v-if="item.city && item.city.state">{{item.city.state.uf}}</span>
                        <v-chip v-else color="grey lighten-3 grey--text" dark>-</v-chip>
                    </template>


                    <template v-slot:item.budget="{ item }">
                        R$ {{ __format(item.budget_total) }}
                    </template>


                    
                    
                    
                    <template v-slot:item.client="{ item }">
                        <v-chip v-if="!item.client" color="grey lighten-3 grey--text" dark>Anônimo</v-chip>
                        <template v-else>
                            {{item.client.name}}
                            <p class="p-0 grey--text">{{ item.client.phone ? item.client.phone : '' }}</p>
                        </template>
                        
                    </template>



                    <template v-slot:item.consultant="{ item }">
                        <v-chip v-if="!item.consultant" color="grey lighten-3 grey--text" dark>Anônimo</v-chip>
                        <template v-else>
                            {{item.consultant.name}}
                            <p class="p-0 grey--text">{{ item.consultant.phone ? item.consultant.phone : '' }}</p>
                        </template>

                    </template>

                    <template v-slot:item.action="{ item }">
                        <v-icon @click="$router.push('/simulations/'+item.id)">mdi-dots-horizontal</v-icon>
                    </template>
                    

                    <template v-slot:item.arrow="{ item }">
                        <v-menu offset-y>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    color="primary"
                                    dark
                                    v-bind="attrs"
                                    v-on="on"
                                    text
                                >
                                    <v-icon>mdi-dots-horizontal</v-icon>
                                </v-btn>
                            </template>
                            <v-card class="mx-auto" max-width="300" tile>
                                <v-list>
                                    <v-list-item link>
                                        <v-list-item-title
                                            @click="
                                                $router.push(
                                                    '/simulations/'+item.id
                                                )
                                            "
                                        ><v-icon>mdi-eye-outline</v-icon>
                                            Visualizar</v-list-item-title
                                        >
                                    </v-list-item>
                                    <v-list-item link>
                                        <v-list-item-title
                                            @click="
                                                $router.push(
                                                     '/simulations/'+item.id+'/details'
                                                )
                                            "
                                        ><v-icon
                                        >mdi-file-document-outline</v-icon
                                        >
                                            Abrir resultado</v-list-item-title
                                        >
                                    </v-list-item>

                                    <v-list-item link :disabled="item.consultant ? true : false" @click="openSetConsultant(item)">
                                        <v-list-item-title class="d-flex">
                                            <div
                                                :style="`
                                                    width: 24px;
                                                    height: 24px;
                                                    background-color: ${item.consultant ? '#9F9DAA' : '#004D99'};
                                                    -webkit-mask: url(/images/icon-user.svg) no-repeat center;
                                                    mask: url(/images/icon-user.svg) no-repeat center;
                                                `"
                                            ></div>
                                            <span :style="`color: ${item.consultant ? '#9F9DAA' : '#004D99'} !important`">Vincular consultor</span>
                                        </v-list-item-title>
                                    </v-list-item>
                                    <!--<v-divider></v-divider>

                                    <v-list-item link>
                                        <v-list-item-title
                                            @click="onDelete(item)"
                                        ><v-icon
                                        >mdi-trash-can-outline</v-icon
                                        >
                                            Excluir</v-list-item-title
                                        >
                                    </v-list-item>-->
                                </v-list>
                            </v-card>
                        </v-menu>
                    </template>
                    
                </v-data-table>
            </v-col>

        </v-row>

        <v-row>
            <v-col>
                <div class="text-center">
                    <v-pagination
                        v-model="page"
                        :length="totalPage"
                        :total-visible="7"
                        next-aria-label="Próximo"
                        @input="load($event)"
                    ></v-pagination>
                </div>
            </v-col>
        </v-row>



        <v-navigation-drawer
            v-model="drawerSetConsultant"
            temporary
            right
            width="420px"
            fixed
            style="padding: 30px"
        >
            <div id="contentSearchConsultant" v-if="simulationSelected">
                <div>
                    <v-card-title>Vincular consultor</v-card-title>
                    <v-autocomplete
                        height="54"
                        v-model="selectedSearchConsultants"
                        color="grey lighten-1"
                        append-icon="mdi-magnify"
                        placeholder="Digite o nome do consultor"
                        :items="itemsSearchConsultant"
                        @update:search-input="sendFilterConsultant"
                        :loading="isLoadingConsultants"
                        chips
                        label="Digite o nome do consultor"
                        item-value="name"
                        return-object
                        :no-filter="true"
                    >

                        <template v-slot:selection="data">
                            <v-chip
                                v-bind="data.attrs"
                                :input-value="data.selected"
                                close
                                @click="data.select"
                                @click:close="selectedSearchConsultants = null"
                            >
                                <v-avatar left>
                                    <v-img :src="data.item.url"></v-img>
                                </v-avatar>
                                {{ data.item.name }}
                            </v-chip>
                        </template>


                        <template v-slot:item="data">
                            <template>
                                <v-list-item-avatar>
                                    <v-img :src="data.item.url"></v-img>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title v-html="data.item.name"></v-list-item-title>
                                    <v-list-item-subtitle v-html="data.item.phone"></v-list-item-subtitle>
                                </v-list-item-content>
                            </template>
                        </template>


                    </v-autocomplete>
                </div>


                <div>
                    <v-btn
                        :loading="isLoaidngUpdateSimulator"
                        :disabled="isLoaidngUpdateSimulator || (!this.simulationSelected || !this.selectedSearchConsultants)"
                        @click="onSetConsultant" 
                        block 
                        class="no-uppercase elevation-0" 
                        large 
                        style="background-color: #0A85FF; height: 57px; padding: 16px 32px; border-radius: 8px; font-size: 18px; color: #FFF"
                    >
                        Confirmar
                    </v-btn>



                    <v-btn 
                        :disabled="isLoaidngUpdateSimulator"
                        @click="drawerSetConsultant = false" block class="no-uppercase" text large style="height: 57px; padding: 16px 32px; border-radius: 8px; font-size: 18px; color: #0A85FF;">
                        Cancelar
                    </v-btn>
                </div>
            </div>
            
            
        </v-navigation-drawer>
    </div>
</template>

<script>
    import {mapActions, mapGetters} from 'vuex'
    import BreadcrumbsComponent from "@/components/BreadcrumbsComponent/Index"
    import FilterPanelComponent from "@/components/FilterPanelComponent/Index"
    

    import { getResidenceType } from "../../utils/residenceType";

    import { getRoofType } from '../../utils/roofType'
    
    import { getPowerInput } from '../../utils/powerInput'
    
    import dayjs from 'dayjs'
    import {formatMoney} from "../../utils/formatMoney";
    import Papa from "papaparse";
    import ActionBarComponent from "../../components/ActionBarComponent/Index";

    export default {
        components: {
            BreadcrumbsComponent, 
            FilterPanelComponent,
            ActionBarComponent
        },
        data: () => {
            return {
                drawerSetConsultant: false,
                confirmationDialog: false,
                selecteds: [],
                isLoadingDelete: false,
                breadcrumbs: [
                    {
                        text: 'Dashboard'
                    },
                    {
                        text: 'Simulações',
                        disabled: true
                    },
                    {
                        text: 'Lista',
                        disabled: true
                    }
                ],
                headers: [],
                items: [],
                isLoading: true,
                page: 1,
                totalPage: 1,

                timerSearch: null,
                filter: {
                    query: "",
                },
                isSearched: false,
                simulationSelected: null,
                itemsSearchConsultant: [],
                timerSearchConsultants: null,
                selectedSearchConsultants: null,
                isLoadingConsultants: false,
                isLoaidngUpdateSimulator: false
            }
        },
        methods: {
            ...mapActions(['listSimulationsNew', 'deleteManySimulations', 'loadConsultants', 'updateSimulator']),
            async load(page = 1, csv = false)
            {
                const filter = { ...this.filter };
                filter.page = page
                if (csv) filter.csv = csv;
                
                this.isLoading = true
                const resp = await this.listSimulationsNew(filter)
                
                if (resp) {
                    if (filter.csv) {
                        const itensCsv = [];
                        for (let i in resp) {
                            const obj = {
                                "Identificador": resp[i].custom_id ? resp[i].custom_id : '',
                                "Criado em": resp[i].created_at,
                                "Endereço": resp[i].address,
                                "Orçamento total": resp[i].budget_total,
                                "Cidade": resp[i].city ? resp[i].city.name : '',
                                "Estado": resp[i].state ? resp[i].state.uf : '',
                                "Cliente": resp[i].client ? resp[i].client.name : '',
                                "CO2": resp[i].co2,
                                "Consultor": resp[i].consultant ? resp[i].consultant.name : '',
                                "Consumo mensal": resp[i].consumption_month,
                                "Tipo de instalação": resp[i].installation_type,
                                "Economia mensal": resp[i].monthly_economy,
                                "Energia mensal": resp[i].monthly_generated_energy,
                                "Geração adicional": resp[i].more_energy + '%',
                                "Painéis de alta performance": resp[i].panels_high_performance,
                                "Painéis Standard": resp[i].panels_standard,
                                "Árvores palntadas": resp[i].planted_trees,
                                "Potência": resp[i].potency,
                                "Tipo de entrada": getPowerInput(resp[i].power_input),
                                "Tipo de residência": getResidenceType(resp[i].residenceType),
                                "Retorno do investimento": this.writeReturnInvestiment(resp[i].return_on_investment),
                                "Tipo de telhado": getRoofType(resp[i].roof_type),
                                "Tributo": resp[i].tribute,
                                "Valor mensal": resp[i].value_month,
                                "Economia de água": resp[i].water_scarcity,
                            };
                            itensCsv.push(obj);
                        }

                        let csv = Papa.unparse(itensCsv, { delimiter: ";" });
                        let csvData = new Blob(["\ufeff", csv], {
                            type: "text/csv;charset=utf-8;",
                        });
                        let csvURL = null;
                        if (navigator.msSaveBlob) {
                            csvURL = navigator.msSaveBlob(csvData, "simulações.csv");
                        } else {
                            csvURL = window.URL.createObjectURL(csvData);
                        }

                        let tempLink = document.createElement("a");
                        tempLink.href = csvURL;
                        tempLink.setAttribute("download", "simulações.csv");
                        tempLink.click();
                    } else {
                        this.items = resp.data;
                        this.totalPage = resp.last_page;
                    }

                    this.isLoading = false;
                }
            },

            openSetConsultant (item) {
                this.simulationSelected = item
                this.drawerSetConsultant = true
            },

            writeReturnInvestiment(value) {
                let decimals = value - Math.floor(value);

                decimals = Math.trunc(decimals * 12);

                let resp = `${Math.trunc(value)} ano${
                    Math.trunc(value) > 1 ? "s" : ""
                }`;

                if (decimals > 0) {
                    resp += ` e ${decimals} ${
                        decimals === 1 ? "mês" : decimals > 1 ? "meses" : ""
                    }`;
                }

                return resp;

                return value;
            },
            
            async onLoadConsultants (query) {
                this.isLoadingConsultants = true
                const resp = await this.loadConsultants({ query })
                if (resp) {
                    this.itemsSearchConsultant = resp.data
                }
                this.isLoadingConsultants = false
            },
            
            async onSetConsultant () {
                this.isLoaidngUpdateSimulator = true
                
                const r = this.updateSimulator({ id: this.simulationSelected.id, consultant_id: this.selectedSearchConsultants.id })
                
                if (r) {
                    this.$eventHub.$emit("snackBar", {
                        color: "success",
                        message: "Consultor vinculado com sucesso à simulação",
                    });
                    this.drawerSetConsultant = false
                    this.simulationSelected.consultant = this.selectedSearchConsultants
                } else {
                    this.$eventHub.$emit("snackBar", {
                        color: "error",
                        message: "Erro ao vincular consultor",
                    });
                }
                
                this.isLoaidngUpdateSimulator = false
            },

            sendFilterConsultant (query) {
                this.isLoadingConsultants = true
                if (query != "" && query) {
                    clearTimeout(this.timerSearch);
                    this.timerSearch = setTimeout(async () => {
                        await this.onLoadConsultants(query)

                        this.isLoadingConsultants = false
                    }, 800)
                } else {
                    this.isLoadingConsultants = false
                    clearTimeout(this.timerSearchConsultants)
                }
            },

            sendFilter(query) {
                this.filter.query = query;
                this.isLoading = true;
                if ((query != "" && query) || this.isSearched) {
                    clearTimeout(this.timerSearch);
                    this.timerSearch = setTimeout(async () => {
                        await this.load(1);

                        this.isLoading = false;
                        if (query) this.isSearched = true;
                    }, 800);
                } else {
                    this.isLoading = false;
                    clearTimeout(this.timerSearch);
                }
            },
            
            __dayjs (date) 
            {
                return dayjs(date)
            },
            
            __getResidenceType (type) {
                return getResidenceType(type)
            },

            __format(value)
            {
                return formatMoney(value)
            },

            async onDeleteMany() {
                this.isLoadingDelete = true;

                const ids = this.selecteds;

                const r = await this.deleteManySimulations({ ids });

                if (r) {
                    this.$eventHub.$emit("snackBar", {
                        color: "success",
                        message: "Clientes apagados com sucesso",
                    });
                    this.selecteds = [];
                    this.confirmationDialog = false;
                    this.load();
                } else {
                    this.$eventHub.$emit("snackBar", {
                        color: "error",
                        message: "Erro ao apagar clientes",
                    });
                }

                this.isLoadingDelete = false;
            },
        },
        mounted() 
        {
            this.load()
            
            this.headers = [
                {
                    text: 'ATUALIZADO EM',
                    align: 'start',
                    sortable: false,
                    value: 'updatedat',
                },
                    {
                        text: 'ID',
                        align: 'start',
                        sortable: false,
                        value: 'custom_id',
                    },
                    {
                        text: 'TIPO',
                        align: 'start',
                        sortable: false,
                        value: 'type',
                    },
                    {
                        text: 'ORÇAMENTO',
                        align: 'start',
                        sortable: false,
                        value: 'budget',
                    },
                    {
                        text: 'CIDADE',
                        align: 'start',
                        sortable: false,
                        value: 'city',
                    },
                    {
                        text: 'UF',
                        align: 'start',
                        sortable: false,
                        value: 'uf',
                    },
                    {
                        text: 'CLIENTE',
                        align: 'start',
                        sortable: false,
                        value: 'client',
                    },
                    {
                        text: 'CONSULTOR',
                        align: 'start',
                        sortable: false,
                        value: 'consultant',
                    },
                    {
                        text: '',
                        align: 'start',
                        sortable: false,
                        //value: this.getUser.user_type === 1 ? "action" : "arrow",
                        value: "arrow"
                    }
                ]
        },
        computed: {
            ...mapGetters(["getUser"]),
        },
        
        
        watch: {

            drawerSetConsultant: function () {
                if (this.drawerSetConsultant == false) {
                    this.simulationSelected = null
                    this.selectedSearchConsultants = null
                    this.isLoadingConsultants = false
                    this.isLoaidngUpdateSimulator = false
                    this.itemsSearchConsultant = []
                }
            },
        }
    }
</script>



<style>
    #contentSearchConsultant {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
</style>