<template>
    <div>
        <v-row no-gutters class="mt-10 mb-8">
            <BreadcrumbsComponent title="Clientes" :items="breadcrumbs">
                <template v-slot:actions>
                    <v-btn elevation="2" large class="white--text grey darken-3 mt-2 ml-2 elevation-0" @click="$router.push(`/clients/view/${$route.params.id}`)">
                        <v-icon class="mr-2">mdi-arrow-left</v-icon>
                        Voltar
                    </v-btn>
                </template>
            </BreadcrumbsComponent>
        </v-row>

        <v-data-table
            :headers="headers"
            :items="items"
            class="elevation-1"
            hide-default-footer
            :items-per-page="-1"
        >
            <template v-slot:item.updated_at="{ item }">
                {{__dayjs(item.updatedat).format(
                        'DD/MM/YYYY H:mm:ss'
                    )}}
            </template>

            <template v-slot:item.simulator.budget_total="{ item }">
                R$ {{ __format(item.simulator.budget_total) }}
            </template>

            <template v-slot:item.client="{ item }">
                {{ item.client.name }}
            </template>

            <template v-slot:item.id="{ item }">
                <span class="blue--text" @click="$router.push('/simulations/'+item.id)">{{ item.id }}</span>
            </template>

            <template v-slot:item.consultant.name="{ item }">
                <div class="d-flex">
                    <v-avatar
                        ><img
                            :src="getPath(item)"
                            alt="consultant image"
                    /></v-avatar>
                    <div class="d-flex flex-column ml-2">
                        {{ item.consultant.name }}
                        <span class="grey--text">11 99000-0009</span>
                    </div>
                </div>
            </template>

            <template v-slot:item.status="{ item }">
                <v-chip color="grey lighten-2">{{
                    item.status
                }}</v-chip>
            </template>
        </v-data-table>
    </div>
</template>


<script>
import dayjs from "dayjs";
import {formatMoney} from "../../utils/formatMoney"
import { mapActions } from 'vuex'
import BreadcrumbsComponent from "@/components/BreadcrumbsComponent/Index"

export default {
    components: {
        BreadcrumbsComponent,
    },
    data: () => {
        return {
            items:[],
            breadcrumbs: [
                {
                    text: 'Dashboard'
                },
                {
                    text: 'Clientes',
                    disabled: true
                },
                {
                    text: 'Falar com consultor',
                    disabled: true
                }
            ],
            headers: [
                { text: "ATUALIZADO EM", align: "start", sortable: false, value: "updated_at" },
                { text: "SIMULAÇÃO", value: "simulator.custom_id", sortable: false },
                { text: "ESTIMATIVA", value: "simulator.budget_total", sortable: false },
                { text: "CLIENTE", value: "client.name", sortable: false },
                { text: "CONSULTOR", value: "consultant.name", sortable: false },
                { text: "STATUS", value: "status", sortable: false },
                { text: "", value: "action", sortable: false },
            ]
        };
    },
    async mounted()
    {
        try {
            const {data} = await this.listBudgets({ clientId: `?client_id=${this.$route.params.id}` })
            this.items = data
        } catch (error) {
            this.$eventHub.$emit('snackBar', {color: 'error', message: 'Erro ao listar simulações'})
        }
    },
    methods: {
        ...mapActions(['listBudgets']),
        getPath(item)
        {
            return `${process.env.VUE_APP_API_URL}${item.consultant.image}`
        },
        __dayjs(date) {
            return dayjs(date);
        },
        __format(value)
        {
            return formatMoney(value)
        }
    }
};
</script>
