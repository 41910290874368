<template>
    <div>
        <v-row no-gutters class="mt-10 mb-8">
            <BreadcrumbsComponent
                title="Administradores"
                :items="breadcrumbs"
            />
        </v-row>

        <v-row>
            <v-col cols="12" md="6">
                <form @keydown.enter="!isLoading && isValid && onSendInsert()">
                    <v-row>
                        <v-col cols="12" md="12">
                            <v-text-field
                                label="Nome"
                                placeholder="Nome"
                                outlined
                                v-model="administrator.name"
                                :rules='rules.nameRules'
                                @blur="validate" 
                                @focus="clearRules"
                            ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="12">
                            <v-text-field
                                label="Email"
                                placeholder="Email"
                                outlined
                                hint="Uma senha automática será gerada e enviada para o e-mail cadastrado."
                                persistent-hint
                                v-model="administrator.email"
                                :rules='rules.emailRules'
                                @blur="validate" 
                                @focus="clearRules"
                            ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="12">
                            <v-btn
                                elevation="2"
                                large
                                block
                                class="white--text grey darken-3 elevation-0"
                                @click="onSendInsert"
                                :disabled="isLoading || !isValid"
                                :loading="isLoading"
                            >
                                Cadastrar
                            </v-btn>
                        </v-col>

                        <v-col cols="12" md="12">
                            <v-btn
                                large
                                block
                                text
                                @click="$router.push('/administrators')"
                            >
                                Cancelar
                            </v-btn>
                        </v-col>
                    </v-row>
                </form>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { mapActions } from "vuex";
import BreadcrumbsComponent from "@/components/BreadcrumbsComponent/Index";

export default {
    components: {
        BreadcrumbsComponent,
    },
    data: () => {
        return {
            rules: [],
            breadcrumbs: [
                {
                    text: "Dashboard",
                },
                {
                    text: "Administradores",
                },
                {
                    text: "Adicionar novo",
                    disabled: true,
                },
            ],
            administrator: {
                name: "",
                email: "",
            },
            isLoading: false,
        };
    },
    computed: {
        isValid() {
            return !!(
                ["name"].every((key) => !!this.administrator[key]) &&
                /^[A-Za-z0-9_\-\.+]+@[A-Za-z0-9_\-\.]{2,}\.[A-Za-z0-9]{2,}(\.[A-Za-z0-9])?/i.test(
                    this.administrator.email
                )
            );
        },
    },
    methods: {
        ...mapActions(["insertAdmin"]),
        async onSendInsert() {
            const r = await this.insertAdmin(this.administrator);
            if (r) {
                this.$eventHub.$emit("snackBar", {
                    color: "success",
                    message: "Administrador inserido com sucesso",
                });
                this.administrator = {
                    name: "",
                    email: "",
                };
            } else {
                this.$eventHub.$emit("snackBar", {
                    color: "error",
                    message: "Erro ao inserir administrador",
                });
            }
        },

        validate() {
            this.rules = {
                "nameRules": [v => !!v || "O preenchimento deste campo é obrigatório"],
                "emailRules": [
                    v => !!v || "O preenchimento deste campo é obrigatório",
                    v => /.+@.+/.test(v) || "Insira um e-mail válido"
                ]
            }
        },

        clearRules(){
            this.rules = []
        }
    },
};
</script>
