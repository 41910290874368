<template>
    <div>
        <v-dialog
            v-model="confirmationDialog"
            transition="dialog-top-transition"
            max-width="600"
            persistent
        >
            <v-card class="pa-5">
                <v-card-text class="pt-4">
                    <h1 class="text-center mb-4" style="line-height: 1">
                        Você selecionou {{ selecteds.length }} item(s), tem
                        certeza que deseja excluí-lo(s)?
                    </h1>
                    <span class="d-block text-center mt-4 mb-5"
                        >Importante: essa ação não poderá ser desfeita.</span
                    >

                    <v-btn
                        :loading="isLoadingDelete"
                        :disabled="isLoadingDelete"
                        elevation="2"
                        large
                        block
                        text
                        class="white--text grey darken-3 mt-5 elevation-0 mx-auto"
                        @click="onDeleteMany"
                        >Sim, confirmo a exclusão</v-btn
                    >
                    <v-btn
                        :disabled="isLoadingDelete"
                        large
                        block
                        text
                        class="mt-3"
                        @click="confirmationDialog = false"
                        >Cancelar</v-btn
                    >
                </v-card-text>
            </v-card>
        </v-dialog>

        <!-- Janel de filtros: TODO => Adicionar os filtros ao filterpanel usando v-chips -->
        <v-dialog
            v-model="dialog"
            transition="dialog-top-transition"
            max-width="600"
        >
            <template v-slot:default="dialog">
                <v-card>
                    <v-card-text class="pt-4">
                        <div class="pt-4 mb-4">
                            <h1>Filtrar por:</h1>
                        </div>

                        <div>
                            <v-btn
                                elevation="2"
                                large
                                block
                                class="white--text grey darken-3 elevation-0"
                                @click="dialog.value = false"
                                >Confirmar</v-btn
                            >
                            <v-btn large block text>Limpar filtro</v-btn>
                        </div>
                    </v-card-text>
                </v-card>
            </template>
        </v-dialog>

        <v-row no-gutters class="mt-10 mb-8">
            <BreadcrumbsComponent title="Pedidos" :items="breadcrumbs" />
        </v-row>

        <v-row no-gutters>
            <v-col cols="12" md="12">
                <FilterPanelComponent
                    v-if="!selected"
                    :show-content="showContent"
                    @onSearching="sendFilter"
                    @onDownload="load(1, true)"
                >
                    <template v-slot:status>
                        <v-tabs v-model="tab" slider-color="light-blue">
                            <v-tab
                                :class="{
                                    'light-blue--text ': true,
                                    'text--darken-3': tab == 0,
                                    'text--darken-0': tab != 0,
                                }"
                                @click="onFilter(null)"
                            >
                                Todos
                                <v-chip
                                    :class="{
                                        'ml-2 light-blue white--text': true,
                                        'darken-3': tab == 0,
                                    }"
                                    small
                                    >{{ totals.all }}</v-chip
                                >
                            </v-tab>
                            <v-tab
                                :class="{
                                    'light-blue--text ': true,
                                    'text--darken-3': tab == 1,
                                    'text--darken-0': tab != 1,
                                }"
                                @click="onFilter('opened')"
                            >
                                Abertos
                                <v-chip
                                    :class="{
                                        'ml-2 light-blue white--text': true,
                                        'darken-3': tab == 1,
                                    }"
                                    small
                                    >{{ totals.total_open }}</v-chip
                                >
                            </v-tab>
                            <v-tab
                                :class="{
                                    'light-blue--text ': true,
                                    'text--darken-3': tab == 2,
                                    'text--darken-0': tab != 2,
                                }"
                                @click="onFilter('finished')"
                            >
                                Encerrados
                                <v-chip
                                    :class="{
                                        'ml-2 light-blue white--text': true,
                                        'darken-3': tab == 2,
                                    }"
                                    small
                                    >{{ totals.total_finished }}</v-chip
                                >
                            </v-tab>
                        </v-tabs>
                    </template>

                    <template v-slot:result>
                        <v-chip
                            class="ma-2"
                            close
                            color="grey darken-1"
                            outlined
                        >
                            <span>Cliente:</span>
                            <span class="ml-2">Logo Nunes Castanheda</span>
                        </v-chip>
                    </template>
                </FilterPanelComponent>

                <ActionBarComponent v-if="selecteds.length > 0">
                    <template v-slot:content>
                        <div class="d-flex justify-space-between flex-wrap">
                            <span class="grey--text text--darken-4"
                                >{{ selecteds.length }} registro(s)
                                selecionado(s)</span
                            >
                            <v-icon
                                color="grey darken-2"
                                @click="confirmationDialog = true"
                                >mdi-trash-can-outline</v-icon
                            >
                        </div>
                    </template>
                </ActionBarComponent>
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <v-data-table
                    :headers="headers"
                    :items="items"
                    hide-default-footer
                    :loading="isLoading"
                    :items-per-page="-1"
                >
                    <template v-slot:item.updatedat="{ item }">
                        <v-checkbox
                            v-model="selecteds"
                            :value="item.id"
                            :label="`${__dayjs(item.updated_at).format(
                                'DD/MM/YYYY HH:mm:ss'
                            )}`"

                            v-if="getUser.user_type === 1"
                        ></v-checkbox>

                        <span v-else>{{ __dayjs(item.updated_at).format('DD/MM/YYYY HH:mm:ss') }}</span>
                    </template>

                    <template v-slot:item.budget_installers="{ item }">
                        <!--<div class="d-flex" v-if="item.installer">
                            <v-avatar
                                size="36"
                                v-if="item.installer.url"
                                class="ml-2"
                            >
                                <img :src="item.installer.url" />
                            </v-avatar>
                            <v-avatar color="#4F4F4F" v-else>
                                <span class="white--text text-h5">{{ getIniciais(item.installer.company_name) }}</span>
                            </v-avatar>
                        </div>-->
                        
                        
                        
                        
                        <div class="d-inline-block">


                            <template v-if="item.installer_id">

                                <template v-for="(inst, i) in item.installers_selected" v-if="item.installer_id == inst.installer_id">

                                    <p style="margin-bottom: 0px;"><v-icon style="transform: rotate(45deg);margin-right: 10px;" v-if="inst.installer">mdi-paperclip</v-icon>{{inst.installer.trading_name}}</p>
                                    
                                </template>




                                <template v-else v-for="(inst, i) in item.installers_selected" v-if="item.installer_id != inst.installer_id">
                                    
                                    <template v-if="inst.installer">
                                        <p v-if="inst.rejected_at != null" style="color: #F50058; margin-bottom: 0px; opacity: 0.5"><v-icon style="color: #F50058;margin-right: 10px;">mdi-close-circle-outline</v-icon>{{inst.installer.trading_name}}</p>

                                        <p v-else-if="inst.budget_send != null" style="margin-bottom: 0px; opacity: 0.5"><v-icon style="transform: rotate(45deg);margin-right: 10px;">mdi-paperclip</v-icon>{{inst.installer.trading_name}}</p>

                                        <p v-else style="margin-bottom: 0px; opacity: 0.2"><v-icon style="transform: rotate(45deg);opacity: 0.2;margin-right: 10px;">mdi-paperclip</v-icon>{{inst.installer.trading_name}}</p>

                                    </template>
                                   
                                </template>
                                
                                
                                
                            </template>
                            
                            

                            <template v-else v-for="(inst, i) in item.installers_selected">

                                <template v-if="inst.installer">
                                
                                    <p v-if="inst.rejected_at != null" style="color: #F50058; margin-bottom: 0px; opacity: 0.5"><v-icon style="color: #F50058;margin-right: 10px;">mdi-close-circle-outline</v-icon>{{inst.installer.trading_name}}</p>
                                    
                                    <p v-else-if="inst.budget_send != null" style="margin-bottom: 0px;"><v-icon style="transform: rotate(45deg);margin-right: 10px;">mdi-paperclip</v-icon>{{inst.installer.trading_name}}</p>
    
                                    <p v-else style="margin-bottom: 0px; opacity: 0.2"><v-icon style="transform: rotate(45deg);opacity: 0.2;margin-right: 10px;">mdi-paperclip</v-icon>{{inst.installer.trading_name}}</p>
                                </template>
                            </template>
                            
                        </div>
                    </template>

                    <template v-slot:item.budget.client.name="{ item }">
                        <template v-if="item.budget && item.budget.client">
                            <span class="d-block grey--text text--darken-2">{{
                                item.budget.client.name
                            }}</span>
                            <span class="grey--text">{{
                                item.budget.client.phone
                            }}</span>
                        </template>
                    </template>

                    <template v-slot:item.consultant="{ item }">
                        <div
                            class="d-flex align-center"
                            v-if="item.budget && item.budget.consultant"
                        >
                            <v-avatar v-if="item.budget.consultant.url">
                                <img :src="item.budget.consultant.url" />
                            </v-avatar>

                            <v-avatar color="#4F4F4F" v-else>
                                <span class="white--text text-h5">{{
                                    getIniciais(item.budget.consultant.name)
                                }}</span>
                            </v-avatar>

                            <div class="ml-2">
                                <span
                                    class="d-block grey--text text--darken-2"
                                    >{{ item.budget.consultant.name }}</span
                                >
                                <span class="grey--text caption">{{
                                    item.budget.consultant.phone
                                }}</span>
                            </div>
                        </div>
                    </template>

                    <template v-slot:item.status="{ item }">
                        <template v-if="item.finished">
                            <span>Pedido finalizado</span>
                            <v-progress-linear
                                color="#219653"
                                class="mt-1"
                                :value="100"
                                height="6"
                                rounded
                            ></v-progress-linear>
                        </template>
                        
                        
                        <template v-else-if="item.step">
                            <span>{{ item.step.title }}</span>
                            <template v-if="steps.length > 0">
                                <v-progress-linear
                                    color="#56CCF2"
                                    class="mt-1"
                                    :value="
                                        getValuePercent(
                                            item.step.order,
                                            steps[steps.length - 1].order
                                        )
                                    "
                                    height="6"
                                    rounded
                                ></v-progress-linear>
                            </template>
                        </template>

                        <template v-else>
                            <v-row>
                                <v-col>
                                    <span>Orçamento</span> 
                                </v-col>
                                <v-col class="d-flex align-content-end justify-end">
                                    <v-icon id="icon-attachment">mdi-attachment</v-icon>
                                    <span>{{item.budget_installers.length}}</span>
                                </v-col>
                            </v-row>
                            <v-progress-linear
                                color="#56CCF2"
                                class="mt-1"
                                :value="5"
                                height="6"
                                rounded
                            ></v-progress-linear>
                        </template>
                    </template>

                    <template v-slot:item.arrow="{ item }">
                        <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            @click="$router.push('/orders/view/' + item.id)"
                        >
                            <path
                                d="M5 12H19"
                                stroke="#828282"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                d="M12 5L19 12L12 19"
                                stroke="#828282"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                        </svg>
                    </template>

                    <template v-slot:item.action="{ item }">
                        <v-menu offset-y>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    color="primary"
                                    dark
                                    v-bind="attrs"
                                    v-on="on"
                                    text
                                >
                                    <v-icon>mdi-dots-horizontal</v-icon>
                                </v-btn>
                            </template>
                            <v-card class="mx-auto" max-width="300" tile>
                                <v-list>
                                    <v-list-item link>
                                        <v-list-item-title
                                            @click="
                                                $router.push(
                                                    '/orders/view/' + item.id
                                                )
                                            "
                                            ><v-icon>mdi-eye-outline</v-icon>
                                            Visualizar</v-list-item-title
                                        >
                                    </v-list-item>
                                    <v-divider></v-divider>

                                    <v-list-item link>
                                        <v-list-item-title
                                            @click="onDelete(item)"
                                            ><v-icon
                                                >mdi-trash-can-outline</v-icon
                                            >
                                            Excluir</v-list-item-title
                                        >
                                    </v-list-item>
                                </v-list>
                            </v-card>
                        </v-menu>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <div class="text-center">
                    <v-pagination
                        v-model="page"
                        :length="totalPage"
                        :total-visible="7"
                        next-aria-label="Próximo"
                        @input="load($event)"
                    ></v-pagination>
                </div>
            </v-col>
        </v-row>

        <v-dialog
            v-model="confirmationDialogDelete"
            transition="dialog-top-transition"
            max-width="600"
            persistent
        >
            <v-card class="pa-5" v-if="order">
                <v-card-text class="pt-4">
                    <h1 class="text-center mb-4">
                        Deseja excluir este pedido?
                    </h1>
                    <span class="d-block text-center mt-4 mb-5"
                        >Importante: essa ação não poderá ser desfeita.</span
                    >

                    <div class="mt-5 mb-5">
                        <span class="d-block font-weight-medium">Pedido</span>
                        <v-divider></v-divider>
                        <div class="mb-3 mt-4">
                            <span class="d-block"
                                ><strong>ID:</strong> {{ order.id }}</span
                            >
                            <span class="d-block"
                                ><strong>CLIENTE:</strong>
                                {{
                                    order.budget && order.budget.client ? order.budget.client.name : "-"
                                }}</span
                            >
                            <span class="d-block"
                                ><strong>CONSULTOR:</strong>
                                {{
                                    order.budget && order.budget.consultant
                                        ? order.budget.consultant.name
                                        : "-"
                                }}</span
                            >
                        </div>
                    </div>

                    <v-btn
                        :disabled="isLoadingDelete"
                        :loading="isLoadingDelete"
                        elevation="2"
                        large
                        block
                        text
                        class="white--text grey darken-3 mt-5 elevation-0 mx-auto"
                        @click="onSendDelete"
                    >
                        Sim, confirmo a exclusão
                    </v-btn>
                    <v-btn
                        :disabled="isLoadingDelete"
                        large
                        block
                        text
                        class="mt-3"
                        @click="confirmationDialogDelete = false"
                        >Cancelar</v-btn
                    >
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import BreadcrumbsComponent from "@/components/BreadcrumbsComponent/Index";
import FilterPanelComponent from "@/components/FilterPanelComponent/Index";
import ActionBarComponent from "@/components/ActionBarComponent/Index";

import dayjs from "dayjs";
import Papa from "papaparse";

export default {
    components: {
        BreadcrumbsComponent,
        FilterPanelComponent,
        ActionBarComponent,
    },
    data: () => {
        return {
            tab: 0,
            selected: false,
            selecteds: [],
            dialog: false,
            confirmationDialog: false,
            searching: "",
            breadcrumbs: [
                {
                    text: "Dashboard",
                },
                {
                    text: "Pedidos",
                    disabled: true,
                },
                {
                    text: "Lista",
                    disabled: true,
                },
            ],
            showContent: false,
            headers: [],
            items: [],
            isLoading: true,
            isLoadingDelete: false,
            page: 1,
            totalPage: 1,
            timerSearch: null,
            filter: {
                query: "",
                status: "",
            },
            isSearched: false,
            confirmationDialogDelete: false,
            order: null,
            totals: {
                all: 0,
                total_open: 0,
                total_finished: 0
            },
            steps: [],
        };
    },
    methods: {
        ...mapActions([
            "listOrders",
            "updateOrder",
            "deleteOrder",
            "deleteManyOrder",
            "listOrdersAll"
        ]),
        onFilter(status) {
            this.filter.status = status;
            this.load();
        },
        getValuePercent(value, t) {
            let total = (value / t) * 100;

            return total;
        },
        getPath(image) {
            return `${process.env.VUE_APP_API_URL}${image}`;
        },
        onDelete(item) {
            console.log('AA', item)
            this.order = item;
            this.confirmationDialogDelete = true;
        },
        async onSendDelete() {
            this.isLoadingDelete = true;

            try {
                const resp = await this.deleteOrder({id: this.order.id});
                this.$eventHub.$emit("snackBar", {
                    color: "success",
                    message: "Pedido apagado com sucesso",
                });
                this.confirmationDialogDelete = false;
                this.load();
            } catch (error) {
                this.$eventHub.$emit("snackBar", {
                    color: "error",
                    message: "Erro ao apagar pedido",
                });
            }

            this.isLoadingDelete = false;
        },
        async load(page = 1, csv = false) {
            this.isLoading = true;
            const filter = { ...this.filter };
            filter.page = page;
            if (csv) filter.csv = csv;
            try {
                const resp = await this.listOrdersAll(filter);

                if (resp) {
                    if (filter.csv) {
                        const itensCsv = [];
                        for (let i in resp) {
                            itensCsv.push({
                                Identificador: resp[i].custom_id,
                                Cliente: resp[i].budget && resp[i].budget.client ? resp[i].budget.client.name : "",
                                Consultor: resp[i].budget && resp[i].budget.consultant ? resp[i].budget.consultant.name : "",
                                Instalador: resp[i].installer ? resp[i].installer.company_name : '',
                                "Simulação": resp[i].budget && resp[i].budget.simulator ? resp[i].budget.simulator.custom_id : "",
                                Cliente: resp[i].budget && resp[i].budget.client ? resp[i].budget.client.name : "",
                                Status: resp[i].step ? resp[i].step.title : "Orçamento",
                                Finalizado: resp[i].finished ? "sim" : "Não"
                            });
                        }

                        let csv = Papa.unparse(itensCsv, { delimiter: ";" });
                        let csvData = new Blob(["\ufeff", csv], {
                            type: "text/csv;charset=utf-8;",
                        });
                        let csvURL = null;
                        if (navigator.msSaveBlob) {
                            csvURL = navigator.msSaveBlob(
                                csvData,
                                "pedidos.csv"
                            );
                        } else {
                            csvURL = window.URL.createObjectURL(csvData);
                        }

                        let tempLink = document.createElement("a");
                        tempLink.href = csvURL;
                        tempLink.setAttribute("download", "pedidos.csv");
                        tempLink.click();
                    } else {
                        this.items = resp?.data || [];
                        this.totalPage = resp.last_page;
                        this.totals.all = resp.total;
                        this.steps = resp.steps;
                        this.totals.total_open = resp.total_open;
                        this.totals.total_finished = resp.total_finished
                    }
                }
            } catch (error) {
                console.log(error)
                this.$eventHub.$emit("snackBar", {
                    color: "error",
                    message: "Erro ao listar pedidos",
                });
            }

            this.isLoading = false;
        },
        __dayjs(date) {
            return dayjs(date);
        },
        async handleVisible(index, item) {
            item.visible = !item.visible;
            try {
                const resp = await this.updateOrder(item);
                this.$eventHub.$emit("snackBar", {
                    color: "success",
                    message: "Pedido editado com sucesso",
                });
            } catch (error) {
                this.$eventHub.$emit("snackBar", {
                    color: "error",
                    message: "Erro ao editar pedido",
                });
            }
        },
        setLoading() {
            this.$eventHub.emit("loading", {
                title: "Realizando operação...",
                subtitle: "Aguarde só um instante...",
                status: true,
            });
        },
        sendFilter(query) {
            this.filter.query = query;
            this.isLoading = true;
            if ((query != "" && query) || this.isSearched) {
                clearTimeout(this.timerSearch);
                this.timerSearch = setTimeout(async () => {
                    await this.load(1);
                    this.isLoading = false;
                    if (query) this.isSearched = true;
                }, 800);
            } else {
                this.isLoading = false;
                clearTimeout(this.timerSearch);
            }
        },
        async onDeleteMany() {
            this.isLoadingDelete = true;
            const ids = this.selecteds;
            try {
                const r = await this.deleteManyOrder({ ids });
                this.$eventHub.$emit("snackBar", {
                    color: "success",
                    message: "Pedidos apagados com sucesso",
                });
                this.selecteds = [];
                this.confirmationDialog = false;
                this.load();
            } catch (error) {
                this.$eventHub.$emit("snackBar", {
                    color: "error",
                    message: "Erro ao apagar pedidos",
                });
            }
            this.isLoadingDelete = false;
        },

        getIniciais(nameString) {
            try {
                const fullName = nameString.split(" ");
                const initials =
                    fullName.shift().charAt(0) + fullName.pop().charAt(0);
                return initials.toUpperCase();
            } catch (e) {
                if (nameString) {
                    return nameString.substring(0, 2).toUpperCase();   
                } else {
                    return ""
                }
            }
        },
    },
    computed: {
        ...mapGetters(["getUser"]),
    },
    mounted() {
        this.headers = [
            {
                text: "ATUALIZADO EM",
                align: "start",
                sortable: false,
                value: "updatedat",
            },
            {
                text: "ID",
                align: "start",
                sortable: false,
                value: "custom_id",
            },
            {
                text: "INSTALADOR",
                align: "start",
                sortable: false,
                value: "budget_installers",
            },
            {
                text: "CLIENTE",
                align: "start",
                sortable: false,
                value: "budget.client.name",
            },
            {
                text: "CONSULTOR",
                align: "start",
                sortable: false,
                value: "consultant",
            },
            {
                text: "STATUS",
                align: "start",
                sortable: false,
                value: "status",
            },
            {
                text: "",
                align: "start",
                sortable: false,
                value: this.getUser.user_type === 1 ? "action" : "arrow",
            },
        ];
        this.load();
    },
};
</script>

<style>
    #icon-attachment{
        font-size: 20px;
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        transform: rotate(-45deg);
    }
</style>