<template>
    <v-app id="inspire">
        <v-app-bar v-if="showBase" app color="white" class="pl-4 pr-4 elevation-1">
            <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
            <v-toolbar-title class="font-weight-medium">
                <img src="./assets/logo.png" alt="Logo" @click="$router.push('/')" />
            </v-toolbar-title>
            <v-spacer></v-spacer>

            <v-menu
                left
                bottom
                v-if="isLogged"
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-avatar
                        v-bind="attrs"
                        v-on="on"
                        color="d-none d-md-flex"
                        size="40"
                        style="background: #002A53;"
                    ><span class="white--text" style="font-size:16px">{{ getUser.name.substr(0,2).toUpperCase() }}</span>

                    </v-avatar>
                </template>
                <v-list>
                    <v-list-item
                        @click="onLogout"
                    >
                        <v-list-item-title>Desconectar</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
        
        </v-app-bar>

        <v-app-bar v-else app flat color="white" class="mt-5 pl-4 pr-4 ml-5 mr-5">
            <v-toolbar-title class="font-weight-medium">
                <img src="./assets/logo.png" alt="Logo" @click="$router.push('/')"  />
            </v-toolbar-title>
            <v-spacer></v-spacer>
        </v-app-bar>
        

        <v-navigation-drawer v-if="showBase" v-model="drawer" fixed temporary class="pl-5 pr-5 pt-5">
            <v-list dense nav>
                <v-list-item-title v-for="menu in menu_list" :key="menu.title">
                    <v-list-item-content class="mt-5">
                        <v-list-item-title class="font-weight-medium title-menu">{{ menu.title }}</v-list-item-title>
                    </v-list-item-content>
                 
                    <v-list-item 
                        v-for="children in menu.children" 
                        :key="children.title"
                        link 
                        @click="changeToPage(children)"
                        :disabled="!children.href"
                        v-if="checkProfile(children)"
                    >
                        <v-list-item-icon>
                            <img :src="children.icon"></img>
                        </v-list-item-icon>

                        <v-list-item-content>
                            <v-list-item-title class="children-menu">{{ children.title }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list-item-title>
            </v-list>
            <v-list dense nav>
                <v-list-item-content class="mt-5">
                        <v-list-item-title class="font-weight-medium title-menu">OPÇÕES</v-list-item-title>
                    </v-list-item-content>
                <v-list-item 
                    @click="onLogout"
                >

                    <v-list-item-icon>
                        <img src="/images/icon-user.svg"></img>
                    </v-list-item-icon>

                    <v-list-item-title class="children-menu">Desconectar</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-navigation-drawer>

        
        <template v-if="showFullWidth">
            <router-view></router-view>
        </template>
        
        <v-main v-else class="ml-5 mr-5">
            <v-container fluid class="mt-5 ml-4 pr-10">
                <router-view></router-view>
            </v-container>
        </v-main>

        <v-dialog v-model="loading.status" transition="dialog-top-transition" max-width="600">
            <template v-slot:default="dialog">
                <v-card>
                    <v-card-text class="pt-4">
                        <h1 class="text-center mb-4">{{loading.title}}</h1>
                        <span class="d-block text-center mt-4 mb-5">{{loading.subtitle}}</span>
                        <v-progress-linear color="pink" class="mt-5" value="15"></v-progress-linear>
                    </v-card-text>
                </v-card>
            </template>
        </v-dialog>

        <v-snackbar :color="snackbar.color" v-model="snackbar.visible" :bottom="'bottom'" :right="'right'" :timeout="snackbar.timeout">
            {{snackbar.message}}
            <template v-slot:action="{ attrs }">
                <v-btn
                    dark
                    text
                    v-bind="attrs"
                    @click="snackbar.visible = false"
                >
                    Fechar
                </v-btn>
            </template>
        </v-snackbar>
    </v-app>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex'

(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-KP6RGX2');
    
    export default {
        data: () => ({
            drawer: null,
            menu_list: [
                {
                    title: 'HOME',
                    children:[
                        { 
                            title: 'Dashboard',
                            icon: '/images/icon-chart-pie.svg',
                            href: '/',
                            profiles: [1]
                        },
                        { 
                            title: 'Atividades recentes', 
                            icon: '/images/icon-notification.svg', 
                            href:'/activities' 
                        },
                    ]
                },
                {
                    title: 'USUÁRIOS',
                    children:[
                        { 
                            title: 'Clientes finais',
                            icon: '/images/icon-user.svg',
                            href: '/clients' 
                        },
                        { 
                            title: 'Instaladores',
                            icon: '/images/icon-vistoria.svg',
                            href: '/installers' 
                        },
                        { 
                            title: 'Distribuidores',
                            icon: '/images/icon-distribuidor.svg',
                            href: '/distributors',
                            profiles: [1]
                        },
                        { 
                            title: 'Consultores',
                            icon: '/images/icon-hands-greeting.svg',
                            href: '/consultants',
                            profiles: [1]
                        },
                        { 
                            title: 'Administradores',
                            icon: '/images/icon-administrador.svg',
                            href: '/administrators',
                            profiles: [1]
                        },
                    ]
                },
                {
                    title: 'INTERAÇÕES',
                    children:[
                        { 
                            title: 'Simulações',
                            icon: '/images/icon-simulacao.svg',
                            href:'/simulations' 
                        },
                        { 
                            title: 'Pré-pedido',
                            icon: '/images/icon-chat.svg',
                            href:'/talk-to-consultant' 
                        },
                        { 
                            title: 'Pedidos',
                            icon: '/images/icon-pedido.svg',
                            href:"/orders" 
                        },
                        { 
                            title: 'Avaliações',
                            icon: '/images/icon-avaliacoes.svg',
                            href: '/avaliations'
                        }
                        
                        
                    ],
                    
                },

                {
                    title: 'EXTRAS',
                    children: [
                        {
                            title: 'Produtos',
                            icon: '/images/icon-box.svg',
                            href:'/products',
                            profiles: [1]
                        },

                        {
                            title: 'Tipos Produtos',
                            icon: '/images/icon-type-product.svg',
                            href:'/products-types',
                            profiles: [1]
                        },
                        {
                            title: 'Marcas',
                            icon: '/images/icon-brand.svg',
                            href:'/brands' ,
                            profiles: [1]
                        },
                        { 
                            title: 'Treinamentos',
                            icon: '/images/icon-treinamento.svg', 
                            href:'/trainings' 
                        },
                    ]
                }
            ],
            right: null,
            snackbar: {
                visible: false,
                timeout: 3000,
                message: '',
                color: 'light-blue darken-4',
            },
            loading:{
                title:'',
                subtitle:'',
                status:false
            }
        }),
        mounted() {
            this.$eventHub.on('snackBar', config => this.snackbar = { ...this.snackbar, ...config, visible:true } )
            this.$eventHub.on('loading', loading => this.loading  = loading )
        },
        
        methods: {
            ...mapActions(['logout']),
            onLogout()
            {
                this.logout()
                this.$router.push('/login')
            },

            checkProfile (menu) {
                if (menu.profiles) {
                    if (menu.profiles.includes(this.getUser.user_type)) {
                        return true
                    } else {
                        return false
                    }
                } else {
                    return true
                }
            },
            
            changeToPage (children) {
                if (children.href == this.$route.path) {
                    this.drawer = false
                    return
                }
                this.$router.push(children.href)
            }
        },
        
        computed: {
            ...mapGetters(['isLogged', 'getUser']),
            showBase () {
                if(['login', '404', 'esqueci-senha', 'reset-password'].includes(this.$route.name)) {
                    return false
                }
                return true
            },

            showFullWidth () {
                if(['simulations-view-details'].includes(this.$route.name)) {
                    return true
                }
                return false
            }
        }
    }
</script>


<style>

    @import '../node_modules/vue-anka-cropper/dist/VueAnkaCropper.css';

    .ankaCropper {
        background-color: transparent !important;
        box-shadow: none !important;
    }
    
    
    .ankaCropper .ankaCropper__droparea {
        border: 0px !important;
        padding: 0px !important;
        margin: 0px !important;
    }
    
    .ankaCropper {
        display: flex;
        flex-direction: column-reverse;
    }
    
    .ankaCropper__navButton {
        width: auto !important;
        padding: 10px;
        padding-bottom: 6px !important;
        margin-right: 5px;
        align-items: center;
        flex-direction: row;
        justify-content: center;
    }

    .ankaCropper__navButton, .ankaCropper__saveButton {
        height: auto !important; 
    }

    .ankaCropper .ankaCropper__navButton svg {
        stroke: #5E5873 !important
    }
    
    .ankaCropper__saveButton {
        background-color: #FFA900 !important;
    }

    .ankaCropper__saveButton svg {
        display: none;
    }

    .ankaCropper__saveButton {
        visibility: hidden;
    }

    .ankaCropper__saveButton:after {
        content:'Salvar';
        visibility: visible;
        display: inline-block;
        background-color: #FFA900;
        float: right;
        padding: 8px 20px;
        font-size: 14px;
        box-shadow: 0 0 4px rgb(0 0 0 / 15%), 1px 1px 2px rgb(0 0 0 / 25%);
        border-radius: 3px;
        margin-top: -8px;
        margin-right: -16px;
    }

    
    .fontRegular {
        font-family: "cerapro-regular" !important;
    }
    
    .v-menu__content .v-list-item *{
        color: #004D99 !important;
        font-family: "cerapro-regular" !important;
    }

    .v-menu__content .v-card {
        padding: 10px !important;
        background: #FFFFFF !important;

        border: 1px solid #85C2FF !important;
        box-sizing: border-box !important;
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1) !important;
        border-radius: 8px !important;
    }
    
    .no-uppercase {
        text-transform: initial !important;
    }
    
    @font-face {
        font-family: "cerapro-regular";
        font-weight: 400;
        font-style: normal;
        font-display: auto;
        unicode-range: U+000-5FF;
        src: url("./assets/fonts/cera-pro/woff/CeraPro-Regular.woff") format("woff");
    }
    
    .no-uppercase {
        text-transform: initial !important;
    }

    @font-face {
        font-family: "cerapro-medium";
        font-weight: 400;
        font-style: normal;
        font-display: auto;
        unicode-range: U+000-5FF;
        src: url("./assets/fonts/cera-pro/woff/CeraPro-Medium.woff") format("woff");
    }
    
    
    .v-application {
        font-family: "cerapro-medium" !important;
    }

    .v-input--selection-controls .v-input__slot > .v-label {
        text-transform: initial !important;
    }
    
    .v-label {
        color: rgba(0, 0, 0, 0.87) !important;
        font-size: 0.875rem !important;
        text-transform: initial !important;
    }
    
    .v-data-table-header {
        background-color: #0066CC;
    }
    
    .v-data-table-header th {
        color: #FFF !important;
    }

    .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
        height: 64px !important;
    }

    .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
        height: 88px !important;
    }

    .v-data-table-header th:nth-of-type(1) {
        border-top-left-radius: 4px !important;
    }

    .v-data-table-header th:last-of-type {
        border-top-right-radius: 4px !important;
    }
    
    
    .titleExpand {
        font-family: Roboto;
        font-size: 24px;
        font-style: normal;
        font-weight: 300;
        line-height: 28px;
        letter-spacing: 0.02em;
        text-align: left;
        padding: 20px 0px;

    }
    
    .title-menu {
        font-family: "cerapro-medium" !important;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 23px;
        letter-spacing: 0em;
        text-align: left;
        color: #5E5873;
    } 
    
    .children-menu {
        font-family: "cerapro-medium" !important;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: left;

        color: #004D99;
    }

    .v-navigation-drawer__content::-webkit-scrollbar {
        width: 8px;
    }

    .v-navigation-drawer__content::-webkit-scrollbar-track {

    }

    .v-navigation-drawer__content::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: rgba(244, 244, 246, 1)
    }
    
    .cursor-pointer {
        cursor: pointer !important;
    }
</style>

