import Http from '@/plugins/Http'

export default {
    async getConsultantById({}, id) 
    {
        const { data } = await Http.get(`/consultant/${id}`)
        return data
    },
    async loadConsultants({}, params) 
    {
        const {data} = await Http.get(`/consultant`, { params })
        return data
    },

    async loadCompanySize({commit}) 
    {
        const {data} =  await Http.get(`/company_size`)
        commit('SET_COMPANY_SIZE', data)
    },
    async loadConsumerType({commit}) 
    {
        const {data} = await Http.get(`/consumer_type`)
        commit('SET_CONSUMER_TYPE', data)
    },
    
    async insertConsultant({}, consultant) 
    {
        return await Http.post('/consultant', consultant)
    },
    async updateConsultant({}, consultant) 
    {
        return await Http.put('/consultant/' + consultant.id, consultant)
    },
    async removeConsultant({}, id) 
    {
        return await Http.delete(`/consultant/${id}`)
    },
    
    async deleteManyConsultant ({}, ids) {
        try {
            await Http.delete('/consultant/delete/many', {
                data: ids
            })
            return true
        } catch (e) {
            return false
        }
    },
}
