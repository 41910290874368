<template>
    <div>
        <v-row no-gutters class="mt-10 mb-8">
            <BreadcrumbsComponent title="Distribuidor" :items="breadcrumbs">
                <template v-slot:actions>
                    <v-btn :disabled="isLoading" elevation="2" large class="white--text grey darken-3 mt-2 ml-2 elevation-0" @click="$router.push('/distributors/edit/' + id)">
                        <v-icon class="mr-2">mdi-square-edit-outline</v-icon>
                        Editar
                    </v-btn>

                    <v-btn
                        :disabled="isLoading"
                        elevation="2"
                        large
                        class="elevation-0 mt-2 ml-2"
                        @click="confirmationDialog = true"
                    >
                        <v-icon class="mr-2">mdi-trash-can-outline</v-icon>
                    </v-btn>
                </template>
            </BreadcrumbsComponent>
        </v-row>
        <v-row v-if="isLoading">
            <v-col
                md="12"
                class="text-center"
            >
                <v-progress-circular
                    indeterminate
                ></v-progress-circular>
            </v-col>
        </v-row>
        <v-row v-else>
            <template v-if="distributor && Object.keys(distributor).length > 0">
                <v-col cols="12" md="9">
                    <DataListComponent >
                        <template v-slot:item>
                            <template v-for="(item, index) in items">
                                <v-divider v-if="index == 0" :key="index"></v-divider>

                                <v-list-item :key="item.title">
                                    <v-col cols="12">
                                        <v-list-item-content class="d-block">
                                            <v-list-item-title :class="`text-caption text--darken-2 font-weight-medium ${item.class}`" v-text="item.title"></v-list-item-title>
                                            <v-list-item-subtitle :class="`text-body-2 text--darken-2 font-weight-regular ${item.class}`" v-text="item.value"></v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-col>
                                </v-list-item>

                                <v-divider v-if="index < items.length - 1" :key="item.value"></v-divider>
                            </template>
                        </template>
                    </DataListComponent>
                </v-col>

                <v-col cols="12" md="3">
                    <DetailsPanelComponent :items="details"/>
                </v-col>


                <v-dialog v-model="confirmationDialog" transition="dialog-top-transition" max-width="600" persistent>
                    <v-card class="pa-5" v-if="distributor">
                        <v-card-text class="pt-4">
                            <h1 class="text-center mb-4">Deseja excluir este distribuidor?</h1>
                            <span class="d-block text-center mt-4 mb-5">Importante: essa ação não poderá ser desfeita.</span>

                            <div class="mt-5 mb-5">
                                <span class="d-block font-weight-medium">Dsitribuidor</span>
                                <v-divider></v-divider>
                                <div class="mb-3">
                                    <span class="d-block">{{ distributor.name }}</span>
                                    <span class="d-block">{{ distributor.user ? distributor.user.email : '-' }}</span>
                                    <span class="d-block">{{ distributor.cpf_cnpj }}</span>
                                </div>
                            </div>

                            <v-btn
                                :disabled="isLoadingDelete"
                                :loading="isLoadingDelete"
                                elevation="2"
                                large
                                block
                                text
                                class="white--text grey darken-3 mt-5 elevation-0 mx-auto"
                                @click="onDelete"
                            >
                                Sim, confirmo a exclusão
                            </v-btn>
                            <v-btn :disabled="isLoadingDelete" large block text class="mt-3" @click="confirmationDialog=false">Cancelar</v-btn>
                        </v-card-text>
                    </v-card>
                </v-dialog>
            </template>

            <template v-else>
                <p>Erro ao carregar usuário</p>
            </template>



        </v-row>
    </div>
</template>


<script>
    import { mapActions } from 'vuex'
    import dayjs from 'dayjs'

    import BreadcrumbsComponent from "@/components/BreadcrumbsComponent/Index"
    import DataListComponent from "@/components/DataListComponent/Index"
    import DetailsPanelComponent from "@/components/DetailsPanelComponent/Index"

    export default {
        components: {
            BreadcrumbsComponent,
            DataListComponent,
            DetailsPanelComponent
        },
        data: () => {
            return {
                confirmationDialog: false,
                title:'MAIS DESSE DISTRIBUIDOR',
                color:'#1E88E5',
                borderColor:'#1E88E5',
                details:[
                    {
                        name: 'Simulações',
                        data: 'simulations',
                    }
                ],
                value:true,
                breadcrumbs: [
                    {
                        text: 'Dashboard'
                    },
                    {
                        text: 'Distribuidores',
                    },
                    {
                        text: 'Visualizar',
                        disabled: true
                    }
                ],
                items:[
                    {
                        title: 'ATUALIZADO EM',
                        class:'grey--text',
                        value: '',
                    },
                    {
                        title: 'NOME',
                        class:'grey--text',
                        value: '',
                    },
                    {
                        title: 'EMAIL',
                        class:'grey--text',
                        value: '',
                    },
                    {
                        title: 'TELEFONE',
                        class:'grey--text',
                        value: '',
                    },
                    {
                        title: 'CNPJ',
                        class:'grey--text',
                        value: '',
                    },
                    {
                        title: 'ESTADO',
                        class:'grey--text',
                        value: ''
                    }
                ],

                distributor: null,
                id: null,
                isLoading: true,
                isLoadingDelete: false
            }
        },

        methods: {
            ...mapActions(['showDistributor', 'deleteDistributor']),

            async onDelete () {
                this.isLoadingDelete = true

                const resp = await this.deleteDistributor(this.id)

                if (resp) {
                    this.$eventHub.$emit('snackBar', {color: 'success', message: 'Distribuidor apagado com sucesso'})
                    this.$router.push('/distributors')
                } else {
                    this.$eventHub.$emit('snackBar', {color: 'error', message: 'Erro ao apagar distribuidor'})
                }

                this.isLoadingDelete = false
            }
        },

        async mounted() {
            const id = this.$route.params.id
            this.id = id
            this.isLoading = true
            const distributor = await this.showDistributor(id)
            this.distributor = distributor
            this.items[0].value = dayjs(distributor.updated_at).format('DD/MM/YYYY H:mm:ss')
            this.items[1].value = distributor.name

            this.items[2].value = distributor.user ? distributor.user.email : '-'
            this.items[3].value = distributor.phone
            this.items[4].value = distributor.cnpj

            this.items[5].value = distributor.state ? distributor.state.name : '-'

            this.isLoading = false
        }
    }
</script>