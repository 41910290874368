<template>
    <div>
        <v-row no-gutters class="mt-10 mb-8">
            <BreadcrumbsComponent title="Instalador" :items="breadcrumbs">
                <template v-slot:actions>
                    <v-btn
                        :disabled="isLoading"
                        elevation="2"
                        large
                        class="white--text grey darken-3 mt-2 ml-2 elevation-0"
                        @click="$router.push('/installers/edit/' + id)"
                    >
                        <v-icon class="mr-2">mdi-square-edit-outline</v-icon>
                        Editar
                    </v-btn>

                    <v-btn
                        :disabled="isLoading"
                        elevation="2"
                        large
                        class="elevation-0 mt-2 ml-2"
                        @click="confirmationDialog = true"
                        v-if="false"
                    >
                        <v-icon class="mr-2">mdi-trash-can-outline</v-icon>
                    </v-btn>
                </template>
            </BreadcrumbsComponent>
        </v-row>

        <div>
            <v-row v-if="isLoading">
                <v-col md="12" class="text-center">
                    <v-progress-circular indeterminate></v-progress-circular>
                </v-col>
            </v-row>
            <v-row v-else>
                <template v-if="installer && Object.keys(installer).length > 0">
                    <template v-if="installer.approved == null">
                        <v-col cols="12" md="12">
                            <v-card
                                elevation="0"
                                style="background-color: #f2f2f2"
                            >
                                <v-card-text
                                    style="
                                        display: flex;
                                        flex-direction: row;
                                        justify-content: space-between;
                                    "
                                >
                                    <div>
                                        Revisar cadastro
                                        <v-btn
                                            outlined
                                            text
                                            class="ml-2 no-uppercase"
                                            >Ver preview</v-btn
                                        >
                                    </div>

                                    <div>
                                        <v-btn
                                            class="white--text grey darken-3 ml-2 elevation-0 no-uppercase"
                                            @click="onUpdateApprove(true)"
                                            :loading="isLoadingApprove"
                                            :disabled="isLoadingApprove"
                                        >
                                            Aprovar cadastro
                                        </v-btn>
                                        <v-btn
                                            class="elevation-0 ml-2 no-uppercase"
                                            style="
                                                background-color: #bdbdbd;
                                                color: #fff;
                                            "
                                            @click="
                                                confirmationDialogApprove = true
                                            "
                                            :loading="isLoadingApprove"
                                            :disabled="isLoadingApprove"
                                            >Reprovar</v-btn
                                        >
                                    </div>
                                </v-card-text>
                            </v-card>
                        </v-col>

                        <v-dialog
                            v-model="confirmationDialogApprove"
                            transition="dialog-top-transition"
                            max-width="600"
                            persistent
                        >
                            <v-card class="pa-5">
                                <v-card-text class="pt-4">
                                    <h1
                                        class="text-center mb-4"
                                        style="line-height: 1"
                                    >
                                        Tem certeza que deseja reprovar o <br />
                                        cadastro desse instalador?
                                    </h1>
                                    <p class="d-block text-center mt-4 mb-5">
                                        Importante: essa ação não poderá ser
                                        desfeita, além <br />
                                        disso, o cadastro será excluído
                                        automaticamente.
                                    </p>

                                    <p class="d-block text-center mt-4 mb-5">
                                        O instalador será avisado, por e-mail,
                                        que seu cadastro <br />
                                        não estava de acordo com os termos de
                                        uso do site
                                    </p>

                                    <v-btn
                                        :loading="isLoadingApprove"
                                        :disabled="isLoadingApprove"
                                        elevation="2"
                                        large
                                        block
                                        text
                                        class="white--text grey darken-3 mt-5 elevation-0 mx-auto"
                                        @click="onUpdateApprove(false)"
                                        >Sim, reprovar este cadastro</v-btn
                                    >
                                    <v-btn
                                        :disabled="isLoadingApprove"
                                        large
                                        block
                                        text
                                        class="mt-3"
                                        @click="
                                            confirmationDialogApprove = false
                                        "
                                        >Cancelar</v-btn
                                    >
                                </v-card-text>
                            </v-card>
                        </v-dialog>
                    </template>

                    <template v-if="installer.approved == 0">
                        <v-col cols="12" md="12">
                            <v-alert color="red" dark
                                >Instalador reprovado</v-alert
                            >
                        </v-col> </template
                    ><v-col cols="12" md="9">
                        <DataListComponent>
                            <template v-slot:item>
                                <template v-for="(item, index) in items">
                                    <v-divider
                                        v-if="index == 0"
                                        :key="index"
                                    ></v-divider>

                                    <v-list-item :key="item.title">
                                        <v-col cols="12">
                                            <v-list-item-content
                                                class="d-block"
                                            >
                                                <v-list-item-title
                                                    :class="`text-caption text--darken-2 font-weight-medium ${item.class} mb-2`"
                                                    v-text="item.title"
                                                ></v-list-item-title>
                                                <v-list-item-subtitle
                                                    :class="`text-body-2 text--darken-2 font-weight-regular ${item.class}`"
                                                >
                                                    <a
                                                        v-if="item.site"
                                                        target="_blank"
                                                        :href="item.site"
                                                        class="text-decoration-none blue--text"
                                                        >Página do instalador</a
                                                    >

                                                    <img
                                                        v-if="item.image"
                                                        :src="item.value"
                                                    />

                                                    <div v-if="item.gallery">
                                                        <SlidesComponent
                                                            :slidesProps="
                                                                slidesProps
                                                            "
                                                            :slides="
                                                                item.gallery
                                                            "
                                                        />
                                                    </div>

                                                    <div
                                                        v-if="item.switch && false"
                                                        class="d-flex justify-space-between align-center"
                                                    >
                                                        <span>{{
                                                            installer.visible
                                                                ? "Sim"
                                                                : "Não"
                                                        }}</span>
                                                        
                                                        {{ item.visible }}
                                                        <v-switch
                                                            :input-value="
                                                                installer.visible
                                                            "
                                                            @change="
                                                                handleVisible
                                                            "
                                                            inset
                                                            label=""
                                                            color="success"
                                                        ></v-switch>
                                                    </div>

                                                    <div
                                                        v-if="item.box"
                                                        class="border-left pl-4"
                                                    >
                                                        <span v-html="item.value" style="overflow: scroll;"></span>
                                                    </div>

                                                    <p
                                                        style="display: inline !important; overflow: auto !important; text-overflow: initial !important; white-space: initial; !important;"
                                                        v-if="item.text"
                                                        v-text="item.value"
                                                    ></p>
                                                </v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-col>
                                    </v-list-item>

                                    <v-divider
                                        v-if="index < items.length - 1"
                                        :key="
                                            Math.random() * (1000 - 1) +
                                            item.value
                                        "
                                    ></v-divider>
                                </template>
                            </template>
                        </DataListComponent>
                    </v-col>

                    <v-col cols="12" md="3">
                        <DetailsPanelComponent
                            :items="details"
                            @onClick="handleDetailsPanel($event)"
                        />
                    </v-col>

                    <v-dialog
                        v-model="confirmationDialog"
                        transition="dialog-top-transition"
                        max-width="600"
                        persistent
                    >
                        <v-card class="pa-5" v-if="installer">
                            <v-card-text class="pt-4">
                                <h1 class="text-center mb-4">
                                    Deseja excluir este instalador?
                                </h1>
                                <span class="d-block text-center mt-4 mb-5"
                                    >Importante: essa ação não poderá ser
                                    desfeita.</span
                                >

                                <div class="mt-5 mb-5">
                                    <span class="d-block font-weight-medium"
                                        >Instalador</span
                                    >
                                    <v-divider></v-divider>
                                    <div class="mb-3">
                                        <span class="d-block">{{
                                            installer.company_name
                                        }}</span>
                                        <span class="d-block">{{
                                            installer.user
                                                ? installer.user.email
                                                : "-"
                                        }}</span>
                                    </div>
                                </div>

                                <v-btn
                                    :disabled="isLoadingDelete"
                                    :loading="isLoadingDelete"
                                    elevation="2"
                                    large
                                    block
                                    text
                                    class="white--text grey darken-3 mt-5 elevation-0 mx-auto"
                                    @click="onDelete"
                                >
                                    Sim, confirmo a exclusão
                                </v-btn>
                                <v-btn
                                    :disabled="isLoadingDelete"
                                    large
                                    block
                                    text
                                    class="mt-3"
                                    @click="confirmationDialog = false"
                                    >Cancelar</v-btn
                                >
                            </v-card-text>
                        </v-card>
                    </v-dialog>
                </template>

                <template v-else>
                    <p>Erro ao carregar usuário</p>
                </template>
            </v-row>
        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex";
import dayjs from "dayjs";

import BreadcrumbsComponent from "@/components/BreadcrumbsComponent/Index";
import DataListComponent from "@/components/DataListComponent/Index";
import DetailsPanelComponent from "@/components/DetailsPanelComponent/Index";
import SlidesComponent from "@/components/SlidesComponent/Index";

export default {
    components: {
        BreadcrumbsComponent,
        DataListComponent,
        DetailsPanelComponent,
        SlidesComponent,
    },
    data: () => {
        return {
            confirmationDialog: false,
            confirmationDialogApprove: false,
            isLoadingApprove: false,
            title: "MAIS DESSE INSTALADOR",
            color: "#1E88E5",
            borderColor: "#1E88E5",
            details: [
                {
                    name: "Simulações",
                    data: "simulations",
                },
            ],
            value: true,
            breadcrumbs: [
                {
                    text: "Dashboard",
                },
                {
                    text: "Instaladores",
                },
                {
                    text: "Visualizar",
                    disabled: true,
                },
            ],
            items: [],
            installer: null,
            id: null,
            isLoading: true,
            isLoadingDelete: false,
            slidesProps: {
                "arrows-outside": true,
                "bullets-outside": true,
                "transition-speed": 250,
                "visible-slides": 3,
                "slide-multiple": true,
                gap: 3,
                "slide-ratio": 1 / 4,
                "dragging-distance": 200,
                breakpoints: {
                    3500: {
                        visibleSlides: 2,
                        slideMultiple: 2,
                    },
                },
            },
        };
    },

    methods: {
        ...mapActions([
            "listSimulations",
            "showInstallers",
            "deleteInstallers",
            "updateInstallers",
            "updateApproveInstaller",
        ]),
        async handleVisible(evt) {
            this.installer.visible = evt
            if (
                await this.updateInstallers({
                    id: this.installer.id,
                    visible: this.installer.visible,
                })
            ) {
                this.$eventHub.$emit("snackBar", {
                    color: "success",
                    message: "Instalador editado com sucesso",
                });
            } else {
                this.$eventHub.$emit("snackBar", {
                    color: "error",
                    message: "Erro ao editar instalador",
                });
            }
        },
        async onDelete() {
            this.isLoadingDelete = true;

            const resp = await this.deleteInstallers(this.id);

            if (resp) {
                this.$eventHub.$emit("snackBar", {
                    color: "success",
                    message: "Instalador apagado com sucesso",
                });
                this.$router.push("/installers");
            } else {
                this.$eventHub.$emit("snackBar", {
                    color: "error",
                    message: "Erro ao apagar instalador",
                });
            }

            this.isLoadingDelete = false;
        },
        async handleDetailsPanel(value) {
            this.$router.push(`/installers/simulations/${this.installer.id}`);
        },
        async onUpdateApprove(status) {
            this.isLoadingApprove = true;

            const r = await this.updateApproveInstaller({
                approve: status,
                id: this.installer.id,
            });

            if (r) {
                this.$eventHub.$emit("snackBar", {
                    color: "success",
                    message: "Instalador aprovado com sucesso",
                });
                this.$router.push("/installers");
            } else {
                this.$eventHub.$emit("snackBar", {
                    color: "error",
                    message: "Erro ao aprovar instalador",
                });
            }

            this.isLoadingApprove = false;
        },
    },

    async mounted() {
        const id = this.$route.params.id;
        this.id = id;
        this.isLoading = true;
        const installer = await this.showInstallers(id);
        this.installer = installer;

        console.log('installer', installer);

        this.items = [
            {
                title: "VISUALIZAR NO SITE",
                class: "grey--text",
                value: installer.site,
                site: true,
            },
            {
                title: "ATUALIZADO EM",
                class: "grey--text",
                value: dayjs(installer.updated_at).format("DD/MM/YYYY H:mm:ss"),
                text: true,
            },
            {
                title: "VISÍVEL NO SITE",
                class: "grey--text",
                value: installer.visible ? "Sim" : "Não",
                text: true,
            },
            {
                title: "CNPJ",
                class: "grey--text",
                value: installer.cnpj,
                text: true,
            },
            {
                title: "NOME FANTASIA",
                class: "grey--text",
                value: installer.trading_name,
                text: true,
            },
            {
                title: "RAZÃO SOCIAL",
                class: "grey--text",
                value: installer.company_name,
                text: true,
            },
            {
                title: "LOGO",
                class: "grey--text",
                value: `${installer.image}`,
                image: true,
            },
            {
                title: "E-MAIL",
                class: "grey--text",
                value: installer.user ? installer.user.email : "-",
                text: true,
            },
            {
                title: "ENDEREÇO",
                class: "grey--text",
                value: installer.address,
                text: true,
            },
            {
                title: "CIDADE",
                class: "grey--text",
                value: (installer.city && installer.city.name) || "",
                text: true,
            },
            {
                title: "ESTADO",
                class: "grey--text",
                value: installer.state ? installer.state.name : "-",
                text: true,
            },
            {
                title: "TELEFONE",
                class: "grey--text",
                value: installer.phone,
                text: true,
            },
            {
                title: "SITE",
                class: "grey--text",
                value: installer.site,
                text: true,
            },
            {
                title: "QUAIS PORTES DE PROJETO SUA EMPRESA EXECUTA?",
                class: "grey--text",
                value: installer.company_size_all
                    .map((c) => c)
                    .filter(Boolean)
                    .map((_) => _.company_size.size)
                    .join(", "),
                text: true,
            },
            {
                title: "QUAIS TIPOS DE CLIENTE SUA EMPRESA ATENDE?",
                class: "grey--text",
                value: installer.consumer_type_all
                    .map((c) => c)
                    .filter(Boolean)
                    .map((_) => _.consumer_type.type)
                    .join(", "),
                text: true,
            },
            {
                title: "REGIÕES ATENDIDAS",
                class: "grey--text",
                value: installer.regions
                    .map((r) => r)
                    .filter(Boolean)
                    .map((_) => {
                        if (_.state) {
                            return _.state.name;
                        } else {
                            return `${_.city.name} - (${_.city.state.uf})`;
                        }
                    })
                    .join(", "),
                text: true,
            },
            {
                title: "SOBRE",
                class: "grey--text",
                value: installer.description.replace("<p>", "<p style='display: flex; flex-wrap: wrap;'>"),
                box: true,
            },
            {
                title: "FOTOS",
                class: "grey--text",
                value: "",
                gallery: installer.images.map((photo) => ({
                    title: "",
                    content: "",
                    image: photo.url,
                })),
            },
            {
                title: "OBRAS INSTALADAS",
                class: "grey--text",
                value: installer.constructions.length + "Obra(s)",
                text: true,
            },
        ];

        this.isLoading = false;
    },
};
</script>

<style scoped>
.border-left {
    border-left: 4px solid #e0e0e0;
}
</style>
