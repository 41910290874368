<template>
    <v-card class="pb-2" outlined @click="$emit('onClick')">
        <v-list-item three-line>
            <v-list-item-content>
                <div class="title">
                    {{title}}
                </div>

                <v-list-item-title class="total">
                    {{total}}
                </v-list-item-title>

                <v-list-item-subtitle class="text-caption mt-2 font-weight-regular d-flex align-center">
                    
                  <span v-if="parseInt(percent) == 0">
                    Sem variação
                  </span>
                  
                    <span v-else :class="{  'green--text':isUp, 'red--text':!isUp, 'text--darken-4':true, 'd-flex':true, 'align-center':true }">
                        <svg v-if="isUp" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M5.83203 14.1666L14.1654 5.83325" stroke="#219653" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/> <path d="M5.83203 5.83325H14.1654V14.1666" stroke="#219653" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/> </svg>
                        <svg v-if="!isUp" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M10 4.16675V15.8334" stroke="#EB5757" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/> <path d="M15.8346 10L10.0013 15.8333L4.16797 10" stroke="#EB5757" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/> </svg>
                        {{percent}}%
                    </span>
                    <span class="grey--text text--darken-4 ml-2">{{label}}</span>
                </v-list-item-subtitle>
            </v-list-item-content>
        </v-list-item>
    </v-card>
</template>


<script>
    export default {
        name:'TotalizersComponent',
        props:{
            isUp:{
                type: Boolean,
                default: true
            },
            title:{
                type: String,
                default: 'PROJETOS CONTRATADOS'
            },
            total:{
                default: '7.433'
            },
            percent:{
                default: '10,7'
            },
            label:{
                type: String,
                default: 'nos últimos 15 dias'
            }
        }
    }
</script>


<style scoped>
  
  .title {
    font-family: "cerapro-regular" !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.02em;
    text-align: left;
    color: #8A8797;
    
  }
  
  .total {
    font-family: "cerapro-medium" !important;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 50px;
    letter-spacing: 0px;
    text-align: left;
    color: #FFA900;

  }
  
</style>