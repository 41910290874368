export default {
    'SET_USER'(state, user)
    {
        state.user = user
        sessionStorage.setItem("user", JSON.stringify(user) )
    },
    'SET_TOKEN'(state, jwt)
    {
        state.token = jwt
        sessionStorage.setItem("token", jwt)
    }
}