<template>
    <div>
        <v-row no-gutters class="mt-10 mb-8">
            <BreadcrumbsComponent title="Administradores" :items="breadcrumbs">
                <template v-slot:actions>
                    <v-btn @click="$router.push('/administrators/edit/' + id)" elevation="2" large class="white--text grey darken-3 mt-2 ml-2 elevation-0">
                        <v-icon class="mr-2">mdi-file-edit-outline</v-icon>
                        Editar
                    </v-btn>

                    <!--<v-btn elevation="2" large class="grey--text text--darken-2 mt-2 ml-2 elevation-0">
                        <v-icon>mdi-trash-can-outline</v-icon>
                    </v-btn>-->
                </template>
            </BreadcrumbsComponent>
        </v-row>

        <v-row>
            <v-col cols="12">
                <DataListComponent >
                    <template v-slot:item>
                        <template v-for="(item, index) in items">
                            <v-divider v-if="index == 0" :key="index"></v-divider>

                            <v-list-item :key="item.title">
                                <v-col cols="12">
                                    <v-list-item-content class="d-block">
                                        <v-list-item-title :class="`text-caption text--darken-2 font-weight-medium ${item.class}`" v-text="item.title"></v-list-item-title>
                                        <v-list-item-subtitle :class="`text-body-2 text--darken-2 font-weight-regular ${item.class}`" v-text="item.value"></v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-col>
                            </v-list-item>

                            <v-divider v-if="index < items.length - 1" :key="item.value"></v-divider>
                        </template>
                    </template>
                </DataListComponent>
            </v-col>
        </v-row>
    </div>
</template>


<script>
    import BreadcrumbsComponent from "@/components/BreadcrumbsComponent/Index"
    import DataListComponent from "@/components/DataListComponent/Index"
    import { mapActions } from 'vuex'
    import dayjs from "dayjs";
    export default {
        components: {
            BreadcrumbsComponent,
            DataListComponent
        },
        data: () => {
            return {
                details:[
                    {
                        name: 'Simulações',
                        data: 'simulations',
                    },
                    {
                        name: 'Falar com o consultor',
                        data: 'consultant',
                    },
                    {
                        name: 'Pedidos',
                        data: 'order',
                    },
                    {
                        name: 'Depoimentos',
                        data: 'depositions',
                    }
                ],
                value:true,
                breadcrumbs: [
                    {
                        text: 'Dashboard'
                    },
                    {
                        text: 'Administradores',
                    },
                    {
                        text: 'Visualizar',
                        disabled: true
                    }
                ],
                items:[
                    {
                        title: 'ATUALIZADO EM',
                        class:'grey--text',
                        value: '-',
                    },
                    {
                        title: 'NOME',
                        class:'grey--text',
                        value: '-',
                    },
                    {
                        title: 'E-MAIL',
                        class:'grey--text',
                        value: '-'
                    }
                ],
                id: null,
                admin: null,
                isLoading: true,
                isLoadingDelete: false
            }
        },
        
        methods: {
            ...mapActions(['showAdmin']),

            async onDelete () {
                this.isLoadingDelete = true

                const resp = await this.deleteAdmin(this.id)

                if (resp) {
                    this.$eventHub.$emit('snackBar', {color: 'success', message: 'Cliente apagado com sucesso'})
                    this.$router.push('/clients')
                } else {
                    this.$eventHub.$emit('snackBar', {color: 'error', message: 'Erro ao apagar cliente'})
                }

                this.isLoadingDelete = false
            }
        },

        async mounted() {
            const id = this.$route.params.id
            this.id = id
            this.isLoading = true
            const admin = await this.showAdmin(id)
            this.admin = admin
            this.items[0].value = dayjs(admin.updated_at).format('DD/MM/YYYY H:mm:ss')
            this.items[1].value = admin.username
            this.items[2].value = admin.email
        }
    }
</script>