<template>
    <v-expansion-panels flat v-model="isOpen">
        <v-expansion-panel :disabled="disabled" :style=" showBorder || isOpen === 0 ? styles : {} " class="rounded-lg mb-2 expansion-panel" :test="styles.borderLeft">
            <v-expansion-panel-header disable-icon-rotate>
                <slot name="header"></slot>

                <template v-slot:actions>
                    <v-icon medium class="grey--text lighten-5" v-text="leftIcon"></v-icon>
                    <span class="mt-1 grey--text lighten-5">{{totalMessages}}</span>
                    
                    <v-icon medium class="ml-3 grey--text lighten-5">mdi-paperclip</v-icon>
                    <span class="mt-1 grey--text lighten-5">{{totalAttachments}}</span>
                    
                    <v-icon medium class="ml-3 grey--text lighten-5" v-text=" isOpen == 0 ? 'mdi-minus' : 'mdi-plus'"></v-icon>
                </template>
            </v-expansion-panel-header>

            <v-expansion-panel-content>
                <slot name="content"></slot>
            </v-expansion-panel-content>
        </v-expansion-panel>
    </v-expansion-panels>
</template>


<script>
    export default {
        name:'ExpandComponent',
        data(){
            return {
                isOpen: this.initOpen ? 0 : null,
                styles: {
                    borderLeft:`6px solid ${this.borderColor}`,
                    boxShadow: `1px 1px 3px 0px ${this.borderColor}`
                }
            }
        },
        watch:{
            isOpen(val)
            {
                this.$emit('onExpand', val !== undefined)
            }
        },
        props:{
            initOpen:{
                type: Boolean,
                default: false
            },
            showBorder:{
                type: Boolean,
                default: false
            },
            borderColor:{
                type: String,
                default: '#bcc9c1'
            },
            leftIcon:{
                type: String,
                default: 'mdi-message-outline'
            },
            totalAttachments:{
                type: Number,
                default: 0
            },
            totalMessages:{
                type: Number,
                default: 0
            },
            disabled:{
                type:Boolean,
                default:false
            }
        }
    }
</script>

<style scoped>
    .expansion-panel{
        border-left:none;
        box-shadow:1px 1px 3px 0px #bcc9c1;
    }
</style>
