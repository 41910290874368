<template>
    <div>
        <v-row no-gutters class="mt-10 mb-8">
            <h2>Dashboard</h2>
        </v-row>
        
        <template v-if="isLoadingCounters">
            <v-row>
                <v-col
                    cols="12"
                    md="3"
                    v-for="i in 8"
                >
                    <v-skeleton-loader
                        type="article"
                    ></v-skeleton-loader>
                </v-col>

            </v-row>
        </template>
        
        <template v-else>
            <v-row>
                <v-col
                    md="3"
                    cols="12"
                >
                    <TotalizersComponent :total="counters.clients.total" title="Clientes finais" :percent="counters.clients.percent" :isUp="counters.clients.percent > 0" />
                </v-col>

                <v-col
                    md="3"
                    cols="12"
                >
                    <TotalizersComponent :total="counters.simulations.total" title="Simulações" :percent="counters.simulations.percent" :isUp="counters.simulations.percent > 0"  />
                </v-col>

                <v-col
                    md="3"
                    cols="12"
                >
                    <TotalizersComponent :total="counters.pre_orders.total" title="Pré-Pedidos" :percent="counters.pre_orders.percent" :isUp="counters.pre_orders.percent > 0" />
                </v-col>

                <v-col
                    md="3"
                    cols="12"
                >
                    <TotalizersComponent :total="counters.orders_approveds.total" title="Pedidos Aprovados" :percent="counters.orders_approveds.percent" :isUp="counters.orders_approveds.percent > 0" />
                </v-col>

            </v-row>


            <v-row>
                <v-col
                    md="3"
                    cols="12"
                >
                    <TotalizersComponent :total="counters.orders_atendimento.total" title="Pedidos em andamento" :percent="counters.orders_atendimento.percent" :isUp="counters.orders_atendimento.percent > 0" />
                </v-col>

                <v-col
                    md="3"
                    cols="12"
                >
                    <TotalizersComponent :total="counters.orders_finished.total" title="Pedidos finalizados" :percent="counters.orders_finished.percent" :isUp="counters.orders_finished.percent > 0" />
                </v-col>

                <v-col
                    md="3"
                    cols="12"
                >
                    <TotalizersComponent :total="counters.installers.total" title="Empresas solar" :percent="counters.installers.percent" :isUp="counters.installers.percent > 0" />
                </v-col>

                <v-col
                    md="3"
                    cols="12"
                >
                    <TotalizersComponent :total="counters.installers_payed.total" title="Assinantes" :percent="counters.installers_payed.percent" :isUp="counters.installers_payed.percent > 0" />
                </v-col>

            </v-row>
        </template>
      
        
        <v-row>
            <v-col
                cols="12"
            >
                <v-card>
                    
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" md="6">
                                <v-select
                                    :items="itemsChart"
                                    label="Evolução semanal"
                                    dense
                                    item-text="title"
                                    item-value="value"
                                    v-model="selectedItemChart"
                                    @change="chanteChart"
                                ></v-select>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    
                    
                    <chart-bar
                        :chartdata="chartdata"
                        :options="options"
                        
                        
                        ref="chart"
                    />
                </v-card>
            </v-col>
        </v-row>
        
        
        <SearchCityOrState />
    </div>
    
</template>

<script>

    import {mapActions, mapGetters} from 'vuex'
    
    import ChartBar from "../components/ChartBarComponent/ChartBar.vue"
    import TotalizersComponent from '../components/TotalizersComponent/Index'
    
    import SearchCityOrState from "../components/SearchCityOrState";

    export default {
        name: 'HomePage',
        components:{
            ChartBar,
            TotalizersComponent,
            SearchCityOrState
        },

        data() {
            return {
                teste: true,
                chartdata: {
                    labels: [],
                    datasets: [
                        {
                            label: "Dados",
                            backgroundColor: "#FFA900",
                            data: [],
                        },
                    ]
                },
                options: {
                    responsive: true,
                    maintainAspectRatio: false
                },
                loadingChart: true,
                isLoadingCounters: false,
                
                counters: {
                    clients: { total: 0, percent: 0 },
                    simulations: { total: 0, percent: 0 },
                    pre_orders: { total: 0, percent: 0 },
                    orders_approveds: { total: 0, percent: 0 },
                    orders_atendimento: { total: 0, percent: 0 },
                    orders_finished: { total: 0, percent: 0 },
                    installers: { total: 0, percent: 0 },
                    installers_payed: { total: 0, percent: 0 }
                },

                dataChart: [],
                
                selectedItemChart: 'clients',

                itemsChart: [
                    {
                        title: 'Clientes cadastrados',
                        value: 'clients'
                    },
                    {
                        title: 'Simulações',
                        value: 'simulations'
                    },
                    {
                        title: 'Pré pedidos',
                        value: 'budgets'
                    },
                    {
                        title: 'Pedidos aprovados',
                        value: 'orders_approveds'
                    },
                    {
                        title: 'Pedidos em andamento',
                        value: 'orders_andamento'
                    },
                    {
                        title: 'Pedidos finalizados',
                        value: 'orders_finished'
                    },
                    {
                        title: 'Empresas solar',
                        value: 'installers'
                    },
                    {
                        title: 'Assinantes',
                        value: 'installers_payed'
                    },
                ]
                
            };
        },
        
        mounted() {


            if (this.getUser.user_type == 2) {
                this.$router.push("/activities");
                return
            }
            
            this.loadCounters()
            
            
            this.loadEvolutionWeek()
        },
        
        
        methods: {
            ...mapActions(['dashboardCounters', 'dashboardEvolutionWeek']),
            
            async loadCounters () {
                this.isLoadingCounters = true
                const r = await this.dashboardCounters()
                this.counters = r
                this.isLoadingCounters = false
            },
            
            async loadEvolutionWeek () {

                this.loadingChart = true

                const r = await this.dashboardEvolutionWeek()
                this.dataChart = r
                
                this.chanteChart()
               
                
                this.loadingChart = false
            },
            
            
            chanteChart () {
                this.loadingChart = true
                
                this.chartdata.labels = [];
                this.chartdata.datasets[0].data = [];
                
                let labels = []
                let datas = []
                
                for (let day of this.dataChart) {
                    
                    
                    
                    
                    labels.push(day['from']);
                    datas.push(day[this.selectedItemChart]);
                }

                this.chartdata.labels = labels;
                this.chartdata.datasets[0].data = datas;
                

                this.loadingChart = false
                
                this.$refs['chart'].forceRender()
                
                
            }
        },

        computed: {
            ...mapGetters(["getUser"]),
        }
    }
</script>

