<template>
    <div class="d-flex align-start" @click="$emit('onClick')">
        <v-icon v-bind="icon.props" :class="`mt-2 ${icon.color}`">{{icon.name}}</v-icon>
        <div class="d-flex flex-column ml-3">
            <span v-if="!this.$slots['content']" class="blue--text text--darken-2 font-weight-medium text-overline">{{title}}</span>
            <span v-if="!this.$slots['content']" class="blue--text text--darken-2 font-weight-light">{{subtitle}}</span>
            <span v-if="!this.$slots['content']" class="black--text text--darken-2 text-caption">{{legend}}</span>
            <slot name="content"></slot>
        </div>
    </div>
</template>


<script>
    export default {
        name:'TextBlockComponent',
        props:{
            icon:{
                type: Object,
                default()
                {
                    return {
                        name: 'mdi-map-marker-outline',
                        color: 'blue--text text--darken-2',
                        props: {
                            small:true
                        }
                    }
                }
            },
            title:{
                type: String,
                default: 'REGIÕES ATENTIDAS'
            },
            subtitle:{
                type: String,
                default: 'Busque e selecione estados ou cidades'
            },
            legend:{
                type: String,
                default: 'Nenhuma seleção'
            }
        }
    }
</script>
