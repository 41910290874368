import axios from 'axios'

const Http = axios.create({
    baseURL: process.env.VUE_APP_API_URL
})

Http.interceptors.request.use( config => {
    config.headers.common['Authorization'] = (() => sessionStorage.getItem('token'))()
    return config
}, Promise.reject)


Http.interceptors.response.use( async function(response) {
    return response
}, async err => {
    
    if (err.response.status === 401) {
        if (err.response.data.type && err.response.data.type == 'error_token') {
            sessionStorage.clear()
            window.location.href = "/"
        }

    } else {
        return Promise.reject(err)
    }
})



export default Http