<template>
    <div>
        <v-row no-gutters class="mt-10 mb-8">
            <h1 style="color:#312F37;font-size: 2.5rem;">Atividades Recentes</h1>
        </v-row>
        
        <v-row>
            <v-col cols="12">
                <DataListComponent>
                    <template v-slot:item>
                        <template v-for="(item, index) in activities">
                            <v-divider v-if="index == 0" :key="index"></v-divider>

                            <v-list-item :key="item.title">
                                <v-col cols="12" class="d-flex justify-space-between align-center">
                                    <TextBlockComponent :icon="{name: 'mdi-circle-small'}" class="mb-4">
                                        <template v-slot:content>
                                            <span class="grey--text text--darken-3 font-weight-regular">{{getTitleNotification(item.type)}}</span>
                                            <span class="text-caption" v-html="item.text"></span>
                                        </template>
                                    </TextBlockComponent>

                                    <small class="grey--text text--darken-1">em: {{__dayjs(item.created_at,'DD/MM/YYYY')}}</small>
                                </v-col>
                            </v-list-item>

                            <v-divider v-if="index < activities.length - 1" :key="item.value"></v-divider>
                        </template>
                    </template>
                </DataListComponent>
            </v-col>
        </v-row>
    </div>
</template>


<script>
    import DataListComponent from '@/components/DataListComponent/Index'
    import TextBlockComponent from '@/components/TextBlockComponent/Index'
    import dayjs from 'dayjs'
    
    import { mapActions } from 'vuex'

    export default {
        name: 'ActivitiesPage',
        components:{
            DataListComponent,
            TextBlockComponent
        },
        data() {
            return {
                activities: []
            };
        },
        methods:{
            ...mapActions(['myNotifications']),
            __dayjs (date, format) 
            {
                return dayjs(date).format(format)
            },


            getTitleNotification (t) {
                
                let options = [
                    { id: 1, value: "Nova simulação" },
                    { id: 2, value: "Falar com o consultor" },
                    { id: 3, value: "Aguardando orçamentos" },
                    { id: 4, value: "Aprovar proposta" },
                    { id: 5, value: "Pedido aprovado" },
                    { id: 6, value: "Status do pedido" },
                    { id: 7, value: "Nova mensagem" },
                    { id: 8, value: "Novo cliente final" },
                    { id: 9, value: "Nova empresa solar" },
                    { id: 10, value: "Nova empresa solar" },
                    { id: 11, value: "Nova assinatura" },
                ];
                
                for (let option of options) {
                    if (option.id == t) {
                        return option.value
                    }
                }

                return "Tipo inválido"
            }
        },
        
        async mounted() {
            const r = await this.myNotifications()
            
            this.activities = r.data
        }
    }
</script>

