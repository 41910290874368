import Http from '@/plugins/Http'

export default {
    async listDistributors ({}, params) 
    {
        try {
            const { data } = await Http.get('/distributor', { params })
            return data
        } catch (e) {
            return false            
        }
    },
    async insertDistributors ({}, distributor) 
    {
        try {
            await Http.post('/distributor', distributor)
            return true
        } catch (e) {
            console.log(e.response.data);
            return false
        }
    },
    
    async showDistributor ({}, id) {
        try {
            const { data } = await Http.get(`/distributor/${id}`)
            return data
        } catch (e) {
            return false
        }
    },

    async updateDistributors ({}, distributor) {
        try {
            await Http.put('/distributor/' + distributor.id, distributor)
            return true
        } catch (e) {
            return false
        }
    },


    async deleteDistributor ({}, id) {
        try {
            await Http.delete('/distributor/' + id)
            return true
        } catch (e) {
            return false
        }
    },

    async deleteManyDistributor ({}, ids) {
        try {
            await Http.delete('/distributor/delete/many', {
                data: ids
            })
            return true
        } catch (e) {
            return false
        }
    },
}
