export const getResidenceType = (type) => {
    
    const array = [
        {
            type: 0,
            value: 'Residência'
        },
        {
            type: 1,
            value: 'Comércio'
        },
        {
            type: 2,
            value: 'Indústria'
        }
    ]
    
    for (let r of array) {
        if (r.type == type) {
            return r.value
        }
    }
    
    return 'Tipo inválido'
}