import Http from '@/plugins/Http'

export default {

    async listBudgets({}, params) {
        try {
            const { data } = await Http.get(`/budget${ params.clientId ? params.clientId : '' }`, { params });
            return data;
        } catch (e) {
            return false;
        }
    },

    async showBudget({}, id) {
        try {
            const { data } = await Http.get(`/budget/${id}`);
            return data;
        } catch (e) {
            return false;
        }
    },
    
    async updateBudget  ({}, body) {
        try {
            const { data } = await Http.put(`/budget/${body.id}`, body)
            return true
        } catch (e) {
            return false
        }
    },
    
    async insertBudget ({}, body) {
        const { data } = await Http.post(`/budget`, body)
        return data
    },

    
    async deleteDocumentBudget ({}, id) {
        try {
            const { data } = await Http.delete(`/deleteDocumentBudget/` + id, )
            return true    
        } catch (e) {
            return false
        }
        
    },
    

    async insertDocumentBudget ({}, body) {
        try {
            const { data } = await Http.post(`/insertDocumentBudget/${body.budget_id}`, body)
            return true
        } catch (e) {
            return false
        }

    },

    async updateDocumentBudget ({}, body) {
        try {
            const { data } = await Http.put(`/updateDocumentBudget/${body.budget_id}`, body)
            return true
        } catch (e) {
            return false
        }

    },


    async getDocumentBudget({}, id) {
        try {
                const { data } = await Http.get(`/documentBudget/${id}/showFile`);
            return data;
        } catch (e) {
            return false;
        }
    },

    async deleteManyBudgets ({}, ids) {
        try {
            await Http.delete('/budgets/delete/many', {
                data: ids
            })
            return true
        } catch (e) {
            return false
        }
    },
}
