<template>
    <div>
        <!-- Janel de filtros: TODO => Adicionar os filtros ao filterpanel usando v-chips -->
        <v-dialog v-model="dialog" transition="dialog-top-transition" max-width="600">
            <template v-slot:default="dialog">
                <v-card>
                    <v-card-text class="pt-4">
                        <div  class="pt-4 mb-4">
                            <h1>Filtrar Consultores por:</h1>
                        </div>

                        <div>
                            <v-select :items="optionsStates" item-value="id" item-text="name" label="Estado" placeholder="Estado" outlined v-model="filter.state"></v-select>
                            <v-select :items="['Foo', 'Bar', 'Fizz', 'Buzz']" label="Tipo" placeholder="Tipo" outlined v-model="filter.category"></v-select>
                            <v-select :items="['Foo', 'Bar', 'Fizz', 'Buzz']" label="Etapa" placeholder="Etapa" outlined v-model="filter.category"></v-select>
                            <v-btn elevation="2" large block class="white--text grey darken-3 elevation-0" @click="dialog.value=false" >Confirmar</v-btn>
                            <v-btn large block text>Limpar filtro</v-btn>
                        </div>
                    </v-card-text>
                </v-card>
            </template>
        </v-dialog>


        <v-dialog v-model="confirmationDialog" transition="dialog-top-transition" max-width="600" persistent>
            <v-card class="pa-5">
                <v-card-text class="pt-4">
                    <h1 class="text-center mb-4" style="line-height: 1">Você selecionou {{ selecteds.length }} item(s), tem certeza que deseja excluí-lo(s)?</h1>
                    <span class="d-block text-center mt-4 mb-5">Importante: essa ação não poderá ser desfeita.</span>

                    <v-btn :loading="isLoadingDelete" :disabled="isLoadingDelete" elevation="2" large block text class="white--text grey darken-3 mt-5 elevation-0 mx-auto" @click="onDeleteMany">Sim, confirmo a exclusão</v-btn>
                    <v-btn :disabled="isLoadingDelete" large block text class="mt-3" @click="confirmationDialog = false">Cancelar</v-btn>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-row no-gutters class="mt-10 mb-8">
            <BreadcrumbsComponent title="Consultores" :items="breadcrumbs">
                <template v-slot:actions>
                    <v-btn elevation="2" large color="secondary" class="white--text mt-2 ml-2 elevation-0" @click="$router.push('/consultants/add')">
                        <v-icon class="mr-2">mdi-plus</v-icon>
                        Adicionar novo
                    </v-btn>
                </template>
            </BreadcrumbsComponent>
        </v-row>
        
        <v-row no-gutters>
            <v-col cols="12" md="12">
                <FilterPanelComponent v-if="!selected" :show-content="showContent" @onSearching="sendFilter" @onDownload="load(1, true)">
                    <template v-slot:result>
                        <v-chip class="ma-2" close color="grey darken-1" outlined> <span>Cliente:</span> <span class="ml-2">Logo Nunes Castanheda</span> </v-chip>
                    </template>
                </FilterPanelComponent>

                <ActionBarComponent v-if="selecteds.length > 0">
                    <template v-slot:content>
                        <div class="d-flex justify-space-between flex-wrap">
                            <span class="grey--text text--darken-4">{{ selecteds.length }} registro(s) selecionado(s)</span>
                            <v-icon color="grey darken-2" @click="confirmationDialog=true">mdi-trash-can-outline</v-icon>
                        </div>
                    </template>
                </ActionBarComponent>
            </v-col>
        </v-row>
        
        <v-row no-gutters>
            <v-col cols="12" md="12">
                <v-data-table :headers="headers" :items="items" hide-default-footer :loading="isLoading" :items-per-page="-1">
                    <template v-slot:item.updated_at="{ item }">
                        <v-checkbox
                            v-model="selecteds"
                            :value="item.id"
                            :label="`${__dayjs(item.updated_at).format('DD/MM/YYYY HH:mm:ss')}`"
                        ></v-checkbox>
                    </template>

                    <template v-slot:item.state_id="{ item }">
                        {{getState(item.state_id)}}
                    </template>

                    <template v-slot:item.active="{ item }">
                        <v-switch :input-value="item.active" @click="handleVisible(index, item)" inset :label="item.active ? 'Ativo' : 'Inativo'" color="success"></v-switch>
                    </template>

                    <template v-slot:item.action="{ item }">
                        <v-menu offset-y>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    color="primary"
                                    dark
                                    v-bind="attrs"
                                    v-on="on"
                                    text
                                >
                                    <v-icon>mdi-dots-horizontal</v-icon>
                                </v-btn>
                            </template>
                            <v-card
                                class="mx-auto"
                                max-width="300"
                                tile
                            >
                                <v-list>
                                    <v-list-item link>
                                        <v-list-item-title @click="$router.push(`/consultants/view/${item.id}`)"><v-icon>mdi-eye-outline</v-icon> Visualizar</v-list-item-title>
                                    </v-list-item>
                                    <v-list-item link>
                                        <v-list-item-title @click="$router.push(`/consultants/edit/${item.id}`)"><v-icon>mdi-square-edit-outline</v-icon> Editar</v-list-item-title>
                                    </v-list-item>
                                    <v-divider></v-divider>

                                    <v-list-item link>
                                        <v-list-item-title @click="onDelete(item)"><v-icon>mdi-trash-can-outline</v-icon> Excluir</v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </v-card>
                        </v-menu>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <div class="text-center">
                    <v-pagination
                        v-model="page"
                        :length="totalPage"
                        :total-visible="7"
                        next-aria-label="Próximo"
                        @input="load($event)"
                    ></v-pagination>
                </div>
            </v-col>
        </v-row>


        <v-dialog v-model="confirmationDialogDelete" transition="dialog-top-transition" max-width="600" persistent>
            <v-card class="pa-5" v-if="consultant">
                <v-card-text class="pt-4">
                    <h1 class="text-center mb-4">Deseja excluir este consultor?</h1>
                    <span class="d-block text-center mt-4 mb-5">Importante: essa ação não poderá ser desfeita.</span>

                    <div class="mt-5 mb-5">
                        <span class="d-block font-weight-medium">Consultor</span>
                        <v-divider></v-divider>
                        <div class="mb-3">
                            <span class="d-block">{{ consultant.name }}</span>
                            <span class="d-block">{{ consultant.user ? consultant.user.email : '-' }}</span>
                        </div>
                    </div>

                    <v-btn
                        :disabled="isLoadingDelete"
                        :loading="isLoadingDelete"
                        elevation="2"
                        large
                        block
                        text
                        class="white--text grey darken-3 mt-5 elevation-0 mx-auto"
                        @click="onSendDelete"
                    >
                        Sim, confirmo a exclusão
                    </v-btn>
                    <v-btn :disabled="isLoadingDelete" large block text class="mt-3" @click="confirmationDialogDelete=false">Cancelar</v-btn>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>



<script>
    import { mapActions } from 'vuex'
    import BreadcrumbsComponent from "@/components/BreadcrumbsComponent/Index"
    import FilterPanelComponent from "@/components/FilterPanelComponent/Index"
    import ActionBarComponent from "@/components/ActionBarComponent/Index"
    import dayjs from "dayjs";
    import Papa from "papaparse";
    
    export default {
        components: {
            BreadcrumbsComponent,
            FilterPanelComponent,
            ActionBarComponent
        },
        data: () => {
            return {
                selected:false, //TODO => handle selecteds when to implement requests,
                selecteds: [],
                breadcrumbs: [
                    {
                        text: 'Dashboard'
                    },
                    {
                        text: 'Consultores',
                        disabled: true
                    }
                ],
                searching:'',
                dialog:false,
                confirmationDialog:false,
                showContent:false,
                headers: [
                    {
                        text: 'ATUALIZADO EM',
                        align: 'start',
                        sortable: false,
                        value: 'updated_at',
                    },
                    {
                        text: 'NOME',
                        align: 'start',
                        sortable: false,
                        value: 'name',
                    },
                    {
                        text: 'E-MAIL',
                        align: 'start',
                        sortable: false,
                        value: 'user.email',
                    },
                    {
                        text: 'DDD Celular',
                        align: 'start',
                        sortable: false,
                        value: 'phone',
                    },
                    {
                        text: 'ESTADO',
                        align: 'start',
                        sortable: false,
                        value: 'state_id',
                    },
                    {
                        text: 'ATIVO',
                        align: 'start',
                        sortable: false,
                        value: 'active',
                    },
                    {
                        text: '',
                        align: 'start',
                        sortable: false,
                        value: 'action',
                    },
                ],
                items: [],
                optionsStates: [],
                isLoadingDelete: false,
                page: 1,
                totalPage: 1,
                isLoading: false,
                timerSearch: null,
                filter: {
                    query: '',
                },
                isSearched: false,
                consultant: null,
                confirmationDialogDelete: false
            }
        },
        async mounted()
        {
            this.load(1)
        },
        methods:{
            ...mapActions(['getStates','loadConsultants', 'updateConsultant', 'deleteManyConsultant', 'removeConsultant']),


            onDelete (item) {
                this.consultant = item
                this.confirmationDialogDelete = true
            },

            async onSendDelete () {
                this.isLoadingDelete = true

                const resp = await this.removeConsultant(this.consultant.id)

                if (resp) {
                    this.$eventHub.$emit('snackBar', {color: 'success', message: 'Consultor apagado com sucesso'})
                    this.confirmationDialogDelete = false
                    this.load()
                } else {
                    this.$eventHub.$emit('snackBar', {color: 'error', message: 'Erro ao apagar consultor'})
                }

                this.isLoadingDelete = false
            },
            
            
            setLoading()
            {
                this.$eventHub.emit('loading', {
                    title:'Gerando a planilha de dados ...',
                    subtitle:'Aguarde só um instante, logo mais o download do <br/> arquivo será iniciado automaticamente.',
                    status:true
                })
            },
            async handleVisible( index, item )
            {
                item.active = !item.active

                if (item.image) delete item.image

                item.changingStatus = true;
                
                const resp = await this.updateConsultant(item)

                if (resp) {
                    this.$eventHub.$emit('snackBar', {color: 'success', message: 'Consultor editado com sucesso'})
                } else {
                    this.$eventHub.$emit('snackBar', {color: 'error', message: 'Erro ao editar consultor'})
                }
            },
            getState( stateId )
            {
                const state = this.optionsStates.find( ({id}) => id == stateId )
                return (state) ? state.name : ''
            },
            __dayjs (date)
            {
                return dayjs(date)
            },

            async onDeleteMany () {
                this.isLoadingDelete = true
                const ids = this.selecteds
                const r = await this.deleteManyConsultant({ids})
                if (r) {
                    this.$eventHub.$emit('snackBar', {color: 'success', message: 'Consultores apagados com sucesso'})
                    this.selecteds = []
                    this.confirmationDialog = false
                    this.load()
                } else {
                    this.$eventHub.$emit('snackBar', {color: 'error', message: 'Erro ao apagar consultores'})
                }


                this.isLoadingDelete = false
            },

            async load (page = 1, csv = false) {
                this.isLoading = true
                const filter = {...this.filter}
                filter.page = page
                if (csv) filter.csv = csv
                const resp = await this.loadConsultants(filter)
                if (resp) {
                    if (filter.csv) {
                        const itensCsv = []
                        for (let i in resp) {
                            const obj = {
                                'Nome': resp[i].name,
                                'Email': resp[i].user ? resp[i].user.email : '',
                                'Telefone': resp[i].phone,
                                "Estado": resp[i].state ? resp[i].state.uf: '',
                                'Ativo': resp[i].active ? 'Sim' : 'Não'
                            }
                            itensCsv.push(obj)
                        }

                        let csv = Papa.unparse(itensCsv, {delimiter: ';'})
                        let csvData = new Blob(["\ufeff", csv], {type: 'text/csv;charset=utf-8;'});
                        let csvURL = null;
                        if (navigator.msSaveBlob) {
                            csvURL = navigator.msSaveBlob(csvData, 'consultores.csv');
                        } else {
                            csvURL = window.URL.createObjectURL(csvData);
                        }

                        let tempLink = document.createElement('a');
                        tempLink.href = csvURL;
                        tempLink.setAttribute('download', 'consultores.csv');
                        tempLink.click();
                    } else {
                        this.items = resp.data
                        this.totalPage = resp.last_page
                    }


                }
                this.optionsStates = await this.getStates()
                this.isLoading = false
            },

            sendFilter (query) {
                this.filter.query = query
                this.isLoading = true
                if (query != "" && query || this.isSearched) {
                    clearTimeout(this.timerSearch);
                    this.timerSearch = setTimeout(async () => {
                        await this.load(1)

                        this.isLoading = false
                        if (query) this.isSearched = true
                    }, 800)
                } else {
                    this.isLoading = false
                    clearTimeout(this.timerSearch)
                }
            },
        }
    }
</script>
