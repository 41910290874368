<template>
    <div>
        <v-row no-gutters class="mt-10 mb-8">
            <BreadcrumbsComponent title="Clientes" :items="breadcrumbs" />
        </v-row>

        <v-row v-if="isLoading">
            <v-col md="12" class="text-center">
                <v-progress-circular indeterminate></v-progress-circular>
            </v-col>
        </v-row>

        <form
            v-else
            @keydown.enter="!isLoadingUpdate && isValid && onUpdatetClient()"
        >
            <v-row>
                <v-col cols="12" md="6">
                    <v-row>
                        <v-col cols="12" md="12">
                            <v-text-field
                                label="Nome"
                                placeholder="Nome"
                                outlined
                                v-model="client.username"
                            ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="12">
                            <v-text-field
                                label="Email"
                                placeholder="Email"
                                outlined
                                persistent-hint
                                v-model="client.email"
                            ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="12">
                            <v-text-field
                                flat
                                solo
                                label="Senha Atual"
                                value="*********"
                                readonly
                            >
                                <template v-slot:append>
                                    <v-btn
                                        depressed
                                        color="grey lighten-2"
                                        class="ma-0"
                                    >
                                        Trocar senha
                                    </v-btn>
                                </template>
                            </v-text-field>
                        </v-col>

                        <v-col cols="12" md="12">
                            <v-text-field
                                label="DDD Celular"
                                placeholder="DDD Celular"
                                outlined
                                hint="Apenas números"
                                persistent-hint
                                v-model="client.phone"
                                v-mask="['(##) ####-####', '(##) #####-####']"
                            ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="12">
                            <v-text-field
                                label="CPF/CNPJ"
                                placeholder="CPF/CNPJ"
                                outlined
                                hint="Apenas números"
                                persistent-hint
                                v-mask="[
                                    '###.###.###-##',
                                    '##.###.###/####-##',
                                ]"
                                v-model="client.cpf_cnpj"
                            ></v-text-field>
                        </v-col>

                        <v-col
                            cols="12"
                            md="12"
                            v-if="client.cpf_cnpj.length >= 15"
                        >
                            <v-text-field
                                label="Razão Social"
                                placeholder="Razão Social"
                                outlined
                                v-model="client.corporate_name"
                            ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="12">
                            <v-select
                                :items="optionsStates"
                                item-value="id"
                                item-text="name"
                                label="Estado"
                                outlined
                                v-model="client.state_id"
                            ></v-select>
                        </v-col>

                        <v-col cols="12" md="12">
                            <v-checkbox
                                v-model="client.opt_in"
                                label="Autorizo a Market Solar a entrar em contato por e-mail, celular ou whatsapp"
                            ></v-checkbox>
                        </v-col>

                        <v-col cols="12" md="12">
                            <v-btn
                                elevation="2"
                                large
                                block
                                class="white--text grey darken-3 elevation-0"
                                :disabled="isLoadingUpdate || !isValid"
                                :loading="isLoadingUpdate"
                                @click="onUpdatetClient"
                            >
                                Atualizar
                            </v-btn>
                        </v-col>

                        <v-col cols="12" md="12">
                            <v-btn
                                large
                                block
                                text
                                @click="$router.push('/clients/view/' + id)"
                            >
                                Cancelar
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </form>
    </div>
</template>

<script>
import BreadcrumbsComponent from "@/components/BreadcrumbsComponent/Index";
import { mapActions } from "vuex";

export default {
    components: {
        BreadcrumbsComponent,
    },
    data: () => {
        return {
            breadcrumbs: [
                {
                    text: "Dashboard",
                },
                {
                    text: "Clientes",
                },
                {
                    text: "Adicionar novo",
                    disabled: true,
                },
            ],
            client: {
                id: "",
                username: "",
                email: "",
                cpf_cnpj: "",
                phone: "",
                opt_in: false,
                state_id: null,
                corporate_name: null,
            },
            id: null,
            isLoading: true,
            optionsStates: [],
            isLoadingUpdate: false,
        };
    },
    computed: {
        isValid() {
            return !!(
                [
                    "username",
                    "cpf_cnpj",
                    "phone",
                    "state_id",
                ].every((key) => !!this.client[key]) &&
                /^[A-Za-z0-9_\-\.+]+@[A-Za-z0-9_\-\.]{2,}\.[A-Za-z0-9]{2,}(\.[A-Za-z0-9])?/i.test(
                    this.client.email
                )
            );
        },
    },
    methods: {
        ...mapActions(["getStates", "showClient", "updateClient"]),

        async onUpdatetClient() {
            this.isLoadingUpdate = true;

            const resp = await this.updateClient(this.client);

            if (resp) {
                this.$eventHub.$emit("snackBar", {
                    color: "success",
                    message: "Cliente editado com sucesso",
                });
                this.$router.push("/clients");
            } else {
                this.$eventHub.$emit("snackBar", {
                    color: "error",
                    message: "Erro ao editar cliente",
                });
            }

            this.isLoadingUpdate = false;
        },
    },

    async mounted() {
        const id = this.$route.params.id;
        this.id = id;
        const [states, client] = await Promise.all([
            this.getStates(),
            this.showClient(id),
        ]);
        this.optionsStates = states;

        if (client) {
            this.client = client;
            this.client.username = client.name;
            this.client.email = client.user ? client.user.email : null;
        } else {
            this.$eventHub.$emit("snackBar", {
                color: "error",
                message: "Erro ao encontrar cliente",
            });
        }
        this.isLoading = false;
    },
};
</script>
