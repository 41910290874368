<template>
    <div>
        <v-row no-gutters class="mt-10 mb-8">
            <BreadcrumbsComponent title="Trainamentos" :items="breadcrumbs" />
        </v-row>

        <form @keydown.enter="!isLoading && isValid && onSendInsert()">
            <v-row>
                <v-col cols="12" md="6">
                    <v-row>
                        <v-col cols="12" md="12">
                            <v-text-field
                                label="Título"
                                placeholder="Título"
                                outlined
                                v-model="trainings.title"
                            ></v-text-field>
                        </v-col>
                        
                        <v-col cols="12" md="12">
                            <vue-editor v-model="trainings.description" />
                        </v-col>

                        <v-col cols="12">
                            <span class="font-weight-regular">Anexos</span>
                            <DropzoneComponent
                                @onOver="onOver"
                                @onLeave="onLeave"
                                bgColor="#FFF"
                                :multiple="true"
                                v-if="trainings.files.length == 0"
                            >
                                <template v-slot:content>
                                    <div class="ma-4">
                                        <div class="text-center">
                                            <svg
                                                width="32"
                                                height="32"
                                                viewBox="0 0 32 32"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M30.6654 25.3333C30.6654 26.0406 30.3844 26.7189 29.8843 27.219C29.3842 27.719 28.7059 28 27.9987 28H3.9987C3.29145 28 2.61318 27.719 2.11308 27.219C1.61298 26.7189 1.33203 26.0406 1.33203 25.3333V10.6667C1.33203 9.95942 1.61298 9.28115 2.11308 8.78105C2.61318 8.28095 3.29145 8 3.9987 8H9.33203L11.9987 4H19.9987L22.6654 8H27.9987C28.7059 8 29.3842 8.28095 29.8843 8.78105C30.3844 9.28115 30.6654 9.95942 30.6654 10.6667V25.3333Z"
                                                    stroke="#828282"
                                                    stroke-width="2"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                />
                                                <path
                                                    d="M16.0013 22.6667C18.9468 22.6667 21.3346 20.2789 21.3346 17.3333C21.3346 14.3878 18.9468 12 16.0013 12C13.0558 12 10.668 14.3878 10.668 17.3333C10.668 20.2789 13.0558 22.6667 16.0013 22.6667Z"
                                                    stroke="#828282"
                                                    stroke-width="2"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                />
                                            </svg>
                                        </div>
                                        <h3
                                            class="mt-3 mb-3 dropzone-custom-title font-weight-regular text-center grey--text text--darken-1"
                                        >
                                            Arraste fotos e documentos, ou
                                            clique<br />
                                            aqui para fazer o upload dos
                                            arquivos necessários.
                                        </h3>
                                        <div
                                            class="subtitle-2 font-weight-regular text-center"
                                        >
                                            <i
                                            ><strong>Dica:</strong> arquivos
                                                aceitos são PDF, DOC, JPG,<br />
                                                PNG, XLS, MP4 e AVI.</i
                                            >
                                        </div>
                                    </div>
                                </template>
                            </DropzoneComponent>



                            <span class="d-block mt-5" v-else>
                                {{ trainings.files.length }} Arquivo selecionado(s)
                                <v-icon @click="trainings.files = []">close</v-icon>
                            </span>
                        </v-col>
                        

                        <v-col cols="12" md="12">
                            <v-btn
                                elevation="2"
                                large
                                block
                                class="white--text grey darken-3 elevation-0"
                                @click="onSendInsert"
                                :disabled="isLoading || !isValid"
                                :loading="isLoading"
                            >Cadastrar</v-btn
                            >
                        </v-col>

                        <v-col cols="12" md="12">
                            <v-btn
                                :disabled="isLoading"
                                large
                                block
                                text
                                @click="$router.push('/trainings')"
                            >Cancelar</v-btn
                            >
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </form>
    </div>
</template>

<script>
    import { mapActions } from "vuex";
    import BreadcrumbsComponent from "@/components/BreadcrumbsComponent/Index";
    import DropzoneComponent from '@/components/DropzoneComponent/Index'

    export default {
        components: {
            BreadcrumbsComponent,
            DropzoneComponent
        },
        data: () => {
            return {
                breadcrumbs: [
                    {
                        text: "Dashboard",
                    },
                    {
                        text: "Trainamentos",
                        disabled: true,
                    },
                    {
                        text: "Cadastro",
                        disabled: true,
                    },
                ],
                trainings: {
                    title: "",
                    description: "",
                    files: []
                },
                isLoading: false,
                optionsStates: [],
            };
        },
        methods: {
            ...mapActions(["insertTrainings"]),
            async onSendInsert() {
                this.isLoading = true;
                const resp = await this.insertTrainings(this.trainings);

                if (resp) {
                    this.$eventHub.$emit("snackBar", {
                        color: "success",
                        message: "Trainamento cadastrado com sucesso",
                    });
                    this.trainings = {
                        title: "",
                        description: "",
                        files: []
                    }
                    this.$router.push('/trainings')
                } else {
                    this.$eventHub.$emit("snackBar", {
                        color: "error",
                        message: "Erro ao cadastrar distribuidor",
                    });
                }
                this.isLoading = false;
            },

            onOver() {},

            async onLeave(file) {
                this.trainings.files = []
                for (let f of file) {
                    this.trainings.files.push(await this.toBase64(f))
                }
                
            },

            toBase64(file) {
                return new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = () => resolve(reader.result);
                    reader.onerror = (error) => reject(error);
                });
            },
            
            
        },
        computed: {
            isValid() {
                return !!(
                    ["title", "description"].every(
                        (key) => !!this.trainings[key]
                    )
                );
            },
        },
        async mounted() {
       
        },
    };
</script>
