import Http from '@/plugins/Http'

export default {
    async listProducts ({}, params ) 
    {
        try {
            const { data } = await Http.get('/product', { params })
            return data
        } catch (e) {
            return false            
        }
    },
    async insertProduct ({}, product) 
    {
        try {
            await Http.post('/product', product)
            return true
        } catch (e) {
            console.log(e.response.data);
            return false
        }
    },

    async updateProduct ({}, product)
    {
        try {
            await Http.put('/product/' + product.id, product)
            return true
        } catch (e) {
            console.log(e.response.data);
            return false
        }
    },

    async loadProduct ({}, id )
    {
        try {
            const { data } = await Http.get('/product/' +id )
            return data
        } catch (e) {
            return false
        }
    },
}
