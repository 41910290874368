<template>
    <v-row cols="12" style="background: #fafafa" class="pb-5">
        <v-col cols="12" lg="6">
            <h1 class="d-flex align-items-center mt-5 ml-lg-6 titles-simulator">
                <svg
                    width="40"
                    height="40"
                    style="margin-right: 10px"
                    viewBox="0 0 40 40"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M7.5 15.0004C7.4981 12.6713 8.14701 10.3879 9.37356 8.40778C10.6001 6.4277 12.3555 4.8297 14.4417 3.794C16.528 2.7583 18.8621 2.3261 21.1809 2.54615C23.4997 2.76619 25.7109 3.62973 27.5651 5.03937C29.4193 6.44901 30.8427 8.34867 31.6749 10.5241C32.5071 12.6996 32.7149 15.0643 32.2749 17.3515C31.835 19.6388 30.7647 21.7576 29.1847 23.4691C27.6048 25.1805 25.5782 26.4165 23.3333 27.0376V25.2975C25.893 24.4692 28.0529 22.7181 29.3924 20.3849C30.732 18.0518 31.1553 15.3036 30.5801 12.6755C30.0048 10.0474 28.4721 7.72736 26.2804 6.16715C24.0887 4.60694 21.3948 3.91819 18.7231 4.23499C16.0515 4.55179 13.5934 5.85147 11.8273 7.881C10.0612 9.91053 9.11362 12.5247 9.16896 15.2144C9.22431 17.9042 10.2786 20.4771 12.1267 22.4323C13.9748 24.3875 16.4843 25.5849 19.1667 25.7916V17.1131L14.5378 14.0271C14.4455 13.967 14.3661 13.8891 14.3041 13.798C14.2421 13.707 14.1987 13.6046 14.1766 13.4966C14.1544 13.3887 14.1539 13.2775 14.175 13.1694C14.1961 13.0613 14.2384 12.9584 14.2995 12.8668C14.3606 12.7751 14.4393 12.6965 14.531 12.6354C14.6226 12.5744 14.7255 12.5321 14.8337 12.511C14.9418 12.49 15.053 12.4906 15.1609 12.5128C15.2688 12.535 15.3712 12.5784 15.4622 12.6404L19.1667 15.11V10.0004C19.1667 9.77944 19.2545 9.56747 19.4107 9.41119C19.567 9.25491 19.779 9.16712 20 9.16712C20.221 9.16712 20.433 9.25491 20.5893 9.41119C20.7455 9.56747 20.8333 9.77944 20.8333 10.0004V18.4433L24.5378 15.9738C24.7217 15.8539 24.9454 15.8114 25.1605 15.8556C25.3755 15.8997 25.5645 16.027 25.6862 16.2096C25.808 16.3923 25.8528 16.6156 25.8108 16.8311C25.7689 17.0466 25.6436 17.2368 25.4622 17.3604L20.8333 20.4464V36.6671C20.8333 36.8881 20.7455 37.1001 20.5893 37.2564C20.433 37.4127 20.221 37.5004 20 37.5004C19.779 37.5004 19.567 37.4127 19.4107 37.2564C19.2545 37.1001 19.1667 36.8881 19.1667 36.6671V27.4582C16.0057 27.2457 13.0433 25.8414 10.8777 23.529C8.71218 21.2166 7.50498 18.1685 7.5 15.0004Z"
                        fill="#0A85FF"
                    />
                </svg>
                <span class="ml-5">Redução de CO2 por ano</span>
            </h1>

            <div class="ml-8 pl-6">
                <span class="orange--text font-weight-bold d-block ml-5 text-h5"
                    >{{
                        parseFloat(co2).toFixed(2).replace(".", ",")
                    }}
                    tonaledas</span
                >
                <span
                    class="ml-5 d-block mt-3 subtitle-1"
                    style="max-width: 448px"
                    >O seu sistema ajudará o planeta e evitará a emissão de
                    gases efeito estufa.
                </span>
            </div>
        </v-col>

        <v-col cols="12" lg="6" class="mt-5">
            <h1 class="d-flex align-items-center mt-5 ml-lg-6 titles-simulator">
                <svg
                    width="40"
                    height="40"
                    style="margin-right: 10px"
                    viewBox="0 0 40 40"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M14.4164 38.3327C9.97683 38.3095 5.942 36.1161 3.59158 32.4663C1.3783 29.0299 1.05368 24.8267 2.72305 21.2225C4.80527 16.7271 8.02943 13.0218 11.1474 9.4382C11.8528 8.62753 12.5584 7.81656 13.2513 6.99672C13.3613 6.86609 13.6969 6.46935 14.2341 6.46935C14.2347 6.46935 14.2353 6.46935 14.2359 6.46935C14.7676 6.47021 15.0882 6.85234 15.28 7.08065C15.9591 7.88904 16.6499 8.6894 17.3408 9.48948C19.9285 12.4867 22.6041 15.5862 24.6476 19.1268C25.8384 21.1899 26.9283 23.4586 26.8426 26.0665C26.621 32.8232 21.2787 38.0964 14.4164 38.3327ZM4.15257 21.8719C2.68514 25.0396 2.96405 28.596 4.91752 31.6293C6.97949 34.831 10.5219 36.7552 14.3933 36.7758C20.3865 36.569 25.0766 31.9429 25.2713 26.0155C25.344 23.7949 24.3585 21.7634 23.2826 19.8988C21.3126 16.4859 18.6859 13.4435 16.1457 10.5007C15.504 9.7576 14.8625 9.01453 14.2295 8.26458C13.6055 8.99878 12.9721 9.72638 12.3391 10.4543C9.29259 13.9559 6.14221 17.5768 4.15257 21.8719ZM14.2469 35.1599L14.1763 33.6047C18.1677 33.4274 21.3557 30.2901 21.757 26.1439L23.3225 26.2928C22.8457 31.2188 19.0287 34.9482 14.2469 35.1599ZM36.6638 14.5231C36.7208 12.7809 36.003 11.2836 35.219 9.92461C33.8953 7.63122 32.1704 5.63318 30.5022 3.70073C30.0598 3.18854 29.6175 2.67607 29.1829 2.15872C29.049 1.99917 28.7703 1.66716 28.2973 1.66602C28.2964 1.66602 28.2962 1.66602 28.2953 1.66602C27.9794 1.66602 27.6944 1.81354 27.4487 2.10458C27.0055 2.6288 26.5545 3.14729 26.1031 3.66607C24.09 5.97922 22.0084 8.37143 20.6561 11.291L22.0857 11.9404C23.3453 9.22107 25.2638 7.01648 27.2945 4.68242C27.627 4.30029 27.9597 3.91786 28.2915 3.53086C28.6277 3.92646 28.9674 4.31977 29.3067 4.71336C30.9275 6.59052 32.6032 8.53185 33.8537 10.6978C34.5231 11.8579 35.1365 13.118 35.0922 14.4733C34.9719 18.1262 32.0807 20.9767 28.367 21.1045L28.4217 22.6608C32.973 22.5039 36.5166 19.0059 36.6638 14.5231Z"
                        fill="#0A85FF"
                    />
                </svg>
                <span class="ml-5">Escassez hídrica</span>
            </h1>

            <div class="ml-8 pl-5">
                <span class="orange--text font-weight-bold d-block ml-5 text-h5"
                    >{{
                        water_saved === 0
                            ? 0
                            : water_saved
                                  .toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                    }}
                    m³</span
                >
                <span
                    class="ml-5 d-block mt-3 subtitle-1"
                    style="max-width: 448px"
                    >Você sabia que quanto maior o seu consumo de energia, maior
                    é o seu consumo de água?</span
                >
                <span
                    class="ml-5 d-block mt-3 subtitle-1"
                    style="max-width: 448px"
                >
                    A sua energia solar ajuda na economia de água dos
                    reservatórios das hidrelétricas.
                </span>
            </div>
        </v-col>
    </v-row>
</template>

<script>
export default {
    name: "ConsumptionView",

    props: {
        water_saved: {
            default: 0,
        },
        co2: {
            default: 0,
        },
    },
};
</script>
