<template>
    <v-card :style="{borderTop: `3px solid ${borderColor}` }" class="elevation-0 pt-3 pl-3 pr-3 d-inline-block" min-width="344">
        <h3 class="mb-3 subtitle-2 font-weight-medium">{{title}}</h3>
        <span @click="$emit('onClick', item)" :style="{color}" class="d-block subtitle-1" v-for="(item,i) in items" :key="i" style="cursor: pointer">{{item.name}}</span>
    </v-card>
</template>


<script>
    export default {
        name: 'DetailsPanelComponent',
        props: {
            title:{
                type: String,
                default:'MAIS INFORMAÇÕES'
            },
            color:{
                type: String,
                default:'#1E88E5'
            },
            borderColor:{
                type: String,
                default:'#1E88E5'
            },
            items:{
                type: Array,
                default(){
                    return [
                        {
                            name: 'Simulações',
                            data: 'simulations',
                        },
                        {
                            name: 'Falar com o consultor',
                            data: 'consultant',
                        },
                        {
                            name: 'Pedidos',
                            data: 'order',
                        },
                        {
                            name: 'Depoimentos',
                            data: 'depositions',
                        }
                    ]
                }
            }
        }
    }
</script>
