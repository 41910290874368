<template>
    <v-row no-gutters class="mt-10 mb-8">
        <v-col cols="12">
            <BreadcrumbsComponent title="Treinamento" :items="breadcrumbs">

                <template v-slot:actions>
                    <v-btn
                        elevation="2"
                        large
                        color="secondary"
                        class="white--text mt-2 ml-2 elevation-0"
                        @click="$router.push('/trainings/add')"
                    >
                        <v-icon class="mr-2">mdi-plus</v-icon>
                        Adicionar novo
                    </v-btn>
                </template>
            </BreadcrumbsComponent>

            <div class="mt-10">
                <ExpandComponent v-for="(n, i) in trainings" :key="i" @onExpand='handleExpand' v-bind="{
                    initOpen:false,
                    showBorder:false,
                    borderColor: 'grey',
                    totalAttachments: getCountFiles(i),
                    totalMessages: getCountVideos(i),
                    leftIcon:'mdi-youtube'
                }">
                    
                    
                    <template v-slot:header>
                        <span class="grey--text text--darken-2 text-h5">{{ n.title }}</span>
                    </template>

                    <template v-slot:content>
                        <span class="d-block grey--text subtitle-1 text--darken-3 mb-10" style="max-width:750px" v-html="n.description">
                            
                        </span>
                        <div class="mx-auto" style="max-width: 90%">
                            <vueper-slides class="no-shadow" v-bind="slidesProps">
                                <vueper-slide v-for="(slide, i) in n.slides" :key="i" :title="slide.title" :video="slide.video" :image="slide.image" :content="slide.content">
                                    <template #content v-if="slide.content">
                                        <div style="width: 100%; justify-content: center; align-items: center; display: flex; flex-direction: column">
                                            <div @click="onGetTrainingFile(slide.content)" style="width: 150px; height: 250px; background: #BDBDBD; border-radius: 8px; cursor: pointer; justify-content: center; align-items: center; display: flex; flex-direction: column">
                                                <img src="/images/icon-download.svg" v-if="!isLoadingDownload">

                                                <v-progress-circular
                                                    indeterminate
                                                    color="white"
                                                    v-else
                                                ></v-progress-circular>
                                            </div>
                                            <p style="margin-top: 30px; color: #BDBDBD; font-size: 14px">Clique para fazer o download do PDF</p>

                                        </div>
                                        
                                    </template>
                                </vueper-slide>
                            </vueper-slides>
                        </div>
                    </template>
                </ExpandComponent>
            </div>
        </v-col>
    </v-row>
</template>

<script>
    
    import { mapActions } from 'vuex'
    
    import BreadcrumbsComponent from "@/components/BreadcrumbsComponent/Index"
    import ExpandComponent from "@/components/ExpandComponent/Index"
    import SlidesComponent from "@/components/SlidesComponent/Index"

    import { VueperSlides, VueperSlide } from 'vueperslides'
    import 'vueperslides/dist/vueperslides.css'

    export default {
        components: {
            BreadcrumbsComponent,
            ExpandComponent,
            SlidesComponent,
            VueperSlides, 
            VueperSlide
        },
        data: () => {
            return {
                breadcrumbs: [
                    {
                        text: 'Home'
                    },
                    {
                        text: 'Treinamento',
                        disabled: true
                    },
                    {
                        text: 'Lista',
                        disabled: true
                    }
                ],
                expands:{
                  
                },
                slide:{
                    slidesProps:{
                        'arrows-outside': true,
                        'bullets-outside': true,
                        'transition-speed': 250
                    },
                    slides: []
                },

                trainings: [],
                videoExtension: ['mp4', 'avi'],
                fileExtension: ['pdf', 'xls', 'xlsx'],
                isLoadingDownload: false
            }
        },
        methods:{
            ...mapActions(['listTrainings', 'getTrainingFile']),
        
            handleExpand( e )
            {
                console.log( e )
            },

            getCountFiles (index) {
                let total = 0
                for (let item of this.trainings[index].files) {
                    if (!this.videoExtension.includes(item.file.split('.').pop())) {
                        total++
                    }
                }
                
                return total
            },
            
            getCountVideos (index) {
                let total = 0
                for (let item of this.trainings[index].files) {
                    if (this.videoExtension.includes(item.file.split('.').pop())) {
                        total++
                    }
                }

                return total
            },

            async onGetTrainingFile (item) {
                this.isLoadingDownload = true
                const r = await this.getTrainingFile(item.id);
                if (r) {
                    this.downloadBase64File('', r.file, r.name)
                } else {
                    this.$eventHub.$emit('snackBar', {color: 'error', message: 'Erro ao baixar arquivo'})
                }
                this.isLoadingDownload = false
            },

            downloadBase64File(contentType, base64Data, fileName) {
                const linkSource = `data:${contentType};base64,${base64Data}`;
                const downloadLink = document.createElement("a");
                downloadLink.href = linkSource;
                downloadLink.download = fileName;
                downloadLink.click();
            },
        },
        
        async mounted() {
           
           const r = await this.listTrainings()
            
            for (let i in r) {
                r[i].slides = []
                
                for (let iFiles in r[i].files) {
                    
                    if (this.videoExtension.includes(r[i].files[iFiles].file.split('.').pop())) {
                        r[i].slides.push({
                            video: {
                                url: r[i].files[iFiles].url,
                                props: {
                                    allow: 'accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
                                }
                            }
                        })

                    } else if (this.fileExtension.includes(r[i].files[iFiles].file.split('.').pop())) {
                        r[i].slides.push({
                            content: r[i]['files'][iFiles],
                        })
                    } else {
                        r[i].slides.push({
                            image: r[i].files[iFiles].url,
                        })
                    }
                    
                }
            }
            
            this.trainings = r
                
        }
    }
</script>

<style>
    .v-expansion-panel.rounded-lg.mb-2.expansion-panel.v-expansion-panel--active{
        border-left:1px solid #2D9CDB !important;
        border: 1px solid #2D9CDB !important;
        box-shadow: 0px 4px 2px rgba(0, 0, 0, 0.05) !important;
    }
    .vueperslides__arrow.vueperslides__arrow--prev, .vueperslides__arrow.vueperslides__arrow--next{
        background-color: #ccc;
        border-radius: 50%;
        width: 56px;
        height: 56px;
    }
    .vueperslides__arrow.vueperslides__arrow--prev svg, .vueperslides__arrow.vueperslides__arrow--next svg{
        width: 55px;
        height: 55px;
    }
    .vueperslides__arrow--prev {
        right: auto;
        left: -4em !important;
    }
    .vueperslides__arrow--next {
        left: auto;
        right: -4em !important;
    }
</style>