<template>
    <div>
        <v-row no-gutters class="mt-10 mb-8">
            <BreadcrumbsComponent title="Consultores" :items="breadcrumbs">
                <template v-slot:actions>
                    <v-btn :disabled="isLoading" elevation="2" large class="white--text grey darken-3 mt-2 ml-2 elevation-0" @click="$router.push('/consultants/edit/' + $route.params.id)">
                        <v-icon class="mr-2">mdi-square-edit-outline</v-icon>
                        Editar
                    </v-btn>

                    <v-btn 
                        :disabled="isLoading" 
                        elevation="2" 
                        large 
                        class="elevation-0 mt-2 ml-2"
                        @click="confirmationDialog = true"
                    >
                        <v-icon class="mr-2">mdi-trash-can-outline</v-icon>
                    </v-btn>
                </template>
            </BreadcrumbsComponent>
        </v-row>
        <v-row v-if="isLoading">
            <v-col
                md="12"
                class="text-center"
            >
                <v-progress-circular
                    indeterminate
                ></v-progress-circular>
            </v-col>
        </v-row>
        <v-row v-else>
            <template v-if="consultant && Object.keys(consultant).length > 0">
                <v-col cols="12" md="9">
                    <DataListComponent >
                        <template v-slot:item>
                            <template v-for="(item, index) in items">
                                <v-divider v-if="index == 0" :key="index"></v-divider>

                                <v-list-item :key="item.title">
                                    <v-col cols="12">
                                        <v-list-item-content class="d-block">
                                            <v-list-item-title :class="`text-caption text--darken-2 font-weight-medium ${item.class}`" v-text="item.title"></v-list-item-title>
                                            <v-list-item-subtitle :class="`text-body-2 text--darken-2 font-weight-regular ${item.class}`" v-text="item.value"></v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-col>
                                </v-list-item>

                                <v-divider v-if="index < items.length - 1" :key="item.value"></v-divider>
                            </template>
                        </template>
                    </DataListComponent>
                </v-col>


                <v-dialog v-model="confirmationDialog" transition="dialog-top-transition" max-width="600" persistent>
                    <v-card class="pa-5" v-if="consultant">
                        <v-card-text class="pt-4">
                            <h1 class="text-center mb-4">Deseja excluir este consultor?</h1>
                            <span class="d-block text-center mt-4 mb-5">Importante: essa ação não poderá ser desfeita.</span>

                            <div class="mt-5 mb-5">
                                <span class="d-block font-weight-medium">Consultor</span>
                                <v-divider></v-divider>
                                <div class="mb-3">
                                    <span class="d-block">{{ consultant.name }}</span>
                                    <span class="d-block">{{ consultant.user ? consultant.user.email : '-' }}</span>
                                </div>
                            </div>

                            <v-btn 
                                :disabled="isLoadingDelete" 
                                :loading="isLoadingDelete" 
                                elevation="2" 
                                large 
                                block 
                                text 
                                class="white--text grey darken-3 mt-5 elevation-0 mx-auto" 
                                @click="onDelete"
                            >
                                Sim, confirmo a exclusão
                            </v-btn>
                            <v-btn :disabled="isLoadingDelete" large block text class="mt-3" @click="confirmationDialog=false">Cancelar</v-btn>
                        </v-card-text>
                    </v-card>
                </v-dialog>
            </template>
            
            <template v-else>
                <v-alert border="left" color="red" type="error">Erro ao carregar consultor</v-alert>
            </template>
        </v-row>
    </div>
</template>


<script>
    import { mapActions } from 'vuex'
    import BreadcrumbsComponent from "@/components/BreadcrumbsComponent/Index"
    import DataListComponent from "@/components/DataListComponent/Index"

    export default {
        components: {
            BreadcrumbsComponent,
            DataListComponent
        },
        data: () => {
            return {
                confirmationDialog: false,
                color:'#1E88E5',
                borderColor:'#1E88E5',
                value:true,
                breadcrumbs: [
                    {
                        text: 'Dashboard'
                    },
                    {
                        text: 'Consultores',
                    },
                    {
                        text: 'Visualizar',
                        disabled: true
                    }
                ],
                items:[],
                consultant: null,
                id: null,
                isLoading: true,
                isLoadingDelete: false
            }
        },
        methods: {
            ...mapActions(['getConsultantById', 'removeConsultant']),
            async onDelete () {
                this.isLoadingDelete = true
                const resp = await this.removeConsultant( this.$route.params.id )
                if (resp) {
                    this.$eventHub.$emit('snackBar', {color: 'success', message: 'Apagado com sucesso'})
                    this.$router.push('/consultants')
                } else {
                    this.$eventHub.$emit('snackBar', {color: 'error', message: 'Erro ao apagar'})
                }
                this.isLoadingDelete = false
            }
        },
        async mounted() 
        {
            this.isLoading = true
            this.consultant = await this.getConsultantById( this.$route.params.id )

            this.items = [
                {
                    title: 'ATUALIZADO EM',
                    class:'grey--text',
                    value: this.consultant.updated_at
                },
                {
                    title: 'NOME',
                    class:'grey--text',
                    value: this.consultant.name
                },
                {
                    title: 'E-MAIL',
                    class:'grey--text',
                    value: this.consultant.user.email
                },
                {
                    title: 'DDD CELULAR',
                    class:'grey--text',
                    value: this.consultant.phone
                }
            ]

            this.isLoading = false
        }
    }
</script>