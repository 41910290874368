<template>
    <div>
        <v-row no-gutters class="mt-10 mb-8">
            <BreadcrumbsComponent title="Instalador" :items="breadcrumbs">
                <template v-slot:actions>
                    <v-btn elevation="2" large class="white--text grey darken-3 mt-2 ml-2 elevation-0" @click="$router.push(`/installers/view/${$route.params.id}`)">
                        <v-icon class="mr-2">mdi-arrow-left</v-icon>
                        Voltar
                    </v-btn>
                </template>
            </BreadcrumbsComponent>
        </v-row>

        <v-data-table
            :headers="headers"
            :items="items"
            hide-default-footer
            :items-per-page="-1"
        >
            <template v-slot:item.id="{ item }">
                <span class="blue--text">{{item.id}}</span>
            </template>

            <template v-slot:item.updatedat="{ item }">
                {{__dayjs(item.updatedat).format('DD/MM/YYYY H:mm:ss')}}
            </template>

            <template v-slot:item.city="{ item }">
                <v-chip v-if="!item.city" color="grey lighten-3 grey--text" dark>-</v-chip>
                <span v-else>{{item.city.name}}</span>
            </template>

            <template v-slot:item.client="{ item }">
                <v-chip v-if="!item.client" color="grey lighten-3 grey--text" dark>Anônimo</v-chip>
                <span v-else>{{item.client.name}}</span>
            </template>
        </v-data-table>
    </div>
</template>

<script>
    import dayjs from 'dayjs'
    import { mapActions } from 'vuex'
    import BreadcrumbsComponent from "@/components/BreadcrumbsComponent/Index"
    
    export default {
        components: {
            BreadcrumbsComponent,
        },
        data: () => {
            return {
                items:[],
                breadcrumbs: [
                    {
                        text: 'Dashboard'
                    },
                    {
                        text: 'Instaladores',
                        disabled: true
                    },
                    {
                        text: 'Simulações',
                        disabled: true
                    }
                ],
                headers: [
                    {
                        text: 'ATUALIZADO EM',
                        align: 'start',
                        sortable: false,
                        value: 'updatedat',
                    },
                    {
                        text: 'ID',
                        align: 'start',
                        sortable: false,
                        value: 'id',
                    },
                    {
                        text: 'TIPO',
                        align: 'start',
                        sortable: false,
                        value: 'type',
                    },
                    {
                        text: 'ORÇAMENTO',
                        align: 'start',
                        sortable: false,
                        value: 'budget',
                    },
                    {
                        text: 'CIDADE',
                        align: 'start',
                        sortable: false,
                        value: 'city',
                    },
                    {
                        text: 'UF',
                        align: 'start',
                        sortable: false,
                        value: 'uf',
                    },
                    {
                        text: 'CLIENTE',
                        align: 'start',
                        sortable: false,
                        value: 'client',
                    },
                    {
                        text: '',
                        align: 'start',
                        sortable: false,
                        value: 'action'
                    }
                ]
            }
        },
        async mounted()
        {
            try {
                const {data} = await this.listSimulations( `&simulator_id=${this.$route.params.id}` )
                this.items = data
            } catch (error) {
                this.$eventHub.$emit('snackBar', {color: 'error', message: 'Erro ao listar simulações'})
            }
        },
        methods: {
            ...mapActions(['listSimulations']),
            __dayjs (date) 
            {
                return dayjs(date)
            }
        }
    }
</script>