import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework'

import 'vuetify/dist/vuetify.min.css'
import 'material-design-icons/iconfont/material-icons.css'

export const options = {
    icons: {
        iconfont: 'mdi',
    },
    theme: {
        themes: {
            light: {
                primary: '#0066CC',
                secondary: '#FFA900'
            },
        },
    },
}

Vue.use(Vuetify)

export default new Vuetify(options)
