<template>
    <div>
        <v-dialog v-model="dialog" persistent max-width="290">
            <v-card>
                <v-card-title class="text-h5">
                    Deseja remover esta marca ?
                </v-card-title>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click="dialog = false">
                        Cancelar
                    </v-btn>

                    <v-btn color="green darken-1" text  @click="removeBrand">
                        Remover
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-row no-gutters class="mt-10 mb-8">
            <BreadcrumbsComponent title="Marcas" :items="breadcrumbs" />
        </v-row>
        <v-row>
            <v-col cols="12" md="3">
                <CardComponent>
                    <template v-slot:content>
                        <div class="grey--text text--darken-2 subtitle-1 mt-5 mb-5 pt-5 pb-5" @click="editing=null;showAdd()">
                            <svg class="d-block mx-auto" width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M32 58.6667C46.7276 58.6667 58.6667 46.7276 58.6667 32C58.6667 17.2724 46.7276 5.33337 32 5.33337C17.2724 5.33337 5.33333 17.2724 5.33333 32C5.33333 46.7276 17.2724 58.6667 32 58.6667Z" stroke="#828282" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/> <path d="M32 21.3334V42.6667" stroke="#828282" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/> <path d="M21.3333 32H42.6667" stroke="#828282" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/> </svg>
                            <span class="d-block text-center text-h5 mt-5 mb-5 font-weight-light">Clique aqui para<br> adicionar uma<br> nova marca</span>
                        </div>
                    </template>
                </CardComponent>
            </v-col>
        
            <v-col cols="12" md="3" v-for="(ob, index) in brands" :key="index">
                <CardComponent :image='ob.url'>
                    <template v-slot:content>
                        <span class="grey--text text--darken-2 subtitle-1 d-block">{{ ob.trading_name }}</span>
                       
                    </template>
        
                    <template v-slot:actionsfloat>
                        <v-btn class="white" depressed elevation="2" icon @click="editing=index;brand=brands[index];dialogInsere=true">
                            <v-icon>mdi-square-edit-outline</v-icon>
                        </v-btn>
        
                        <v-btn class="white ml-3" depressed elevation="2" icon @click="dialog=true;removeDialog=index">
                            <v-icon>mdi-trash-can-outline</v-icon>
                        </v-btn>
                    </template>
                </CardComponent>
            </v-col>

            <v-dialog transition="dialog-bottom-transition" max-width="450" v-model="dialogInsere">
                <template>
                    <v-card>
                        <v-card-title class="text-h5">
                            <span v-if="editing !== null">Editar marca</span>
                            <span v-else>Adicionar marca</span>
                        </v-card-title>

                        <v-card-text>
                            <DropzoneComponent @onLeave="onLeavePhoto" bgColor='transparent' :noBorder="true">

                                <template v-slot:content>
                                    <v-card class="mx-auto textCardObra">
                                        
                                        <template v-if="editing">
                                            <div class="imageBgIcon" v-if="!brand.url && !brand.photo">
                                                <v-icon color="#fff" size="30">mdi-camera</v-icon>
                                            </div>
                                            
                                            <v-img v-else height="171" :src="brand.photo ? brand.photo : brand.url"></v-img>
                                        </template>
                                        
                                        <template v-else>
                                            <div class="imageBgIcon" v-if="!brand.photo">
                                                <v-icon color="#fff" size="30">mdi-camera</v-icon>
                                            </div>

                                            <v-img v-else height="171" :src="brand.photo"></v-img>
                                        </template>
                                       

                                        <v-card-text>
                                            <h3 class="mt-5 mb-3 dropzone-custom-title font-weight-light grey--text text--darken-1">Arraste a foto aqui ou clique para fazer o upload da foto da obra</h3>
                                            <div class="caption text-center mt-5"><i><strong>Dica:</strong> use um arquivo PNG, <br/> quadrado, com 256x256 pixels</i></div>
                                        </v-card-text>
                                    </v-card>
                                </template>
                            </DropzoneComponent>

                            <v-col
                                cols="12"
                                md="12"
                            >
                                <v-text-field
                                    label="Nome"
                                    placeholder="Nome"
                                    outlined
                                    v-model="brand.trading_name"
                                ></v-text-field>
                            </v-col>


                            <v-col cols="12" md="12">
                                <v-select
                                    :items="products"
                                    item-value="id"
                                    item-text="name"
                                    label="Produto"
                                    placeholder="Produto"
                                    outlined
                                    v-model="brand.products"
                                    multiple
                                ></v-select>
                            </v-col>

                            <v-col cols="12" md="12">
                                <v-btn 
                                    v-if="editing !== null" 
                                    elevation="2" 
                                    large 
                                    block 
                                    class="white--text grey darken-3 elevation-0" 
                                    @click="onEdit(editing)"
                                    :loading="isLoadingInsere"
                                    :disabled="isLoadingInsere"
                                >Atualizar
                                </v-btn>
                                <v-btn 
                                    v-else 
                                    elevation="2" 
                                    large 
                                    block 
                                    class="white--text grey darken-3 elevation-0" 
                                    @click="onAddBrand"
                                    :loading="isLoadingInsere"
                                    :disabled="isLoadingInsere"
                                >Adicionar</v-btn>
                            </v-col>

                            <v-col cols="12" md="12">
                                <v-btn :disabled="isLoadingInsere" large block text  @click="dialogInsere=false">Cancelar</v-btn>
                            </v-col>
                        </v-card-text>
                    </v-card>
                </template>
            </v-dialog>
        </v-row>
    </div>
</template>


<script>
    import { mapActions } from 'vuex'
    
    import BreadcrumbsComponent from "@/components/BreadcrumbsComponent/Index"
    import DropzoneComponent from '@/components/DropzoneComponent/Index'
    import CardComponent from "@/components/CardComponent/Index"
    import dayjs from "dayjs";
    
    
    export default {
        components: {
            BreadcrumbsComponent,
            DropzoneComponent,
            CardComponent
        },
        data: () => {
            return {
                dialog:false,
                removeDialog:null,
                dialogInsere: false,
                brands: [],
                editing: null,
                breadcrumbs: [
                    {
                        text: 'Dashboard'
                    },
                    {
                        text: 'Marcas',
                    },
                    {
                        text: 'Lista',
                    }
                ],
                filters: {},

                brand: {
                    photo: null,
                    trading_name: '',
                    products: []
                },
                isLoadingInsere: false,
                brandDelete: null,
                confirmationDialogDelete: false,
                products: []
            }
        },
        
        mounted() {
            this.load()
        },


        methods: {
            ...mapActions(['listBrands', 'insertBrand', 'updatetBrand', 'deleteBrand', 'listProducts']),
            async removeBrand()
            {
                try {
                    await this.deleteBrand( this.brands[ this.removeDialog ].id )
                    this.brandDelete = null
                    this.confirmationDialogDelete = false
                    this.dialog = false
                    this.load()
                } catch (error) {
                    this.$eventHub.$emit('snackBar', {color: 'error', message: 'Erro ao remover marca'})
                }
            },
            cleanBrand () {
                this.brand = {
                    photo: null,
                    trading_name: '',
                    products: []
                }
            },
            
            async load (page = 1, csv = false)
            {
                this.isLoading = true
                const filter = {...this.filter}
                filter.page = page

                const resp = await this.listBrands(filter)

                const products = await this.listProducts({ csv: true })
                if (products) {
                    this.products = products   
                }
                
                if (resp) {
                    this.brands = resp
                }
            },

            async onAddBrand () {
                this.isLoadingInsere = true
                const r = await this.insertBrand(this.brand)
                
                if (r) {
                    this.$eventHub.$emit('snackBar', {color: 'success', message: 'Marca inserida com sucesso'})
                    this.load()
                    this.cleanBrand()
                    this.dialogInsere = false
                } else {
                    this.$eventHub.$emit('snackBar', {color: 'error', message: 'Erro ao inserir marca'})
                }
                
                this.isLoadingInsere = false
            },

            async onEdit (index) {
                this.isLoadingInsere = true
                const r = await this.updatetBrand(this.brand)

                if (r) {
                    this.$eventHub.$emit('snackBar', {color: 'success', message: 'Marca editada com sucesso'})
                    this.load()
                    this.cleanBrand()
                    this.dialogInsere = false
                } else {
                    this.$eventHub.$emit('snackBar', {color: 'error', message: 'Erro ao editar marca'})
                }

                this.isLoadingInsere = false
            },

            onDelete (item) {
                this.brandDelete = item
                this.confirmationDialogDelete = true
            },

            __dayjs (date)
            {
                return dayjs(date)
            },

            showAdd() {
                this.cleanBrand()
                this.dialogInsere = true
            },

            async onLeavePhoto(file) {
                this.brand.photo = await this.toBase64(file)
            },
            toBase64(file) {
                return new Promise((resolve, reject) => {
                    const reader = new FileReader()
                    reader.readAsDataURL(file)
                    reader.onload = () => resolve(reader.result)
                    reader.onerror = error => reject(error)
                })
            },
        }
    }
</script>