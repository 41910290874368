import Http from '@/plugins/Http'

export default {
    async listProductsTypes ({}, params = {}) 
    {
        try {
            const { data } = await Http.get('/product-types', { params })
            return data
        } catch (e) {
            return false            
        }
    },
    async insertProductType ({}, product) 
    {
        try {
            await Http.post('/product-types', product)
            return true
        } catch (e) {
            console.log(e.response.data);
            return false
        }
    }
}
